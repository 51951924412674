import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";

export function UseLanguage(selectedLanguage) {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    if (selectedLanguage) {
      setLoading(true);
      getLanguage(selectedLanguage.toLowerCase())
        .then((languageRes) => {
          setLanguage(languageRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [selectedLanguage]);
  return {
    languageObject: {
      data: language,
      loading: loading,
      get: getLanguage,
      set: setLanguage,
    },
  };
}

// Get all Localisations terms
export async function getLanguage(selectedLanguage) {
  const url = api(selectedLanguage);
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Localisations Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = await response.json();
    console.log("Error", response);
    response = [];
    console.log(erMessage);
  }

  return response;
}

export function api(languageCode) {
  // EXAMPLE DEV ENDPOINT: https://arcadis-i18n-api-dev.azurewebsites.net/api/v1.0/2488061d-1876-4c40-b85c-0f3ea10aff54/en/namespaces/common/?code=EjmzCv2V4mM9PmRf2zWSnWfLaZ7dQmj4W2HQgeUNT57htPh25bxT
  // EXAMPLE PRD ENDPOINT: https://arcadis-i18n-api.azurewebsites.net/api/v1.0/2488061d-1876-4c40-b85c-0f3ea10aff54/en/namespaces/common/?code=EjmzCv2V4mM9PmRf2zWSnWfLaZ7dQmj4W2HQgeUNT57htPh25bxT

  const solutionId = "2488061d-1876-4c40-b85c-0f3ea10aff54";
  const code = "EjmzCv2V4mM9PmRf2zWSnWfLaZ7dQmj4W2HQgeUNT57htPh25bxT";
  const host = window.location.hostname;
  const api_dev = `https://arcadis-i18n-api-dev.azurewebsites.net/api/v1.0/${solutionId}/${languageCode}/namespaces/common/?code=${code}`;
  const api_prod = `https://arcadis-i18n-api.azurewebsites.net/api/v1.0/${solutionId}/${languageCode}/namespaces/common/?code=${code}`;

  // Local
  if (host.includes("localhost")) {
    return api_dev;
  }

  // Dev Deployment
  if (host.includes("dev")) {
    return api_dev;
  }

  // Test Deployment
  if (host.includes("test")) {
    return api_dev;
  }

  // Production Deployment
  if (host.includes("www")) {
    return api_prod;
  }
}
