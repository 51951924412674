import { pcAPIs } from "../api-endpoints/PostContractEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { processTime } from "../../components/post-contract/PostContract.functions";

export async function getProjectPostContract(projectIDs, abortSignal) {
  const url = pcAPIs().get_pc_configs;
  const payload = JSON.stringify({
    ProjectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectPostContract Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function addProjectPostContractData(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectPostContract(projectIDs, abortSignal);

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};

    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.project_id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}

export async function addProjectsPostContract(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectsPC(projectIDs, abortSignal);

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};
    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      // We need to process the time of each set of data
      processTime(newData.postContract.data.data, project);
      processTime(newData.postContract.data.costReports, project);
      processTime(newData.postContract.data.progressClaims, project);
      processTime(newData.postContract.data.variations, project);

      let latestCostReport = {};

      // Find the last issued Cost Report
      newData.postContract.data.costReports.forEach((CR) => {
        if (CR.cost_report_issued) {
          latestCostReport = CR;
        }
      });

      // Attach latest Cost Plan and Cost Report
      newData.latest_cost_report = latestCostReport;

      const combinedProject = {
        ...project,
        ...newData,
      };

      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });

  return updatedProjects;
}

export async function getProjectsPC(projectIDs, abortSignal) {
  const url = pcAPIs().get_projects_post_contract;

  const payload = JSON.stringify({
    ProjectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectsPC Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function getProjectCostReportStats(projectIDs, abortSignal) {
  const url = pcAPIs().get_cost_report_stats;
  const payload = JSON.stringify({
    ProjectIDs: projectIDs,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    signal: abortSignal,
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (err) {
    if (err.name !== "AbortError") {
      console.log("getProjectCostReportStats Error - ", err.name);
    }
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
  }

  return response;
}

export async function addProjectCostReportStats(projects, abortSignal) {
  const projectIDs = projects.map((p) => p.id);
  const newProjectData = await getProjectCostReportStats(
    projectIDs,
    abortSignal
  );

  const updatedProjects = [];
  projects.forEach((project) => {
    let match = false;
    let newData = {};

    // Try and find a match for this project
    newProjectData.forEach((data) => {
      if (project.id.toString() === data.project_id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the project with the data
    if (match) {
      const combinedProject = {
        ...project,
        ...newData,
      };
      updatedProjects.push(combinedProject);
    } else {
      // If there is no match simply return the plain project
      updatedProjects.push(project);
    }
  });
  return updatedProjects;
}
