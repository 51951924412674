import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import isEmpty from "../../validation/is-empty";
import { VALUE_ENGINEERING } from "./ValueEngineering.strings";
import { tranStr } from "../../utils/translation";

const getConfig = (payload) => {
  return {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };
};

export async function saveValueEngineeringItems({ data, veItems }) {
  const { project_id, cost_plan_id, estimate_id } = data;

  let response = { ok: false };
  let error = false;
  let errorMessage = {
    text: "",
    type: "",
  };

  if (isEmpty(veItems)) {
    error = true;
  }

  veItems.forEach((row) => {
    if (!row.flag_marked_for_deletion) {
      if (isEmpty(row.title) || isEmpty(row.value) || isEmpty(row.wbs_code)) {
        error = true;
      }

      if (isEmpty(row.status)) {
        row.status = VALUE_ENGINEERING.STATUS.FORREVIEW;
      }
    }
  });

  if (error) {
    errorMessage = {
      text: VALUE_ENGINEERING.WARNINGS.VM_ITEMS_SAVE_VALIDATION,
      type: "feedback-error",
    };

    return { response, errorMessage };
  }

  const payload = {
    project_id,
    cost_plan_id,
    estimate_id,
    value_engineering_items: veItems.filter(
      (ve) => !ve.flag_marked_for_deletion
    ),
  };

  const config = getConfig(payload);

  const url = cpAPIs().save_value_engineering;

  response = await fetch(url, config);

  return { response, errorMessage };
}

export async function saveValueEngineeringItemsStatus(
  data,
  veItemsStatusChanged
) {
  const { project_id, cost_plan_id, estimate_id } = data;

  const payload = {
    project_id,
    cost_plan_id,
    estimate_id,
    value_engineering_items: veItemsStatusChanged,
  };

  const config = getConfig(payload);

  const url = cpAPIs().save_value_engineering_items_status;

  const response = await fetch(url, config);

  return response;
}

export async function uploadValueEngineering(data) {
  if (data.file === null) {
    return data.setError({
      text: tranStr("Please attach file"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  let field = JSON.stringify({
    project_id: data.projectID,
    cost_plan_id: data.CP.cost_plan_id,
    estimate_id: data.estimate,
  });
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // COST PLAN API
  const url = cpAPIs().upload_value_engineering;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    data.CPs.set(await data.CPs.get(data.project.id, data.project));
    return data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
  } else {
    // Response Not OK
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}
