import React from "react";

import EstimateCommentaryToggle from "./EstimateCommentaryToggle";
import EstimateCommentaryText from "./EstimateCommentaryText";
import EstimateCommentaryTableEditor from "./EstimateCommentaryTableEditor";
import "./EstimateCommentary.css";
import { translate } from "../../utils/translation";

export default function EstimateCommentary(props) {
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { commentNumber } = props;
  const { ectBasis, setBasis } = props;
  const { modal, setModal } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;

  return (
    <div className="estimate-commentary">
      <div className="commentary-title-flexbox">
        <label className="label-title-toggle">
          {translate("Select Type")}:
        </label>
        <EstimateCommentaryToggle ectBasis={ectBasis} setBasis={setBasis} />
      </div>
      <EstimateCommentaryText
        CPs={CPs}
        project_id={project.id}
        commentNumber={commentNumber}
        project={project}
        ectBasis={ectBasis}
        CP={CP}
        show={modal}
        setShow={setModal}
        URLs={URLs}
        CostId={CostId}
        Module={Module}
      />
      <EstimateCommentaryTableEditor
        CPs={CPs}
        project_id={project.id}
        commentNumber={commentNumber}
        project={project}
        ectBasis={ectBasis}
        CP={CP}
        show={modal}
        setShow={setModal}
        URLs={URLs}
        CostId={CostId}
        Module={Module}
      />
    </div>
  );
}

export function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
