// MSAL
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export async function getDmsToken() {
  // GET A DMS TOKEN
  const myMSALObj = new Msal.PublicClientApplication(msalConfig());
  const account = myMSALObj.getAllAccounts()[0];

  const REACT_APP_SCOPE_DMS = detectDeployment();
  const accessTokenRequestForDMS = {
    scopes: [`openid ` + REACT_APP_SCOPE_DMS],
    account: account,
  };

  let dmsToken = {};

  try {
    dmsToken = await myMSALObj.acquireTokenSilent(accessTokenRequestForDMS);
  } catch (e) {
    console.log(e.message);
  }

  return dmsToken;
}

export function detectDeployment() {
  // Current Host
  const host = window.location.hostname;

  // Hosts
  const local = "https://ArcadisB2CDEV.onmicrosoft.com/GlobalDMSAPI/Caller";
  const dev = "https://ArcadisB2CDEV.onmicrosoft.com/GlobalDMSAPI/Caller";
  const tst = "https://ArcadisB2C.onmicrosoft.com/GlobalDMSAPI/Caller";
  const prd = "https://ArcadisB2C.onmicrosoft.com/GlobalDMSAPI/Caller";

  // Local
  if (host.includes("localhost")) {
    return local;
  }

  // Dev Deployment
  if (host.includes("dev")) {
    return dev;
  }

  // Test Deployment
  if (host.includes("test")) {
    return tst;
  }

  // Production Deployment
  if (host.includes("www")) {
    return prd;
  }
}
