import React from "react";
import { translate } from "../../utils/translation";

import isEmpty from "../../validation/is-empty";

import "./DecisionsAdvice.css";

export default function DecisionsAdvice(props) {
  const { decisions } = props;
  const { type } = props;
  const { column1, column2 } = props;
  const { isGlossary, isDecision } = props;

  let displayedData = [];

  if (!isEmpty(decisions)) {
    displayedData = decisions.filter((x) => x.type === type);
  }

  if (isEmpty(displayedData)) {
    return null;
  }

  if (isGlossary) {
    return (
      <div className="decisions-advice-container">
        <div className="cost-report-comparison-chart-hint">
          {translate(
            "The following terms used within this report align to the corresponding terms identified for use on this project and/or in the underlying contract."
          )}
        </div>
        <div className="decisions-advice-display-area">
          <div className="decisions-advice-table">
            <div className="decisions-advice-row">
              <div className={column1}>
                <b>{translate("Reporting Term")}</b>
              </div>
              <div className={column2}>
                <b>{translate("Project Specific or Contractual Term")}</b>
              </div>
            </div>
            {displayedData.map((d, i) => {
              return (
                <div className="decisions-advice-row" key={i}>
                  <div className={column1}>{d.decision}</div>
                  <div className={column2}>{d.required_by}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (isDecision) {
    return (
      <div className="decisions-advice-container">
        <div className="decisions-advice-display-area">
          <div className="decisions-advice-table">
            {displayedData.map((d, i) => {
              return (
                <div className="decisions-advice-row" key={i}>
                  <div className="decisions-advice-bullet" />
                  <div className={column1}>{d.decision}</div>
                  <div className={column2}>{d.required_by}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
