import React from "react";
import isEmpty from "../../validation/is-empty";

import ValueEngineeringMiniTable from "../value-engineering/ValueEngineeringMiniTable";

import "./PDCPValueEngineeringItemsList.css";

export default function PDCPValueEngineeringItemsList(props) {
  const { CP } = props;

  if (isEmpty(CP) || isEmpty(CP.estimates) || isEmpty(CP.value_engineerings)) {
    return null;
  }

  const veItems = gatherValueEngineeringItemsList(CP);

  return (
    <div id="pdcp-value-engineering-items-list">
      <div className="ve-items-list-table">
        <ValueEngineeringMiniTable veItems={veItems} />
      </div>
    </div>
  );
}

export function gatherValueEngineeringItemsList({
  estimates,
  value_engineerings,
}) {
  const valueEngineeringItemsList = [];
  for (const value_engineering of value_engineerings) {
    const estimate = estimates.find(
      (e) => e.id === value_engineering.estimate_id
    )?.name;

    // group by estimate name
    valueEngineeringItemsList.push({
      name: estimate,
      veItems: [...value_engineering.value_engineering_items],
    });
  }
  return valueEngineeringItemsList;
}
