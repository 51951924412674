import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { contactUsApis } from "../api-endpoints/FormDataEndpoints";
export async function getDemos() {
  const url = contactUsApis().get_demos;
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Demos Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function useDemos() {
  const [loading, setLoading] = useState(true);
  const [demos, setDemos] = useState({});
  useEffect(() => {
    setLoading(true);
    getDemos()
      .then((demosRes) => {
        setDemos(demosRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    demos: {
      data: demos,
      loading: loading,
      get: getDemos,
      set: setDemos,
    },
  };
}
