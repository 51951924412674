import React from "react";
import { useBenchmarkWithRows } from "../../api/benchmarking/BenchmarkingAPI";
import PropTypes from "prop-types";

import "./BenchmarkView.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BenchmarkProjectInfoView from "./BenchmarkProjectInfoView";
import BenchmarkNotesView from "./BenchmarkNotesView";
import { BenchmarkElementAnalysisView } from "./BenchmarkElementAnalysisView";
import BenchmarkMetricsView from "./BenchmarkMetricsView";
import isEmpty from "../../validation/is-empty";

BenchmarkView.propTypes = {
  selectedRow: PropTypes.object,
};

export default function BenchmarkView(props) {
  const { selectedRow } = props;
  const tabNames = ["projectInfo", "elementAnalysis", "benchmarks", "notes"];

  const { benchmarkWithRows } = useBenchmarkWithRows(selectedRow.id);
  const [tabValue, setTabValue] = React.useState(tabNames[0]);

  let rowData = {};

  if (!isEmpty(benchmarkWithRows)) {
    rowData = benchmarkWithRows.data[0];
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className="bm-full-width-box-container">
      <Box className="bm-tab-box-container">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          aria-label="Benchmark view tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--heritage)",
            },
          }}
        >
          <Tab
            label="Project Information"
            value={tabNames[0]}
            style={{ outline: "none" }}
          />
          <Tab
            label="Element Analysis"
            value={tabNames[1]}
            style={{ outline: "none" }}
          />
          <Tab
            label="Benchmarks"
            value={tabNames[2]}
            style={{ outline: "none" }}
          />
          <Tab label="Notes" value={tabNames[3]} style={{ outline: "none" }} />
        </Tabs>
      </Box>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[0]}>
        <BenchmarkProjectInfoView rowData={rowData} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[1]}>
        <BenchmarkElementAnalysisView rowData={rowData} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[2]}>
        <BenchmarkMetricsView rowData={rowData?.metrics} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[3]}>
        <BenchmarkNotesView rowData={rowData} />
      </BenchmarkCustomTabPanel>
    </Box>
  );
}

function BenchmarkCustomTabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className="tabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`bm-tabpanel-${index}`}
      aria-labelledby={`bm-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
