// React
import React from "react";
export default function CarbonCost(props) {
  const { project } = props;

  return (
    <div className="alc-column">
      <div>
        <div className="toggle-button-text-container">
          <p></p>
          <p></p>
          <p>
            Arcadis is committed to helping the transition to a 1.5°C world, and
            to help the teams we work with to reduce Greenhouse Gas emissions in
            support of this target.
          </p>
          <p>
            As well as providing advice on the Construction cost of your asset,
            Arcadis can also estimate the amount of carbon dioxide equivalent
            created in the construction
            <br /> of your asset, and support you in identifying ways to reduce
            the carbon dioxide equivalent generated - or Carbon Cost - of your
            project.
          </p>
          <p>
            For more information on the Carbon Costing, please get in touch with
            your
            <a
              href={
                window.location.origin +
                "/project/" +
                project.id +
                "/project-team/"
              }
            >
              {" "}
              Arcadis contact.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
