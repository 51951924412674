import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Table from "../table/Table";
import Modal from "../modal/Modal";

import AccountProfile from "./AccountProfile";
import Spinner from "../common/Spinner";

import { translate, tranStr } from "../../utils/translation";
import { columns } from "./AccountManagementTable.functions";
import { displayUserImage } from "../../validation/image-clean";

AccountManagement.propTypes = {
  accounts: PropTypes.object,
  projects: PropTypes.object,
};

export default function AccountManagement(props) {
  const { accounts } = props;
  const { projects } = props;

  const [selectedAccount, setSelectedAccount] = useState({});
  const [modal, setModal] = useState(false);

  if (accounts.loading) {
    return (
      <div className="project-management-content">
        <Spinner marginLeft={"625px"} marginTop={"200px"} />
      </div>
    );
  }

  const users = accounts.data.map((u) => {
    u.image = displayUserImage(u);
    return u;
  });

  return (
    <div className="company-management-content">
      <Table
        title={tranStr("Accounts")}
        tableArray={users}
        columns={columns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Row Selection
        selectedRow={selectedAccount}
        setSelectedRow={setSelectedAccount}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={AccountProfile}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        account={selectedAccount}
        accounts={accounts}
        projects={projects}
      />
    </div>
  );
}
