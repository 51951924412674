import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { AutoCloseModal } from "../modal/Modal";

export function addSubtotal(index, selectionArray, setSelectionArray) {
  let temp = [...selectionArray];

  // Add the Subtotal to the array
  temp[index].subtotal_below = true;

  // Add grouping to array elements
  incrementSubtotalGroup(temp);

  setSelectionArray(temp);
}

export function removeSubtotal(index, selectionArray, setSelectionArray) {
  let temp = [...selectionArray];

  // Add the Subtotal to the array
  temp[index].subtotal_below = false;

  // Add grouping to array elements
  incrementSubtotalGroup(temp);

  setSelectionArray(temp);
}

function incrementSubtotalGroup(temp) {
  let subtotal_group = 1;
  temp.forEach((e) => {
    e.subtotal_group = subtotal_group;
    if (e.subtotal_below) {
      subtotal_group += 1;
    }
  });
}

export function selectCostPlan(estimates) {
  // based on the currently selected stage
  return estimates.map((e) => {
    return {
      id: e.id,
      name: e.name,
      project_id: e.project_id,
      subtotal_below: e.subtotal_below,
      subtotal_name: e.subtotal_name,
      subtotal_gfa_override: e.subtotal_gfa_override,
      total_name: e.total_name,
      total_gfa_override: e.total_gfa_override,
      is_included: e.is_included,
    };
  });
}

export async function saveSubtotals(data) {
  const {
    estimates,
    totalName,
    totalGFAOverride,
    subtotalNames,
    GFAOverrides,
    setError,
    setShow,
    CPs,
    projectID,
    project,
  } = data;

  estimates.forEach((estimate) => {
    // Only update the name if not null
    if (subtotalNames[estimate.id] !== null) {
      estimate.subtotal_name = subtotalNames[estimate.id];
    }
    // We want to be able to delete the GFA override with a blank input
    estimate.subtotal_gfa_override = GFAOverrides[estimate.id];
  });

  // COST PLAN API
  const url = cpAPIs().save_subtotals;
  const payload = JSON.stringify({
    estimates,
    totalName,
    totalGFAOverride,
    projectID,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  setError({
    text: "Saving...",
    type: "feedback-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK

    CPs.set(await CPs.get(projectID, project));

    setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    // close modal after timeout
    AutoCloseModal(setShow);
  } else {
    setError({
      text: "Save Failed",
      type: "feedback-error",
    });
    return console.log("Network response was not ok.");
  }
}
