import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import isEmpty from "../../validation/is-empty";

/// props validation | SQ(javascript:S6774)
MuiAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  defaultExpanded:PropTypes.bool
};
///
export function MuiAccordion(props) {
  const { title } = props;
  const { children } = props;
const{defaultExpanded}=props;
  return (
    <Accordion
      defaultExpanded={isEmpty(defaultExpanded) ? true : defaultExpanded}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        paddingBottom: "20px",
        margin: "0px",

        position: "unset",
        "&.Mui-expanded": {
          margin: "0px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              backgroundColor: "var(--fluidity3) ",
              color: "var(--integrity)",
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: "var(--fluidity3)",
          marginBottom: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          color: "var(--integrity)",
          height: "35px",
          minHeight: "35px",
          "&.Mui-expanded": {
            minHeight: "35px",
            marginTop: "0px",
          },
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "10px 0px",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
