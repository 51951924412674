import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { portAPIs } from "../api-endpoints/PortfolioEndpoints";
import isEmpty from "../../validation/is-empty";

export async function getDashboardSettings(portfolio) {
  if (isEmpty(portfolio.id)) {
    return [];
  }

  // PROJECT API
  const url = portAPIs().Get + "/" + portfolio.id;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    let port = await response.json();
    if (!isEmpty(port.dashboardSettings)) {
      const setting = {
        dashboard_type: "PORTFOLIO",
        layout: port.dashboardSettings,
      };
      response = [setting];
    } else {
      response = [];
    }
  } else {
    console.log("response", response);
    response = [];
    console.log("Network Error");
  }

  return response;
}

export function useDashboardSettings(portfolio) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (portfolio.id !== "all-projects") {
      setLoading(true);
      getDashboardSettings(portfolio)
        .then((dataRes) => {
          setData(dataRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [portfolio.id, portfolio]);

  return {
    dashboardSettings: {
      data: data,
      loading: loading,
      get: getDashboardSettings,
      set: setData,
      save: savePortfolioDashboardSettings,
    },
  };
}

export async function savePortfolioDashboardSettings(
  portfolio,
  dashboardType,
  layout,
  setError
) {
  // PROJECT API
  const url = portAPIs().Put;

  portfolio.dashboardSettings = JSON.parse(layout);

  const config = {
    method: "PUT",
    body: JSON.stringify(portfolio),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    setError({
      text: "Saved Successfully",
      type: "dashboard-editor-success",
    });
    response = await response.json();
  } else {
    setError({
      text: "Save Failed",
      type: "dashboard-editor-error",
    });
    response = [];
    console.log("Network Error");
  }

  return response;
}
