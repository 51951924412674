import React, { useState } from "react";

import { useDemos } from "../../api/admin/RequestDemoAPI";
import {
  contactedDemos,
  completedDemos,
  deleteDemos,
} from "./DemoManagement.functions";

import "./DemoManagement.css";

import Spinner from "../common/Spinner";
import Table from "../table/Table";
import { tranStr } from "../../utils/translation";

import { columns } from "./DemoManagementTable.functions";

export default function DemoManagement() {
  const { demos } = useDemos();
  const [selectedDemos, setSelectedDemos] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const [modal, setModal] = useState(false);

  if (demos.loading) {
    return (
      <div className="project-management-content">
        <Spinner marginLeft={"625px"} marginTop={"200px"} />
      </div>
    );
  }

  return (
    <div className="account-management-content">
      <Table
        title={tranStr("Feedback")}
        tableArray={demos.data}
        columns={columns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedDemos}
        setChecked={setSelectedDemos}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        modal={modal}
        setModal={setModal}
      />
      <div className="account-management-container">
        <CompanyControlPanel selectedDemos={selectedDemos} demos={demos} />
      </div>
    </div>
  );
}

function CompanyControlPanel(props) {
  const { selectedDemos } = props;
  const { demos } = props;

  return (
    <div className="project-management-control-box">
      <div className="display-4" style={{ fontSize: "25px" }}>
        Demo Controls
      </div>

      <div className="project-management-control-panel-content">
        <Button
          label={"Contacted"}
          selectedDemos={selectedDemos}
          demos={demos}
          onClick={contactedDemos}
        />
        <Button
          label={"Completed"}
          selectedDemos={selectedDemos}
          demos={demos}
          onClick={completedDemos}
        />
        <Button
          label={"Delete"}
          selectedDemos={selectedDemos}
          demos={demos}
          onClick={deleteDemos}
        />
      </div>
    </div>
  );
}

function Button(props) {
  const { selectedDemos } = props;
  const { demos } = props;
  const { onClick } = props;
  const { label } = props;

  if (selectedDemos.length < 1) {
    return null;
  }

  const data = {
    demoIDs: selectedDemos.map((c) => c.id),
    demos: demos,
  };

  return (
    <div className="company-management-control-panel-row">
      <div
        className="project-management-control-panel-delete-button"
        onClick={() => {
          onClick(data);
        }}
      >
        {label}
      </div>
    </div>
  );
}
