import React, { useState } from "react";

// Components
import Table from "../table/Table";
import PostContractPTConsultantFeesVariationsModal from "../post-contract-table-modals/PostContractPTConsultantFeesVariationsModal";
import Modal from "../modal/Modal";

// Functions
import { PTFCVColumns } from "../post-contract-tables/PostContractTable.functions";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

// Styles
import "./PostContractConsultantFeesVariations.css";

export default function PostContractConsultantFeesVariations(props) {
  const { PTCFVs } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  // No data available so return null
  return isEmpty(PTCFVs) ? null : (
    <div>
      <div className="pct-spacer" />
      <Table
        title={"Project Team Consultant Fees Variations"}
        tableArray={PTCFVs}
        columns={PTFCVColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractPTConsultantFeesVariationsModal}
        modal={modal}
        setModal={setModal}
        columns={PTFCVColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
