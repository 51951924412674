import React, { useState } from "react";
import { renderToString } from "react-dom/server";
import { translate } from "../../utils/translation";

import {
  mapOrder,
  findDraggedColumn,
  updateColumnsDashboardEditor,
} from "./Dashboard.functions";

import "./DashboardEditor.css";

export default function DashboardEditor(props) {
  const { display, column } = props;
  const { columns } = props;
  const { dragID } = props;
  const { project, dashboardType } = props;
  const { dashboardSettings } = props;
  const { setShow } = props;

  const [error, setError] = useState({});

  // Determine the content to be displayed
  let components = mapOrder(display, column, "dashboardID");

  return (
    <div className="dashboard-editor-container">
      <div className="dashboard-editor-title">
        <div className="dashboard-editor-close"></div>
        <h1 className="display-4">{translate("Dashboard Panel")}</h1>
        <div className="dashboard-editor-close">
          <div
            className="fas fa-times"
            onClick={() => {
              setShow(false);
            }}
          ></div>
        </div>
      </div>

      <div className="dashboard-editor-control">
        <div className="dashboard-editor-error-container" />
        <div
          className="dashboard-editor-save-button"
          onClick={() => {
            onSave(
              project,
              dashboardType,
              columns,
              dashboardSettings,
              setError
            );
          }}
        >
          {translate("Save Layout")}
        </div>
        <Error error={error} />
      </div>

      <div className="dashboard-editor-content">
        {components.map((component, i) => {
          const { dashboardID } = component.props;
          const { title } = component.props;

          // Do not render empty components
          let stringElement = renderToString(component);
          if (stringElement === "") {
            return null;
          }

          return (
            <DashboardEditorPanel
              key={i}
              dragID={dragID}
              dashboardID={dashboardID}
              title={title}
              component={component}
            />
          );
        })}
      </div>
      <DashboardEditorSlot dragID={dragID} columns={columns} />
    </div>
  );
}

function DashboardEditorPanel(props) {
  const { dragID, dashboardID, title, component } = props;
  return (
    <div className="dashboard-editor-panel">
      <Title dragID={dragID} dashboardID={dashboardID} title={title} />
      <div className="dashboard-editor-component">{component}</div>
    </div>
  );
}

function Title(props) {
  const { title, dashboardID } = props;
  const { dragID } = props;

  return (
    <div
      className="dashboard-title"
      draggable="true"
      onDragStart={() => {
        dragID.current = dashboardID;
      }}
    >
      <div className="dashboard-title-text">&nbsp;{title}</div>
    </div>
  );
}

function Error(props) {
  const { error } = props;
  return (
    <div className="dashboard-editor-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function DashboardEditorSlot(props) {
  const { columns, dragID } = props;
  const [highlight, setHighlight] = useState(false);

  let type = "dashboard-editor-slot";
  if (highlight) {
    type = "dashboard-editor-slot-highlight";
  }

  return (
    <div
      className={type}
      onDrop={(e) => {
        e.preventDefault();
        let remove = findDraggedColumn(columns, dragID);
        updateColumnsDashboardEditor(remove, dragID);
        setHighlight(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setHighlight(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setHighlight(false);
      }}
    >
      <i className="far fa-trash-alt" />
    </div>
  );
}

async function onSave(
  project,
  dashboardType,
  columns,
  dashboardSettings,
  setError
) {
  let layout = JSON.stringify(columns);
  await dashboardSettings.save(project, dashboardType, layout, setError);
  dashboardSettings.set(await dashboardSettings.get(project));
}
