import React, { useState, useContext } from "react";

import { hasRoles } from "../../utils/roles";

import { PCRALogHeader } from "./PCRALogHeader";
import { PCRAJourneys } from "./PCRAJourneys";
import { summarizeJourneys } from "./PCRA.functions";

import { PCRALogRootContext } from "./PCRALogRoot";

import { PCRA_MARKUP } from "./PCRAGlossary.constants";
import PCRABanner from "./PCRABanner";

export function PCRALog(props) {
  const context = useContext(PCRALogRootContext);
  const { user, data, isLocked, lockedReason } = context;

  const isClient = hasRoles(user.roles, ["Client"]);

  const { processType } = data;

  const [showPastJourneys, setShowPastJourneys] = useState(false);

  const journeys = summarizeJourneys(data);

  const showPCRALockedReason = isLocked && !journeys[0].isComplete;

  return (
    <div className="pcra-log-container">
      {showPCRALockedReason && (
        <PCRABanner icon={PCRA_MARKUP.STATUS_ICON.LOCKED} text={lockedReason} />
      )}
      <PCRALogHeader processType={processType} />
      <PCRAJourneys
        processType={processType}
        journeys={journeys}
        enablePastJourneys={!isClient}
        showPastJourneys={showPastJourneys}
        setShowPastJourneys={setShowPastJourneys}
      />
    </div>
  );
}
