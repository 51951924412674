import React from "react";
import isEmpty from "../../validation/is-empty";

import "./PDCPCurrency.css";

export default function PDCPCurrency(props) {
  const { project } = props;

  if (isEmpty(project)) {
    return null;
  }

  return (
    <div className="pdcp-currency">
      <div className="pdcp-project-text1">All values for </div>
      <div className="pdcp-project-title">
        <b>{project.title}</b>
      </div>
      <div className="pdcp-project-text2">are expressed in </div>
      <div className="pdcp-project-currency">
        <b>{project.currency}</b>
      </div>
      .
    </div>
  );
}
