import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

import { useComparisonComments } from "../../api/estimates/EstimateReconciliationApi";

import ReconciliationCommentModal from "./ReconciliationCommentModal";

import "./DetailedComparisonTable.css";

export default function DetailedComparisonTable(props) {
  const { e } = props;
  const { project } = props;

  const { comparisonComments, setComparisonComments } = useComparisonComments(
    e.id,
    project.id
  );

  return (
    <div className="detailed-comparison-table">
      <TableHead />
      <Table
        project={project}
        data={e.deltas}
        comparisonComments={comparisonComments}
        setComparisonComments={setComparisonComments}
      />
      <TableTotal
        project={project}
        totalDelta={e.totalDelta}
        total={e.calculated_total}
        comparisonTotal={e.comparison_calculated_total}
      />
    </div>
  );
}

function TableHead() {
  let row = {
    description: "Description",
    quantity: "Previous",
    unit_of_measure: "Current",
    rate: "Delta",
    note: "Note",
  };

  return (
    <div className="detailed-comparison-table-head-row">
      <div className="detailed-comparison-table-description-cell">
        <b>{row.description}</b>
      </div>
      <div className="detailed-comparison-table-previous-cell-head">
        <b>{row.quantity}</b>
      </div>
      <div className="detailed-comparison-table-current-cell-head">
        <b>{row.unit_of_measure}</b>
      </div>
      <div className="detailed-comparison-table-subtotal-cell-head">
        <b>{row.rate}</b>
      </div>
      <div className="detailed-comparison-table-note-cell-head">
        <b>{row.note}</b>
      </div>
    </div>
  );
}

function Table(props) {
  const { data } = props;
  const { comparisonComments, setComparisonComments } = props;
  const { project } = props;

  return (
    <div>
      {data.map((row, i) => {
        // Find any matching comment for this row
        let comment = "";
        if (!isEmpty(comparisonComments)) {
          comparisonComments.forEach((com) => {
            if (
              parseInt(com.row_id) === parseInt(row.id) &&
              parseInt(com.comparison_row_id) === parseInt(row.comparison_id)
            ) {
              comment = com;
            }
          });
        }

        // Subtotal styling for other rows
        let rowType = "detailed-comparison-table-row";
        let bold = false;
        if (
          row.code === "st" ||
          row.code === "cc" ||
          row.code === "pc" ||
          row.code === "pct"
        ) {
          bold = true;
          rowType = "detailed-comparison-table-total-row";
        }

        let valueType = "detailed-comparison-table-value-negative";

        if (row.delta >= 0) {
          valueType = "detailed-comparison-table-value-positive";
        }

        return (
          <div key={i} className={rowType}>
            <div className="detailed-comparison-table-description-cell">
              {valueBolder(row.description, bold)}
            </div>
            <div className="detailed-comparison-table-previous-cell ">
              <div>{valueBolder(project.currency_symbol, bold)}</div>
              <div>{valueBolder(comma(row.previousSubtotal), bold)}</div>
            </div>
            <div className="detailed-comparison-table-current-cell">
              <div>{valueBolder(project.currency_symbol, bold)}</div>
              <div>{valueBolder(comma(row.subtotal), bold)}</div>
            </div>

            <div className="detailed-comparison-table-subtotal-cell ">
              <div className={valueType}>
                <div>{valueBolder(project.currency_symbol, bold)}</div>
              </div>
              <div className={valueType}>
                <b>
                  {valueBolder(
                    generateBrackets(comma(Math.round(row.delta))),
                    bold
                  )}
                </b>
              </div>
            </div>

            <div className="detailed-comparison-table-note-cell">
              <ReconciliationCommentModal
                title={row.description}
                row={row}
                comment={comment}
                setComparisonComments={setComparisonComments}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function TableTotal(props) {
  const { comparisonTotal } = props;
  const { total } = props;
  const { totalDelta } = props;
  const { project } = props;

  let valueType = "detailed-comparison-table-value-negative";

  if (totalDelta >= 0) {
    valueType = "detailed-comparison-table-value-positive";
  }

  return (
    <div className="detailed-comparison-table-total-row">
      <div className="detailed-comparison-table-description-cell">
        <b>Total</b>
      </div>
      <div className="detailed-comparison-table-previous-cell">
        <div>{valueBolder(project.currency_symbol, true)}</div>
        <b>{comma(Math.round(comparisonTotal))}</b>
      </div>
      <div className="detailed-comparison-table-current-cell">
        <div>{valueBolder(project.currency_symbol, true)}</div>
        <b>{comma(Math.round(total))}</b>
      </div>
      <div className="detailed-comparison-table-subtotal-cell">
        <div className={valueType}>
          <div>{valueBolder(project.currency_symbol, true)}</div>
        </div>
        <div className={valueType}>
          <b>{generateBrackets(comma(Math.round(totalDelta)))}</b>
        </div>
      </div>
      <div className="detailed-comparison-table-note-cell">
        <b></b>
      </div>
    </div>
  );
}

// Helper Functions
function valueBolder(value, isBold) {
  if (isBold) {
    return <b>{value}</b>;
  } else {
    return value;
  }
}

function generateBrackets(string) {
  if (string.toString().includes("-")) {
    return "(" + string.split("-")[1] + ")";
  } else {
    return "+" + string;
  }
}
