import React, { useState } from "react";

import { tranStr } from "../../utils/translation";
import { uploadArticle } from "../tender-price-indexing/MarketInsights.functions";

import {
  Heading,
  DisplayError,
} from "../tender-price-indexing/MarketInsights.components";

import { ImageUpload } from "../images/ImageUpload";

export function InsightArticleUploadModal(props) {
  const { selectedTopic, user } = props;
  const { setShow } = props;
  const { setInsights } = props;
  const { region, country } = props;

  const [title, setTitle] = useState("");
  const [docLink, setDocLink] = useState("");
  const [global, setGlobal] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [articleType, setArticleType] = useState("file");
  const [error, setError] = useState({});
  let filesToggle;
  let linkToggle;

  //style changes according to toggle
  if (articleType === "file") {
    filesToggle = "mi-toggle-active-button";
    linkToggle = "mi-toggle-inactive-button";
  }
  if (articleType === "link") {
    linkToggle = "mi-toggle-active-button";
    filesToggle = "mi-toggle-inactive-button";
  }
  return (
    <div className="category-edit">
      <Heading title={title} />
      <div className="general-row-container">
        <ImageUpload
          imagePreview={imagePreview}
          setImage={setImage}
          setImagePreview={setImagePreview}
          imageWidth={"320px"}
          imageHeight={"300px"}
        />
      </div>
      <div className="general-row-container">
        <div className="category-edit-field">
          <b>Article Title</b>
        </div>
        <input
          id={"article-title-input"}
          className="article-title-text"
          value={title}
          placeholder={"Article Title"}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="general-row-container">
        <div className="category-edit-field">
          <b>Global Article</b>
        </div>
        <div className="category-title-input">
          <input
            id={"article-global-input"}
            type="checkbox"
            checked={global}
            onChange={(e) => {
              setGlobal(e.target.checked);
            }}
          />
        </div>
      </div>
      <div className="post-contract-clarifications">
        <div className="post-contract-toggle">
          <div
            id="insight-upload-file-button"
            className={filesToggle}
            onClick={async () => {
              setArticleType("file");
            }}
          >
            File
          </div>
          <div
            id="insight-upload-link-button"
            className={linkToggle}
            onClick={async () => {
              setArticleType("link");
            }}
          >
            Document Link
          </div>
        </div>
      </div>
      {articleType === "file" && (
        <div>
          <div className="general-row-container">
            <UploadFile setFunction={setFile} name={"Add File"} />
          </div>
          <div className="general-row-container">
            <FileTray file={file} />
          </div>
        </div>
      )}
      {articleType === "link" && (
        <div className="general-row-container">
          <input
            className="article-link"
            value={docLink}
            placeholder={"Document link"}
            onChange={(e) => {
              setDocLink(e.target.value);
            }}
          />
        </div>
      )}
      {error.text && (
        <div className="general-row-container">
          <DisplayError error={error} setError={setError} />
        </div>
      )}
      <div className="general-button-container">
        <div
          id="insight-upload-button"
          className="general-upload-button"
          onClick={() => {
            const data = {
              file,
              image,
              title,
              articleType,
              docLink,
              selectedTopic,
              user,
              setError,
              setInsights,
              setShow,
              region,
              country,
              global,
            };
            uploadArticle(data);
          }}
        >
          Submit
        </div>
      </div>
    </div>
  );
}

function FileTray(props) {
  const { file } = props;
  let fileName = tranStr("No File Added");

  if (file !== null) {
    fileName = file.name;
  }

  return <div className="category-edit-file-display">File: {fileName}</div>;
}

function UploadFile(props) {
  const { setFunction, name } = props;

  return (
    <div className="upload-buttons-container">
      <label className="general-modal-button">
        <input
          type="file"
          className="category-edit-button"
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              setFunction(e.target.files[0]);
            }
          }}
        />
        {name}
      </label>
    </div>
  );
}
