import { useState } from "react";
import PropTypes from "prop-types";

import { translate } from "../../utils/translation";
import {
  storeObject,
  retrieveObject,
} from "../../helpers/local-storage.functions";

import { CONTROL_TRIGGER_TYPES } from "../master/MasterPage";

import { MASTER_BENCHMARKING } from "../benchmarking/BenchmarkCreate.strings";

import BenchmarkingDisclaimer from "../benchmarking/BenchmarkingDisclaimer";

/// props validation | SQ(javascript:S6774)
useBenchmarkingDisclaimerManager.propTypes = {
  user: PropTypes.object,
};
///
export function useBenchmarkingDisclaimerManager({ user }) {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(() =>
    isDisclaimerAcceptedByUser({ user })
  );

  const [error, setError] = useState(<></>);

  function clearError() {
    setError(<></>);
  }

  function validateDisclaimerAcceptance() {
    clearError();

    if (!disclaimerAccepted) {
      setError(<>{MASTER_BENCHMARKING.WARNINGS.DISCLAIMER_NOT_ACCEPTED}</>);
    }

    return disclaimerAccepted;
  }

  function onDisclaimerBeforeOpen() {
    clearError();
  }

  function onDisclaimerAcceptedChange({ disclaimerAccepted }) {
    // check localStorage
    const isDisclaimerAlreadyAcceptedByUser = isDisclaimerAcceptedByUser({
      user,
    });

    // if localStorage contains state, lock the state
    if (isDisclaimerAlreadyAcceptedByUser) {
      return;
    }

    // otherwise allow checkbox to be toggled
    if (disclaimerAccepted) {
      clearError();
    }
    setDisclaimerAccepted(disclaimerAccepted);
  }

  function onDisclaimerAccept() {
    if (disclaimerAccepted) {
      storeDisclaimerAcceptedByUser({ user, disclaimerAccepted });
    }

    return validateDisclaimerAcceptance();
  }

  function onDisclaimerButtonClick() {
    onDisclaimerBeforeOpen();
    setShowDisclaimer(!showDisclaimer);
  }

  const manager = {
    key: "disclaimer",
    label: translate("Disclaimer"),
    className: "",
    type: CONTROL_TRIGGER_TYPES.OPEN_MODAL,
    show: showDisclaimer,
    setShow: setShowDisclaimer,
    ModalComponent: BenchmarkingDisclaimer,
    // modal button props
    buttonLabel: (
      <>
        {translate("Disclaimer")} : {translate("Limitations on Benchmark Data")}
      </>
    ),
    buttonClassName: "benchmarking-disclaimer-trigger-button",
    onClick: onDisclaimerButtonClick,
    // modal component props
    user,
    disclaimerAccepted,
    setDisclaimerAccepted,
    onDisclaimerAcceptedChange,
    get isActionButtonHidden() {
      return isDisclaimerAcceptedByUser({ user });
    },
    isAcceptReady: disclaimerAccepted,
    onAccept: onDisclaimerAccept,
    error,
  };

  return [manager];
}

function isDisclaimerAcceptedByUser({ user }) {
  const key = MASTER_BENCHMARKING.KEYS.DISCLAIMER_LOCAL_STORAGE_KEY;
  const store = retrieveObject(key);
  return store[user?.id] || false;
}

function storeDisclaimerAcceptedByUser({ user, disclaimerAccepted }) {
  const key = MASTER_BENCHMARKING.KEYS.DISCLAIMER_LOCAL_STORAGE_KEY;
  const store = retrieveObject(key);
  user && (store[user.id] = disclaimerAccepted);
  storeObject(key, store);
}
