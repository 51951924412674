import React, { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import isEmpty from "../../validation/is-empty";
import "./PostContractCommentary.css";

import Commentary from "./Commentary";
import "../post-contract-commentary/TabbedCommentary.css";
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";
import { tranStr } from "../../utils/translation";

export default function PostContractCommentary(props) {
  const { projectID } = props;
  const { costReport } = props;
  const { commentary } = costReport;
  const { postContract } = props;
  const postContractID = costReport.id;
  const [error, setError] = useState({});

  const [fieldSet, setFieldSet] = useState([
    {
      comment: "1. The report is based on information available at XXXXX",
      type: "cost_report_clarifications",
    },
    {
      comment: "2. The report incorporates variations up to number YYYYY",
      type: "cost_report_clarifications",
    },
    {
      comment: "3. The next report is due to be issued on ZZZZZ",
      type: "cost_report_clarifications",
    },
  ]);
  const mainHeading =
    "Add Clarifications and Notes below to be displayed on the Post Contract Dashboard.";
  const subHeading = "Post Contract Notes and Clarifications";

  const data = {
    setError: setError,
  };
  useEffect(() => {
    //if there is data in db
    if (!isEmpty(commentary)) {
      //if there are any cost report commentary
      const clarifications = commentary.filter(
        (x) => x.type === "cost_report_clarifications"
      );
      if (!isEmpty(clarifications)) {
        //load only cost report commentary data from db
        setFieldSet(
          clarifications.map((c) => {
            return {
              comment: c.comment,
              type: c.type,
            };
          })
        );
      }
    }
  }, [commentary]);

  return (
    <div>
      <div className="post-contract-clarifications">
        <Commentary
          fields={fieldSet}
          setFields={setFieldSet}
          mainHeading={mainHeading}
          crType={"cost_report_clarifications"}
          subHeading={subHeading}
          commentary={commentary}
        />
      </div>
      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton />
      </div>
    </div>
  );
  function SaveButton() {
    return (
      <div className="general-upload-button" onClick={Save}>
        Save
      </div>
    );
  }

  //Save
  async function Save(e) {
    e.preventDefault();

    const comments = [];

    // Add the changed Clarifications
    fieldSet.forEach((f) => {
      f.post_contract_id = postContractID;
      if (!isEmpty(f.comment)) {
        comments.push(f);
      }
    });

    if (!isEmpty(commentary)) {
      // We need to add exclusions in as well since they share 1 endpoint
      // Otherwise they will be deleted when clarifications are updated
      commentary.forEach((x) => {
        if (x.type === "cost_report_exclusions") {
          comments.push(x);
        }
      });
    }

    data.setError({
      text: "Saving...",
      type: "feedback-success",
    });

    const payload = {
      comments: comments,
      postContractID: postContractID,
      projectID: projectID,
      type: "cost_report",
    };
    const field = JSON.stringify(payload);
    const formData = new FormData();
    formData.append("field", field);

    // POST CONTRACT API
    const url = pcAPIs().save_commentary;

    const config = {
      method: "POST",
      body: formData,
      headers: authHeader({
        authJson: false,
        authForm: true,
        guestJson: false,
      }),
    };

    let response = await fetch(url, config);

    if (response.ok) {
      data.setError({
        text: "Saved Successfully",
        type: "feedback-success",
      });
      postContract.set(await postContract.get(projectID));
    } else {
      // Response Not OK
      console.log("Network Error");
    }
  }
}

export function Notify(props) {
  const { error } = props;

  return (
    <div className="pcu-error-container">
      <div className={error.type}>
        <b>{tranStr(error.text)}</b>
      </div>
    </div>
  );
}
