import React from "react";

import isEmpty from "../../validation/is-empty";
import { Donut, Legend } from "britecharts-react";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";

import "./PDCPDonutChart.css";

export default function PDCPDonutChart(props) {
  const { data } = props;
  const { width, height } = props;
  const { eRadius, iRadius } = props;
  const { lHeight, lWidth } = props;
  const { margin } = props;
  const { projectDashboard, colorSchema } = props;
  const { CP } = props;

  if (isEmpty(data)) {
    return null;
  }

  const maxObject = data.reduce((prev, current) => {
    return prev.value > current.value ? prev : current;
  });

  let ID = 0;

  if (!isEmpty(maxObject)) {
    ID = maxObject.id;
  }

  // Try and truncate text to prevent overflow.
  data.forEach((object) => {
    let displayName = object.name;
    const limit = 25;
    const elipsis = limit - 3;
    if (displayName.length > limit) {
      displayName = displayName.slice(0, elipsis) + "...";
    }
    object.name = displayName;
  });

  return (
    <div className="pdcp-donut-chart-container">
      <Donut
        isAnimated={false}
        colorSchema={colorSchema || null}
        data={data}
        width={width || 425}
        height={height || 200}
        externalRadius={eRadius || 90}
        internalRadius={iRadius || 70}
        highlightSliceById={ID}
        hasFixedHighlightedSlice={true}
      />
      <div className="pdcp-donut-chart-legend">
        <Legend
          colorSchema={colorSchema || null}
          data={data}
          height={lHeight || 120}
          width={lWidth || 365}
          numberFormat={","}
          highlightEntryById={ID}
          margin={margin || null}
        />
        {projectDashboard && (
          <ProjectGraphTotal
            data={data}
            calculatedTotal={CP.calculated_total}
          />
        )}
      </div>
    </div>
  );
}

function ProjectGraphTotal(props) {
  const { data } = props;
  const { calculatedTotal } = props;

  let value = calculateTotal(data);

  // Calculate Difference
  const difference = calculatedTotal - value;

  // Difference is less than positive 5 it is rounding only
  if (difference <= 5) {
    value = calculatedTotal;
  }

  // Difference is less than negative -5 it is rounding only
  if (difference >= -5) {
    value = calculatedTotal;
  }

  return (
    <div className="pdcp-donut-chart-total">
      <div className="pdcp-donut-chart-total-label">
        <b>{translate("Project Cost (Excl. Tax)")}</b>
      </div>
      <div className="pdcp-donut-chart-total-value">
        <b>{comma(value)}</b>
      </div>
    </div>
  );
}

function calculateTotal(selection) {
  let sum = 0;
  selection.forEach((item) => {
    sum += item.value;
  });
  return Math.round(sum);
}
