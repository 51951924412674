import React from "react";
import { PCRA_GLOSSARY, PCRA_MARKUP } from "./PCRAGlossary.constants";
import { PCRAStatusBar } from "./PCRAStatusBar";

const { AUDIT_STAGE } = PCRA_GLOSSARY;
const { CONTINUITY_CONNECTOR_TYPES } = PCRA_MARKUP;

const NONE = "NONE";

export function PCRAJourneyContinuity(props) {
  const { id } = props;
  const { endStage } = props;
  const { processType } = props;

  const stages = Object.values(AUDIT_STAGE(processType));

  const statusConnectors = [];
  const statusIndicators = [];

  function _addContinuity(type, i) {
    statusConnectors.push(
      <StatusConnector key={`${id}_connector_${i}`} type={type} />
    );
    statusIndicators.push(
      <StatusIndicator key={`${id}_indicator_${i}`} type={type} />
    );
  }

  for (let i = 0; i < stages.length; i++) {
    const stage = stages[i];

    if (i === 0 && stage === endStage) {
      _addContinuity(CONTINUITY_CONNECTOR_TYPES.SHORT, i);
      break;
    }

    if (i === 0) {
      _addContinuity(CONTINUITY_CONNECTOR_TYPES.BEGIN, i);
      continue;
    }

    if (stage === endStage) {
      _addContinuity(CONTINUITY_CONNECTOR_TYPES.END, i);
      break;
    }

    _addContinuity(CONTINUITY_CONNECTOR_TYPES.CONTINUE, i);
  }

  return (
    <>
      <div className="pcra-journey-continuity">{statusConnectors}</div>
      <div className="pcra-journey-continuity">{statusIndicators}</div>
    </>
  );
}

function StatusConnector({ type }) {
  return <div className={`status-connector ${type}`}></div>;
}

function StatusIndicator({ type }) {
  const { END, SHORT } = CONTINUITY_CONNECTOR_TYPES;

  if (type === END || type === SHORT) {
    return (
      <PCRAStatusBar
        from={NONE}
        status={PCRA_GLOSSARY.AUDIT_STATUS.FAILED}
        to={NONE}
      />
    );
  }

  return <PCRAStatusBar from={NONE} status={NONE} to={NONE} />;
}
