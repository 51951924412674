import PCRARequestingUser from "./PCRARequestingUser.class";
import { PCRA_GLOSSARY } from "./PCRAGlossary.constants";

const { AUDIT_STAGE, AUDIT_STATUS } = PCRA_GLOSSARY;

export function timestampToDateAndTime(timestamp) {
  let date = "",
    time = "";

  if (timestamp) {
    const datetime = new Date(timestamp); // timestamp must be an ISO datetime string, eg. 2024-01-10T18:27:52.0789205Z

    date = datetime.toLocaleDateString([], {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    time = datetime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return [date, time];
}

export function summarizeJourneys({ journeys, processType }) {
  return journeys
    .map((journey) => summarizeJourney({ journey, processType }))
    .reverse();
}

export function summarizeJourney({ journey, processType }) {
  const stages = Object.values(AUDIT_STAGE(processType));
  const finalStage = stages[stages.length - 1];

  const summary = [];
  let endStage = null;
  let isComplete = false;

  const { logs } = journey;

  for (const stage of stages) {
    const log = logs.findLast((log) => log.stageDisplay === stage);
    summary.push(log);

    if (log.statusDisplay === AUDIT_STATUS.FAILED) {
      endStage = stage;
      break;
    }

    isComplete =
      log.stageDisplay === finalStage &&
      log.statusDisplay === AUDIT_STATUS.PASSED;
  }

  return { ...journey, logs: summary, endStage, isComplete };
}

export function getPCRARequestingUser({ user }) {
  if (!user) {
    return null;
  }

  return new PCRARequestingUser({
    id: user.id,
    email: user.email,
    name: "",
    givenName: user.name,
    surname: user.surname,
    windowsAccountName: "",
  });
}

export function getPCRARequestingUserDisplayName(user) {
  if (!user) {
    return "";
  }

  return (
    user.name ||
    ((user.givenName || user.surname) && `${user.givenName} ${user.surname}`) ||
    user.windowsAccountName
  );
}
