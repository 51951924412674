import React from "react";
import ModalEditCommentary from "./EstimateCommentaryEdit";
import isEmpty from "../../validation/is-empty";

import { onMoveComment } from "./Commentary.functions";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

import "./Commentary.css";
import DisplayCommentary from "./DisplayCommentary";
import DeleteCommentary from "./DeleteCommentary";

export default function Commentary(props) {
  const { user } = props;
  const { project } = props;
  const { CP } = props;
  const { setComments } = props;
  const { CPs } = props;

  const { URLs } = props;
  const { Module } = props;
  const { Comments } = props;
  const { CostId } = props;

  if (isEmpty(CP)) {
    switch (Module) {
      case "PostContract":
        return (
          <div className="commentary">
            {translate(
              "TA Commentary is available after a Cost Report has been uploaded"
            )}
          </div>
        );
      case "CostPlanning":
        return (
          <div className="commentary">
            {translate(
              "Cost Plan Commentary is available after a Cost Plan has been uploaded"
            )}
          </div>
        );
      default:
        return null;
    }
  }

  if (isEmpty(Comments)) {
    return (
      <div className="commentary">
        {translate(
          "No commentary has been added. Please click Add Commentary button to add commentary."
        )}
      </div>
    );
  }

  return (
    <div className="commentary">
      {Comments.map((c, i) => {
        return (
          <div className="commentary-flex-box" key={i}>
            <div className="commentary-box">
              <div className="commentary-box-title">{c.title}</div>
              <DisplayCommentary c={c} />
            </div>
            <CommentaryButtons
              project={project}
              CP={CP}
              CPs={CPs}
              commentary={Comments}
              setComments={setComments}
              user={user}
              i={i}
              c={c}
              URLs={URLs}
              CostId={CostId}
              Module={Module}
            />
          </div>
        );
      })}
    </div>
  );
}

export function CommentaryButtons(props) {
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { user } = props;
  const { i } = props;
  const { c } = props;
  const { commentary } = props;
  const { setComments } = props;
  const { URLs } = props;
  const { CostId } = props;
  const { Module } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div className="commentary-buttons-container">
      <div className="commentary-buttons">
        <div className="commentary-move-buttons">
          <button
            className="commentary-move-button"
            id="moveup"
            onClick={async () => {
              // Not the first comment
              if (i !== 0) {
                const thisComment = {
                  projectID: project.id,
                  CostId: CostId,
                  c: commentary[i], // The current comment
                  part: i - 1, // Move Up
                  setComments: setComments,
                  Module: Module,
                };

                const nextComment = {
                  projectID: project.id,
                  CostId: CostId,
                  c: commentary[i - 1], // The next comment
                  part: i, // Move to this spot
                  setComments: setComments,
                  Module: Module,
                };

                await onMoveComment(thisComment, URLs);
                await onMoveComment(nextComment, URLs);
                CPs.set(await CPs.get(project.id, project));
              }
            }}
          >
            {"▲"}
          </button>
          <button
            className="commentary-move-button"
            id="movedown"
            onClick={async () => {
              // Not the last comment
              if (i !== commentary.length - 1) {
                const thisComment = {
                  projectID: project.id,
                  CostId: CostId,
                  c: commentary[i], // The current comment
                  part: i + 1, // Move Down
                  setComments: setComments,
                  Module: Module,
                };

                const nextComment = {
                  projectID: project.id,
                  CostId: CostId,
                  c: commentary[i + 1], // The next comment
                  part: i, // Move to this spot
                  setComments: setComments,
                  Module: Module,
                };

                await onMoveComment(thisComment, URLs);
                await onMoveComment(nextComment, URLs);
                CPs.set(await CPs.get(project.id, project));
              }
            }}
          >
            {"▼"}
          </button>
        </div>
{
   
  ((project.project_phase === "COST_PLANNING" && Module==="CostPlanning") 
  || (project.project_phase==="POST_CONTRACT" && Module==="PostContract") )
  &&
 
  <>
        {c.type === "TEXT" && (
          <ModalEditCommentary
            user={user}
            project={project}
            projectID={project.id}
            setComments={setComments}
            comment={c}
            CP={CP}
            CPs={CPs}
            part={i}
            URLs={URLs}
            CostId={CostId}
            Module={Module}
          />
        )}
        <DeleteCommentary
          project={project}
          c={c}
          setComments={setComments}
          CPs={CPs}
          URLs={URLs}
        />
        </>
        }

      </div>
    </div>
  );
}
