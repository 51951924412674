import React from "react";

import Accordion from "../accordion/Accordion";
import CostPlanningDashboard from "../cost-planning/CostPlanningDashboard";
import EstimatesBenchmarkDonutComponent from "./EstimatesBenchmarkDonutComponent";
import EstimateSummaryTable from "./EstimateSummaryTable";
import EstimatesTPI from "./EstimatesTPI";
import EstimatesSubtotal from "./EstimatesSubtotal";
import EstimateControl from "./EstimateControl";

export default function Estimate(props) {
  const { project } = props;
  const { user } = props;
  const { CP } = props;
  const { CPs } = props;
  const { TPI } = props;
  const { e } = props;
  const { dragID } = props;
  const { accordionState } = props;

  // State management from parent
  const { isLinkedTPI, setIsLinkedTPI } = props;
  const { linkedTPISelectedYear, setLinkedTPISelectedYear } = props;
  const { linkedTPIPercentage, setLinkedTPIPercentage } = props;

  let rate = Math.round(e.calculated_total / e.local_region_area);

  const thisBenchmark = e.benchmark;
  const benchmarks = e.benchmark?.linked_benchmarks;

  return (
    <div className="estimate-wrapper">
      <div className="estimate-flex-box">
        <Accordion
          key={e.id}
          label={e.name}
          local_region_area={e.local_region_area}
          unit={project.unit_of_measure}
          rate={rate}
          total={Math.round(e.calculated_total)}
          dragID={dragID}
          // State
          accordionState={accordionState}
          accordionSection={"SUMMARY"}
          estimateID={e.id}
        >
          <div className="estimate-table-container">
            <div className="estimate-table-and-chart-container">
              <div className="estimate-chart-container">
                <CostPlanningDashboard
                  estimates={[e]}
                  calculatedTotal={e.calculated_total}
                  CPs={CPs}
                  project={project}
                />
                <CostPlanningDashboard
                  estimates={[e]}
                  calculatedTotal={e.calculated_total}
                  subTotalChart={true}
                  projectId={project.project_id}
                  CPs={CPs}
                  project={project}
                />
                <EstimatesBenchmarkDonutComponent
                  project={project}
                  thisBenchmark={thisBenchmark}
                  benchmarks={benchmarks}
                  projectId={project.project_id}
                />
              </div>
              <div className="estimate-table-and-TPI-container">
                <EstimateSummaryTable
                  name={e.name}
                  estimateId={e.id}
                  data={e.rows}
                  project={project}
                />
                <EstimatesTPI
                  estimate={e}
                  project={project}
                  user={user}
                  TPI={TPI}
                  CPs={CPs}
                  isClassic={CP.estimates.length < 2}
                  isLinkedTPI={isLinkedTPI}
                  setIsLinkedTPI={setIsLinkedTPI}
                  linkedTPISelectedYear={linkedTPISelectedYear}
                  setLinkedTPISelectedYear={setLinkedTPISelectedYear}
                  linkedTPIPercentage={linkedTPIPercentage}
                  setLinkedTPIPercentage={setLinkedTPIPercentage}
                />
              </div>
            </div>
          </div>
        </Accordion>
        <EstimateControl
          project={project}
          user={user}
          CP={CP}
          CPs={CPs}
          estimate={e}
        />
      </div>
      <EstimatesSubtotal
        project={project}
        estimates={CP.estimates}
        estimateID={e.id}
        subtotal_below={e.subtotal_below}
        subtotal_name={e.subtotal_name}
        subtotal_gfa_override={e.subtotal_gfa_override}
      />
    </div>
  );
}
