import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { commaToFixed } from "../../helpers/common.functions";

import "./CostReportForecastCostSummary.css";

export default function CostReportForecastCostSummary(props) {
  const { CR } = props;

  return (
    <div className="crfcs-content">
      <TitleRow CR={CR} />
      <ContractSum
        CR={CR}
        objKey={"contract_sum"}
        title={translate("Contract Sum")}
      />
      <Variations CR={CR} objKey={"approved"} title={translate("Variations")} />
      <AdjustmentsToProvisionalItems
        CR={CR}
        objKey={"adjustment"}
        title={translate("Adjustments to Provisional Items")}
      />
      <ForecastTotal
        CR={CR}
        objKey={"forecast_total"}
        title={translate("Forecast Total Cost")}
      />
    </div>
  );
}

function TitleRow(props) {
  const { CR } = props;

  return (
    <div className="crfcs-title-row">
      <div className="crfcs-description-cell"></div>
      <div className="crfcs-value-cell-heading">
        <b>{translate("Construction")}</b>
      </div>
      <div className="crfcs-value-cell-heading">
        <b>{translate("Client Directs")}</b>
      </div>
      <div className="crfcs-value-cell-heading">
        <b>
          {translate("Total Excl tax", {
            tax: isEmpty(CR.tax_type) ? "tax" : CR.tax_type,
          })}
        </b>
      </div>
    </div>
  );
}

function ContractSum(props) {
  const { CR } = props;
  const { title } = props;

  const { objKey } = props;
  const constructionCosts =
    CR[`construction_cost_${objKey}`] +
    CR[`provisional_quantities_${objKey}`] +
    CR[`provisional_sums_${objKey}`] +
    CR[`delays_and_extensions_of_time_${objKey}`];

  const clientDirectCosts =
    CR[`consultants_fees_${objKey}`] +
    CR[`client_staffing_${objKey}`] +
    CR[`authority_fees_${objKey}`] +
    CR[`legal_fees_${objKey}`] +
    CR[`ffe_${objKey}`] +
    CR[`loose_furniture_${objKey}`] +
    CR[`workstations_${objKey}`] +
    CR[`audio_visual_${objKey}`] +
    CR[`specialist_equipment_${objKey}`] +
    CR[`ict_${objKey}`] +
    CR[`relocation_${objKey}`] +
    CR[`other1_${objKey}`] +
    CR[`other2_${objKey}`] +
    CR[`other3_${objKey}`];

  const totalExclTax = CR[`forecast_cost_excl_tax_${objKey}`];

  return (
    <div className="crfcs-row">
      <div className="crfcs-description-cell">{title}</div>
      <div className="crfcs-value-cell">
        <div></div>
        {commaToFixed(constructionCosts)}
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        {commaToFixed(clientDirectCosts)}
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(totalExclTax)}</b>
      </div>
    </div>
  );
}

function Variations(props) {
  const { CR } = props;
  const { title } = props;

  const { objKey } = props;
  const constructionCosts = CR[`variations_forecast_total`];

  const clientDirectCosts =
    CR[`consultants_fees_${objKey}`] +
    CR[`client_staffing_${objKey}`] +
    CR[`authority_fees_${objKey}`] +
    CR[`legal_fees_${objKey}`] +
    CR[`ffe_${objKey}`] +
    CR[`loose_furniture_${objKey}`] +
    CR[`workstations_${objKey}`] +
    CR[`audio_visual_${objKey}`] +
    CR[`specialist_equipment_${objKey}`] +
    CR[`ict_${objKey}`] +
    CR[`relocation_${objKey}`] +
    CR[`other1_${objKey}`] +
    CR[`other2_${objKey}`] +
    CR[`other3_${objKey}`];

  const totalExclTax = constructionCosts + clientDirectCosts;

  return (
    <div className="crfcs-row">
      <div className="crfcs-description-cell">{title}</div>
      <div className="crfcs-value-cell">
        <div></div>
        {commaToFixed(constructionCosts)}
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        {commaToFixed(clientDirectCosts)}
      </div>

      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(totalExclTax)}</b>
      </div>
    </div>
  );
}

function AdjustmentsToProvisionalItems(props) {
  const { CR } = props;
  const { title } = props;

  const { objKey } = props;
  const constructionCosts =
    CR[`provisional_quantities_${objKey}`] +
    CR[`provisional_sums_${objKey}`] +
    CR[`delays_and_extensions_of_time_${objKey}`];

  const clientDirectCosts =
    CR[`consultants_fees_${objKey}`] +
    CR[`client_staffing_${objKey}`] +
    CR[`authority_fees_${objKey}`] +
    CR[`legal_fees_${objKey}`] +
    CR[`ffe_${objKey}`] +
    CR[`loose_furniture_${objKey}`] +
    CR[`workstations_${objKey}`] +
    CR[`audio_visual_${objKey}`] +
    CR[`specialist_equipment_${objKey}`] +
    CR[`ict_${objKey}`] +
    CR[`relocation_${objKey}`] +
    CR[`other1_${objKey}`] +
    CR[`other2_${objKey}`] +
    CR[`other3_${objKey}`];

  const totalExclTax = constructionCosts + clientDirectCosts;

  return (
    <div className="crfcs-row">
      <div className="crfcs-description-cell">{title}</div>
      <div className="crfcs-value-cell">
        <div></div>
        {commaToFixed(constructionCosts)}
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        {commaToFixed(clientDirectCosts)}
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(totalExclTax)}</b>
      </div>
    </div>
  );
}

function ForecastTotal(props) {
  const { CR } = props;
  const { title } = props;

  const { objKey } = props;
  const constructionCosts =
    CR[`construction_cost_${objKey}`] +
    CR[`variations_${objKey}`] +
    CR[`provisional_quantities_${objKey}`] +
    CR[`provisional_sums_${objKey}`] +
    CR[`delays_and_extensions_of_time_${objKey}`];

  const clientDirectCosts =
    CR[`consultants_fees_${objKey}`] +
    CR[`client_staffing_${objKey}`] +
    CR[`authority_fees_${objKey}`] +
    CR[`legal_fees_${objKey}`] +
    CR[`ffe_${objKey}`] +
    CR[`loose_furniture_${objKey}`] +
    CR[`workstations_${objKey}`] +
    CR[`audio_visual_${objKey}`] +
    CR[`specialist_equipment_${objKey}`] +
    CR[`ict_${objKey}`] +
    CR[`relocation_${objKey}`] +
    CR[`other1_${objKey}`] +
    CR[`other2_${objKey}`] +
    CR[`other3_${objKey}`];

  const totalExclTax = CR[`forecast_cost_excl_tax_${objKey}`];

  return (
    <div className="crfcs-total-row">
      <div className="crfcs-description-cell">
        <b>{title}</b>
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(constructionCosts)}</b>
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(clientDirectCosts)}</b>
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(totalExclTax)}</b>
      </div>
    </div>
  );
}
