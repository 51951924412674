import React, { useState, useEffect } from "react";
import { GENERATED } from "../../actions/types";
import { submit } from "./PostContractStages.functions";
import { translate } from "../../utils/translation";
import "./PostContractStages.css";
import PostContractCashflowToggle from "../cashflow/PostContractCashflowToggle";
import isEmpty from "../../validation/is-empty";

export default function PostContractStages(props) {
  const { project } = props;
  const { projectID } = props;
  const { postContract } = props;
  const { setShow } = props;

  const [error, setError] = useState({});

  // Post Contract
  const [startMonth, setStartMonth] = useState("");
  const [startYear, setStartYear] = useState("");
  const [duration, setDuration] = useState("");
  const [cashflowBasis, setCashflowBasis] = useState(GENERATED);

  useEffect(() => {
    if (!isEmpty(project.post_contract_start_month))
      setStartMonth(project.post_contract_start_month);
    if (!isEmpty(project.post_contract_start_year))
      setStartYear(project.post_contract_start_year);
    if (!isEmpty(project.post_contract_duration))
      setDuration(project.post_contract_duration);
    if (!isEmpty(project.post_contract_cashflow_basis))
      setCashflowBasis(project.post_contract_cashflow_basis);
  }, [project]);

  const data = {
    projectID: projectID,
    project: project,
    postContract: postContract,
    startMonth: startMonth,
    startYear: startYear,
    duration: duration,
    cashflowBasis: cashflowBasis,
    setError: setError,
    setShow: setShow,
  };

  return (
    <div className="post-contract-stages-control">
      <div className="general-row-container">
        <div className="post-contract-stages-row">
          <div className="project-stages-post-contract-control-label">
            {translate("Start Date")}
          </div>
          <Select
            value={startMonth}
            set={setStartMonth}
            options={months()}
            classType={"project-stages-month-select"}
          />
          <input
            className="project-stages-post-contract-duration-input"
            placeholder="Year"
            value={startYear}
            onChange={(e) => {
              setStartYear(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="general-row-container">
        <div className="post-contract-stages-row">
          <div className="project-stages-post-contract-control-label">
            {translate("Duration")}
          </div>
          <input
            className="project-stages-post-contract-duration-input"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
          />
          {translate("Months")}
        </div>
      </div>

      <div className="general-row-container">
        <div className="post-contract-stages-row">
          <div className="project-stages-post-contract-control-label">
            {translate("Cashflow Source")}
          </div>

          <div className="project-stages-post-contract-duration-input">
            <PostContractCashflowToggle
              cashflowBasis={cashflowBasis}
              projectID={projectID}
              setCashflowBasis={setCashflowBasis}
            />
          </div>
        </div>
      </div>
      <div className="general-row-container">
        <Error error={error} setError={setError} />
      </div>

      <div className="general-button-container">
        <div
          className="general-upload-button"
          onClick={async () => {
            await submit(data);
          }}
        >
          {translate("Save")}
        </div>
      </div>
    </div>
  );
}

function Select(props) {
  const { value, set, options, classType } = props;

  return (
    <select
      className={classType}
      value={value}
      onChange={(e) => {
        set(e.target.value);
      }}
    >
      <SelectList options={options} />
    </select>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item.value} className="option">
        {item.name}
      </option>
    );
  });
}

function Error(props) {
  const { error } = props;
  return (
    <div className="project-stages-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function months() {
  return [
    { name: "Select Month", value: 0 },
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
}
