import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";
import "./EstimatesTPI.css";

import {
  round,
  getYearFromDate,
  computeEstimateTPISpecifics,
} from "./EstimatesTPI";
import EstimatesTPISaveEscalation from "./EstimatesTPISaveEscalation";
import { SAVE_MESSAGES } from "./EstimatesTPISaveMessages";
import EstimatesTPIAnticipatedCost from "./EstimatesTPIAnticipatedCost";

export default function EstimatesTPITotal(props) {
  // Props
  const { estimates, project } = props;
  const { user } = props;
  const { TPI } = props;
  const { CPs } = props;
  const { isBreakdown } = props;
  const { selectedRow } = props;

  // State management from parent
  const { isLinkedTPI = false, setIsLinkedTPI } = props;
  const { setLinkedTPISelectedYear } = props;
  const { setLinkedTPIPercentage } = props;

  // State
  const [futureYear, setFutureYear] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [message, setMessage] = useState(null);

  //////
  /// ESTIMATION SUMMARY
  //////

  // Prepare a summarised estimate from all available estimates
  const { estimate, estimateIdList } = getEstimateSummary({
    estimates,
    isLinkedTPI,
  });

  //////
  /// DATES
  //////

  let escalationDate = parseInt(estimate.escalation_date);

  // Get the base date of the estimate
  let currentYear = getYearFromDate(estimate.date);

  // Get the future date of the estimate
  let estimateFutureYear = isLinkedTPI
    ? getYearFromDate(escalationDate)
    : currentYear;

  // Set the percentage
  useEffect(() => {
    setPercentage(estimate.escalation_percentage);
  }, [estimate.escalation_percentage]);

  // Display message if percentage changes to prompt user that it is not saved
  useEffect(() => {
    setLinkedTPIPercentage(percentage);

    if (percentage !== estimate.escalation_percentage) {
      setMessage(SAVE_MESSAGES.NOT_SAVED);
    }
    if (percentage === estimate.escalation_percentage) {
      setMessage(null);
    }
  }, [estimate.escalation_percentage, percentage, setLinkedTPIPercentage]);

  // If no future year is set, set the future year to current year
  useEffect(() => {
    if (!isEmpty(TPI)) {
      if (isNaN(escalationDate)) {
        setFutureYear(currentYear);
      } else {
        setFutureYear(estimateFutureYear);
      }
    }
  }, [TPI, currentYear, escalationDate, estimateFutureYear]);

  useEffect(() => {
    if (isLinkedTPI) {
      setLinkedTPISelectedYear(futureYear);
    }
  }, [futureYear, isLinkedTPI, setLinkedTPISelectedYear]);

  // Early exit
  if (isEmpty(TPI) || (isBreakdown && selectedRow !== "breakdown")) {
    return null;
  }

  // compute specifics of Estimate TPI
  const {
    estimateCost,
    inflation,
    containerType,
    descriptionType,
    calculatePercentage,
  } = computeEstimateTPISpecifics({
    estimate,
    project,
    TPI,
    isBreakdown,
    selectedRow,
    percentage,
    currentYear,
  });

  // this map is used to return differential values for isLinkedTPI state vs !isLinkedTPI state
  const map = {
    // isLinkedTPI = true, map[isLinkedTPI] will return the below object
    true: {
      iconClass: "linkedTPI",
      textClass: "",
      icon: <i className="fas fa-equals"></i>,
      estimateCost: commaToFixed(estimateCost),
      totalCost: comma(inflation),
    },
    // isLinkedTPI = false, map[isLinkedTPI] will return the below object
    false: {
      iconClass: "delinkedTPI",
      textClass: "disabled",
      icon: <i className="fas fa-not-equal"></i>,
      estimateCost: "",
      totalCost: "",
    },
  };

  return (
    <div className="estimates-TPI">
      <div className="estimates-TPI-row">
        <div className={containerType}>
          <div className={descriptionType}>
            <div
              className={`estimates-TPI-icon ${map[isLinkedTPI].iconClass}`}
              onClick={() => setIsLinkedTPI(!isLinkedTPI)}
            >
              {map[isLinkedTPI].icon}
            </div>
            <div
              className={`estimates-TPI-label ${map[isLinkedTPI].textClass}`}
            >
              {translate("Tender Price Inflation")}
            </div>
            <div className="estimates-tpi-slider-container">
              <div
                className={`estimates-TPI-label ${map[isLinkedTPI].textClass}`}
              >
                {currentYear}
              </div>
              <input
                disabled={!isLinkedTPI}
                className="estimates-tpi-slider"
                type="range"
                min={currentYear}
                max={TPI.years[TPI.years.length - 1]}
                value={futureYear}
                onChange={(e) => {
                  let selectedYear = parseInt(e.target.value);

                  let percentage = calculatePercentage(selectedYear);

                  setFutureYear(selectedYear);
                  setPercentage(percentage);
                  setMessage(null);
                }}
              />
              <div
                className={`estimates-TPI-label ${map[isLinkedTPI].textClass}`}
              >
                <b>{futureYear}</b>
              </div>
            </div>
          </div>
          {isLinkedTPI ? (
            <input
              disabled={false}
              className="estimates-TPI-value"
              value={percentage.toString()}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setPercentage(e.target.value);
                }
              }}
            ></input>
          ) : (
            <div className="estimates-TPI-value"></div>
          )}
          <div className={`estimates-TPI-unit ${map[isLinkedTPI].textClass}`}>
            %
          </div>
          <div className="estimates-TPI-construction-cost">
            {map[isLinkedTPI].estimateCost}
          </div>

          <div className="estimates-TPI-total">
            {map[isLinkedTPI].totalCost}
          </div>
        </div>
        {isLinkedTPI ? (
          <EstimatesTPISaveEscalation
            isLinkedTPI={isLinkedTPI}
            estimateIDs={estimateIdList}
            futureYear={futureYear}
            percentage={percentage}
            user={user}
            message={message}
            setMessage={setMessage}
            CPs={CPs}
            project={project}
          />
        ) : (
          <></>
        )}
      </div>
      <EstimatesTPIAnticipatedCost
        estimate={estimate}
        project={project}
        futureYear={futureYear}
        inflation={inflation}
        containerType={containerType}
        descriptionType={descriptionType}
        isBreakdown={isBreakdown}
        isDisabled={!isLinkedTPI}
      />
    </div>
  );
}

function getEstimateSummary({ estimates, isLinkedTPI }) {
  // Prepare a summarised estimate from all available estimates

  const estimate = {
    date: estimates[0].date,
    escalation_date: estimates[0].escalation_date,
    construction_cost: 0,
    escalationAllowance: 0,
    escalation_percentage: 0,
    local_region_area: 0, // will be SUM(local_region_area) from all estimates
  };

  const estimateIdList = [];

  if (isLinkedTPI) {
    estimates.forEach((e) => {
      estimateIdList.push(e.id);

      estimate.date =
        getYearFromDate(e.date) > getYearFromDate(estimate.date)
          ? e.date
          : estimate.date;

      estimate.escalation_date =
        getYearFromDate(e.escalation_date) <
        getYearFromDate(estimate.escalation_date)
          ? e.escalation_date
          : estimate.escalation_date;

      estimate.construction_cost += e.construction_cost;

      estimate.escalationAllowance += e.escalationAllowance;

      estimate.local_region_area += e.local_region_area;
    });

    estimate.escalation_percentage = round(
      (estimate.escalationAllowance / estimate.construction_cost) * 100,
      2
    );
  }

  return { estimate, estimateIdList };
}
