import React, { useState } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";

import { tranStr } from "../../utils/translation";
import { columns } from "./CompanyEmployeesTable.functions";
import { displayUserImage } from "../../validation/image-clean";

import "./CompanyEmployeesTable.css";

// Components
import Table from "../table/Table";

CompanyEmployeesTable.propTypes = {
  data: PropTypes.array,
  dataLoading: PropTypes.bool,
  selectedUsers: PropTypes.array,
  selectedDataLoading: PropTypes.bool,
  setSelectedUsers: PropTypes.func,
};

export default function CompanyEmployeesTable(props) {
  const { data, dataLoading } = props;
  const { selectedUsers, setSelectedUsers, selectedDataLoading } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(data) || dataLoading || selectedDataLoading) {
    return null;
  }

  const users = data.map((u) => {
    u.image = displayUserImage(u);
    return u;
  });

  return (
    <Table
      title={tranStr("Accounts")}
      tableArray={users}
      columns={columns()}
      tableSize={10}
      isTitleDisabled={true}
      isTotalDisabled={true}
      // Checked Selection
      checked={selectedUsers}
      setChecked={setSelectedUsers}
      // Row Selection
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      // Modal Control
      modal={modal}
      setModal={setModal}
    />
  );
}
