import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { localizationAPIs } from "../api-endpoints/LocalizationEndpoints";

export function UseProjectLocalisations(currentLanguage, terminology) {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    if (currentLanguage && terminology) {
      // Keep only language code "en" in case of "en-US"
      const twoLetterCode = currentLanguage.slice(0, 2);
      const namespace = twoLetterCode + "-" + terminology;
      setLoading(true);
      getLocalisations(namespace.toLowerCase())
        .then((languageRes) => {
          setLanguage(languageRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [currentLanguage, terminology]);
  return {
    localizationObject: {
      data: language,
      loading: loading,
      get: getLocalisations,
      set: setLanguage,
    },
  };
}

//function to get all Localisations terms
export function UseLocalisations(selectedLanguage) {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    if (selectedLanguage) {
      setLoading(true);
      getLocalisations(selectedLanguage.toLowerCase())
        .then((languageRes) => {
          setLanguage(languageRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [selectedLanguage]);
  return {
    languageObject: {
      data: language,
      loading: loading,
      get: getLocalisations,
      set: setLanguage,
    },
  };
}

//Get all Localisations terms
export async function getLocalisations(selectedLanguage) {
  const url = localizationAPIs().get + `/${selectedLanguage}`;
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Localisations Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = await response.json();
    console.log("Error", response);
    response = [];
    console.log(erMessage);
  }

  return response;
}

//Save localisation - Save Changes button
export async function saveLocalisation(d) {
  const data = d;
  const payload = data.language;
  const selectedLanguage = data.selectedLanguage.toLowerCase();

  const url = localizationAPIs().update + `/${selectedLanguage}`;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Set Translations Error";
  let response = await fetch(url, config);

  if (response.ok) {
    return "Success";
  } else {
    console.log(erMessage);
    return erMessage;
  }
}

//Clone localisation - Create copy button
export async function cloneLocalisation(d) {
  const data = d;
  const payload = data.language;
  const selectedLanguage = data.selectedLanguage.toLowerCase();

  const url = localizationAPIs().clone + `/${selectedLanguage}`;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Clone Localisations Error";
  let response = await fetch(url, config);

  if (response.ok) {
    return "Success";
  } else {
    console.log(erMessage);
    return erMessage;
  }
}

//Get all namespaces function
export function useNamespaces() {
  const [loading, setLoading] = useState(true);
  const [namespaces, setNamespaces] = useState([]);

  useEffect(() => {
    setLoading(true);
    getNamespaces()
      .then((namespacesRes) => {
        setNamespaces(namespacesRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    namespacesObject: {
      data: namespaces,
      loading: loading,
      get: getNamespaces,
      set: setNamespaces,
    },
  };
}

// Get a list of available namespaces
export async function getNamespaces() {
  const url = localizationAPIs().get_all_namespaces;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Namespaces Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

//Delete localisation
export async function deleteNamespace(namespace) {
  const url =
    localizationAPIs().delete +
    `?localizationNamespace=${namespace.toLowerCase()}`;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Delete Namespace Error";
  let response = await fetch(url, config);

  if (response.ok) {
    return "Success";
  } else {
    console.log(erMessage);
    return erMessage;
  }
}

//Import new localisation set
export async function uploadLocalisation(data) {
  const formData = new FormData();
  formData.append("namespace", JSON.stringify(data.fileName.toLowerCase()));
  formData.append("Files", data.file);

  const url = localizationAPIs().upload;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };
  let erMessage = "Import Localisation Error";
  let response = await fetch(url, config);

  if (response.ok) {
    return "Success";
  } else {
    return erMessage;
  }
}
