import React from "react";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import "./ProgressClaimDetailsGrid.css";

export default function ProgressClaimDetailsGrid(props) {
  const { PC } = props;

  let submission_date = "";
  let recommendation_date = "";
  let work_completed_to_date = "";

  // Validation
  if (!isEmpty(PC.submission_date)) {
    submission_date =
      PC.submission_date.day +
      " " +
      PC.submission_date.month +
      " " +
      PC.submission_date.year;
  }

  // Validation
  if (!isEmpty(PC.recommendation_date)) {
    recommendation_date =
      PC.recommendation_date.day +
      " " +
      PC.recommendation_date.month +
      " " +
      PC.recommendation_date.year;
  }

  // Validation
  if (!isEmpty(PC.work_completed_to_date)) {
    work_completed_to_date =
      PC.work_completed_to_date.day +
      " " +
      PC.work_completed_to_date.month +
      " " +
      PC.work_completed_to_date.year;
  }

  return (
    <div className="progress-claim-details">
      <GridItem
        value={PC.progress_claim_number}
        text={translate("Payment No")}
        type={"progress-claim-details-box2"}
      />
      <GridItem
        value={PC.contract_type}
        text={translate("Contract Type")}
        type={"progress-claim-details-box1"}
      />
      <GridItem
        value={Math.round(PC.overall_percent_complete) + "%"}
        text={translate("Overall % Complete")}
        type={"progress-claim-details-box1"}
      />
      <GridItem
        value={submission_date}
        text={translate("Submission Date")}
        type={"progress-claim-details-box3"}
      />
      <GridItem
        value={recommendation_date}
        text={translate("Recommendation Date")}
        type={"progress-claim-details-box3"}
      />
      <GridItem
        value={work_completed_to_date}
        text={translate("Work Complete To")}
        type={"progress-claim-details-box3"}
      />
    </div>
  );
}

function GridItem(props) {
  const { value, text, type } = props;

  return (
    <div className="progress-claim-details-item">
      <div className={type}>
        <b>{value}</b>
      </div>
      <div className="progress-claim-details-label">{text}</div>
    </div>
  );
}
