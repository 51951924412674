import React, { useState, useEffect } from "react";

import PostContractSelectorOptions from "./PostContractSelectorOptions";
import PostContractSliderTicks from "./PostContractSliderTicks";
import { tranStr } from "../../utils/translation";

import isEmpty from "../../validation/is-empty";

export default function PostContractCostReportSelector(props) {
  const { data } = props;
  const { costReport } = props;
  const { setCostReport } = props;
  const { setComparisonCRs } = props;
  const { setVariations } = props;

  const [maxReports, setMaxReports] = useState(10);

  // All previous Cost Reports inclusive
  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(costReport)) {
      let temp = [];
      let stop = false;
      data.forEach((PC) => {
        if (!stop) {
          temp.push(PC);
        }
        if (PC.id === costReport.id) {
          stop = true;
        }
      });
      setComparisonCRs(temp);
    }
  }, [data, costReport, setComparisonCRs]);

  if (isEmpty(costReport)) {
    return null;
  }

  // Cost Report Page
  if (
    !window.location.href.includes("cost-report") &&
    !window.location.href.includes("risk-register") &&
    !window.location.href.includes("dashboard")
  ) {
    return null;
  }

  const { length } = data;
  let minValue = length - maxReports < 0 ? 0 : length - maxReports;
  let maxValue = length - 1;
  let currentValue = data.findIndex((e) => e.id === costReport.id);

  return (
    <div className="post-contract-register-selector">
      <PostContractSelectorOptions
        maxReports={maxReports}
        setMaxReports={setMaxReports}
        enableFilter={false}
        height={50}
      />
      <div>
        <div className="post-contract-stages-label">
          {tranStr("Cost Report") +
            " " +
            costReport.cost_report.cost_report_number}
        </div>
        <input
          className="post-contract-stages-slider"
          type="range"
          min={minValue}
          max={maxValue}
          value={currentValue}
          onChange={(e) => {
            let index = parseInt(e.target.value);
            setCostReport(data[index]);
            setVariations(data[index]);
          }}
        />
        <PostContractSliderTicks
          minValue={minValue}
          maxValue={maxValue}
          currentValue={currentValue}
          pcaLog={data.map(({ cost_report }) => cost_report.pcra_log)}
        />
      </div>
    </div>
  );
}
