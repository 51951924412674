import React from "react";
import PDPCDonutChart from "./PDPCDonutChart";
import isEmpty from "../../validation/is-empty";

import { generateChangeTypeData } from "../post-contract-variations/PostContractVariations.functions";

function DonutChangeTypeSummary(props) {
  const { variations } = props;

  // No Variations return empty
  if (isEmpty(variations)) {
    return null;
  }

  let changeTypeData = generateChangeTypeData(variations);

  return (
    <PDPCDonutChart
      data={changeTypeData}
      width={200}
      height={200}
      eRadius={90}
      iRadius={70}
      lWidth={270}
      lHeight={150}
    />
  );
}

const PDPCDonutChangeTypeSummary = React.memo(DonutChangeTypeSummary);

export default PDPCDonutChangeTypeSummary;
