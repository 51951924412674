// React
import React from "react";

// API
import { useUsersByGroup } from "../../api/users/UserAPI";

// Components
import Spinner from "../common/Spinner";
import Table from "../table/Table";
import { columns } from "./CreateProjectSelectCostManager.functions";
import { displayUserImage } from "../../validation/image-clean";

export default function CreateProjectSelectCostManager(props) {
  const { show, setShow } = props;
  const { setFunction } = props;

  const { users } = useUsersByGroup(show, "internal");

  if (users.loading) {
    return <Spinner marginTop={"170px"} marginBottom={"170px"} />;
  }

  const userArray = users.data.map((u) => {
    u.image = displayUserImage(u);
    return u;
  });

  return (
    <Table
      title={""}
      tableArray={userArray}
      columns={columns()}
      tableSize={15}
      isTitleDisabled={true}
      isTotalDisabled={true}
      // Row Selection
      selectedRow={{}}
      setSelectedRow={setFunction}
      isClickToClose={true}
      // Modal Control
      setModal={setShow}
    />
  );
}
