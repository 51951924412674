// React
import React from "react";

// Style
import "./PortfolioDashboardTitle.css";

export default function PortfolioDashboardTitle(props) {
  const { selectedPortfolio } = props;

  return (
    <div className="portfolio-title-banner-row">
      <div className="portfolio-title-banner">
        <div className="portfolio-title-banner-row-title">
          <div className="display-4">{selectedPortfolio.name}</div>
        </div>
      </div>
    </div>
  );
}
