import React, { useState } from "react";
import Table from "../table/Table";
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";
import { columns } from "./ProjectControlEstimateMetricsTable.functions";

export default function ProjectControlEstimateMetricsTable(props) {
  const { data } = props;
  const { dataLoading } = props;
  const { selectedData } = props;
  const { selectedDataLoading } = props;
  const { setSelectedData } = props;

  // Table Row Selection
  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(data) || dataLoading || selectedDataLoading) {
    return <div></div>;
  }

  return (
    <div>
      <h1
        className="display-4"
        style={{
          fontSize: "20px",
          marginBottom: "15px",
          color: "rgb(228, 97, 15)",
        }}
      >
        <b>
          Select a metric, or select multiple metrics of the same type to
          combine them into a single metric.
        </b>
      </h1>
      <Table
        title={tranStr("Cost Plan Table")}
        tableArray={data}
        columns={columns()}
        tableSize={8}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedData}
        setChecked={setSelectedData}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        modal={modal}
        setModal={setModal}
      />
    </div>
  );
}
