// React
import React from "react";
import { getDmsToken } from "../../active-directory/getDmsToken";

// Style
import "./Migration.css";

export default function Migration() {
  return (
    <div className="migration-container">
      <div
        className="migrate-button"
        id="button-migration-id-token"
        onClick={async () => {
          const idToken = localStorage.getItem("idToken");
          console.log("idToken", idToken);
        }}
      >
        ID Token
      </div>
      <div
        className="migrate-button"
        id="button-migration-dms-token"
        onClick={async () => {
          const dmsToken = await getDmsToken();
          console.log("dmsBearer", dmsToken.accessToken);
        }}
      >
        DMS Token
      </div>
    </div>
  );
}
