import React from "react";
import isEmpty from "../../../validation/is-empty";
import { translate } from "../../../utils/translation";
import PropTypes from "prop-types";

/// props validation | SQ(javascript:S6774)
CurrencyExchange.propTypes = {
  currencies: PropTypes.array,
};
export default function CurrencyExchange(props) {
  const { currencies } = props;
  if (isEmpty(currencies)) {
    return <div>{translate("There are no currencies available to show")}</div>;
  }
  currencies.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });
  return (
    <div>
      <div className="insight-path">
        <div className="path-link">Insights</div>
        <div className="path-divider">{" > "}</div>
        <div className="path-link">Currency Exchange</div>
      </div>
      <div className="location-factors-table">
        <TableHeader />
        <div className="location-factors-table-body">
          <TableRow />
        </div>
      </div>
    </div>
  );

  function TableRow() {
    return currencies.map((f, i) => {
      return (
        <div key={f.city} className="location-factors-table-row">
          <div className="location-factors-table-description-cell ">
            {`${f.name} (${f.code})`}
          </div>
          <div className="location-factors-table-quantity-cell ">{f.rate}</div>
        </div>
      );
    });
  }
}
function TableHeader() {
  return (
    <div className="location-factors-table-head-row">
      <div className="location-factors-table-description-cell ">Currency</div>
      <div className="location-factors-table-quantity-cell ">Exchange Rate</div>
    </div>
  );
}
