import React, { Fragment, useState, useEffect } from "react";
import { translate, tranStr } from "../../utils/translation";
import { IMPACT_RATING_SCALE } from "./OptionsAnalysisAPI.constants";

import "./OptionsAnalysisForm.css";
import { saveOptionsAnalysisItems } from "./OptionsAnalysis.functions";

const FORM_FIELDS = {
  SUMMARY: {
    label: tranStr("Summary"),
    className: "oa-title",
    dbLinkedField: "summary",
  },
  COST_IMPACT: {
    label: tranStr("Cost Impact"),
    className: "oa-text-field",
    dbLinkedField: "costImpact",
  },
  COST_IMPACT_RATING: {
    label: tranStr("Cost Impact Rating"),
    className: "oa-select-field",
    dbLinkedField: "costImpactRating",
    dataSource: IMPACT_RATING_SCALE,
  },
  PROGRAMME_IMPACT: {
    label: tranStr("Prog. Impact"),
    className: "oa-text-field",
    dbLinkedField: "programmeImpact",
  },
  PROGRAMME_IMPACT_RATING: {
    label: tranStr("Prog. Impact Rating"),
    className: "oa-select-field",
    dbLinkedField: "programmeImpactRating",
    dataSource: IMPACT_RATING_SCALE,
  },
  ENVIRONMENTAL_IMPACT: {
    label: tranStr("Env. Impact"),
    className: "oa-select-field",
    dbLinkedField: "environmentalImpact",
    dataSource: IMPACT_RATING_SCALE,
  },
  OPERATIONAL_COST_IMPACT: {
    label: tranStr("Opr. Cost Impact"),
    className: "oa-text-field",
    dbLinkedField: "operationalCostImpact",
  },
  OPERATIONAL_COST_IMPACT_RATING: {
    label: tranStr("Opr. Cost Impact Rating"),
    className: "oa-select-field",
    dbLinkedField: "operationalCostImpactRating",
    dataSource: IMPACT_RATING_SCALE,
  },
  OTHER_CONSIDERATIONS: {
    label: tranStr("Other Cons."),
    className: "oa-text-field",
    dbLinkedField: "otherConsiderations",
  },
  DELETE: {
    label: null,
    className: "advice-delete-button",
    headingClass: "advice-delete-spacer",
  },
};

export default function OptionsAnalysisForm(props) {
  const { project } = props;
  const { setModal } = props;
  const { options, getOptions, setOptions } = props;
  const { stageId } = props;

  const [error, setError] = useState(null);

  const [formRows, setFormRows] = useState([]);

  useEffect(() => {
    // Set the formRows state with the filtered data from optionsAnalysisItems when options changes
    const filteredItems =
      options?.optionsAnalysisStages?.find((stage) => stage.id === stageId)
        ?.optionsAnalysisItems || [];
    setFormRows(filteredItems);
  }, [options, stageId]);

  let data = {
    project,
    stageId,
    setModal,
    formRows,
    getOptions,
    setOptions,
    setError,
  };

  return (
    <div className="project-control-advice options-analysis-modal-form">
      <div className="advice-title">
        {`Add Options Analysis Items to the stage.`}
      </div>

      <div className="options-analysis-inputs-grid">
        {/* Headings */}
        <HeadingRow />

        {/* Data rows */}
        <InputRows formRows={formRows} setFormRows={setFormRows} />
      </div>

      <div className="general-row-container">
        <AddFormRow setFormRows={setFormRows} />
      </div>
      <div className="general-row-container">
        <div>
          <div>
            {error && (
              <div className={`error-message ${error.type?.toLowerCase()}`}>
                {error.text}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="general-button-container">
        <div
          id={"button-save-oa"}
          className="general-upload-button"
          onClick={async () => {
            saveOptionsAnalysisItems(data);
          }}
        >
          {translate("Save")}
        </div>
      </div>
    </div>
  );
}

function InputRows({ formRows, setFormRows }) {
  return (
    <>
      {formRows.map((formRow, index) => {
        const formRowKey = `oa-field-${index}`;
        return (
          <Fragment key={formRowKey}>
            {Object.keys(FORM_FIELDS).map((name) => {
              const field = FORM_FIELDS[name];
              const { dbLinkedField, className, dataSource } = field;
              const fieldKey = `${formRowKey}-${dbLinkedField}`;
              return (
                dbLinkedField && (
                  <FieldInput
                    key={fieldKey}
                    index={index}
                    className={className}
                    dbLinkedField={dbLinkedField}
                    dataSource={dataSource}
                    formRow={formRow}
                    formRows={formRows}
                    setFormRows={setFormRows}
                  />
                )
              );
            })}

            <div
              className={FORM_FIELDS.DELETE.className}
              id={"delete-form-field"}
              onClick={() => handleDeleteRow(index, setFormRows)}
            >
              <i className="far fa-trash-alt" />
            </div>
          </Fragment>
        );
      })}
    </>
  );
}

function FieldInput({
  index,
  className,
  dbLinkedField,
  dataSource,
  formRow,
  formRows,
  setFormRows,
}) {
  const handleFieldInputChange = (e) =>
    updateField(dbLinkedField, e.target.value, index, formRows, setFormRows);

  if (className.includes("select-field")) {
    return (
      <Select
        className={className}
        dataSource={dataSource}
        value={formRow[dbLinkedField]}
        onChange={handleFieldInputChange}
      />
    );
  }

  return (
    <Input
      className={className}
      value={formRow[dbLinkedField]}
      onChange={handleFieldInputChange}
    />
  );
}

function Select({ className, dataSource, value, onChange }) {
  return (
    <select className={className} value={value} onChange={onChange}>
      {Object.keys(dataSource || {}).map((name) => {
        const { display, value } = dataSource[name];
        const key = `${name}_${value}`;
        // convert value to string before placing into <option>
        return (
          <option key={key} value={`${value}`}>
            {value} - {display}
          </option>
        );
      })}
    </select>
  );
}

function Input({ className, value, onChange }) {
  return (
    <input
      className={className}
      value={value}
      maxLength={200}
      onChange={onChange}
    />
  );
}

function updateField(field, value, index, formRows, setFormRows) {
  let temp = [...formRows];
  temp[index][field] = value;

  setFormRows(temp);
}

function HeadingRow() {
  const formRowKey = `oa-field-heading`;
  return (
    <>
      {Object.keys(FORM_FIELDS).map((name) => {
        const field = FORM_FIELDS[name];
        const { dbLinkedField, className, label } = field;
        const fieldKey = `${formRowKey}-${dbLinkedField}`;
        return (
          dbLinkedField && (
            <div key={fieldKey} className={className}>
              <b>{label}</b>
            </div>
          )
        );
      })}

      <div className={FORM_FIELDS.DELETE.headingClass}></div>
    </>
  );
}

function handleDeleteRow(rowIndex, setFormRows) {
  setFormRows((prevRows) => {
    const updatedRows = [...prevRows];
    updatedRows.splice(rowIndex, 1);
    return updatedRows;
  });
}

function AddFormRow(props) {
  const { setFormRows } = props;
  return (
    <div
      id={"button-add-oa"}
      className="general-modal-button"
      onClick={() => {
        setFormRows((prevRows) => [
          ...prevRows,
          {
            summary: "",
            costImpact: "",
            programmeImpact: "",
            environmentalImpact: "",
            operationalCostImpact: "",
            otherConsiderations: "",
          },
        ]);
      }}
    >
      {translate("Add Field")}
    </div>
  );
}
