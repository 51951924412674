import React, { Component } from "react";
import PropTypes from "prop-types";

import { Doughnut } from "react-chartjs-2";
import isEmpty from "../../validation/is-empty";

import "./BenchmarkDoughnutChart.css";

/// props validation | SQ(javascript:S6774)
// PropTypes defined after class body
///
export default class BenchmarkDoughnutChart extends Component {
  render() {
    const { data, data2, title, thisBenchmark, caller } = this.props;
    let labels = [title, "Benchmark Average"];
    let field = [];
    let value = [];
    let value2 = [];
    let percentages = [[], []];
    let isMasterBenchmarking = caller === "MASTER_BENCHMARKING";

    data.forEach((d, i) => {
      // If the comparison dataset has a matching section
      if (d !== undefined && !isEmpty(data2[i])) {
        let name = d.name;
        field.push(name);
        value.push(d.quantity);
        value2.push(data2[i].quantity);
        percentages[0].push(d.percentage);
        percentages[1].push(data2[i].percentage);
      }

      // If the comparison dataset does not have a matching section
      if (d !== undefined && isEmpty(data2[i])) {
        let name = d.name;
        field.push(name);
        value.push(d.quantity);
        value2.push(0);
        percentages[0].push(d.percentage);
        percentages[1].push(0);
      }
    });

    // Colours
    let heritage = "rgba(228, 97, 15, 1)";
    let aqua = "rgba(50, 98, 149, 1)";
    let collaboration1 = "rgba(85, 87, 90, 1)";
    let heritage2 = "rgba(228, 97, 15, 0.5)";
    let aqua2 = "rgba(50, 98, 149, 0.5)";
    let collaboration2 = "rgba(85, 87, 90, 0.7)";
    let collaboration3 = "rgba(85, 87, 90, 0.3)";
    let collaboration4 = "rgba(184, 228, 198, 0.3)";
    let collaboration5 = "rgba(184, 228, 198, 0.8)";

    let colours = [
      heritage,
      aqua,
      collaboration1,
      aqua2,
      heritage2,
      collaboration2,
      collaboration3,
      collaboration4,
      collaboration5
    ];

    let datasets = [
      {
        label: "Project",
        data: value,
        barThickness: 10,
        backgroundColor: colours,
      },
      {
        label: "Benchmark",
        data: value2,
        barThickness: 2,
        backgroundColor: colours,
      },
    ];
    let cutoutPercentage = 70;
    if (isMasterBenchmarking) {
      datasets.pop();
      cutoutPercentage = 80;
    }

    // Create the graph object
    let graph = {
      labels: field,
      datasets: datasets,
      text: field,
    };

    // Create the options object
    let options = {
      plugins: {
        datalabels: {
          display: false,
          color: "gray",
          formatter: function (tick, context) {
            return percentages[context.datasetIndex][context.dataIndex] + "%";
          },
        },
      },
      cutoutPercentage: cutoutPercentage,
      animation: {
        duration: 2000,
      },
      tooltips: {
        enabled: true,
        xPadding: 10,
        yPadding: 10,
        callbacks: {
          label: function (tooltipItem, chart) {
            return (
              labels[tooltipItem.datasetIndex] +
              " | " +
              chart.labels[tooltipItem.index] +
              " | " +
              percentages[tooltipItem.datasetIndex][tooltipItem.index] +
              "%"
            );
          },
        },
      },
      layout: {
        padding: {
          right: 20,
          top: 20,
        },
      },
      legend: {
        display: false,
        position: "right",
        fullWidth: true,
        reverse: false,
        labels: {
          usePointStyle: true,
        },
      },
    };

    return (
      <div>
        {!isMasterBenchmarking && (
          <div className="legend-project-label">Project</div>
        )}
        <div className="legend-benchmark-label">Benchmark Average</div>

        {!isMasterBenchmarking && (
          <svg className="legend-svg1">
            <polyline className="legend-svg-line1" points="7,40 50,40 70,60" />
          </svg>
        )}
        <svg className="legend-svg2">
          <polyline className="legend-svg-line2" points="143,20 20,20 0,0" />
        </svg>

        <div className="benchmarking-cost-summary-comparison">
          <div className="benchmarking-cost-summary-comparison-chart">
            <Doughnut data={graph} options={options} height={240} />
          </div>
          <div className="benchmarking-cost-summary-comparison-legend">
            <Legend
              data={data}
              colours={colours}
              thisBenchmark={thisBenchmark}
            />
          </div>
        </div>
      </div>
    );
  }
}
/// props validation | SQ(javascript:S6774)
BenchmarkDoughnutChart.propTypes = {
  data: PropTypes.any,
  data2: PropTypes.any,
  title: PropTypes.string,
  thisBenchmark: PropTypes.object,
  caller:PropTypes.string
};
///

function Legend(props) {
  const { data, colours } = props;

  let legend = data.map((o, i) => {
    o.colour = colours[i];
    return o;
  });

  return legend.map((item) => {
    let classType = "benchmark-legend-row";

    if (item.total) {
      return (
        <div className={classType} key={item.name}>
          <div className="benchmark-legend-label">
            <div className="benchmark-legend-circle-container">
              <div
                className="benchmark-legend-circle"
                style={{ backgroundColor: item.colour }}
              />
            </div>
            <b>{item.name}</b>
          </div>
        </div>
      );
    }

    return (
      <div className={classType} key={item.name}>
        <div className="benchmark-legend-label">
          <div className="benchmark-legend-circle-container">
            <div
              className="benchmark-legend-circle"
              style={{ backgroundColor: item.colour }}
            />
          </div>
          {item.name}
        </div>
      </div>
    );
  });
}
