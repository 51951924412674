import isEmpty from "../../validation/is-empty";

export function processPermissionsPayload(data) {
  // Get Data
  const { existingUsers } = data;
  const { usersSelected } = data;
  const { project_leader } = data;
  const { peer_reviewer } = data;
  const {companyId}=data;

  const uniqueUsers = new Set();
  usersSelected.forEach((u) => {
    uniqueUsers.add(u.toString());
  });
  if (!isEmpty(project_leader)) uniqueUsers.add(project_leader);
  if (!isEmpty(peer_reviewer)) uniqueUsers.add(peer_reviewer);
  const users = Array.from(uniqueUsers);

  // Get IDs
  const currentIDs = existingUsers.map((u) => u.id.toString());
  const completeIDs = users.map((u) => u);

  // Two sets of IDs
  const removedIDs = currentIDs.filter((x) => !completeIDs.includes(x));
  const addedIDs = completeIDs.filter((x) => !currentIDs.includes(x));
  const updatedIDs = currentIDs.filter((x) => !removedIDs.includes(x));

  // Form Payload
  const payload = [];

  // Users to remove
  removedIDs.forEach((id) => {
    payload.push({
      userGlobalId: id.toString(),
      userRoleList: ["USER"],
      isRecordDeleted: true,
      companyId:companyId
    });
  });

  // Users to add
  addedIDs.forEach((id) => {
    if (id.toString() === project_leader.toString()) {
      payload.push({
        userGlobalId: id.toString(),
        userRoleList: ["PROJECT_LEADER"],
        isRecordDeleted: false,
        companyId:companyId
      });
    } else if (id.toString() === peer_reviewer.toString()) {
      payload.push({
        userGlobalId: id.toString(),
        userRoleList: ["PEER_REVIEWER"],
        isRecordDeleted: false,
        companyId:companyId
      });
    } else {
      payload.push({
        userGlobalId: id.toString(),
        userRoleList: ["USER"],
        isRecordDeleted: false,
        companyId:companyId
      });
    }
  });

  // Users to update
  updatedIDs.forEach((id) => {
    if (id.toString() === project_leader.toString()) {
      payload.push({
        userGlobalId: id.toString(),
        userRoleList: ["PROJECT_LEADER"],
        isRecordDeleted: false,
        companyId:companyId
      });
    } else if (id.toString() === peer_reviewer.toString()) {
      payload.push({
        userGlobalId: id.toString(),
        userRoleList: ["PEER_REVIEWER"],
        isRecordDeleted: false,
        companyId:companyId
      });
    } else {
      payload.push({
        userGlobalId: id.toString(),
        userRoleList: ["USER"],
        isRecordDeleted: false,
        companyId:companyId
      });
    }
  });

  return payload;
}
