import { detectDeployment } from "./Utilities";

export function bmAPIs() {
  const config = {
    serviceAPI: true,
   localService:
  //  "https://localhost:44382/v1.0",
      "https://dev.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0",
    deployedService: "/postcontract/apis/benchmarkingservice/v1.0",
  };

  // Deployed local "https://localhost:44382/v1.0"
  // Deployed dev   "https://dev.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0"
  // Deployed test  "https://test.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0"
  // Deployed prd   "https://www.cost-clarity.com/postcontract/apis/benchmarkingservice/v1.0"

  const D = detectDeployment(config);

  return {
    // Create Benchmarks
    create_benchmark: `${D}/benchmarking/create_benchmark`,
    upload_benchmark: `${D}/benchmarking/upload_benchmark`,

    // Read Benchmarks
    read_benchmarks_complete: `${D}/benchmarking/read_benchmarks_complete_by_ids`,
    read_benchmarks_core: `${D}/benchmarking/read_benchmarks_core`,
    get_benchmarks_sensitivity_level: `${D}/benchmarking/get_benchmarks_sensitivity_level`,

    // Update Benchmarks
    update_benchmarks_tpi_city: `${D}/benchmarking/update_benchmarks_tpi_city`,
    update_benchmarks_comparisons: `${D}/benchmarking/update_benchmarks_comparisons`,
    update_benchmarks_comparisons_anonymity: `${D}/benchmarking/update_benchmarks_comparisons_anonymity`,
    update_benchmarks_info_sensitivity_level: `${D}/benchmarking/update_benchmarks_info_sensitivity_level`,
    update_benchmarks_info_company_id:`${D}/benchmarking/update_benchmarks_info_company_id`,
    update_benchmarks_info_coordinates:`${D}/benchmarking/update_benchmarks_info_coordinates`,

    // Delete Benchmark
    delete_benchmarks: `${D}/benchmarking/delete_benchmarks`,

    // CBS
    create_cbs: `${D}/benchmarking/create_cbs`,
    get_cbs: `${D}/benchmarking/read_cbs`,
    update_cbs_publish: `${D}/benchmarking/update_cbs_publish`,
    update_cbs: `${D}/benchmarking/update_cbs`,
    delete_cbs: `${D}/benchmarking/delete_cbs`,
    get_cbs_type_codes: `${D}/benchmarking/read_cbs_type_codes`,

    // CBS Elements
    add_element: `${D}/benchmarking/add_element`,
    edit_element: `${D}/benchmarking/edit_element`,
    move_element: `${D}/benchmarking/move_element`,
    delete_element: `${D}/benchmarking/delete_element`,

    // CBS Categories
    add_category_item: `${D}/benchmarking/add_category_item`,
    edit_category_item: `${D}/benchmarking/edit_category_item`,
    delete_category_item: `${D}/benchmarking/delete_category_item`,
  };
}
