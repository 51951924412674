import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./CostReportRetentionAnalysisTable.css";
import { commaToFixed } from "../../helpers/common.functions";

// Variation
export default function CostReportRetentionAnalysisTable(props) {
  const { CR } = props;
  if (isEmpty(CR)) {
    return null;
  }

  const PC = CR.progress_claim;

  if (isEmpty(PC)) {
    return null;
  }

  return (
    <div className="retention-status">
      <div className="retention-status-table">
        <Row
          title={"Construction Costs Expenditure to Date"}
          value={checkUndefined(PC.gross_total_work_executed_excl_tax)}
        />
        <Row title={"Deduct Rentention"} value={checkUndefined(PC.deduct_security)} />
        <Row
          title={"Construction Costs Payment to Date"}
          value={checkUndefined(PC.gross_total_work_executed_excl_tax) - checkUndefined(PC.deduct_security)}
        />
      </div>
    </div>
  );
}

function Row(props) {
  const { title, value } = props;
  return (
    <div className="retention-status-row">
      <div className="retention-status-label">{translate(title)}</div>
      <div className="retention-status-value">{commaToFixed(value)}</div>
    </div>
  );
}

export function checkUndefined(value) {
  return value === undefined ? 0 : value;
}
