// React
import React from "react";

export default function EnvironmentalCost(props) {
  return (
    <div className="alc-column">
      <div>
        <div className="toggle-button-text-container">
          <p></p>
          <p></p>
          <p>
            Arcadis is aware that a successful project is one that minimises the
            impact on the environment, as well as being delivered in a cost
            effective way. <br />
            As well as providing advice on the Construction cost of your asset,
            Arcadis can also help you to assess the impact of your project on
            the environment
            <br />
            using our environmental assessment tool, MKI. This tool indicates
            the environmental cost associated with your design by considering
            the wider <br />
            ecological impacts of design decisions, including:
          </p>
          <ul>
            <li>Air pollution (smog and ozone layer depletion)</li>
            <li>Climate change</li>
            <li>Raw material usage</li>
            <li>Toxicity to ecosystems</li>
          </ul>
          <p>
            For more information on MKI and Environmental Costs Indicator,
            please get in touch with <b>Jan Zandbergen</b> (
            <a href={"mailto:jan.zandbergen@arcadis.com"}>
              {"jan.zandbergen@arcadis.com"}
            </a>
            ).
          </p>
        </div>
      </div>
    </div>
  );
}
