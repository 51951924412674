import React from "react";
import isEmpty from "../../validation/is-empty";
import { Bar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";

import "./CostReportComparisonChart.css";

import { tranStr, translate } from "../../utils/translation";

export default function CostReportComparisonChart(props) {
  const { CRs } = props;

  if (isEmpty(CRs)) {
    return null;
  }

  const reversedCRs = [...CRs].reverse();

  const field = [];
  const constructionCost = [];
  const forecastVariations = [];
  const submittedVariations = [];
  const approvedVariations = [];
  const provisionalQuantities = [];
  const provisionalSums = [];
  const delaysEoTs = [];
  const clientDirects = [];

  reversedCRs.forEach((CR) => {
    field.push(
      tranStr("Cost Report") + " " + CR.cost_report.cost_report_number
    );
    if (CR.cost_report.construction_cost_forecast_total !== 0) {
      constructionCost.push(CR.cost_report.construction_cost_forecast_total);
    }
    if (CR.cost_report.variations_forecast !== 0) {
      forecastVariations.push(CR.cost_report.variations_forecast);
    }
    if (CR.cost_report.variations_submitted !== 0) {
      submittedVariations.push(CR.cost_report.variations_submitted);
    }
    if (CR.cost_report.variations_approved) {
      approvedVariations.push(CR.cost_report.variations_approved);
    }
    if (CR.cost_report.provisional_quantities_forecast_total !== 0) {
      provisionalQuantities.push(
        CR.cost_report.provisional_quantities_forecast_total
      );
    }
    if (CR.cost_report.provisional_sums_forecast_total !== 0) {
      provisionalSums.push(CR.cost_report.provisional_sums_forecast_total);
    }
    if (CR.cost_report.delays_and_extensions_of_time_forecast_total !== 0) {
      delaysEoTs.push(
        CR.cost_report.delays_and_extensions_of_time_forecast_total
      );
    }
    const objKey = "forecast_total";
    const clientDirectCosts =
      CR.cost_report[`consultants_fees_${objKey}`] +
      CR.cost_report[`client_staffing_${objKey}`] +
      CR.cost_report[`authority_fees_${objKey}`] +
      CR.cost_report[`legal_fees_${objKey}`] +
      CR.cost_report[`ffe_${objKey}`] +
      CR.cost_report[`loose_furniture_${objKey}`] +
      CR.cost_report[`workstations_${objKey}`] +
      CR.cost_report[`audio_visual_${objKey}`] +
      CR.cost_report[`specialist_equipment_${objKey}`] +
      CR.cost_report[`ict_${objKey}`] +
      CR.cost_report[`relocation_${objKey}`] +
      CR.cost_report[`other1_${objKey}`] +
      CR.cost_report[`other2_${objKey}`] +
      CR.cost_report[`other3_${objKey}`];
    if (clientDirectCosts !== 0) {
      clientDirects.push(clientDirectCosts);
    }
  });

  const dataSetArray = [];

  const heritage1 = "rgba(228, 97, 15, 1)";
  const aqua1 = "rgba(50, 98, 149, 1)";
  const collaboration1 = "rgba(85, 87, 90, 1)";

  const heritage2 = "rgba(228, 97, 15, 0.7)";
  const aqua2 = "rgba(50, 98, 149, 0.7)";
  const collaboration2 = "rgba(85, 87, 90, 0.7)";

  const heritage3 = "rgba(228, 97, 15, 0.3)";
  const aqua3 = "rgba(50, 98, 149, 0.3)";

  const barThickness = 10;
  if (!isEmpty(constructionCost)) {
    dataSetArray.push({
      label: tranStr("Construction Cost"),
      data: constructionCost,
      barThickness,
      backgroundColor: heritage1,
    });
  }
  if (!isEmpty(forecastVariations)) {
    dataSetArray.push({
      label: tranStr("Forecast Variations"),
      data: forecastVariations,
      barThickness,
      backgroundColor: aqua1,
    });
  }
  if (!isEmpty(submittedVariations)) {
    dataSetArray.push({
      label: tranStr("Submitted Variations"),
      data: submittedVariations,
      barThickness,
      backgroundColor: collaboration1,
    });
  }
  if (!isEmpty(approvedVariations)) {
    dataSetArray.push({
      label: tranStr("Approved Variations"),
      data: approvedVariations,
      barThickness,
      backgroundColor: heritage2,
    });
  }
  if (!isEmpty(provisionalQuantities)) {
    dataSetArray.push({
      label: tranStr("Provisional Quantities"),
      data: provisionalQuantities,
      barThickness,
      backgroundColor: aqua2,
    });
  }
  if (!isEmpty(provisionalSums)) {
    dataSetArray.push({
      label: tranStr("Provisional Sums"),
      data: provisionalSums,
      barThickness,
      backgroundColor: collaboration2,
    });
  }
  if (!isEmpty(delaysEoTs)) {
    dataSetArray.push({
      label: tranStr("Delays / EoTs"),
      data: delaysEoTs,
      barThickness,
      backgroundColor: heritage3,
    });
  }
  if (!isEmpty(clientDirects)) {
    dataSetArray.push({
      label: tranStr("Client Directs"),
      data: clientDirects,
      barThickness,
      backgroundColor: aqua3,
    });
  }

  // Create the graph object
  let graph = {
    labels: field,
    datasets: dataSetArray,
    text: field,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return (
            comma(tooltipItem.yLabel) +
            " (" +
            chart.datasets[tooltipItem.datasetIndex].label +
            ")"
          );
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 15,
        bottom: 15,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          position: "left",
          stacked: true,
          ticks: {
            callback: function (value, index, values) {
              return comma(Math.round(value));
            },
          },
        },
      ],
    },

    legend: {
      display: true,
      position: "bottom",
      fullWidth: true,
      reverse: false,
    },
  };

  return (
    <div>
      <div className="cost-report-comparison-chart-hint">
        {translate("Data sets can be toggled when clicked in the legend.")}
      </div>
      <Bar data={graph} options={options} height={200} />
    </div>
  );
}
