import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { translate, tranStr } from "../../utils/translation";
import { comma } from "../../helpers/common.functions";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import "./EstimatesBenchmarkDonutChart.css";



export default class EstimatesBenchmarkDonutChart extends Component {
  render() {
    const { data, data2, projectName, benchmarks, thisBenchmark } = this.props;

    let labels = [projectName, "Benchmark Average"];
    let field = [];
    let value = [];
    let value2 = [];
    let percentages = [[], []];

    data.forEach((d, i) => {
      // If the comparison dataset has a matching section
      if (d !== undefined && !isEmpty(data2[i])) {
        field.push(d.name);
        value.push(d.quantity);
        value2.push(data2[i].quantity);
        percentages[0].push(d.percentage);
        percentages[1].push(data2[i].percentage);
      }

      // If the comparison dataset does not have a matching section
      if (d !== undefined && isEmpty(data2[i])) {
        field.push(d.name);
        value.push(d.quantity);
        value2.push(0);
        percentages[0].push(d.percentage);
        percentages[1].push(0);
      }
    });

    // Colours
    let heritage = "rgba(228, 97, 15, 1)";
    let aqua = "rgba(50, 98, 149, 1)";
    let collaboration1 = "rgba(85, 87, 90, 1)";
    let heritage2 = "rgba(228, 97, 15, 0.5)";
    let aqua2 = "rgba(50, 98, 149, 0.5)";
    let collaboration2 = "rgba(85, 87, 90, 0.7)";
    let collaboration3 = "rgba(85, 87, 90, 0.3)";

    let colours = [
      heritage,
      aqua,
      collaboration1,
      aqua2,
      heritage2,
      collaboration2,
      collaboration3,
    ];

    // Create the graph object
    let graph = {
      labels: field,
      datasets: [
        {
          label: tranStr("Project"),
          data: value,
          barThickness: 10,
          backgroundColor: colours,
        },
        {
          label: tranStr("Benchmark"),
          data: value2,
          barThickness: 10,
          backgroundColor: colours,
        },
      ],
      text: field,
    };

    // Create the options object
    let options = {
      plugins: {
        datalabels: {
          display: false,
          color: "gray",
          formatter: function (tick, context) {
            return percentages[context.datasetIndex][context.dataIndex] + "%";
          },
        },
      },
      cutoutPercentage: 70,
      animation: {
        duration: 2000,
      },
      tooltips: {
        enabled: true,
        xPadding: 10,
        yPadding: 10,
        callbacks: {
          label: function (tooltipItem, chart) {
            return (
              labels[tooltipItem.datasetIndex] +
              " | " +
              chart.labels[tooltipItem.index] +
              " | " +
              percentages[tooltipItem.datasetIndex][tooltipItem.index] +
              "%"
            );
          },
        },
      },
      layout: {
        padding: {
          // // left: 10,
          // // right: 10,
          // top: 10,
          // bottom: 10,
        },
      },
      legend: {
        display: false,
        align: "start",
        position: "bottom",
        fullWidth: false,
        reverse: false,
        labels: {
          usePointStyle: true,
        },
      },
    };

    return (
      <div className="estimate-dashboard">
        {!isEmpty(benchmarks) && (
          <div className="legend-project-label">{tranStr("Project")}</div>
        )}
        {!isEmpty(benchmarks) && (
          <div className="legend-benchmark-label">
            {tranStr("Benchmark Avg")}
          </div>
        )}
        {!isEmpty(benchmarks) && (
          <svg className="legend-svg1">
            <polyline className="legend-svg-line1" points="7,40 50,40 70,60" />
          </svg>
        )}
        {!isEmpty(benchmarks) && (
          <svg className="legend-svg2">
            <polyline
              className="legend-svg-line2"
              points="143,20 20,20 0,0  "
            />
          </svg>
        )}
        <div className="estimate-construction-cost-comparison">
          <Doughnut data={graph} options={options} height={160} />
        </div>
        <Legend data={data.filter(item=>(item.value!==0))} colours={colours} thisBenchmark={thisBenchmark} />
      </div>
    );
  }
}
EstimatesBenchmarkDonutChart.propTypes = {
  data: PropTypes.array,
};

/// props validation | SQ(javascript:S6774)
Legend.propTypes = {
  data: PropTypes.object,
};
///
function Legend(props) {
  const { data, colours, thisBenchmark } = props;

  let legend = data.map((o, i) => {
    o.colour = colours[i];
    return o;
  });

  let displayTotal = Math.round(sum(legend));

  const constructionCost = thisBenchmark?.construction_cost;
  // Calculate Difference
  const difference = constructionCost - displayTotal;
  // Difference is less than positive 5 it is rounding only
  if (difference <= 5) {
    displayTotal = constructionCost;
  }

  // Difference is less than negative -5 it is rounding only
  if (difference >= -5) {
    displayTotal = constructionCost;
  }

  let total = {
    quantity: Math.round(displayTotal),
    name: translate("Total Construction Cost"),
    total: true,
    colour: null,
  };

  legend.push(total);

  return legend.map((item) => {
    let classType = "estimate-benchmark-legend-row";

    if (item.total) {
      return (
        <div className={classType} key={item.name}>
          <div className="estimate-benchmark-legend-label">
            <div
              className="estimate-benchmark-legend-circle"
              style={{ backgroundColor: item.colour }}
            />
            <b>{item.name}</b>
          </div>
          <div className="estimate-benchmark-legend-number">
            <b> {comma(item.quantity)}</b>
          </div>
        </div>
      );
    }

    return (
      <div className={classType} key={item.name}>
        <div className="estimate-benchmark-legend-label">
          <div
            className="estimate-benchmark-legend-circle"
            style={{ backgroundColor: item.colour }}
          />
          {item.name}
        </div>
        <div className="estimate-benchmark-legend-number">
          {comma(item.quantity)}
        </div>
      </div>
    );
  });
}

function sum(array) {
  let theSum = 0;

  array.forEach((item) => {
    theSum += item.quantity || 0;
  });

  return theSum;
}
