import React from "react";

import "./PostContractTableContainer.css";

export default function PostContractTableContainer(props) {
  const { Components } = props;

  const display = [];

  Components.forEach((Component, i) => {
    display.push(
      <div key={i} className="pctc-visual">
        {Component}
      </div>
    );
  });

  return <div className="pctc">{display}</div>;
}
