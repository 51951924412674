import React from "react";
import generateXLSX from "../../utils/spreadsheets";

export default function ExportAccounts(props) {
  const { accounts } = props;
  const headings = ["Name", "Surname", "Email", "Company", "Role"];
  const data = [];

  // For each row of data we want to compose a user
  accounts.data.forEach((r) => {
    // The user array
    const user = [];

    user.push(r.name);
    user.push(r.surname);
    user.push(r.email);
    user.push(r.company_name);
  });
  data.unshift(headings);

  return (
    <div
      className="estimate-export-button"
      onClick={() => {
        generateXLSX("Cost Clarity Accounts", data);
      }}
    >
      <i className="fas fa-download"></i>
    </div>
  );
}
