import React from "react";
import TableViewer from "./CommentaryTableViewer";
import TextViewer from "../text-editor/TextViewer";

export default function DisplayCommentary(props) {
  const { c } = props;

  if (c.type === "TEXT") {
    return <TextViewer text={c.text}></TextViewer>;
  }

  if (c.type === "TABLE") {
    return (
      <div className="ect-container">
        <TextViewer text={c.text}></TextViewer>
        <TableViewer table={c}></TableViewer>
      </div>
    );
  }
}
