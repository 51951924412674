import React, { useState, useEffect } from "react";
import { calculateVersionNumber } from "../cost-planning/CostPlanningUpload.functions";
import { tranStr, translate } from "../../utils/translation";
import {
  onSubmit,
  calculateCostPlan,
} from "./ValueEngineeringDuplicateCP.functions";

import "../dropzone/Dropzone.css";

import isEmpty from "../../validation/is-empty";
export default function ValueEngineeringDuplicateCPModal(props) {
  const { CPs, project } = props;
  const { CP } = props;
  const { setShow } = props;

  const [stage, setStage] = useState(1);
  const [revision, setRevision] = useState(1);
  const [error, setError] = useState({});
  const [costPlanId, setCostPlanId] = useState("");

  // Establish Stage Number
  useEffect(() => {
    // If a selectedStage is available use it
    if (!isEmpty(CP)) {
      setStage(CP.stage);
      setCostPlanId(CP.cost_plan_id);
    } else if (!isEmpty(CPs.data)) {
      // Otherwise use the first available stage
      setStage(CPs.data[0].stage);
    }
  }, [CPs.data, CP]);

  // Establish Starting Version Number
  useEffect(() => {
    if (!isEmpty(CPs)) {
      setRevision(calculateVersionNumber(CPs, stage));
      //If the stage was changed using the dropdown
      if (stage !== 1 && CP.stage !== stage) {
        setCostPlanId(calculateCostPlan(CPs, stage, revision));
      }
    }
  }, [CPs, stage, revision, CP.stage]);

  if (isEmpty(CPs)) {
    return null;
  }
  let data = {
    revision: revision,
    setError: setError,
    costPlanId: costPlanId,
    project_id: project.id,
    setShow: setShow,
    CPs: CPs,
    CP: CP,
    project: project,
  };

  return (
    <div className="cost-planning-upload">
      <div className="general-row-container">
        This will create a new Cost Plan which will include any approved Value
        Engineering items as a new Summary item in the related Estimates. Please
        select the Cost Plan Stage for this new Estimate:
      </div>
      <div className="general-row-container">
        <div className="field-name">{translate("Create in Stage")}:</div>

        <select
          value={stage}
          className="cost-planning-upload-control-input"
          onChange={(e) => {
            setStage(e.target.value);
          }}
        >
          {selectStages(CPs.data)}
        </select>
      </div>
      <div className="row-container">
        <div className="field-name">{translate("Release")}:</div>
        <div className="cost-planning-upload-control-input">
          {tranStr("Release") + " " + revision + " " + tranStr("(New)")}
        </div>
      </div>
      <div className="row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <div
          id="button-cp-duplicate"
          className="general-modal-button-disabled"
          onClick={() => {
            console.log(onSubmit);
            console.log(data);
          }}
        >
          {translate("Coming Soon")}
        </div>
      </div>
    </div>
  );
}

function DisplayError(props) {
  const { error } = props;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
function selectStages(array) {
  return array.map(function (item, i) {
    //show only the stages which has cost plans
    if (item.stage_name !== "" && !isEmpty(item.versions)) {
      return (
        <option key={item.stage} value={item.stage}>
          {item.stage_name}
        </option>
      );
    } else {
      return null;
    }
  });
}
