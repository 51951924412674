// React
import React from "react";
import { Link } from "react-router-dom";
// API
import "./PortfolioControlRow.css";

export default function PortfolioControlRow(props) {
  const { portfolio } = props;
  const { portfolioId } = props;
  const { setIsDropEnabled } = props;
  const { setIsEditEnabled } = props;

  let classType = "portfolio-control-item";

  if (portfolio.projectIds && portfolio.projectIds.length < 1) {
    classType = "portfolio-control-item-empty";
  }

  if (portfolioId === portfolio.id) {
    classType = "portfolio-control-item-selected";
  }

  return (
    <div
      onClick={() => {
        // Clear out the previously selected portfolio
        setIsDropEnabled(false);
        setIsEditEnabled(false);
      }}
    >
      <Link to={`/portfolio/${portfolio.id}/projects/`} className={classType}>
        {portfolio.name}
      </Link>
    </div>
  );
}
