import React, { useState, useEffect } from "react";

import PostContractSelectorOptions from "./PostContractSelectorOptions";
import PostContractSliderTicks from "./PostContractSliderTicks";
import { tranStr } from "../../utils/translation";
import { filterReports } from "./PostContractCostReportRegister.functions";
import isEmpty from "../../validation/is-empty";

export default function PostContractCostReportRegisterSelector(props) {
  const { data } = props;
  const { register } = props;
  const { setRegister } = props;
  const { registerIssued } = props;
  const { registerType } = props;
  const { visibleLocations } = props;

  const [maxReports, setMaxReports] = useState(10);
  const [filters, setFilters] = useState([
    { label: tranStr("Cost Reports"), value: "Cost Reports" },
    { label: tranStr("Progress Claims"), value: "Progress Claims" },
    { label: tranStr("Variation Updates"), value: "Variation Updates" },
  ]);
  const [filteredVariations, setFilteredVariations] = useState([]);

  // Filter variations
  useEffect(() => {
    if (!isEmpty(data)) {
      const filtered = filterReports(data, filters);
      setFilteredVariations(filtered);
    }
  }, [data, filters]);

  // Set to filtered
  useEffect(() => {
    if (!isEmpty(filteredVariations)) {
      const latestVR = filteredVariations[filteredVariations.length - 1];
      setRegister(latestVR);
    }
  }, [filteredVariations, setRegister]);

  if (isEmpty(register)) {
    return null;
  }

  // Pages to display
  if (!visibleLocations.some((e) => window.location.href.includes(e))) {
    return null;
  }

  const { length } = filteredVariations;
  let minValue = length - maxReports < 0 ? 0 : length - maxReports;
  let maxValue = length - 1;
  let currentValue = filteredVariations.findIndex((e) => e.id === register.id);
  let filteredPcaVariations = filteredVariations.map((v) => v.variations[0]);
  let variationsPca = filteredPcaVariations.map((v) => v.pcra_log);

  return (
    <div className="post-contract-register-selector">
      <PostContractSelectorOptions
        filters={filters}
        setFilters={setFilters}
        maxReports={maxReports}
        setMaxReports={setMaxReports}
        enableFilter={true}
        height={150}
      />
      <div>
        <div className="post-contract-stages-label">
          {issue(register, registerIssued, registerType)}
        </div>
        <input
          className="post-contract-stages-slider"
          type="range"
          min={minValue}
          max={maxValue}
          value={currentValue}
          onChange={(e) => {
            let index = parseInt(e.target.value);
            setRegister(filteredVariations[index]);
          }}
        />
        <PostContractSliderTicks
          minValue={minValue}
          maxValue={maxValue}
          currentValue={currentValue}
          pcaLog={variationsPca}
        />
      </div>
    </div>
  );
}

function issue(register, registerIssued, registerType) {
  if (register.cost_report_issued) {
    return (
      tranStr("Cost Report") + " " + register.cost_report.cost_report_number
    );
  }

  if (register.progress_claim_issued) {
    return (
      tranStr("Progress Claim") +
      " " +
      register.progress_claim.progress_claim_number
    );
  }

  if (
    !register.cost_report_issued &&
    !register.progress_claim_issued &&
    registerIssued
  ) {
    return registerType + " Update";
  }
}
