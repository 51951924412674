import React from "react";
import isEmpty from "../../validation/is-empty";
import BalanceOfContingencyChart from "../charts/BalanceOfContingencyChart";
import "./CostReportBalanceOfContingencyChart.css";

import { translate } from "../../utils/translation";

export default function CostReportBalanceOfContingencyChart(props) {
  let headroomToBudget = 0;
  let originalContingency = 0;
  let adjustedContingency = 0;
  let { CR } = props;
  let { project } = props;

  if (isEmpty(CR)) {
    return null;
  }

  headroomToBudget = CR.forecast_cost_excl_tax_contingency;
  originalContingency = CR.original_contingency;
  adjustedContingency = CR.adjusted_contingency;
  let contingencyRemainingPercent = 0;
  if (originalContingency !== 0) {
    contingencyRemainingPercent = Math.floor(
      (adjustedContingency / originalContingency) * 100
    );
  }

  return (
    <div className="bocc">
      <BalanceOfContingencyChart
        headroomToBudget={headroomToBudget}
        originalContingency={originalContingency}
        adjustedContingency={adjustedContingency}
        project={project}
      />
      <PercentageContingency />
    </div>
  );
  function PercentageContingency() {
    if (!isNaN(contingencyRemainingPercent)) {
      return (
        <div className="bocc-percent-container">
          <div className="bocc-percent">
            <b>{Math.round(contingencyRemainingPercent)}%</b>
          </div>
          <div className="bocc-text">
            {translate("of Contingency Remaining")}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
