import React, { useState } from "react";

import CostPlanningUpload from "./CostPlanningUpload";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

import "./CostPlanningUploadModal.css";

export default function CostPlanningUploadModal(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;

  const [modal, setModal] = useState(false);

  // Hide if not Summary
  let subLocation = determineSubLocation();
  if (subLocation !== "summary") {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div>
      <div
        className="cost-planning-upload-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Upload Cost Plan")}
      </div>
      <Modal
        title={translate("Upload Cost Plan")}
        Component={CostPlanningUpload}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        projectID={project.id}
        project={project}
        CPs={CPs}
        CP={CP}
      />
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
