import React from "react";
import { timestampToDateAndTime } from "./PCRA.functions";

export function PCRALogBoxPastOrPending(props) {
  const { comments, username, email, timestamp } = props;
  const [date, time] = timestampToDateAndTime(timestamp);

  return (
    <div className="pcra-log-box">
      <div className="comments">{comments}</div>
      <div className="username">{username}</div>
      <div className="timestamp">
        {date}
        <br />
        {time}
      </div>
      <div className="email">{email}</div>
    </div>
  );
}
