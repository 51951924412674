// MSAL
import refreshToken from "./refreshToken";
import store from "../store";

// Auth
import { setCurrentUser, logoutUser } from "../actions/authActions";

export default async function userSession(props) {
  const { privateRoute } = props;

  if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    const currentTime = Date.now() / 1000; // Convert from milliseconds to seconds

    if (!privateRoute) {
      store.dispatch(setCurrentUser(user)); // Reset redux on page refresh
    }

    // 5 minutes until expiry
    const fiveMinutes = 300;
    if (user.exp - fiveMinutes < currentTime) {
      refreshToken(user);
    }

    // Check for expired token
    if (user.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());

      // Redirect to login
      window.location.href = "/";
    }
  }
}
