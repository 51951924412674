import React from "react";

import { translate } from "../../utils/translation";

import "./ProgressClaimDetails.css";

export default function ProgressClaimDetails(props) {
  const { PC } = props;
  return (
    <div className="progress-claim-panel">
      <div className="pcpd-content">
        <div className="pcpd-column">
          <Row title={translate("Contractor Name")} value={PC.contractor} />
          <Row
            title={translate("Contractor Abbreviated Name")}
            value={PC.contractor_name_abbreviated}
          />
          <Row title={translate("Contract Type")} value={PC.contract_type} />
          <Row
            title={translate("Payment No")}
            value={PC.progress_claim_number}
          />
          <Row
            title={translate("Submission Date")}
            value={
              PC.submission_date.day +
              " " +
              PC.submission_date.month +
              " " +
              PC.submission_date.year
            }
          />
          <Row
            title={translate("Recommendation Date")}
            value={
              PC.recommendation_date.day +
              " " +
              PC.recommendation_date.month +
              " " +
              PC.recommendation_date.year
            }
          />
          <Row
            title={translate("Work Complete To")}
            value={
              PC.work_completed_to_date.day +
              " " +
              PC.work_completed_to_date.month +
              " " +
              PC.work_completed_to_date.year
            }
          />
          <Row
            title={translate("Overall % Complete")}
            value={PC.overall_percent_complete + "%"}
          />
        </div>
      </div>
    </div>
  );
}

function Row(props) {
  const { title, value } = props;

  return (
    <div className="pcpd-row">
      <div className="pcpd-description">
        <b>{title}</b>
      </div>
      <div className="pcpd-value">{value}</div>
    </div>
  );
}
