import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";
import OptionsAnalysisForm from "./OptionsAnalysisForm";
import { hasRoles } from "../../utils/roles";
import isEmpty from "../../validation/is-empty";

export default function OptionsAnalysisModal(props) {
  const { modal, setModal } = props;
  const { project } = props;
  const { options, getOptions, setOptions } = props;
  const { user } = props;
  const { stage } = props;

  if (isEmpty(project)) {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <Modal
      Component={OptionsAnalysisForm}
      title={translate("Add Options Analysis Items")}
      modal={modal}
      setModal={setModal}
      setShow={setModal}
      show={modal}
      project={project}
      options={options}
      getOptions={getOptions}
      setOptions={setOptions}
      stageId={stage?.id}
    />
  );
}
