import { comma } from "../../../helpers/common.functions";

export const COLUMNS = [
  {
    title: "Type",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "type",
    value: (row) => row["type"],
    visible: false,
    type: "string",
  },
  {
    title: "Id",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "id",
    value: (row) => row["id"],
    visible: false,
    type: "number",
  },
  {
    title: "Code",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "code",
    value: (row) => row["code"],
    visible: false,
    type: "number",
  },
  {
    title: "Description",
    className: "options-analysis-table-cell breakdown-description-cell",
    field: "description",
    value: (row) => row["description"],
    visible: true,
    type: "string",
  },
  {
    title: "Quantity",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "quantity",
    value: (row) => comma(row["quantity"]),
    visible: true,
    type: "number",
  },
  {
    title: "UOM",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "uom",
    value: (row) => row["uom"],
    visible: true,
    type: "string",
  },
  {
    title: "Rate",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "rate",
    value: (row) => comma(row["rate"]),
    visible: true,
    type: "number",
  },
  {
    title: "Factor",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "factor",
    value: (row) => comma(row["factor"]),
    visible: false,
    type: "number",
  },
  {
    title: "Code Element",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "codeElement",
    value: (row) => comma(row["codeElement"]),
    visible: false,
    type: "number",
  },
  {
    title: "Elemental Qty",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "elementalQty",
    value: (row) => comma(row["elementalQty"]),
    visible: false,
    type: "number",
  },
  {
    title: "Total",
    className: "options-analysis-table-cell breakdown-quantity-cell",
    field: "total",
    value: (row) => comma(row["total"]),
    visible: true,
    type: "number",
  },
];
