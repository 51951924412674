import React from "react";
import PropTypes from "prop-types";
import "./ChartCard.css";

function ChartCard(props) {
  const { children, label, labelClassName } = props;

  return (
    <div className="card">
      {children}
      {label && <div className={`card-label ${labelClassName}`}>{label}</div>}
    </div>
  );
}

ChartCard.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default ChartCard;
