import React from "react";

import isEmpty from "../../validation/is-empty";
import "./PCCommentaryTable.css"

export default function PCCommentaryTable(props) {
  
  const { commentary } = props;

  if (isEmpty(commentary)) {
    return null;
  }
  
  return (
    <div className="pdcp-advice-highlights-display-area">
      <div className="pdcp-advice-highlights-highlight-table">
        {commentary.map((d, i) => {
          return (
            <div className="pc-commentary-row" key={i}>
              <div className="pdcp-advice-highlights-bullet" />
              <div className="pdcp-advice-highlights-highlight">
                {d.comment}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}