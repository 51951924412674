import Accordion from "../accordion/Accordion";
import EstimatesSubtotal from "../estimates/EstimatesSubtotal";
import EstimatesTotal from "../estimates/EstimatesTotal";
import Cashflow from "./Cashflow";

export default function CashflowSplit(props) {
  const { project, CP, CPs, cashflowBasis } = props;

  const { accordionState } = props;

  const cashflow = [];

  const estimates = CP?.estimates;
  estimates?.forEach((e) => {
    if (e.is_included) {
      cashflow.push(
        <CashflowRow
          key={e.id}
          e={e}
          project={project}
          CP={CP}
          CPs={CPs}
          accordionState={accordionState}
          cashflowBasis={cashflowBasis}
        />
      );
    }
  });
  cashflow.push(<EstimatesTotal key={-1} CP={CP} project={project} />);
  estimates?.forEach((e) => {
    if (!e.is_included) {
      cashflow.push(
        <CashflowRow
          key={e.id}
          e={e}
          project={project}
          CP={CP}
          CPs={CPs}
          accordionState={accordionState}
          cashflowBasis={cashflowBasis}
        />
      );
    }
  });

  return <div className="estimate-cashflow">{cashflow}</div>;
}

function CashflowRow(props) {
  const { e } = props;
  const { project, CP, CPs } = props;
  const { accordionState } = props;
  const { cashflowBasis } = props;

  const rate = Math.round(e.calculated_total / e.local_region_area);
  const uom = "";

  const cashflowData = CP.cashflow?.Cashflows.find(
    (c) => c.EstimateId === e.id
  );

  const totalCost = Math.round(e.construction_cost + e.escalationAllowance);

  return (
    <div>
      <Accordion
        label={e.name}
        local_region_area={e.local_region_area}
        unit={uom}
        rate={rate}
        total={Math.round(e.calculated_total)}
        isOpen={true}
        // State
        accordionState={accordionState}
        accordionSection={"CASHFLOW"}
        estimateID={e.id}
      >
        <Cashflow
          project={project}
          CP={CP}
          CPs={CPs}
          cashflowData={cashflowData}
          estimateID={e.id}
          totalCost={totalCost}
          cashflowBasis={cashflowBasis}
        />
      </Accordion>
      <EstimatesSubtotal
        project={project}
        estimates={CP.estimates}
        estimateID={e.id}
        subtotal_below={e.subtotal_below}
        subtotal_name={e.subtotal_name}
        subtotal_gfa_override={e.subtotal_gfa_override}
      />
    </div>
  );
}
