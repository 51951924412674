import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../modal/Modal";
import isEmpty from "../../validation/is-empty";
import browserDetector from "../browser/BrowserDetector";
import brand1 from "../../img/ArcadisClarity-01.png";
import brand2 from "../../img/ArcadisClarity-02b.png";

import Feedback from "../feedback/Feedback";
import Correspondence from "../correspondence/Correspondence";
import EditProfile from "../edit-profile/EditProfile";

import { translate, tranStr } from "../../utils/translation";
import { hasRoles, accountRole } from "../../utils/roles";

// Language
import LanguageSelect from "./LanguageSelect";
import LanguageSelectGuest from "./LanguageSelectGuest";

// Import redux to access the authentication state
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "./NavbarTop.css";

// MSAL
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "../../active-directory/authConfig";

// Functions
import { sendEditRequest } from "../../api/users/UserAPI";
import { displayUserImage } from "../../validation/image-clean";
import ClientViewToggle from "./ClientViewToggle";

import { ImageDisplay } from "../images/ImageDisplay";
import Notification from "../common/Notification";

// Language

function Navbar(props) {
  // Get from the auth property the authentication status
  const { isAuthenticated, user } = props.auth;
  const logoutUserConnect = props.logoutUser;
  const idToken = localStorage.getItem("idToken");

  const authLinks = (
    <div className="navigation-bar">
      <div className="navigation-left-side">
        <div className="navigation-brand-wrapper" aria-label="Clarity" to="/">
          <Link
            className="navigation-brand"
            aria-label="Clarity"
            to="/portfolio/all-projects/projects/"
          >
            <img
              src={brand1}
              width="150"
              className="d-inline-block align-top"
              alt=""
              aria-label="Clarity"
            />
          </Link>
        </div>
        <div className="navigation-links-wrapper">
          <Link
            className="navigation-link"
            to="/portfolio/all-projects/projects/"
          >
            <div className="navigation-link-icon">
              <i className="fas fa-grip-horizontal" />
            </div>
            {translate("Portfolio")}
          </Link>
          {!hasRoles(user.roles, ["Admin", "CostManager"]) ? null : (
            <Link className="navigation-link" to="/home/benchmarking">
              <div className="navigation-link-icon">
                <i className="fas fa-chart-pie" />
              </div>
              {translate("Benchmarking")}
            </Link>
          )}
          <Link className="navigation-link" to="/insights">
            <div className="navigation-link-icon">
              <i className="fas fa-chart-line" />
            </div>
            <div>
              {translate("Insights")}
              <Notification at="navbar" />
            </div>
          </Link>
          <Link className="navigation-link" to="/contact">
            <div className="navigation-link-icon">
              <i className="fas fa-phone"></i>
            </div>
            {translate("Contact Us")}
          </Link>
          <Feedback user={user} />
          <Correspondence user={user} />
          <LanguageSelect />
        </div>
      </div>
      <div className="navigation-right-side">
        <div className="navigation-account-wrapper">
          <div className="navigation-welcome-label">{welcomeNote(user)}</div>
          <Avatar user={user} />
          <div className="dropdown">
            <button className="dropbtn">
              {translate("Control Panel")}
              <div className="dropbtn-caret">
                <i className="fa fa-caret-down" />
              </div>
            </button>
            <div className="dropdown-content">
              <div className="account-role">{accountRole(user.roles)}</div>
              {!hasRoles(user.roles, ["Admin"]) ? null : (
                <Link className="navigation-menu-link" to="/admin">
                  {translate("Admin")}
                </Link>
              )}
              {!hasRoles(user.roles, ["Admin", "CostManager"]) ? null : (
                <div className="navigation-menu-link">
                  <a
                    href="https://app.smartsheet.com/b/form/0bbe4a41303443a99fe98b2c7d08227c"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {translate("Feature Request")}
                  </a>
                </div>
              )}
              <Logout logoutUserConnect={logoutUserConnect} />
              <ClientViewToggle idToken={idToken} user={user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const guestLinks = (
    <div className="navigation-bar-guest">
      <div className="navigation-left-side">
        <div className="navigation-brand-wrapper">
          <Link className="navigation-brand" aria-label="Clarity" to="/">
            <img
              src={brand2}
              width="150"
              className="d-inline-block align-top"
              alt=""
              aria-label="Clarity"
            />
          </Link>
        </div>
        <div className="navigation-links-wrapper">
          <Link className="navigation-link-guest" to="/information">
            {translate("What is Cost Clarity?")}
          </Link>
          <Link className="navigation-link-guest" to="/contact-us">
            {translate("Contact Us")}
          </Link>
          <Link className="navigation-link-guest" to="/privacy">
            {translate("Our Privacy Policy")}
          </Link>
          <Link className="navigation-link-guest" to="/about">
            {translate("About")}
          </Link>
          <LanguageSelectGuest />
        </div>
      </div>
    </div>
  );

  let navbar;

  if (isAuthenticated) navbar = authLinks;
  if (!isAuthenticated) navbar = guestLinks;
  if (browserDetector().unsupported) navbar = null;

  return <div>{navbar}</div>;
}

export function Logout(props) {
  const { logoutUserConnect } = props;
  return (
    <a
      href="/logout"
      onClick={async (e) => {
        e.preventDefault();

        logoutUserConnect();
        const myMSALObj = new Msal.PublicClientApplication(msalConfig());
        await myMSALObj.logoutRedirect();
      }}
      className="navigation-menu-link"
    >
      {translate("Logout")}
    </a>
  );
}

export function welcomeNote(user) {
  if (isEmpty(user)) {
    return null;
  } else {
    return tranStr(`Welcome`) + ` ${user.name}`;
  }
}

export function Avatar(props) {
  const { user } = props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <div
        onClick={() => {
          setModal(true);
        }}
      >
        <ImageDisplay
          image={displayUserImage(user)}
          imageWidth={"30px"}
          imageHeight={"30px"}
          borderRadius={"30px"}
        />
      </div>
      <Modal
        // Modal Props
        title={"Edit Profile"}
        Component={EditProfile}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        user={user}
        sendRequest={sendEditRequest}
      />
    </div>
  );
}

// Add prop types
Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

// Bring in the auth state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
