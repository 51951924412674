import React from "react";

import { validateCurrency } from "./PostContractTableModal.functions";

import {
  // Description,
  ExpandableRow,
  OneValueRow,
} from "./PostContractModal.components";

import "./PostContractTableModal.css";

export default function PostContractClientDirectsModal(props) {
  const { selectedRow } = props;
  const R = selectedRow;

  return (
    <div className="pctm">
      <div className="pctm-container-row">
        <OneValueRow heading1={"Description"} value1={R.description} />
      </div>
      <div className="pctm-container-row">
        <OneValueRow heading1={"Consultant"} value1={R.consultant} />
      </div>
      <div className="pctm-container-row">
        <OneValueRow
          heading1={"Client Cost Type"}
          value1={R.client_cost_type}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={[
            "Budget",
            "Contract Value Excl Provisional Items",
            "Provisional Items",
            "Contract Value Incl Provisional Items",
            "Variations",
            "Provisional Item Actual Cost",
            "Provisional Item Adjustment",
            "Total Cost",
            "Balance of Contingency",
            "Expenditure To Date",
          ]}
          values={[
            validateCurrency(R.budget),
            validateCurrency(R.contract_value_excl_prov_items),
            validateCurrency(R.provisional_items),
            validateCurrency(R.contract_value_incl_prov_items),
            validateCurrency(R.variations),
            validateCurrency(R.provisional_item_actual_cost),
            validateCurrency(R.provisional_item_adjustment),
            validateCurrency(R.total_cost),
            validateCurrency(R.balance_of_contingency),
            validateCurrency(R.value_complete_to_date),
          ]}
        />
      </div>
    </div>
  );
}
