import React from "react";

import {
  // Description,
  ExpandableRow,
  OneValueRow,
  DocumentLink,
} from "./PostContractModal.components";

import "./PostContractTableModal.css";

export default function PostContractClientDirectVariationsModal(props) {
  const { selectedRow } = props;
  const R = selectedRow;

  return (
    <div className="pctm">
      <div className="pctm-container-row">
        <OneValueRow heading1={"Description"} value1={R.description} />
      </div>
      <div className="pctm-container-row">
        <OneValueRow heading1={"Consultant"} value1={R.consultant} />
      </div>
      <div className="pctm-container-row">
        <OneValueRow
          heading1={"Client Cost Type"}
          value1={R.client_cost_type}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={["Value", "Percentage Complete", "Value Complete"]}
          values={[
            R.value,
            R.percent_complete_to_date,
            R.value_complete_to_date,
          ]}
        />
        <ExpandableRow
          headings={["Status", "Action With"]}
          values={[R.status, R.action]}
        />
      </div>
      <div className="pctm-container-row">
        <OneValueRow heading1={"Comments"} value1={R.comments} />
      </div>
      <div className="pctm-container-row">
        <DocumentLink R={R} />
      </div>
    </div>
  );
}
