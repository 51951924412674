export function filterReports(data, filters) {
  const reports = [];

  const selection = {
    cr: "Cost Reports",
    pc: "Progress Claims",
    vr: "Variation Updates",
  };
  const type = {
    cr: "cost_report_issued",
    pc: "progress_claim_issued",
    vr: "variations_issued",
  };

  data.forEach((report) => {
    // Cost Report is in selection
    if (filters.some((y) => y.value === selection.cr)) {
      // Include any Cost Report
      if (report[type.cr]) {
        reports.push(report);
      }
    }

    // Progress Claim is in selection
    if (filters.some((y) => y.value === selection.pc)) {
      // Include any Progress Claim
      if (report[type.pc]) {
        reports.push(report);
      }
    }

    // Variations is in selection
    if (filters.some((y) => y.value === selection.vr)) {
      // Include Variations ONLY
      if (report[type.vr] && !report[type.cr] && !report[type.pc]) {
        reports.push(report);
      }
    }
  });

  return reports;
}
