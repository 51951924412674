import React from "react";
import PropTypes from "prop-types";

import { MASTER_BENCHMARKING } from "./BenchmarkCreate.strings";

import BenchmarkCreateAnalysis from "./BenchmarkCreateAnalysis";

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysisGate.propTypes = {};
///
export default function BenchmarkCreateAnalysisGate(props) {
  return (
    <div className="page-content-center">
      <BenchmarkCreateAnalysisGateContent {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysisGateContent.propTypes = {
  configurationComplete: PropTypes.bool,
  benchmarkSelectionComplete: PropTypes.bool,
};
///
function BenchmarkCreateAnalysisGateContent(props) {
  const { configurationComplete } = props;
  const { benchmarkSelectionComplete } = props;

  if (!configurationComplete || !benchmarkSelectionComplete) {
    return (
      <div className="benchmark-create-analysis-gate">
        {!configurationComplete && (
          <div className="error-message">
            {MASTER_BENCHMARKING.WARNINGS.CONFIGURATION_INCOMPLETE}
          </div>
        )}
        {!benchmarkSelectionComplete && (
          <div className="error-message">
            {MASTER_BENCHMARKING.WARNINGS.BENCHMARK_SELECTION_INCOMPLETE}
          </div>
        )}
      </div>
    );
  }

  return <BenchmarkCreateAnalysis {...props} />;
}
