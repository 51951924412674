// React
import React, { useState, useEffect } from "react";

// Component
import { CbsDropDown } from "./CbsDropdown";

// Functions
import isEmpty from "../../validation/is-empty";

export function CbsCategoryControlPanel(props) {
  const { cbs } = props;

  const { setSelectedCategory } = props;

  const [selectedCategoryName, setSelectedCategoryName] =
    useState("Element Categories");

  // Set the Selected CBS Category. We only update this if the category list changes
  useEffect(() => {
    const selectCategory = cbs?.data?.categoryGroups?.find(
      (c) => c.categoryName === selectedCategoryName
    );

    let heritage = "rgba(228, 97, 15, 1)";
    let aqua = "rgba(50, 98, 149, 1)";
    let collaboration1 = "rgba(85, 87, 90, 1)";
    let heritage2 = "rgba(228, 97, 15, 0.7)";
    let aqua2 = "rgba(50, 98, 149, 0.7)";
    let collaboration12 = "rgba(85, 87, 90, 0.7)";

    const colourSchema = [
      heritage,
      collaboration1,
      aqua,
      collaboration12,
      aqua2,
      heritage2,
    ];

    selectCategory?.categoryItems?.forEach((catItem, i) => {
      catItem.colour = colourSchema[i % colourSchema.length];
    });
    setSelectedCategory(selectCategory);
  }, [cbs?.data?.categoryGroups, selectedCategoryName, setSelectedCategory]);

  if (isEmpty(cbs?.data?.categoryGroups)) {
    return null;
  }

  return (
    <div className="cbs-category-control">
      <div className="cbs-row">
        <CbsDropDown
          placeholder={"Select Category"}
          id={"cbs-admin-dropdown-categories"}
          get={selectedCategoryName}
          set={setSelectedCategoryName}
          data={cbs?.data?.categoryGroups?.map((c) => c.categoryName)}
        />
      </div>
    </div>
  );
}
