import React, { useEffect, useState } from "react";

import "./PostContractUpload.css";
import { uploadPostContract } from "../../api/post-contract/PostContractAPI";
import isEmpty from "../../validation/is-empty";

export default function PostContractUpload(props) {
  const { projectID, project } = props;
  const { postContract } = props;
  const { pcIssued } = props;
  const { setShow } = props;
  const [linkedID, setLinkedID] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(postContract.data.progressClaims)) {
      setLinkedID(
        postContract.data.progressClaims[
          postContract.data.progressClaims.length - 1
        ].id
      );
    }
  }, [postContract]);

  const data = {
    file: file,
    projectID: projectID,
    project: project,
    setError: setError,
    postContract: postContract,
    pcIssued: pcIssued,
    setShow: setShow,
    linkedID: linkedID,
  };

  const draftCostReportWarning = `
    Uploading a Progress Claim will also create a draft Cost Report and 
    this will be visible to clients when the PCA progress has been completed. 
    Draft Cost Reports can be toggled off in the toggle features menu.
  `;

  return (
    <div className="pcu-container">
      <p>
        Please note that all manually entered general Dashboard Commentary will
        be copied forward and may require updating.
      </p>
      {pcIssued.progress_claim_issued && <p>{draftCostReportWarning}</p>}
      <div className="general-row-container">
        <FileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} />
      </div>
      <SelectProgressClaim
        postContract={postContract}
        linkedID={linkedID}
        setLinkedID={setLinkedID}
        pcIssued={pcIssued}
      />
      <div className="general-row-container">
        <Error error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <Upload uploadFile={uploadPostContract} data={data} />
      </div>
    </div>
  );
}

function SelectProgressClaim(props) {
  const { postContract } = props;
  const { linkedID } = props;
  const { setLinkedID } = props;
  const { pcIssued } = props;

  if (pcIssued.progress_claim_issued) {
    return null;
  }

  let selectList = postContract.data.progressClaims;
  if (selectList === undefined) {
    selectList = [];
  }

  return (
    <div className="general-row-container">
      <div className="pcu-select-container">
        <div className="pcu-select-label">Linked Progress Claim</div>
        <select
          className="pcu-select-list"
          value={linkedID}
          onChange={(e) => {
            setLinkedID(e.target.value);
          }}
        >
          <SelectList options={selectList} />
        </select>
      </div>
    </div>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item.id} className="option">
        {"Progress Claim " + item.progress_claim.progress_claim_number}
      </option>
    );
  });
}

function UploadFile(props) {
  const { setFile } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      Attach File
    </label>
  );
}

function FileTray(props) {
  const { file } = props;
  let fileName = "No File Added";

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pcu-file-display">
      <b>File:</b> {fileName}
    </div>
  );
}

function Upload(props) {
  const { uploadFile } = props;
  const { data } = props;
  return (
    <div
      className="general-upload-button"
      onClick={async () => {
        uploadFile(data);
      }}
    >
      Upload
    </div>
  );
}

function Error(props) {
  const { error } = props;
  return (
    <div className="pcu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
