import React from "react";
import PropTypes from "prop-types";

import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";

/// props validation | SQ(javascript:S6774)
TotalRow.propTypes = {
  benchmarks: PropTypes.array,
  currencySymbol: PropTypes.string,
};
///
export default function TotalRow(props) {
  const { benchmarks } = props;
  const { currencySymbol } = props;

  return (
    <div className="bdt-total-row">
      <div className="bdt-row-name-cell">
        <b>{translate("Average")}</b>
      </div>
      <div className="bdt-base-date"></div>
      <div className="bdt-lra-unit"></div>
      <div className="bdt-gia">
      <b>
          {currencySymbol +
            comma(
              avgObjectArray(benchmarks, "gia").toFixed(0)
            )}
        </b>
      </div>
      <div className="bdt-gfa"></div>
      <div className="bdt-cost">
        <b>
          {currencySymbol +
            comma(
              avgObjectArray(benchmarks, "exchanged_calculated_construction_cost_rate").toFixed(0)
            )}
        </b>
      </div>
      <div className="bdt-cost">
        <b>
          {currencySymbol +
            comma(
              avgObjectArray(
                benchmarks,
                "escalated_calculated_construction_cost_rate"
              ).toFixed(0)
            )}
        </b>
      </div>
      <div className="bdt-procurement"></div>
      <div className="bdt-type"></div>
      <div className="bdt-quality"></div>
      <div className="bdt-sector"></div>
    </div>
  );
}

function avgObjectArray(array, key) {
  let sum = 0;

  array.forEach((o) => {
    sum += o[key];
  });

  return sum / array.length;
}
