import { authHeader } from "../../_helpers/auth-header";
import { pcAPIs } from "../api-endpoints/PostContractEndpoints";

export async function onDelete(data) {
  let result = window.confirm(
    `This will permanently delete all data associated with this uploaded sheet, including information displayed on other pages. Confirm?`
  );

  if (!result) {
    return null;
  }

  // POST CONTRACT API
  const url = pcAPIs().delete_post_contract;

  const payload = {
    projectID: data.projectID,
    postContractId: data.postContractID,
  };
  const field = JSON.stringify(payload);
  const formData = new FormData();
  formData.append("field", field);
  const config = {
    method: "DELETE",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    data.postContract.set(await data.postContract.get(data.projectID));
  } else {
    console.log("Progress Claim Delete Error");
  }
}

export async function onDeleteRiskRegister(data) {
  let result = window.confirm(
    `This will permanently delete this risk register from this Cost Report. Confirm?`
  );

  if (!result) {
    return null;
  }

  // POST CONTRACT API
  const url = pcAPIs().delete_risk_register;

  const payload = {
    project_id: data.projectID,
    postContractID: data.postContractID,
  };
  const field = JSON.stringify(payload);

  const config = {
    method: "DELETE",
    body: field,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    data.postContract.set(await data.postContract.get(data.projectID));
  } else {
    console.log("Progress Claim Delete Error");
  }
}
