import React, { useState } from "react";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export function PortfolioInputSingle(props) {
  const { placeholder, icon } = props;
  const { type, filters, setFilters } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "portfolio-filter-input-icon";
  if (focus || filters[type] !== "") {
    iconType = "portfolio-filter-input-icon-focus";
  }

  return (
    <div className="portfolio-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="portfolio-filter-input-col">
        <div className="portfolio-filter-input-heading">
          {translate(placeholder)}
        </div>
        <div className="portfolio-filter-input-row">
          <input
            className="portfolio-filter-input"
            type="text"
            value={filters[type]}
            onChange={(e) => {
              let text = e.target.value;
              return filterState(text, setFilters, type);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}

function filterState(text, setFunction, type) {
  return setFunction((filtersState) => {
    let temp = { ...filtersState };
    temp[type] = text;
    return temp;
  });
}

export function PortfolioSelectSingle(props) {
  const { placeholder, icon, options } = props;
  const { type, filters, setFilters } = props;
  const { childSelection } = props;

  const [focus, setFocus] = useState(false);

  if (isEmpty(filters)) {
    return null;
  }
  let iconType = "portfolio-filter-input-icon";

  if (focus || filters[type] !== "") {
    iconType = "portfolio-filter-input-icon-focus";
  }

  return (
    <div className="portfolio-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="portfolio-filter-input-col">
        <div className="portfolio-filter-input-heading">
          {translate(placeholder)}
        </div>
        <div className="portfolio-filter-input-row">
          <select
            className="portfolio-filter-input"
            type="text"
            value={filters[type]}
            onChange={(e) => {
              let text = e.target.value;
              setFilters((filtersState) => {
                let temp = { ...filtersState };
                temp[type] = text;
                if (childSelection) {
                  temp[childSelection] = "";
                }
                return temp;
              });
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptions(options)}
          </select>
        </div>
      </div>
    </div>
  );

  function selectOptions(list) {
    return list.map((option) => (
      <option key={option.label} value={option.value}>
        {option.label}
      </option>
    ));
  }
}

export function PortfolioSelectSingleFilter(props) {
  const { placeholder, icon, options } = props;
  const { type, filters, setFilters } = props;
  const { filter } = props;
  const [focus, setFocus] = useState(false);
  if (isEmpty(filters)) {
    return null;
  }
  let iconType = "portfolio-filter-input-icon";
  if (focus || filters[type] !== "")
    iconType = "portfolio-filter-input-icon-focus";

  return (
    <div className="portfolio-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="portfolio-filter-input-col">
        <div className="portfolio-filter-input-heading">
          {translate(placeholder)}
        </div>
        <div className="portfolio-filter-input-row">
          <select
            className="portfolio-filter-input"
            type="text"
            value={filters[type]}
            onChange={(e) => {
              let text = e.target.value;
              return filterState(text, setFilters, type);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptions(options)}
          </select>
        </div>
      </div>
    </div>
  );

  function selectOptions(list) {
    return list.map((option) => {
      if (option.type === filter || option.type === "") {
        return (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        );
      } else {
        return null;
      }
    });
  }
}

export function PortfolioInputDouble(props) {
  const { value1, onChange1 } = props;
  const { value2, onChange2 } = props;
  const { placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "portfolio-filter-input-icon";
  if (focus || value1 !== "" || value2 !== "")
    iconType = "portfolio-filter-input-icon-focus";

  return (
    <div className="portfolio-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="portfolio-filter-input-col">
        <div className="portfolio-filter-input-heading">
          {translate(placeholder)}
        </div>
        <div className="portfolio-filter-input-row">
          <input
            className="portfolio-filter-input-pair"
            type="text"
            value={value1}
            onChange={(e) => {
              onChange1(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder="Min"
          ></input>
          <input
            className="portfolio-filter-input-pair-second"
            type="text"
            value={value2}
            onChange={(e) => {
              onChange2(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder="Max"
          ></input>
        </div>
      </div>
    </div>
  );
}
