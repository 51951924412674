import React, { useState, useEffect } from "react";
import isEmpty from "../../../validation/is-empty";
import { translate } from "../../../utils/translation";
import "../location-factors/LocationFactors.css";
import { saveLocationFactors } from "./LocationFactors.functions";

export function LocationFactorsModal(props) {
  const { locations } = props;
  const { locationFactors } = props;

  const { region, country } = props;
  const [error, setError] = useState({});

  const [fields, setFields] = useState([
    {
      city: "",
      factor: "",
      globalfactor:""
    },
  ]);

  useEffect(() => {
    if (!isEmpty(locations)) {
      let filtered = locations.map((d) => {
        return {
          city: d.city,
          factor: d.factor,
          globalfactor:d.globalfactor
        };
      });
      if (filtered.length > 0) {
        setFields(filtered);
      }
    }
  }, [locations]);

  if (isEmpty(locations)) {
    return (
      <div>
        {translate("There are no cities added for the selected country")}
      </div>
    );
  }
  const data = {
    fields: fields,
    region: region,
    country: country,
    setError: setError,
    locationFactors: locationFactors,
  };

  return (
    <div className="tpi-calc location-contianer">
      <div className="location-container">{Fields()}</div>
      <Error error={error} setError={setError} />

      <div className="general-button-container">
        <SaveButton data={data} />
      </div>
      {locations[0].author && (
        <div className="location-author-info">{`Last updated by ${locations[0].author} on ${locations[0].time}`}</div>
      )}
    </div>
  );

  function Fields() {
    return fields.map((f, i) => {
      return (
        <div key={f.city} className="location-factor-field-container">
          <div className="location-field">{f.city}</div>
          <input
            type="number"
            step="any"
            className="location-factor"
            value={f.factor}
            maxLength={90}
            onChange={(e) => {
              updateField("factor", e.target.value, i, fields);
            }}
            placeholder="Location Factor"
          />
          <input
            type="number"
            step="any"
            className="location-factor"
            value={f.globalfactor}
            maxLength={90}
            onChange={(e) => {
              updateField("globalfactor", e.target.value, i, fields);
            }}
            placeholder="Global Factor"

          />
        </div>
      );
    });
  }

  function updateField(field, value, i, array) {
    let temp = [...array];
    temp[i][field] = value;
    setFields(temp);
  }
}
function SaveButton(props) {
  const { data } = props;
  return (
    <div
      id={"button-save-location-factor"}
      className="general-upload-button"
      onClick={async () => {
        await saveLocationFactors(data);
      }}
    >
      {translate("Save")}
    </div>
  );
}

function Error(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
