import React from "react";

import { Link } from "react-router-dom";
import ZoomOut from "../../img/zoom-out.png";

import { translate } from "../../utils/translation";

export default function EstimateBreakdownTableHead(props) {
  const { projectID } = props;
  const { selectedRow } = props;
  const { search, setSearch } = props;
  const { estimateID } = props;
  const { enableLineRefs } = props;
  let { filtered } = props;

  let row = {
    description: translate("Description"),
    quantity: translate("Quantity"),
    unit_of_measure: translate("Unit"),
    rate: translate("Rate"),
    subtotal: translate("Total"),
  };

  let descriptionType = "ebt-description-cell";
  if (enableLineRefs) {
    descriptionType = "ebt-description-cell-ref";
  }
  let headRowType="ebt-head-row";
  if (filtered !== undefined) {
    descriptionType = "ebt-description-cell-filtered";
    headRowType = "ebt-head-row-filtered"
  }

  return (
    <div className={headRowType}>
      <DisplayRefHeading enableLineRefs={enableLineRefs} />
      <div className={descriptionType}>
        <b>{row.description}</b>
        {filtered === undefined && (
          <><Search search={search} setSearch={setSearch} />
        
        <ZoomOutButton
          selectedRow={selectedRow}
          projectID={projectID}
          estimateID={estimateID}
        /></>
        )}
      </div>
      <div className="ebt-quantity-cell ">
        <b>{row.quantity}</b>
      </div>
      <div className="ebt-unit-cell">
        <b>{row.unit_of_measure}</b>
      </div>
      <div className="ebt-rate-cell ">
        <b>{row.rate}</b>
      </div>
      <div className="ebt-subtotal-cell">
        <b>{row.subtotal}</b>
      </div>
    </div>
  );
}

function Search(props) {
  const { search, setSearch } = props;
  return (
    <input
      className="ebt-search"
      placeholder={"Search"}
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
    />
  );
}

function ZoomOutButton(props) {
  const { selectedRow } = props;
  const { projectID } = props;

  if (selectedRow === "breakdown") {
    return null;
  }

  return (
    <Link
      className="ebt-view-all-button"
      to={`/project/${projectID}/cost-planning/breakdown`}
    >
      <img alt="" className="ebt-view-zoom-out" src={ZoomOut} />
    </Link>
  );
}

function DisplayRefHeading(props) {
  const { enableLineRefs } = props;

  if (enableLineRefs) {
    return (
      <div className="ebt-section-cell">
        <b>{translate("Line")}</b>
      </div>
    );
  } else {
    return null;
  }
}
