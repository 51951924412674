import React from "react";
import isEmpty from "../../validation/is-empty";
import Breakdown from "./Breakdown";
import EstimatesTPITotal from "../estimates/EstimatesTPITotal";
import EstimatesTotalAccordion from "../estimates/EstimatesTotalAccordion";
// Functions
import { determineSelection } from "./EstimateBreakdown.functions";
import { translate } from "../../utils/translation";

import "./EstimateBreakdown.css";

export default function EstimateBreakdown(props) {
  const { user } = props;
  const { TPI } = props;
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { accordionState } = props;

  const { isLinkedTPI, setIsLinkedTPI } = props;
  const { linkedTPISelectedYear, setLinkedTPISelectedYear } = props;
  const { linkedTPIPercentage, setLinkedTPIPercentage } = props;

  // Loaded and no Estimate.
  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return (
      <div>
        {translate(
          "Cost Plan Breakdown is available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  const selectedRow = determineSelection();

  // Loaded
  const display = [];

  // Estimates Included in the Total
  CP.estimates.forEach((estimate) => {
    if (estimate.is_included) {
      display.push(
        <Breakdown
          user={user}
          TPI={TPI}
          key={estimate.id}
          projectID={project.id}
          project={project}
          CPs={CPs}
          CP={CP}
          estimate={estimate}
          accordionState={accordionState}
          selectedRow={selectedRow}
          isLinkedTPI={isLinkedTPI}
          setIsLinkedTPI={setIsLinkedTPI}
          linkedTPISelectedYear={linkedTPISelectedYear}
          setLinkedTPISelectedYear={setLinkedTPISelectedYear}
          linkedTPIPercentage={linkedTPIPercentage}
          setLinkedTPIPercentage={setLinkedTPIPercentage}
        />
      );
    }
  });

  // The Total including Total TPI
  display.push(
    <EstimatesTotalAccordion
      project={project}
      CP={CP}
      key={-1}
      isBreakdown={true}
      selectedRow={selectedRow}
      accordionState={accordionState}
    >
      <EstimatesTPITotal
        key={-2}
        estimates={CP.estimates}
        project={project}
        user={user}
        TPI={TPI}
        CPs={CPs}
        isBreakdown={true}
        selectedRow={selectedRow}
        isLinkedTPI={isLinkedTPI}
        setIsLinkedTPI={setIsLinkedTPI}
        linkedTPISelectedYear={linkedTPISelectedYear}
        setLinkedTPISelectedYear={setLinkedTPISelectedYear}
        linkedTPIPercentage={linkedTPIPercentage}
        setLinkedTPIPercentage={setLinkedTPIPercentage}
      />
    </EstimatesTotalAccordion>
  );

  // Estimates Excluded from the Total
  CP.estimates.forEach((estimate) => {
    if (!estimate.is_included) {
      display.push(
        <Breakdown
          user={user}
          TPI={TPI}
          key={estimate.id}
          projectID={project.id}
          project={project}
          CPs={CPs}
          CP={CP}
          estimate={estimate}
          accordionState={accordionState}
          selectedRow={selectedRow}
          isLinkedTPI={isLinkedTPI}
          setIsLinkedTPI={setIsLinkedTPI}
          linkedTPISelectedYear={linkedTPISelectedYear}
          setLinkedTPISelectedYear={setLinkedTPISelectedYear}
          linkedTPIPercentage={linkedTPIPercentage}
          setLinkedTPIPercentage={setLinkedTPIPercentage}
        />
      );
    }
  });

  return <div className="estimate-breakdown">{display}</div>;
}
