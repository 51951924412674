import React from "react";
import isEmpty from "../../../validation/is-empty";
import { translate } from "../../../utils/translation";

export default function LocationFactors(props) {
  const { region, country } = props;
  const { locations } = props;
  if (isEmpty(locations)) {
    return (
      <div>
        {translate(
          "There are no city added for the selected country and region"
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="insight-path">
        <div className="path-link">{region}</div>
        <div className="path-divider">{" > "}</div>
        <div className="path-link">{country}</div>
        <div className="path-divider">{" > "}</div>
        <div className="path-link">Location Factors</div>
      </div>
      <div className="location-factors-table">
        <TableHeader />
        <TableRow />
      </div>
    </div>
  );

  function TableRow() {
    return locations.map((f, i) => {
      return (
        <div key={f.city} className="location-factors-table-row">
          <div className="location-factors-table-description-cell ">
            {f.city}
          </div>
          <div className="location-factors-table-quantity-cell ">
            {f.factor}
          </div>
          <div className="location-factors-table-quantity-cell ">
            {f.globalfactor}
          </div>
        </div>
      );
    });
  }
}
function TableHeader() {
  return (
    <div className="location-factors-table-head-row">
      <div className="location-factors-table-description-cell ">City</div>
      <div className="location-factors-table-quantity-cell ">
        Location Factor
      </div>
      <div className="location-factors-table-quantity-cell ">
        Global Factor
      </div>
    </div>
  );
}
