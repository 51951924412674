import React from "react";
import { Translation } from "react-i18next";
import i18n from "../i18n";

export function translate(value, interpolation) {
  return <Translation>{(t) => t(value, interpolation)}</Translation>;
}

export function tranStr(value) {
  return i18n.t(value);
}
