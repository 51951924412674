import React from "react";

const NotFound = () => {
  return (
    <div>
      <div
        className="container"
        style={{ marginTop: "100px", marginBottom: "100px" }}
      >
        <h1 className="display-4">Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
