import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { commaToFixed } from "../../helpers/common.functions";

import "./CostReportBudgetSummary.css";

export default function CostReportBudgetSummary(props) {
  const { CR } = props;

  return (
    <div className="crbs-content">
      <TitleRow CR={CR} />
      <Row
        CR={CR}
        objKey={"original_budget"}
        title={translate("Original Budget")}
      />
      <Row
        CR={CR}
        objKey={"adjustment_budget"}
        title={translate("Budget Adjustments")}
      />
      <Row
        CR={CR}
        objKey={"adjusted_budget"}
        title={translate("Adjusted Budget")}
        isTotal
      />
    </div>
  );
}

function TitleRow(props) {
  const { CR } = props;

  return (
    <div className="crbs-title-row">
      <div className="crbs-description-cell"></div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Construction")}</b>
      </div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Client Directs")}</b>
      </div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Contingency")}</b>
      </div>
      <div className="crbs-value-cell-heading">
        <b>
          {translate("Total Excl tax", {
            tax: isEmpty(CR.tax_type) ? "tax" : CR.tax_type,
          })}
        </b>
      </div>
    </div>
  );
}

function Row(props) {
  const { CR } = props;
  const { title } = props;
  const { isTotal } = props;

  const { objKey } = props;
  const constructionCosts =
    CR[`construction_cost_${objKey}`] +
    CR[`variations_${objKey}`] +
    CR[`provisional_quantities_${objKey}`] +
    CR[`provisional_sums_${objKey}`] +
    CR[`delays_and_extensions_of_time_${objKey}`];

  const clientDirectCosts =
    CR[`consultants_fees_${objKey}`] +
    CR[`client_staffing_${objKey}`] +
    CR[`authority_fees_${objKey}`] +
    CR[`legal_fees_${objKey}`] +
    CR[`ffe_${objKey}`] +
    CR[`loose_furniture_${objKey}`] +
    CR[`workstations_${objKey}`] +
    CR[`audio_visual_${objKey}`] +
    CR[`specialist_equipment_${objKey}`] +
    CR[`ict_${objKey}`] +
    CR[`relocation_${objKey}`] +
    CR[`other1_${objKey}`] +
    CR[`other2_${objKey}`] +
    CR[`other3_${objKey}`];

  const projectContingency = CR[`project_contingency_${objKey}`];

  const totalExclTax = CR[`forecast_cost_excl_tax_${objKey}`];

  if (isTotal) {
    return (
      <div className="crbs-total-row">
        <div className="crbs-description-cell">
          <b>{title}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(constructionCosts)}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(clientDirectCosts)}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(projectContingency)}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(totalExclTax)}</b>
        </div>
      </div>
    );
  } else {
    return (
      <div className="crbs-row">
        <div className="crbs-description-cell">{title}</div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(constructionCosts)}
        </div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(clientDirectCosts)}
        </div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(projectContingency)}
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(totalExclTax)}</b>
        </div>
      </div>
    );
  }
}
