// React
import React from "react";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

// Page
import Dashboard from "../dashboard/Dashboard";

// Components
import ProgressClaimPaymentRecommendation from "./ProgressClaimPaymentRecommendation";
import ProgressClaimContractStatement from "./ProgressClaimContractStatement";
import ProgressClaimPaymentOverview from "./ProgressClaimPaymentOverview";
import ProgressClaimDetailsGrid from "../post-contract-progress-claim/ProgressClaimDetailsGrid";
import ProgressClaimDetails from "../post-contract-progress-claim/ProgressClaimDetails";
import ProgressClaimReconciliation from "../post-contract-progress-claim/ProgressClaimReconciliation";
import ProgressClaimContractTracker from "../post-contract-progress-claim/ProgressClaimContractTracker";
import CostReportTimebar from "../post-contract-cost-report/CostReportTimebar";
import ProgressClaimCommentary from "./ProgressClaimCommentary";

// Generic Components
import DashboardDecisions from "../dashboard-components/DecisionsAdvice";

// Styles
import "./ProgressClaim.css";

export default function ProgressClaim(props) {
  const { project } = props;
  const { progressClaim } = props;
  const { showPCPanel, setShowPCPanel } = props;
  const { dashboardSettings } = props;

  if (isEmpty(progressClaim.progress_claim)) {
    return null;
  }
  // Create Dashboard IDs
  const RECOMMENDATION = "RECOMMENDATION";
  const CONTRACT = "CONTRACT";
  const TRACKER = "TRACKER";
  const DETAILS = "DETAILS";
  const DETAILSGRID = "DETAILSGRID";
  const TIMEBAR = "TIMEBAR";
  const OVERVIEW = "OVERVIEW";
  const RECONCILIATION = "RECONCILIATION";
  const PCCOMMENTARY = "PCCOMMENTARY";
  const GLOSSARY = "GLOSSARY";

  let display = [
    <ProgressClaimPaymentRecommendation
      title={translate("Progress Payment Recommendation")}
      PC={progressClaim.progress_claim}
      dashboardID={RECOMMENDATION}
    />,
    <ProgressClaimContractStatement
      title={translate("Contract Statement")}
      PC={progressClaim.progress_claim}
      dashboardID={CONTRACT}
    />,
    <ProgressClaimContractTracker
      progressClaim={progressClaim.progress_claim}
      dashboardID={TRACKER}
      title={translate("Contract Tracker")}
    />,
    <ProgressClaimDetails
      PC={progressClaim.progress_claim}
      dashboardID={DETAILS}
      title={translate("Payment Details")}
    />,
    <ProgressClaimDetailsGrid
      PC={progressClaim.progress_claim}
      dashboardID={DETAILSGRID}
      title={translate("Payment Details")}
    />,
    <ProgressClaimPaymentOverview
      PC={progressClaim.progress_claim}
      dashboardID={OVERVIEW}
      title={translate("Payment Overview")}
    />,
    <ProgressClaimReconciliation
      title={translate("Progress Claim Reconciliation")}
      PC={progressClaim.progress_claim}
      dashboardID={RECONCILIATION}
    />,
    <CostReportTimebar
      title={translate("Time Elapsed")}
      dashboardID={TIMEBAR}
      progressClaim={progressClaim.progress_claim}
    />,
    <ProgressClaimCommentary
      title={translate("Progress Commentary")}
      dashboardID={PCCOMMENTARY}
      progressClaim={progressClaim}
    />,
    <DashboardDecisions
      title={translate("Contractual Glossary")}
      dashboardID={GLOSSARY}
      project={project}
      decisions={progressClaim.glossary}
      type={"glossary"}
      column1={"decisions-advice-glossary-term"}
      column2={"decisions-advice-glossary-definition"}
      isGlossary
    />,
  ];

  const column1Set = [RECOMMENDATION, CONTRACT, PCCOMMENTARY];
  const column2Set = [DETAILSGRID, RECONCILIATION, GLOSSARY];
  const column3Set = [OVERVIEW, TRACKER, TIMEBAR];
  const column4Set = column1Set.concat(column2Set.concat(column3Set));

  return (
    <div className="progress-claim">
      <Dashboard
        display={display}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={project}
        // Dashboard Settings
        showDashboard={showPCPanel}
        setShowDashboard={setShowPCPanel}
        dashboardSettings={dashboardSettings}
        dashboardType={"PROGRESS_CLAIM"}
      />
    </div>
  );
}
