import React from "react";

import isEmpty from "../../validation/is-empty";
import CommentaryTable from "../post-contract-commentary/PCCommentaryTable";

export default function ProgressClaimCommentary(props) {
  const { progressClaim } = props;

  if (isEmpty(progressClaim)) {
    return null;
  }

  const { commentary } = progressClaim;

  if (isEmpty(commentary)) {
    return null;
  }

  const pc_commentary = commentary.filter((x) => x.type === "progress_claim");

  if (isEmpty(pc_commentary)) {
    return null;
  }

  return <CommentaryTable commentary={pc_commentary} />;
}
