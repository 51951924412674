export function detectDeployment(config) {
  // Current Deployment
  const deployment = window.location.hostname;

  // Legacy Hosts
  const localLegacy = "http://localhost:5000/api";
  const legacyDeployed = "/api";

  if (!config.serviceAPI) {
    if (deployment.includes("localhost")) {
      return localLegacy;
    } else {
      // All legacy deployments will append www.../api
      return legacyDeployed;
    }
  }

  if (config.serviceAPI) {
    if (deployment.includes("localhost")) {
      return config.localService;
    } else {
      // All service deployments will append www.../apis/v1.0
      return config.deployedService;
    }
  }
}
