import { detectDeployment } from "./Utilities";

export function permAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev.cost-clarity.com/apis/permissionservice/v1.0",
    deployedService: "/apis/permissionservice/v1.0",
  };

  // Local: https://localhost:44300/v1.0
  // Dev Deployed: https://dev.cost-clarity.com/apis/permissionservice/v1.0

  const D = detectDeployment(config);

  return {
    // Project Users
    GetMappings: `${D}/Permission/GetMappings`,
    GetUserList: `${D}/Permission/GetUserList`,
    UpdateMappingList: `${D}/Permission/UpdateMappingList`,
    UpdateUserMappings: `${D}/Permission/UpdateUserMappings`,
  };
}
