import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      heading: tranStr(""),
      key: "id",
      type: "CHECK",
      width: 50,
    },
    {
      heading: tranStr("Code"),
      key: "code",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Metric"),
      key: "metric",
      type: "STATUS",
      width: 511,
      isFiltered: true,
    },
    {
      heading: tranStr("Quantity"),
      key: "quantity",
      type: "QUANTITY",
      width: 100,
    },
    {
      heading: tranStr("Unit"),
      key: "unit",
      type: "STATUS",
      width: 100,
    },
  ];
}
