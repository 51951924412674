import React from "react";
import isEmpty from "../../validation/is-empty";

import "./IdleLogout.css";

function IdleLogout(props) {
  const { setModal } = props;

  // clear the logged out modal varable from local storage
  const clear = () => {
    if (!isEmpty(localStorage.idleLogout)) {
      localStorage.removeItem("idleLogout");
    }
    setModal(false);
  };
  return (
    <div className="idle-logout">
      <div>You have been logged out due to inactivity.</div>
      <div>
        <div className="idle-logout-button" onClick={clear}>
          OK
        </div>
      </div>
    </div>
  );
}

export default IdleLogout;
