import React from "react";
import { CORRESPONDENCE } from "./Correspondence.strings";

export default function ProjectNotSelected() {
  return (
    <div className="correspondence-project-not-selected">
      <i className="fas fa-comments fa-10x" />
      <div>{CORRESPONDENCE.REQUIRE_PROJECT_SELECTION_HINT}</div>
    </div>
  );
}
