import { authHeader } from "../../_helpers/auth-header";
import { AutoCloseModal } from "../../components/modal/Modal";
import { tranStr } from "../../utils/translation";

export async function sendData(data) {
  const { setError } = data;
  const { url } = data;
  const payload = data.projectData;
  const { CPs, project, setShow } = data;

  setError({
    text: tranStr("Saving..."),
    type: "feedback-success",
  });

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    CPs.set(await CPs.get(payload.projectId, project));
    setError({
      text: tranStr("Saved Successfully"),
      type: "feedback-success",
    });

    AutoCloseModal(setShow);
  } else {
    // Response Not OK
    setError({
      text: tranStr("Save Failed"),
      type: "feedback-error",
    });
  }
}
