import React from "react";

import RequestDemo from "./ContactUsForm";

import "./GuestContact.css";

export default function GuestContact() {
  return (
    <div className="guest-contacts">
      <div className="guest-contacts-background2">
        <RequestDemo />
      </div>
    </div>
  );
}
