// React
import React from "react";

// Functions
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";
import { comma } from "../../helpers/common.functions";

// Styles
import "./CostReportComparisonTable.css";
let gCheck = [];
export default function CostReportComparisonTable(props) {
  const { CRs } = props;

  if (isEmpty(CRs)) {
    return null;
  }

  const reversedCRs = [...CRs].reverse();

  if (reversedCRs.length < 2) {
    return null;
  }

  const rows = [
    { value: tranStr("Cost Report"), bold: true },
    { value: tranStr("Construction Cost") },
    { value: tranStr("Forecast Variations") },
    { value: tranStr("Submitted Variations") },
    { value: tranStr("Approved Variations") },
    { value: tranStr("Provisional Quantities") },
    { value: tranStr("Provisional Sums") },
    { value: tranStr("Delays / EoTs") },
    { value: tranStr("Client Directs") },
    {
      value: tranStr("Forecast Total"),
      total: true,
    },
  ];

  return (
    <div className="crct">
      <div className="crct-description-col">
        <Column
          data={rows}
          rowClass={"crct-description-col-row"}
          titleClass={"crct-description-col-row-title"}
          totalClass={"crct-description-col-row-total"}
        />
      </div>
      <div className="crct-table">
        {reversedCRs.map((CR, i) => {
          return <CRData key={i} CR={CR} />;
        })}
      </div>
    </div>
  );
}

function CRData(props) {
  const { CR } = props;

  const column = [];
  const RE = CR.cost_report;

  column.push({
    value: tranStr("Cost Report") + " " + RE.cost_report_number,
    bold: true,
  });
  column.push({ value: RE.construction_cost_forecast_total });
  column.push({ value: RE.variations_forecast });
  column.push({ value: RE.variations_submitted });
  column.push({ value: RE.variations_approved });
  column.push({ value: RE.provisional_quantities_forecast_total });
  column.push({ value: RE.provisional_sums_forecast_total });
  column.push({ value: RE.delays_and_extensions_of_time_forecast_total });
  column.push({ value: calculateClientDirectCosts(CR) });
  column.push({ value: RE.forecast_cost_excl_tax_forecast_total, total: true });

  return (
    <div className="crct-col">
      {
        <Column
          data={column}
          rowClass={"crct-col-row"}
          titleClass={"crct-col-row-title"}
          totalClass={"crct-col-row-total"}
        />
      }
    </div>
  );
}
//Column
function Column(props) {
  const { data } = props;
  const { rowClass, totalClass, titleClass } = props;
  const extraHeight = " crct-dd";
  return data.map((row, i) => {
    let heightClass = rowClass;

    if (row.value) {
      if (row.value.length > 33) {
        heightClass = rowClass + extraHeight;
        gCheck.push(i);
      }
    } 
    if (gCheck.indexOf(i) > -1) {
      heightClass = rowClass + extraHeight;
    }
    if (row.bold) {
      if (i === 0) {
        return (
          <div key={i} className={titleClass}>
            {<b>{comma(row.value)}</b>}
          </div>
        );
      }
      return (
        <div key={i} className={heightClass}>
          {<b>{comma(row.value)}</b>}
        </div>
      );
    }

    if (row.total) {
      return (
        <div key={i} className={totalClass}>
          {<b>{comma(row.value)}</b>}
        </div>
      );
    }

    return (
      <div key={i} className={heightClass}>
        {comma(row.value)}
      </div>
    );
  });
}

function calculateClientDirectCosts(CR) {
  const objKey = "forecast_total";

  return (
    CR.cost_report[`consultants_fees_${objKey}`] +
    CR.cost_report[`client_staffing_${objKey}`] +
    CR.cost_report[`authority_fees_${objKey}`] +
    CR.cost_report[`legal_fees_${objKey}`] +
    CR.cost_report[`ffe_${objKey}`] +
    CR.cost_report[`loose_furniture_${objKey}`] +
    CR.cost_report[`workstations_${objKey}`] +
    CR.cost_report[`audio_visual_${objKey}`] +
    CR.cost_report[`specialist_equipment_${objKey}`] +
    CR.cost_report[`ict_${objKey}`] +
    CR.cost_report[`relocation_${objKey}`] +
    CR.cost_report[`other1_${objKey}`] +
    CR.cost_report[`other2_${objKey}`] +
    CR.cost_report[`other3_${objKey}`]
  );
}
