import React from "react";
import isEmpty from "../../validation/is-empty";
import ProgressClaimContractTrackerChart from "./ProgressClaimContractTrackerChart";
import "./ProgressClaimContractTracker.css";

import { translate, tranStr } from "../../utils/translation";

export default function ProgressClaimContractTracker(props) {
  const { progressClaim } = props;

  if (isEmpty(progressClaim)) {
    return null;
  }

  let values = [
    progressClaim.gross_total_work_executed_excl_tax,
    progressClaim.forecast_adjusted_contract_sum_excl_tax,
  ];
  let fields = [
    tranStr("Total Work Executed"),
    tranStr("Forecast Contract Sum"),
  ];

  return (
    <div className="progress-claim-panel">
      <div className="pcct">
        <div className="pcct-horizontal-chart">
          <ProgressClaimContractTrackerChart values={values} fields={fields} />
        </div>
        <div className="pcct-percent-container">
          <div className="pcct-percent">
            <b>{Math.round(progressClaim.overall_percent_complete)}%</b>
          </div>
          <div className="pcct-text">
            {translate("Forecast Final Cost Approved to Date")}
          </div>
        </div>
      </div>
    </div>
  );
}
