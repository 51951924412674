// React
import React from "react";

// General
import Dashboard from "../dashboard/Dashboard";
import MemoizedMap from "../maps/Map";
import ProjectDashboardImage from "../dashboard-components/ProjectDashboardImage";
import PDCPProjectTeam from "../dashboard-components/PDCPProjectTeam";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

// Cost Report
import CostReportBudgetSummary from "../post-contract-cost-report/CostReportBudgetSummary";
import CostReportForecastCostSummary from "../post-contract-cost-report/CostReportForecastCostSummary";
import CostReportBalanceOfContingency from "../post-contract-cost-report/CostReportBalanceOfContingency";
import CostReportTrendChartContainer from "../post-contract-cost-report-comparison/CostReportTrendChartContainer";
import CostReportDetails from "../post-contract-cost-report/CostReportDetails";
import PDPCCommentary from "../post-contract-cost-report/PDPCCommentary";
import CostReportComparisonChart from "../post-contract-cost-report-comparison/CostReportComparisonChart";
import CostReportComparisonTable from "../post-contract-cost-report-comparison/CostReportComparisonTable";
import ProgressClaimPaymentRecommendation from "../post-contract-progress-claim/ProgressClaimPaymentRecommendation";
import ProgressClaimContractTracker from "../post-contract-progress-claim/ProgressClaimContractTracker";
import ProgressClaimCommentary from "../post-contract-progress-claim/ProgressClaimCommentary";
import CostReportTimebar from "../post-contract-cost-report/CostReportTimebar";
import PDPCDonutChangeTypeSummary from "../post-contract-cost-report/PDPCDonutChangeTypeSummary";
import PDPCTableChangeTypeSummary from "../post-contract-cost-report/PDPCTableChangeTypeSummary";
import CostReportCriticalList from "../post-contract-cost-report/CostReportCriticalList";

import CostReportBalanceOfContingencyBar from "../post-contract-cost-report/CostReportBalanceOfContingencyBar";
import CostReportClientDirectCostsSummary from "../post-contract-cost-report/CostReportClientDirectCostsSummary";
import CostReportBalanceOfContingencyChart from "../post-contract-cost-report/CostReportBalanceOfContingencyChart";
import PDPCDonutProvisionalSumSummary from "../post-contract-provisional-sums/PDPCDonutProvisionalSumSummary";

// Variations
import CostReportVariationStatus from "../post-contract-variations/CostReportVariationStatus";

// Provisional Sums
import PCProvisionalSumsSummary from "../post-contract-provisional-sums/PostContractProvisionalSumsSummary";
import PCProvisionalSumsSummaryBar from "../post-contract-provisional-sums/PCProvisionalSumsSummaryBar";

// Risk Register
import CostReportRiskRegisterChart from "../post-contract-risk-register/CostReportRiskRegisterChart";
import CostReportRiskRegisterTable from "../post-contract-risk-register/CostReportRiskRegisterTable";

// Generic Components
import DashboardRisks from "../dashboard-components/RisksAdvice";
import DashboardDecisions from "../dashboard-components/DecisionsAdvice";

export default function PDPCLayout(props) {
  const { project } = props;
  const { dashboardSettings } = props;
  const { showDashboard, setShowDashboard } = props;

  // Cost Report
  const { costReport } = props;
  const { comparisonCRs } = props;
  const { variations } = props;
  if (isEmpty(costReport.cost_report)) {
    return null;
  }

  // Create Dashboard IDs
  // Project
  const IMAGE = "IMAGE";
  const MAP = "MAP";
  const TEAM = "TEAM";

  // Cost Report
  const BUDGET = "BUDGETSUMMARY";
  const FORECASTCOST = "FORECASTCOST";
  const CONTINGENCYBALANCE = "CONTINGENCYBALANCE";
  const CONTINGENCYBALANCE_BAR = "CONTINGENCYBALANCE_BAR";
  const CONTINGENCYBALANCE_CHART = "CONTINGENCYBALANCE_CHART";
  const DETAILS = "DETAILSPANEL";

  // Variations
  const VARI = "VARIATIONPANEL";
  const CRITICALVARIATIONS = "CRITICALVARIATIONS";
  const CHANGETYPE = "CHANGETYPE";
  const CHANGETYPE_TABLE = "CHANGETYPE_TABLE";

  // History
  const TRENDCHART = "TRENDCHART";
  const COMPARISONCHART = "COMPARISONCHART";
  const COMPARISONTABLE = "COMPARISONTABLE";

  // Commentary
  const DECISIONS = "DECISIONS";
  const RISKS = "RISKS";
  const NOTES = "NOTES";
  const GLOSSARY = "GLOSSARY";
  const EXCLUSIONS = "EXCLUSIONS";
  const PCCOMMENT = "PCCOMMENTARY";

  // Progress Claim
  const TRACKER = "TRACKER";
  const RECOMMENDATION = "RECOMMENDATION";
  const TIMEBAR = "TIMEBARGRAPH";

  //Provisional Sums
  const CRITICALPROVISIONALSUMS = "CRITICALPROVISIONALSUMS";
  const PROVISIONALSUMSDONUT = "PROVISIONALSUMSDONUT";
  const PSSUMMARY = "PSSUMMARY";
  const PSSTATUS = "PSSTATUS";

  //Client Directs
  const CDCOSTSUMMARY = "CDCOSTSUMMARY";

  // Risk Register
  const RRTYPEGRAPH = "RRTYPEGRAPH";
  const RRTYPETABLE = "RRTYPETABLE";
  const CRITICALRR = "CRITICALRR";

  // The Components
  const display = [
    <CostReportBalanceOfContingencyChart
      title={translate("Balance of Contingency")}
      dashboardID={CONTINGENCYBALANCE_CHART}
      project={project}
      CR={costReport.cost_report}
    />,
    <CostReportRiskRegisterChart
      title={"Risk Types"}
      dashboardID={RRTYPEGRAPH}
      RRs={costReport.RRs}
    />,
    <CostReportRiskRegisterTable
      title={"Risk Types"}
      dashboardID={RRTYPETABLE}
      RRs={costReport.RRs}
    />,
    <CostReportCriticalList
      title={translate("Critical Variations")}
      dashboardID={CRITICALVARIATIONS}
      data={costReport.variations}
    />,
    <CostReportCriticalList
      title={translate("Critical Provisional Sums")}
      dashboardID={CRITICALPROVISIONALSUMS}
      data={costReport.PSs}
    />,
    <CostReportCriticalList
      title={translate("Critical Risks")}
      dashboardID={CRITICALRR}
      data={costReport.RRs}
    />,
    <PCProvisionalSumsSummaryBar
      title={translate("Provisional Sums Summary")}
      dashboardID={PSSUMMARY}
      costReport={costReport.cost_report}
    />,
    <PCProvisionalSumsSummary
      title={translate("Provisional Sums Status")}
      dashboardID={PSSTATUS}
      CR={costReport.cost_report}
    />,
    <CostReportComparisonChart
      title={translate("Cost Report Comparison")}
      dashboardID={COMPARISONCHART}
      CRs={comparisonCRs}
    />,
    <CostReportComparisonTable
      title={translate("Cost Report Comparison")}
      dashboardID={COMPARISONTABLE}
      CRs={comparisonCRs}
    />,
    <CostReportBudgetSummary
      title={translate("Budget Summary")}
      dashboardID={BUDGET}
      CR={costReport.cost_report}
    />,
    <CostReportForecastCostSummary
      title={translate("Forecast Cost Summary")}
      dashboardID={FORECASTCOST}
      CR={costReport.cost_report}
    />,
    <CostReportBalanceOfContingency
      title={translate("Balance of Contingency")}
      dashboardID={CONTINGENCYBALANCE}
      CR={costReport.cost_report}
    />,
    <ProgressClaimPaymentRecommendation
      title={translate("Progress Payment Recommendation")}
      PC={costReport.progress_claim}
      dashboardID={RECOMMENDATION}
    />,
    <CostReportTrendChartContainer
      title={translate("Cost Report Trends")}
      dashboardID={TRENDCHART}
      CRs={comparisonCRs}
    />,
    <CostReportDetails
      title={translate("Cost Report Details")}
      dashboardID={DETAILS}
      CR={costReport.cost_report}
      variations={costReport.variations}
    />,
    <CostReportTimebar
      title={translate("Time Elapsed")}
      dashboardID={TIMEBAR}
      progressClaim={costReport.progress_claim}
    />,
    <CostReportVariationStatus
      title={translate("Variation Status")}
      dashboardID={VARI}
      variations={variations}
      CR={costReport.cost_report}
      projectID={project.id}
    />,
    <PDCPProjectTeam
      title={translate("Project Team")}
      dashboardID={TEAM}
      project={project}
    />,
    <DashboardDecisions
      title={translate("Decisions Required")}
      dashboardID={DECISIONS}
      project={project}
      decisions={costReport.decisions}
      type={"decision"}
      column1={"decisions-advice-decision"}
      column2={"decisions-advice-required_by"}
      isDecision
    />,
    <DashboardDecisions
      title={translate("Contractual Glossary")}
      dashboardID={GLOSSARY}
      project={project}
      decisions={costReport.glossary}
      type={"glossary"}
      column1={"decisions-advice-glossary-term"}
      column2={"decisions-advice-glossary-definition"}
      isGlossary
    />,
    <DashboardRisks
      title={translate("Risks / Opportunities")}
      dashboardID={RISKS}
      risks={costReport.risks}
    />,
    <ProjectDashboardImage
      title={translate("Project Image")}
      dashboardID={IMAGE}
      project={project}
    />,
    <MemoizedMap
      title={translate("Project Location")}
      dashboardID={MAP}
      lat={project.lat}
      lng={project.lng}
    />,
    <PDPCCommentary
      title={translate("Notes and Clarifications")}
      dashboardID={NOTES}
      costReport={costReport}
      commentType="cost_report_clarifications"
    />,
    <PDPCCommentary
      title={translate("Exclusions")}
      dashboardID={EXCLUSIONS}
      costReport={costReport}
      commentType="cost_report_exclusions"
    />,

    <ProgressClaimCommentary
      title={translate("Progress Commentary")}
      dashboardID={PCCOMMENT}
      progressClaim={costReport.progress_claim}
    />,
    <ProgressClaimContractTracker
      progressClaim={costReport.progress_claim}
      dashboardID={TRACKER}
      title={translate("Contract Tracker")}
    />,
    <PDPCDonutChangeTypeSummary
      title={translate("Change Type Summary")}
      dashboardID={CHANGETYPE}
      variations={costReport.variations}
    />,
    <PDPCTableChangeTypeSummary
      title={translate("Change Type Summary")}
      dashboardID={CHANGETYPE_TABLE}
      variations={costReport.variations}
    />,
    <PDPCDonutProvisionalSumSummary
      title={translate("Provisional Sums Summary")}
      dashboardID={PROVISIONALSUMSDONUT}
      CR={costReport.cost_report}
    />,
    <CostReportBalanceOfContingencyBar
      title={translate("Headroom to Budget")}
      dashboardID={CONTINGENCYBALANCE_BAR}
      costReport={costReport.cost_report}
    />,
    <CostReportClientDirectCostsSummary
      title={translate("Client Direct Costs Summary")}
      dashboardID={CDCOSTSUMMARY}
      costReport={costReport.cost_report}
    />,
  ];

  const column1Set = [
    RRTYPEGRAPH,
    RRTYPETABLE,
    CRITICALRR,
    BUDGET,
    FORECASTCOST,
    CONTINGENCYBALANCE,
    CONTINGENCYBALANCE_BAR,
    CONTINGENCYBALANCE_CHART,
    CRITICALPROVISIONALSUMS,
  ];
  const column2Set = [
    NOTES,
    EXCLUSIONS,
    DETAILS,
    VARI,
    CRITICALVARIATIONS,
    COMPARISONCHART,
    COMPARISONTABLE,
  ];
  const column3Set = [IMAGE, MAP, TEAM, RECOMMENDATION];
  const column4Set = [
    DETAILS,

    BUDGET,
    FORECASTCOST,

    CONTINGENCYBALANCE,
    CONTINGENCYBALANCE_BAR,
    CONTINGENCYBALANCE_CHART,

    CRITICALVARIATIONS,
    CRITICALRR,
    CRITICALPROVISIONALSUMS,

    VARI,

    TRENDCHART,
    COMPARISONCHART,
    COMPARISONTABLE,

    DECISIONS,
    RISKS,
    NOTES,
    EXCLUSIONS,
    PCCOMMENT,
    GLOSSARY,
    RECOMMENDATION,
    TIMEBAR,
    TRACKER,
    CHANGETYPE,
    CHANGETYPE_TABLE,
    PROVISIONALSUMSDONUT,

    PSSUMMARY,
    PSSTATUS,

    CDCOSTSUMMARY,

    RRTYPEGRAPH,
    RRTYPETABLE,

    IMAGE,
    TEAM,
    MAP,
  ];

  return (
    <div>
      <Dashboard
        display={display}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={project}
        // Dashboard Settings
        showDashboard={showDashboard}
        setShowDashboard={setShowDashboard}
        dashboardSettings={dashboardSettings}
        dashboardType={"POST_CONTRACT"}
      />
    </div>
  );
}
