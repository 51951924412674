import React from "react";
import { displayProjectImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

export default function ProjectImage(props) {
  const { project } = props;

  return (
    <ImageDisplay
      image={displayProjectImage(project)}
      imageWidth={"30px"}
      imageHeight={"20px"}
      isBezierDisabled={true}
    />
  );
}
