import React from "react";
import browserDetector from "../browser/BrowserDetector";

export default function BrowserSupport(props) {
  if (!browserDetector().unsupported) {
    props.history.push("/");
  }

  return (
    <div>
      Your browser is not yet supported. We request that you use Google Chrome
      or the new Microsoft Edge (version 80 and above), which is included in the
      latest updates to Windows 10. We apologise for any inconvenience.
    </div>
  );
}
