// React
import React, { useState } from "react";

// Components
import Table from "../table/Table";
import Modal from "../modal/Modal";
import PostContractCriticalSelection from "../post-contract-table-critical-selection/PostContractCriticalSelection";
import PostContractProvisionalSumsModal from "../post-contract-table-modals/PostContractProvisionalSumsModal";

// Visualisations
import PostContractTableContainer from "../post-contract-tables/PostContractTableContainer";
import PCProvisionalSumsSummary from "../post-contract-provisional-sums/PostContractProvisionalSumsSummary";
import PCProvisionalSumsSummaryBar from "../post-contract-provisional-sums/PCProvisionalSumsSummaryBar";
import PDPCDonutProvisionalSumSummary from "./PDPCDonutProvisionalSumSummary";

// Functions
import { psColumns } from "../post-contract-tables/PostContractTable.functions";
import isEmpty from "../../validation/is-empty";
import { translate, tranStr } from "../../utils/translation";

// API
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

// Styles
import "./PostContractProvisionalSums.css";

export default function PostContractProvisionalSums(props) {
  const { PSs } = props;
  const { postContract } = props;
  const { variations } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  // No data available so return null
  if (isEmpty(PSs)) {
    return null;
  }

  // Visualisations
  const visuals = [
    <PCProvisionalSumsSummaryBar
      title={translate("Provisional Sums Summary")}
      costReport={variations.cost_report}
    />,
    <PCProvisionalSumsSummary
      title={translate("Provisional Sums Status")}
      CR={variations.cost_report}
    />,
    <PDPCDonutProvisionalSumSummary
      title={translate("Provisional Sums Summary")}
      CR={variations.cost_report}
    />,
  ];

  return (
    <div>
      <PostContractTableContainer Components={visuals} />
      <Table
        title={tranStr("Provisional Sums")}
        tableArray={PSs}
        columns={psColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal1}
      />
      <Modal
        // Modal Props
        title={tranStr("Provisional Sums")}
        Component={PostContractProvisionalSumsModal}
        modal={modal1}
        setModal={setModal1}
        columns={psColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
      <Modal
        // Modal Props4
        title={"Select Critical"}
        Component={PostContractCriticalSelection}
        modal={modal2}
        setModal={setModal2}
        // Component Props
        setShow={setModal2}
        data={variations.PSs.map((x) => {
          x.id = x.id.toString();
          return x;
        })}
        label={"Select Critical"}
        postContract={postContract}
        url={pcAPIs().save_critical_provisional_sums}
      />
      <button
        className="general-upload-button"
        onClick={() => {
          setModal2(true);
        }}
      >
        {"Select Critical"}
      </button>
    </div>
  );
}
