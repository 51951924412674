import React from "react";
import { Line } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";

import isEmpty from "../../validation/is-empty";
import "./TenderPriceIndexingCalculatorChart.css";

export default function TenderPriceIndexingCalculatorChart(props) {
  const { TPI, selectedLocations, minYear, maxYear } = props;
  const { cost } = props;
  const { currentIndex } = props;

  if (isEmpty(TPI)) {
    return null;
  }

  // Get current year
  let currentYear = parseInt(
    new Date(Date.now()).toLocaleString("default", {
      year: "numeric",
    }) - 1 // current year is a forecast so we subtract 1 year
  );

  // Format the data
  let field = TPI.years.filter((year) => year >= minYear && year <= maxYear);
  let datasets = [];

  selectedLocations.forEach((location, i) => {
    let value = [];
    let pastValue = [];

    // Filter to this location and within selected years
    TPI.raw.forEach((record) => {
      if (
        record.location === location &&
        record.year >= minYear &&
        record.year <= maxYear
      ) {
        // Future year only needs 1
        if (record.year > currentYear) {
          let escalation = calculateEscalation(
            record.value,
            currentIndex,
            cost
          );
          value.push(escalation);
          pastValue.push(null);
        }

        // Current year needs both
        if (record.year === currentYear) {
          let escalation = calculateEscalation(
            record.value,
            currentIndex,
            cost
          );
          value.push(escalation);
          pastValue.push(escalation);
        }

        // Future year only needs 1
        if (record.year < currentYear) {
          let escalation = calculateEscalation(
            record.value,
            currentIndex,
            cost
          );

          value.push(null);
          pastValue.push(escalation);
        }
      }
    });

    datasets.push({
      label: location,
      data: pastValue,
      barThickness: 10,
      borderColor: colourArray(i, 1),
      backgroundColor: colourArray(i, 1),
      lineTension: 0,
      fill: false,
    });

    datasets.push({
      label: location + " Forecast",
      data: value,
      barThickness: 10,
      borderColor: colourArray(i, 0.2),
      backgroundColor: colourArray(i, 0.2),
      lineTension: 0,
      fill: false,
    });
  });

  // Create the graph object
  let graph = {
    labels: field,
    datasets: datasets,
    text: field,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },

    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem) {
          return "$" + comma(tooltipItem.yLabel);
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: false,
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            callback: function (value, index, values) {
              return "$" + comma(value);
            },
          },
        },
      ],
    },
    legend: {
      display: false,
      position: "right",
      fullWidth: false,
      reverse: false,
    },
  };

  return (
    <div className="tpi-calc-chart-container">
      <div className="tpi-calc-chart">
        <Line data={graph} options={options} />
      </div>
    </div>
  );
}

function calculateEscalation(futureIndex, currentIndex, cost) {
  let percentage = round((futureIndex / currentIndex) * 100 - 100, 2);
  let value = parseInt(cost);
  let inflation = parseInt((value * percentage) / 100);
  return Math.round(value + inflation);
}

function colourArray(i, shade) {
  let heritage = `rgba(228, 97, 15, ${1 * shade})`;
  let aqua = `rgba(50, 98, 149, ${1 * shade})`;
  let collaboration1 = `rgba(85, 87, 90, ${1 * shade})`;
  let heritage2 = `rgba(228, 97, 15, ${0.3 * shade})`;
  let aqua2 = `rgba(50, 98, 149, ${0.3 * shade})`;
  let collaboration2 = `rgba(85, 87, 90, ${0.7 * shade})`;
  let collaboration3 = `rgba(85, 87, 90, ${0.3 * shade})`;

  let array = [
    heritage,
    aqua,
    collaboration1,
    aqua2,
    heritage2,
    collaboration2,
    collaboration3,
  ];

  let index = i % array.length;

  return array[index];
}

function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
