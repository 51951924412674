import React from "react";

import { validateCurrency } from "./PostContractTableModal.functions";

import {
  // Description,
  ExpandableRow,
  OneValueRow,
  DocumentLink,
} from "./PostContractModal.components";

import "./PostContractTableModal.css";

export default function PostContractRiskRegisterModal(props) {
  const { selectedRow } = props;
  const R = selectedRow;

  return (
    <div className="pctm">
      <div className="pctm-container-row">
        <OneValueRow heading1={"Description"} value1={R.description} />
      </div>
      <div className="pctm-container-row">
        <OneValueRow heading1={"Risk Impact"} value1={R.risk_impact} />
      </div>
      <div className="pctm-container-row">
        <OneValueRow
          heading1={"Mitigation Actions"}
          value1={R.mitigation_actions}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={["Consequence Rating", "Likelihood Rating", "Time Impact"]}
          values={[R.consequence_rating, R.likelihood_rating, R.time_impact]}
        />
        <ExpandableRow
          headings={["Status", "Action With"]}
          values={[R.status, R.action]}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow headings={["Risk Type"]} values={[R.risk_type]} />
        <ExpandableRow
          headings={["Best Case", "Most Likely", "Worst Case"]}
          values={[
            validateCurrency(R.best_case_value),
            validateCurrency(R.most_likely_value),
            validateCurrency(R.worst_case_value),
          ]}
        />
        <ExpandableRow
          headings={["Risk Analysis"]}
          values={[validateCurrency(R.risk_analysis)]}
        />
      </div>

      <div className="pctm-container-row">
        <OneValueRow heading1={"Comments"} value1={R.comments} />
      </div>
      <div className="pctm-container-row">
        <DocumentLink R={R} />
      </div>
    </div>
  );
}
