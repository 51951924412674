import React from "react";
import isEmpty from "../../validation/is-empty";
import Table from "../table/Table";

import { columns } from "./CreateProjectSelectClientCompanyTable.functions";

export default function CreateProjectSelectClientCompanyTable(props) {
  const { data, dataLoading } = props;
  const { setFunction } = props;
  const { setShow } = props;

  if (isEmpty(data) || dataLoading) {
    return <div></div>;
  }

  return (
    <Table
      title={""}
      tableArray={data}
      columns={columns()}
      tableSize={15}
      isTitleDisabled={true}
      isTotalDisabled={true}
      // Row Selection
      selectedRow={{}}
      setSelectedRow={setFunction}
      isClickToClose={true}
      // Modal Control
      setModal={setShow}
    />
  );
}
