import React from "react";
import { hasRoles } from "../../utils/roles";
import isEmpty from "../../validation/is-empty";

import CostReportPCRALogModalTrigger from "./CostReportPCRALogModalTrigger";
import ProgressClaimPCRALogModalTrigger from "./ProgressClaimPCRALogModalTrigger";
import VariationsPCRALogModalTrigger from "./VariationsPCRALogModalTrigger";

import { PCRA_MARKUP } from "../pcra/PCRAGlossary.constants";

export default function PostContractPCRAModalTrigger(props) {
  const { project } = props;
  const { postContract } = props;

  const { costReport } = props;
  const { progressClaim } = props;
  const { variations } = props;

  const { user } = props;

  const isUserCostManager = hasRoles(user.roles, ["CostManager"]);
  const isUserClient = hasRoles(user.roles, ["Client"]);

  const isActionable =
    project.project_phase === "POST_CONTRACT" && isUserCostManager;

  const isLocked = !isActionable;
  const lockedReason = determineLockedReason({
    isLocked,
    isUserClient,
    project,
  });

  return (
    <>
      {!isEmpty(costReport.cost_report) && (
        <CostReportPCRALogModalTrigger
          user={user}
          project={project}
          postContract={postContract}
          costReport={costReport}
          isLocked={isLocked}
          lockedReason={lockedReason}
        />
      )}
      {!isEmpty(progressClaim) && (
        <ProgressClaimPCRALogModalTrigger
          user={user}
          project={project}
          postContract={postContract}
          progressClaim={progressClaim}
          isLocked={isLocked}
          lockedReason={lockedReason}
        />
      )}
      {!isEmpty(progressClaim) && (
        <VariationsPCRALogModalTrigger
          user={user}
          project={project}
          postContract={postContract}
          variations={variations}
          isLocked={isLocked}
          lockedReason={lockedReason}
        />
      )}
    </>
  );
}

function determineLockedReason({ isLocked, isUserClient, project }) {
  if (isUserClient) {
    return PCRA_MARKUP.LOCKED_REASONS.QA_LOG_LOCKED_FOR_CLIENT;
  }

  if (project.project_phase === "POST_CONTRACT") {
    return PCRA_MARKUP.LOCKED_REASONS
      .PREV_POST_CONTRACT_REPORT_LOCKED_SINCE_NEWER_REPORT_UPLOADED;
  } else {
    return PCRA_MARKUP.LOCKED_REASONS
      .POST_CONTRACT_QA_LOG_LOCKED_WHEN_PROJECT_PHASE_NOT_IN_POST_CONTRACT;
  }
}
