import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";

import Table from "../table/Table";
import { pqColumns } from "../post-contract-tables/PostContractTable.functions";
import PostContractProvisionalQuantitiesModal from "../post-contract-table-modals/PostContractProvisionalQuantitiesModal";
import Modal from "../modal/Modal";
import { translate, tranStr } from "../../utils/translation";

export default function PostContractProvisionalQuantities(props) {
  const { PQs } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(PQs)) {
    return null;
  }

  return (
    <div>
      <div className="pct-spacer" />
      <Table
        title={tranStr("Provisional Quantities")}
        tableArray={PQs}
        columns={pqColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractProvisionalQuantitiesModal}
        modal={modal}
        setModal={setModal}
        columns={pqColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
