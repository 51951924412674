import React from "react";
import isEmpty from "../../validation/is-empty";

import "./ArticleProfile.css";

export default function ArticleProfile(props) {
  const { selectedArticle } = props;

  const URL = window.location.href;

  if (!URL.includes("article/")) {
    return null;
  }

  if (isEmpty(selectedArticle)) {
    return null;
  }

  return (
    <div className="category-profile">
      <div
        className="article-profile-image"
        style={{
          backgroundImage: `url(${selectedArticle.image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="article-profile-image-banner">
          <h1 className="display-4" style={{ fontSize: "20px" }}>
            {selectedArticle.title}
          </h1>
        </div>
      </div>
    </div>
  );
}
