import React from "react";
import isEmpty from "../../validation/is-empty";
import {
  headerTitles,
  Cell,
  Row,
  Table,
  groupBy,
} from "./BenchmarkElementAnalysisView.functions";
import "./BenchmarkElementAnalysisView.css";
import PropTypes from "prop-types";
import { formatNumber } from "./Benchmark.functions";

BenchmarkElementAnalysisView.propTypes = {
  rowData: PropTypes.object,
};

export function BenchmarkElementAnalysisView(props) {
  const { rowData } = props;
  let headerRow = [];
  let rows = [];
  if (
    isEmpty(rowData) ||
    isEmpty(rowData.category_groups) ||
    rowData.rows.length === 0
  ) {
    return (
      <div className="no-data-available">
        <i className="fas fa-thumbs-down" /> No Elemental Data Available
      </div>
    );
  }

  //get All codes from category groups
  prepareElementalAnalysisTable(rowData, headerRow, rows);

  return <Table rows={rows} headerRow={headerRow}></Table>;
}

export function prepareElementalAnalysisTable(rowData, headerRow, rows) {
  let ui = 0;
  let cells = [];
  if (isEmpty(rowData) || isEmpty(rowData.category_groups)) {
    return null;
  }
  const allcodes = rowData.category_groups
    .map((group) =>
      group.categoryItems.map((items) =>
        items.codes?.map((code) => [
          { code: code, field: items.field, category: group.categoryName },
        ])
      )
    )
    .flat(3);

  //Filter Element Categories from allCodes retrieved from above step
  const elementItems = allcodes.filter(
    (co) => co?.category === "Element Categories"
  );

  //insert corresponding category for each row in rowData.rows collection by using the elementItems collection filtered from the above step
  rowData.rows.forEach((row) => {
    row["category"] = elementItems.filter(
      (filtered) => filtered.code === row["code"]
    )[0]?.field;
  });

  //Prepare Groups based on code in rowData rows
  const result = groupBy(rowData.rows, "category");

  // Prepare Header Row and push to row object
  cells.push(
    Object.entries(headerTitles).map(([key, value]) => {
      let className = "info-table-cell header " + value.type;
      return (
        <Cell
          key={ui++}
          content={value.content}
          cellSpecificClass={className}
          isReporting={value.isReport}
        ></Cell>
      );
    })
  );
  headerRow.push(<Row key="header" Cells={cells}></Row>);

  //Prepare Rows for each Category group and Prepare Sub Total row for each group in description column.
  Object.entries(result).forEach((group, index) => {
    //Prepare Cell Content for Each column and push it to Row Array
    let hasData = true;
    let showTotal = [];

    group[1].forEach((element, rowIndex) => {
      cells = [];
      cells.push(
        Object.entries(headerTitles).map(([key, value]) => {
          let cellContent = "";
          cellContent = formatNumber(value.type, element[`${key}`]);
          return (
            <Cell
              key={ui++}
              content={cellContent}
              cellSpecificClass={value.type}
              isReporting={value.isReport}
            ></Cell>
          );
        })
      );
      //check whether row should be shown or not based on the values that row has all (currency and number fields) zero values
      hasData = Object.values(element).filter((k) => k === 0).length < 5;
      showTotal.push(hasData);
      hasData &&
        rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);
    });

    //Prepare Sum for each column under a Category group and prepare cells and push it to a sub heading total row.
    cells = [];
    Object.entries(headerTitles).map(([key, value]) => {
      let className = "info-table-cell total-subheading " + value.type;
      let sum = 0;
      let category = "";
      group[1].map((item) => {
        if (value.type === "number" || value.type === "currency") {
          sum += Number(item[key]);
        }
        category = item["category"];
        return sum;
      });
      if (value.type === "description") {
        cells.push(
          <Cell
            key={ui++}
            content={isEmpty(category) ? " Total" : category + " Total"}
            cellSpecificClass={className}
            isReporting={value.isReport}
          ></Cell>
        );
      } else {
        cells.push(
          <Cell
            key={ui++}
            content={
              isNaN(sum) || sum === 0 ? "" : formatNumber(value.type, sum)
            }
            cellSpecificClass={className}
            isReporting={value.isReport}
          ></Cell>
        );
      }
      return cells;
    });

    //check whether total row should be shown or not based on the values that row has all (currency and number fields) zero values
    showTotal.some((k) => k === true) &&
      rows.push(<Row key={ui++} Cells={cells} rowSpecificClass={""}></Row>);
  });
}
