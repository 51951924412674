import React from "react";

import { hasRoles } from "../../utils/roles";

import PCRALogModalTrigger from "../pcra/PCRALogModalTrigger";
import ProgressClaimPCRALogModal from "./ProgressClaimPCRALogModal";

export default function ProgressClaimPCRALogModalTrigger(props) {
  const { user } = props;
  const { project } = props;
  const { postContract } = props;
  const { progressClaim } = props;
  const { isLocked, lockedReason } = props;

  // Hide if not Cost Report
  let subLocation = determineSubLocation();
  if (subLocation !== "progress-claims") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager", "Client"])) {
    return null;
  }

  return (
    <PCRALogModalTrigger
      modalComponent={ProgressClaimPCRALogModal}
      projectID={project.id}
      project={project}
      postContract={postContract}
      progressClaim={progressClaim}
      user={user}
      isLocked={isLocked}
      lockedReason={lockedReason}
    />
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
