import React from "react";
import isEmpty from "../../validation/is-empty";
import ExportCashflow from "../export/ExportCashflow";
import { translate } from "../../utils/translation";
import { commaWithNaN } from "../../helpers/common.functions";

import "./CashflowTable.css";

export default function CashflowTableMonthly(props) {
  const { cashflow } = props;

  if (isEmpty(cashflow)) {
    return null;
  }

  return (
    <div className="post-contract-cashflow-table">
      <TableHead cashflow={cashflow} />
      <Table cashflow={cashflow} />
      <TableTotal cashflow={cashflow} />
    </div>
  );
}

function TableHead(props) {
  const { cashflow } = props;
  let row = {
    column1: translate("Month"),
    column2: translate("Forecast"),
    column3: translate("Contractor"),
    column4: translate("Actuals"),
  };

  return (
    <div className="post-contract-cashflow-table-head-row">
      <div className="post-contract-cashflow-table-description-cell">
        <b>{row.column1}</b>
      </div>
      <div className="post-contract-cashflow-table-quantity-cell ">
        <b>{row.column2}</b>
      </div>
      <div className="post-contract-cashflow-table-unit-cell">
        <b>{row.column3}</b>
      </div>
      <div className="post-contract-cashflow-table-unit-cell">
        <b>{row.column4}</b>
        <ExportCashflow cashflow={cashflow} />
      </div>
    </div>
  );
}

function Table(props) {
  const { cashflow } = props;

  return (
    <div>
      {cashflow.data.map((row, i) => {
        if (row.quantity <= 0) {
          return null;
        }

        // Subtotal styling for other rows
        let rowType = "post-contract-cashflow-table-row";

        return (
          <div key={i} className={rowType}>
            <div className="post-contract-cashflow-table-description-cell">
              {row.date}
            </div>
            <div className="post-contract-cashflow-table-quantity-cell ">
              <div></div>
              <div>{commaWithNaN(row.forecastMonthly)}</div>
            </div>
            <div className="post-contract-cashflow-table-unit-cell">
              <div></div>
              <div>{commaWithNaN(row.contractorMonthly)}</div>
            </div>
            <div className="post-contract-cashflow-table-unit-cell">
              <div></div>
              <div>{commaWithNaN(row.actualMonthly)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function TableTotal(props) {
  const { cashflow } = props;

  return (
    <div className="post-contract-cashflow-table-total-row">
      <div className="post-contract-cashflow-table-description-cell">
        <b>{translate("Total")}</b>
      </div>
      <div className="post-contract-cashflow-table-quantity-cell ">
        <div></div>
        <b>{commaWithNaN(Math.round(cashflow.totalForecast))}</b>
      </div>
      <div className="post-contract-cashflow-table-unit-cell">
        <b></b>
        <b>{commaWithNaN(Math.round(cashflow.totalContractor))}</b>
      </div>
      <div className="post-contract-cashflow-table-unit-cell">
        <b></b>
        <b>{commaWithNaN(Math.round(cashflow.totalActuals))}</b>
      </div>
    </div>
  );
}
