import isEmpty from "../../validation/is-empty";
import { calculateEscalationShallow } from "../../helpers/escalation.functions";
import calculateBudget from "../../validation/calculate-budget";
import { benchmarkCategories } from "../estimates/EstimatesBenchmarkDonutComponent.functions";
import { AVERAGE } from "../../actions/types";

export function processTotals(p, portfolioDashboard) {
  const { budget } = portfolioDashboard;
  const { totals } = portfolioDashboard;
  const { latest_cost_plan } = p;
  const { latest_cost_report } = p;

  totals.portfolioProjects += 1;

  // The budget for COST PLANNING is taken from the Project
  if (p.project_phase === "COST_PLANNING") {
    processCostPlanningTotals(p, latest_cost_plan, budget, totals);
  }

  // The budget for POST CONTRACT is taken from the adjusted budget in the Cost Report
  if (p.project_phase === "POST_CONTRACT") {
    processPostContractTotals(p, latest_cost_report, budget, totals);
  }
}

function processCostPlanningTotals(p, latest_cost_plan, budget, totals) {
  if (!isEmpty(latest_cost_plan)) {
    // Process the BUDGET
    const costPlanningBudget = calculateBudget(latest_cost_plan, false);
    const costPlanningBudgetAppr = calculateBudget(latest_cost_plan, true);

    if (!isNaN(costPlanningBudget)) {
      budget.costPlanningBudget += costPlanningBudget;
      budget.portfolioBudget += costPlanningBudget;
      budget.budgetValues.push({ value: costPlanningBudget, field: p.title });
    }

    if (!isNaN(costPlanningBudgetAppr)) {
      budget.costPlanningBudgetAppr += costPlanningBudgetAppr;
      budget.portfolioBudgetAppr += costPlanningBudgetAppr;
    }

    // Process the COST
    const costPlanningCost = latest_cost_plan.calculated_total;
    totals.costPlanningCost += costPlanningCost;
    totals.portfolioCost += costPlanningCost;

    // Process the TOTAL VARIANCE
    if (!isNaN(costPlanningBudget) && !isNaN(costPlanningCost)) {
      budget.portfolioVariance += costPlanningBudget - costPlanningCost;
    }

    // Process the APPROVED VARIANCE
    if (!isNaN(costPlanningBudgetAppr) && !isNaN(costPlanningCost)) {
      budget.portfolioVarianceAppr += costPlanningBudgetAppr - costPlanningCost;
    }
  }
}

function processPostContractTotals(p, latest_cost_report, budget, totals) {
  if (!isEmpty(latest_cost_report)) {
    // Process the BUDGET
    const postContractBudget =
      latest_cost_report.cost_report.forecast_cost_excl_tax_adjusted_budget;

    if (!isNaN(postContractBudget)) {
      budget.postContractBudget += postContractBudget;
      budget.portfolioBudget += postContractBudget;
      budget.budgetValues.push({ value: postContractBudget, field: p.title });
    }

    // Process the COST
    const postContractCost =
      latest_cost_report.cost_report.forecast_cost_excl_tax_forecast_total;
    totals.postContractCost += postContractCost;
    totals.portfolioCost += postContractCost;

    // Process the VARIANCE
    if (!isNaN(postContractBudget) && !isNaN(postContractCost)) {
      budget.portfolioVariance += postContractBudget - postContractCost;
    }
  }
}

export function processRisks(p, portfolioDashboard) {
  const { risks } = portfolioDashboard;

  const { latest_cost_plan } = p;
  const { latest_cost_report } = p;
  let projectRisks = [];

  if (p.project_phase === "COST_PLANNING" && latest_cost_plan) {
    projectRisks = latest_cost_plan.risks;
  }
  if (p.project_phase === "POST_CONTRACT" && latest_cost_report) {
    projectRisks = latest_cost_report.risks;
  }

  if (!isEmpty(projectRisks)) {
    processRiskRatings(projectRisks, p, risks);
  }

  // Sort by risk weighting
  risks.sort((a, b) => b.weighting - a.weighting);
}

function processRiskRatings(projectRisks, p, risks) {
  projectRisks.forEach((risk) => {
    risk.project_title = p.title;
    if (risk.urgency === "High") {
      risk.weighting = 3;
    }
    if (risk.urgency === "Medium") {
      risk.weighting = 2;
    }
    if (risk.urgency === "Low") {
      risk.weighting = 1;
    }
    if (isEmpty(risk.urgency)) {
      risk.weighting = 0;
    }
    risks.push(risk);
  });
}

export function processDecisions(p, portfolioDashboard) {
  const { decisions } = portfolioDashboard;

  const { latest_cost_plan } = p;
  const { latest_cost_report } = p;
  let projectDecisions = [];

  if (p.project_phase === "COST_PLANNING" && latest_cost_plan) {
    projectDecisions = latest_cost_plan.decisions;
  }

  if (p.project_phase === "POST_CONTRACT" && latest_cost_report) {
    projectDecisions = latest_cost_report.decisions;
  }

  if (!isEmpty(projectDecisions)) {
    projectDecisions.forEach((decision) => {
      decision.project_title = p.title;
      if (decision.type === "decision") {
        decisions.push(decision);
      }
    });
  }

  // Sort by date
  decisions.sort(compareDecisions);
}

function compareDecisions(a, b) {
  a = a.required_by.split("/").reverse().join("");
  b = b.required_by.split("/").reverse().join("");

  if (a < b) {
    return 1;
  } else if (a > b) {
    return -1;
  } else {
    return 0;
  }
}

export function processBenchmarks(p, portfolioDashboard, TPI) {
  const { latest_cost_plan } = p;

  let estimates = [];
  if (!isEmpty(latest_cost_plan)) {
    estimates = latest_cost_plan.estimates;
  }

  if (!isEmpty(estimates)) {
    estimates.forEach((estimate) => {
      if (isEmpty(estimate.benchmark)) {
        return;
      }
      // Benchmarks for Estimate
      const thisBenchmark = estimate.benchmark;
      const benchmarks = estimate.benchmark.linked_benchmarks;

      // Escalation
      const futureYear = parseInt(
        new Date(thisBenchmark.base_date).toLocaleString("default", {
          year: "numeric",
        })
      );
      calculateEscalationShallow(
        TPI,
        [thisBenchmark, ...benchmarks],
        futureYear
      );

      // Average Calculation
      const averages = [];
      benchmarks.forEach((b) => {
        averages.push(Math.round(b.displayed_construction_cost_rate));
      });

      // The values to send back
      const estimateRate = Math.round(
        estimate.construction_cost / estimate.local_region_area
      );
      const averageRate = Math.round(calculateAverage(averages));

      // Only add this data if it is valid
      if (
        estimateRate !== Infinity &&
        estimateRate !== 0 &&
        !isNaN(estimateRate) &&
        !isNaN(averageRate)
      ) {
        portfolioDashboard.benchmarking.averages.push({
          projectName: p.title,
          estimateName: estimate.name,
          estimateRate: estimateRate,
          averageRate: averageRate,
        });
      }
    });
  }
}

export function processCostCategories(p, portfolioDashboard) {
  const { latest_cost_plan } = p;
  const { costCategories } = portfolioDashboard;

  let estimates = [];

  if (!isEmpty(latest_cost_plan)) {
    estimates = latest_cost_plan.estimates;
  }

  const benchmarks = estimates.map((e) => e.benchmark);
  let projectCostCategories = benchmarkCategories(
    benchmarks,
    "Cost Categories",
    AVERAGE
  );

  // Don't add to Cost Categories if all are 0
  let valid = true;
  if (projectCostCategories.every((x) => x.value === 0)) {
    valid = false;
  }

  // Add project to Cost Categories
  if (valid) {
    costCategories.projectTitles.push(p.title);
    projectCostCategories.forEach((y) => {
      if (!costCategories.masterCategories.some((x) => x.field === y.field)) {
        costCategories.masterCategories.push({ field: y.field, value: [] });
      }
    });
    costCategories.costCategoryData.push({
      projectTitle: p.title,
      projectCostCategories: projectCostCategories,
    });
  }
}

export function processDurations(p, portfolioDashboard) {
  const { latest_cost_report } = p;
  // Compose the default object for this data
  const projectProgress = {
    projectTitle: p.title,
    forecastFinalCostApprovedToDate: 0,
    programElapsed: 0,
  };

  // If a cost report exists
  if (!isEmpty(latest_cost_report)) {
    const { progress_claim } = latest_cost_report;
    // And there is a linked progress claim to the cost report
    if (!isEmpty(progress_claim)) {
      // The progress in percent completed
      projectProgress.forecastFinalCostApprovedToDate =
        progress_claim.overall_percent_complete;

      // The progress in time
      const currentMonth = progress_claim.time.elapsed;
      const totalMonths = progress_claim.time.total_months_number;

      let programElapsed = Math.round((currentMonth / totalMonths) * 100);

      // Validate the number
      if (isNaN(programElapsed)) {
        programElapsed = 0;
      }

      // Cannot exceed 100
      if (programElapsed > 100) {
        programElapsed = 100;
      }

      // Cannot be less than 0
      if (programElapsed < 0) {
        programElapsed = 0;
      }

      projectProgress.programElapsed = programElapsed;
    }
  }

  if (
    projectProgress.forecastFinalCostApprovedToDate !== 0 &&
    projectProgress.programElapsed !== 0
  ) {
    portfolioDashboard.postContract.progress.push(projectProgress);
  }
}

function calculateAverage(arr) {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
}

export function processAreas(p, portfolioDashboard) {
  const { metrics } = portfolioDashboard;

  metrics.projectAreas.push({
    projectTitle: p.title,
    projectArea: p.local_region_area,
    projectGia: p.gross_internal_area,
  });
}

export function processMetrics(p, portfolioDashboard) {
  const { latest_cost_plan } = p;
  const { metrics } = portfolioDashboard;

  let estimates = [];
  if (!isEmpty(latest_cost_plan)) {
    estimates = latest_cost_plan.estimates;
  }

  if (!isEmpty(estimates)) {
    estimates.forEach((estimate) => {
      const { benchmark } = estimate;
      benchmark?.metrics?.forEach((metric) => {
        // Add Project information
        metric.projectTitle = p.title;
        metric.estimateName = estimate.name;
        if (metric.code === "C1.4") {
          metrics.servicesMetrics.push(metric);
        }
      });
    });
  }
}
