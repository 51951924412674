// React
import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";

import PostContractStages from "./PostContractStages";
import Clarifications from "../post-contract-commentary/Clarifications";
import Exclusions from "../post-contract-commentary/Exclusions";

import PostContractEditRisks from "./PostContractEditRisks";
import ProgressClaimCommentary from "../post-contract-commentary/ProgressClaimCommentary";
import PostContractToggleFeatures from "./PostContractToggleFeatures";
import ReportModal from "../reports/ReportModal";

// Generic Components
import AdviceDecisionsEdit from "../dashboard-components/AdviceDecisionsEdit";
import Modal from "../modal/Modal";

// Functions
import { savePostContractDecisionsAndGlossary } from "../../api/post-contract/PostContractAdviceAPI";
import { hasRoles } from "../../utils/roles";
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";
import isEmpty from "../../validation/is-empty";

export default function ProjectControlPostContract(props) {
  const { user } = props;
  const { postContract } = props;

  const { costReport } = props;
  const { progressClaim } = props;
  const { project } = props;
  const { projectID } = props;
  const [fields, setFields] = useState([]);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  let title = "";
  if (window.location.href.includes("dashboard")) {
    title = tranStr("Post Contract Control");
  }
  if (window.location.href.includes("cost-report")) {
    title = tranStr("Cost Report Control");
  }
  if (window.location.href.includes("progress-claims")) {
    title = tranStr("Progress Claim Control");
  }

  return (
    <div className="project-control-panel-controls">
      <h1 className="display-4">{title}</h1>
      <Button
        Component={PostContractStages}
        postContract={postContract}
        projectID={projectID}
        project={project}
        width={"670px"}
        icon={<i className="fas fa-bars" />}
        text={"Post Contract Details"}
        showLocation={"dashboard"}
      />
      <Button
        Component={PostContractToggleFeatures}
        project={project}
        width={"670px"}
        icon={<i className="fas fa-eye-slash" />}
        text={"Toggle Features"}
        showLocation={"dashboard"}
        disabled={isEmpty(project.post_contract_duration)}
      />

      <Button
        Component={ReportModal}
        projectID={projectID}
        project={project}
        projectTitle={project.title}
        projectPhase={"POST_CONTRACT"}
        costReport={costReport}
        icon={<i className="fas fa-download" />}
        width={"1000px"}
        text={translate("Export Cost Report")}
        showLocation={"dashboard"}
        disabled={false}
        type={"Cost Report"}
      />

      <Button
        Component={Clarifications}
        projectID={projectID}
        project={project}
        costReport={costReport}
        fields={fields}
        setFields={setFields}
        width={"670px"}
        icon={<i className="fas fa-clipboard-list" />}
        text={"Notes and Clarifications"}
        postContract={postContract}
        showLocation={"cost-report"}
        disabled={isEmpty(project.post_contract_duration)}
      />

      <Button
        Component={Exclusions}
        projectID={projectID}
        project={project}
        costReport={costReport}
        fields={fields}
        setFields={setFields}
        width={"670px"}
        icon={<i className="fas fa-clipboard-list" />}
        text={"Exclusions"}
        postContract={postContract}
        showLocation={"cost-report"}
        disabled={isEmpty(project.post_contract_duration)}
      />

      <Button
        Component={ProgressClaimCommentary}
        projectID={projectID}
        project={project}
        progressClaim={progressClaim}
        width={"670px"}
        icon={<i className="fas fa-clipboard-list" />}
        text={"Progress Commentary"}
        postContract={postContract}
        showLocation={"progress-claim"}
        disabled={isEmpty(project.post_contract_duration)}
      />
      <Button
        Component={PostContractEditRisks}
        projectID={projectID}
        project={project}
        costReport={costReport}
        postContract={postContract}
        icon={
          <i
            className="fas fa-exclamation-triangle"
            style={{ marginBottom: "3px" }}
          ></i>
        }
        text={"Risks / Opportunities"}
        showLocation={"cost-report"}
        disabled={isEmpty(project.post_contract_duration)}
      />
      <Button
        Component={AdviceDecisionsEdit}
        decisions={costReport.decisions}
        requiredData={postContract}
        saveFunction={savePostContractDecisionsAndGlossary}
        // POST CONTRACT API
        saveURL={pcAPIs().set_decisions}
        icon={<i className="fas fa-asterisk"></i>}
        text={"Key Decisions"}
        type={"decision"}
        fieldTypes={{ field1: "advice-decision", field2: "advice-required-by" }}
        labels={{ label1: "Decision", label2: "Required Date (DD/MM/YYYY)" }}
        note={
          "Add up to 5 key Decisions below to be displayed on the Post Contract Dashboard."
        }
        max={5}
        data={{
          projectID: projectID,
          postContractID: costReport.id,
          postContract: postContract,
          type: "decision",
        }}
        showLocation={"cost-report"}
        disabled={isEmpty(project.post_contract_duration)}
      />
      {window.location.href.includes("progress-claims") && (
        <Button
          Component={AdviceDecisionsEdit}
          decisions={progressClaim.glossary}
          requiredData={postContract}
          saveFunction={savePostContractDecisionsAndGlossary}
          // POST CONTRACT API
          saveURL={pcAPIs().set_decisions}
          icon={<i className="fas fa-file-alt"></i>}
          text={"Contractual Glossary"}
          type={"glossary"}
          fieldTypes={{ field1: "advice-glossary", field2: "advice-glossary" }}
          labels={{
            label1: "Reporting Term",
            label2: "Project Specific or Contractual Term",
          }}
          note={
            "Use the fields below to clarify how reporting terms used in Cost Clarity relate to project specific and/or contractual terms."
          }
          max={20}
          data={{
            projectID: projectID,
            postContractID: progressClaim.id,
            postContract: postContract,
            type: "glossary",
          }}
          showLocation={"progress-claim"}
          disabled={isEmpty(project.post_contract_duration)}
        />
      )}
    </div>
  );
}

function Button(props) {
  const [modal, setModal] = useState(false);
  const { icon, text } = props;
  const { Component } = props;
  const { showLocation } = props;
  const { disabled } = props;

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (disabled) {
    return (
      <div className="project-control-panel-button-container">
        <div className={"project-control-panel-button-disabled"}>{icon}</div>
        <h1
          className="display-4"
          style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
        >
          {text}
        </h1>
        <Modal
          // Modal Props
          title={text}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          {...props}
        />
        <span className="tooltiptext">{"Post Contract Details"}</span>
      </div>
    );
  }

  return (
    <div className="project-control-panel-button-container">
      <div
        className="project-control-panel-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {icon}
      </div>
      <h1
        className="display-4"
        style={{ fontSize: "15px", marginBottom: "0px", marginLeft: "10px" }}
      >
        {text}
      </h1>
      <Modal
        // Modal Props
        title={text}
        Component={Component}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}
