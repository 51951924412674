import React from "react";
import DesignDevelopmentProgression from "../charts/DesignDevelopmentProgression";

import {
  designDevelopmentProgressionByStages,
  designDevelopmentProgressionByRevisions,
} from "../charts/DesignDevelopmentProgression.functions";

import "./PDCPDesignProgressionChart.css";

export default function PDCPDesignProgressionChart(props) {
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { type, height, width } = props;

  let DCs,
    CCs,
    fields = [];

  if (type === "STAGE") {
    const { designContingencies, costChanges, field } =
      designDevelopmentProgressionByStages(CP, CPs);

    DCs = designContingencies;
    CCs = costChanges;
    fields = field;
  }

  if (type === "RELEASE") {
    const { designContingencies, costChanges, field } =
      designDevelopmentProgressionByRevisions(CP, CPs);

    DCs = designContingencies;
    CCs = costChanges;
    fields = field;
  }

  if (fields.length <= 1) {
    return null;
  }

  return (
    <div className="pdcp-design-progression-chart">
      <DesignDevelopmentProgression
        project={project}
        CP={CP}
        CPs={CPs}
        height={height}
        width={width}
        type={type}
        DCs={DCs}
        CCs={CCs}
        fields={fields}
      />
    </div>
  );
}
