import React from "react";
import {
  timestampToDateAndTime,
  getPCRARequestingUserDisplayName,
} from "./PCRA.functions";
import { PCRA_GLOSSARY } from "./PCRAGlossary.constants";

const { MESSAGE_STRINGS } = PCRA_GLOSSARY;

export function PCRALogBoxCurrent(props) {
  const currentTimestamp = new Date().toISOString();
  const [date, time] = timestampToDateAndTime(currentTimestamp);

  const { currentUser: user } = props;
  const { currentComments, setCurrentComments } = props;

  const { email } = user;

  return (
    <div className="pcra-log-box">
      <textarea
        className="comments"
        placeholder={MESSAGE_STRINGS.CURRENT_COMMENTS_HINT}
        value={currentComments || ""}
        onChange={(e) => {
          setCurrentComments(e.target.value);
        }}
      />
      <div className="username">{getPCRARequestingUserDisplayName(user)}</div>
      <div className="timestamp">
        {date}
        <br />
        {time}
      </div>
      <div className="email">{email}</div>
    </div>
  );
}
