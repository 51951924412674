import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";
import CompanyProfile from "./CompanyProfile";
import Spinner from "../common/Spinner";
import PropTypes from "prop-types";

import Table from "../table/Table";
import Modal from "../modal/Modal";

import { columns } from "./CompanyManagementTable.functions";

import "./CompanyManagement.css";

CompanyManagement.propTypes = {
  companies: PropTypes.object,
};

export default function CompanyManagement(props) {
  const { companies } = props;

  // Clicked Row
  const [selectedCompany, setSelectedCompany] = useState({});
  const [modal, setModal] = useState(false);

  if (companies.loading) {
    return (
      <div className="company-management-content">
        <Spinner marginLeft={"625px"} marginTop={"200px"} />
      </div>
    );
  }

  const companiesArray = companies.data.map((company) => {
    company.id = company.id.toString();
    return company;
  });

  return (
    <div className="company-management-content">
      <Table
        title={tranStr("Companies")}
        tableArray={companiesArray}
        columns={columns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Row Selection
        selectedRow={selectedCompany}
        setSelectedRow={setSelectedCompany}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("Company Profile")}
        Component={CompanyProfile}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        company={selectedCompany}
        setCompany={setSelectedCompany}
        companies={companies}
      />
    </div>
  );
}
