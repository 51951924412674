import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";
import { translate, tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export default function BudgetSummaryChart(props) {
  const { budgetTotals } = props;

  if (isEmpty(budgetTotals)) {
    return null;
  }
  let values = budgetTotals;

  let fields = [tranStr("Total Budget"), tranStr("Approved Budget")];

  // Create the graph object
  let graph = {
    labels: fields,
    datasets: [
      {
        data: values,
        barThickness: 10,
        backgroundColor: colourGenerator(values, 1),
        borderColor: colourGenerator(values, 1),
      },
    ],
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem) {
          return comma(Math.round(tooltipItem.xLabel));
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (tick, index, ticks) {
              return comma(tick);
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (tick, index, ticks) {
              return tick;
            },
          },
        },
      ],
    },
    legend: {
      display: false,
      position: "bottom",
      fullWidth: false,
      reverse: false,
    },
  };
  return (
    <div className="project-budget-chart-box">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Total Budget Summary")}
      </h1>
      <div>
        <HorizontalBar
          data={graph}
          options={options}
          responsive={true}
          updateMode="resize"
          maintainAspectRatio={false}
        />
      </div>
    </div>
  );
}

function colourGenerator(array, opacity) {
  return array.map((v, i) => {
    if (i === 0) {
      return `rgba(228, 97, 15,${opacity})`;
    } else {
      return `rgba(85, 87, 90,${opacity})`;
    }
  });
}
