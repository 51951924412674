// React
import React, { useState } from "react";

// Components
import { TranslationList } from "../admin-translation/TranslationTable";

// Style
import "./AdminLocalisationTable.css";

export default function AdminLocalisationTable(props) {
  const { language } = props;
  const { tempLanguage } = props;
  const { setLanguage } = props;
  const { setTempLanguage } = props;
  const { activePage, setActivePage } = props;

  const keys = Object.keys(language);

  // Page & Filters
  const [keyFilterText, setKeyFilterText] = useState("");
  const [valueFilterText, setValueFilterText] = useState("");

  return (
    <div>
      <div className="admin-localisation-table-header">
        <div className="admin-translation-label">
          <b>Terminology Key</b>
        </div>
        <div className="admin-translation-label">
          <b>Terminology Value</b>
        </div>
      </div>

      <div className="admin-localisation-table-header">
        <div className="admin-translation-label">
          <input
            className="admin-localisation-table-key-search"
            placeholder="Search Key"
            value={keyFilterText}
            onChange={(e) => {
              setValueFilterText("");
              setKeyFilterText(e.target.value);
              updateKeys(e.target.value);
              setActivePage(1);
            }}
          />
        </div>
        <input
          className="admin-localisation-table-value-search"
          placeholder="Search Value"
          value={valueFilterText}
          onChange={(e) => {
            setKeyFilterText("");
            setValueFilterText(e.target.value);
            updateValues(e.target.value);
            setActivePage(1);
          }}
        />
      </div>
      <TranslationList
        language={language}
        setLanguage={setLanguage}
        activePage={activePage}
        tempLanguage={tempLanguage}
      />
    </div>
  );

  async function updateKeys(filterTxt) {
    setTempLanguage(
      keys.filter(
        (key) => key.toLowerCase().indexOf(filterTxt.toLowerCase()) !== -1
      )
    );
  }
  async function updateValues(filterTxt) {
    setTempLanguage(
      keys.filter(
        (key) =>
          language[key].toLowerCase().indexOf(filterTxt.toLowerCase()) !== -1
      )
    );
  }
}
