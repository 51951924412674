import React from "react";

import "./Checkboxes.css";
import isEmpty from "../../validation/is-empty";

export default function CheckBoxes(props) {
  const { array, setFunction, selection } = props;

  if (isEmpty(array)) {
    return null;
  }

  return (
    <div>
      <label className="container">
        <div className="checkbox-label">Select All</div>
        <input
          type="checkbox"
          name={"Select All"}
          value={"Select All"}
          onChange={(e) => {
            let checked = e.target.checked;
            setFunction([]);
            // If checking this option
            if (checked) {
              // Set all values to checked
              setFunction((selectedItems) => [...selectedItems, ...array]);
            }

            // If unchecking the option
            if (!checked) {
              // Set all values to unchecked
              setFunction((selectedItems) => []);
            }
          }}
        />
        <span className="checkmark"></span>
      </label>
      {array.map((item, i) => {
        return (
          <label key={i} className="container">
            <div className="checkbox-label">{item}</div>
            <input
              key={i}
              type="checkbox"
              name={item}
              value={item}
              checked={selection.includes(item)}
              onChange={(e) => {
                let selected = e.target.name;
                let checked = e.target.checked;

                // If checking this option
                if (checked) {
                  // Set the locations to current selection + the selected
                  setFunction((selectedItems) => [...selectedItems, selected]);
                }

                // If unchecking the option
                if (!checked) {
                  // Set the locations to the current selection
                  setFunction((selectedItems) =>
                    // Filtering out the seleted
                    selectedItems.filter((x) => x !== selected)
                  );
                }
              }}
            />
            <span className="checkmark"></span>
          </label>
        );
      })}
    </div>
  );
}
