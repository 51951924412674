import { escalateFer } from "./escalateCurrency.functions";
import { escalateTfi } from "./escalateLocality.functions";
import { escalateTpi } from "./escalateInflation.functions";

export function escalation(escalationData) {
  const { costData } = escalationData;
  const { benchmarks } = costData;
  const { isLocalRegionFactorEnabled } = escalationData;

  // Escalate Costs for Benchmarks
  benchmarks.forEach((benchmark) => {
    let bm = benchmark;

    bm.calculated_construction_cost_rate = isLocalRegionFactorEnabled
      ? bm.construction_cost_rate
      : (bm.construction_cost / bm.gia).toFixed(0);

    let key = "calculated_construction_cost_rate"; // or GIA based on isLocalRegionFactorEnabled
    let costObj = bm;
    escalate(escalationData, bm, costObj, key);

    // Rows
    bm.rows.forEach((row) => {
      const bmRowCostObj = row;

      row.calculated_local_region_rate = isLocalRegionFactorEnabled
        ? row.local_region_rate
        : row.gia_rate;

      // Cost / m2 GFA (Local Region Area)
      const rowKey = "calculated_local_region_rate"; // or GIA based on isLocalRegionFactorEnabled (isLocalRegionFactorEnabled ? row.local_region_rate : row.gia_rate)
      escalate(escalationData, bm, bmRowCostObj, rowKey);

      row.calculated_rate = isLocalRegionFactorEnabled
        ? row.rate
        : row.gia_rate;

      // Elemental Unit Rate
      const rowElementKey = "calculated_rate";
      escalate(escalationData, bm, bmRowCostObj, rowElementKey);
    });

    // Categories
    bm.category_groups?.forEach((group) => {
      group.categoryItems.forEach((item) => {
        item.calculated_rate = isLocalRegionFactorEnabled
          ? item.localRegionRate
          : item.giaRate;
        let key = "calculated_rate"; //rate is based on the local and global
        let costObj = item;
        escalate(escalationData, bm, costObj, key);
        item.calculated_value = item.value;
        key = "calculated_value";// This is based on total of the category elements  / This is generating for future usage..
        costObj = item;
        escalate(escalationData, bm, costObj, key);
      });
    });
  });
}

function escalate(cData, b, costObject, key) {
  const rate = costObject[key];
  // Exchange Escalation
  const { ferRate, ferPercent } = escalateFer(cData, b, rate);
  costObject["exchanged_" + key] = ferRate;
  costObject["percent_fer_escalated_" + key] = ferPercent;

  // Locality Escalation
  const cEscalated = costObject["exchanged_" + key];
  const { lfiRate, lfiPercent } = escalateTfi(cData, b, cEscalated);
  costObject["locality_escalated_" + key] = lfiRate;
  costObject["percent_lfi_escalated_" + key] = lfiPercent;

  // Inflation Escalation
  const lEscalated = costObject["locality_escalated_" + key];
  const { tpiRate, tpiPercent } = escalateTpi(cData, b, lEscalated);
  costObject["escalated_" + key] = tpiRate;
  costObject["percent_tpi_escalated_" + key] = tpiPercent;
}

// Project Dashboard
export function calculateEscalationShallow(TPI, benchmarks, futureYear) {
  // Escalation
  const escalationData = {
    costData: {
      comparisonBenchmarks: benchmarks,
    },
    inflationData: {
      TPI: TPI,
      futureYear: futureYear,
    },
  };

  // Escalate High level benchmark costs
  benchmarks?.forEach((b) => {
    let rate = b.construction_cost_rate;
    const { tpiRate } = escalateTpi(escalationData, b, rate);
    let escalated_rate = tpiRate;

    b.escalated_construction_cost_rate = escalated_rate;
    b.displayed_construction_cost_rate = escalated_rate;
  });
}

export function toggleEscalation(isEscalated, benchmarks) {
  ///////////////////
  //Display Costs
  ///////////////////

  const bms = benchmarks;

  // Determine Display Cost
  if (isEscalated) {
    bms.forEach((b) => {
      // High Level Costs
      b.displayed_construction_cost_rate =
        b.escalated_calculated_construction_cost_rate;

      // Row Costs
      b.rows.forEach((row) => {
        row.displayed_local_region_rate =
          row.escalated_calculated_local_region_rate;
        row.displayed_rate = row.escalated_calculated_rate;
      });

      // Category Costs
      b.category_groups?.forEach((group) => {
        group.categoryItems.forEach((item) => {
          item.displayed_rate = item.escalated_calculated_rate;
          item.displayed_value = item.escalated_calculated_value; // For future usage of this dispalyed_value
        });
      });
    });
  } else {
    bms.forEach((b) => {
      // High Level Costs
      b.displayed_construction_cost_rate =
        b.exchanged_calculated_construction_cost_rate;

      // Row Costs
      b.rows.forEach((row) => {
        row.displayed_local_region_rate =
          row.exchanged_calculated_local_region_rate;
        row.displayed_rate = row.exchanged_calculated_rate;
      });

      // Category Costs
      b.category_groups?.forEach((group) => {
        group.categoryItems.forEach((item) => {
          item.displayed_rate = item.exchanged_calculated_rate;
          item.displayed_value = item.exchanged_calculated_value; // For future usage of this dispalyed_value
        });
      });
    });
  }
}
