import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";

// Get Tender Price Index
export async function getTPI(region, country, isGlobal) {
  const url = sharedAPIs().get_tpi;

  const payload = {
    region: region,
    country: country,
  };

  return await getResponse({ url, payload });
}

async function getResponse({ url, payload }) {
  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get TPI Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function useTPI(active, region, country, isGlobal) {
  const [loading, setLoading] = useState(true);
  const [tpi, setTpi] = useState({});
  useEffect(() => {
    if (active && !isEmpty(region) && !isEmpty(country)) {
      setLoading(true);
      getTPI(region, country, isGlobal)
        .then((tpi) => {
          setTpi(tpi);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [active, region, country, isGlobal]);

  return {
    TPILoading: loading,
    TPI: tpi,
    setTPI: setTpi,
    error: null,
  };
}

// regionCountryList must be of the format [ { region: "...", country: "..." }, ... ]
export async function getMultiRegionTPI(regionCountryList) {
  const url = sharedAPIs().get_multi_region_tpi;

  // remove empty entries and duplicates
  const filteredList = regionCountryList.filter(
    ({ region, country }, index, self) =>
      !isEmpty(region) &&
      !isEmpty(country) &&
      index ===
        self.findIndex((x) => x.region === region && x.country === country)
  );

  const payload = filteredList;

  return await getResponse({ url, payload });
}

// regionCountryList must be of the format [ { region: "...", country: "..." }, ... ]
export function useMultiRegionTPI(regionCountryList) {
  const [tpiLoading, setTpiLoading] = useState(true);
  const [multiRegionTpi, setMultiRegionTpi] = useState({});
  useEffect(() => {
    if (!isEmpty(regionCountryList)) {
      setTpiLoading(true);
      getMultiRegionTPI(regionCountryList)
        .then((tpi) => {
          setMultiRegionTpi(tpi);
          setTpiLoading(false);
        })
        .catch((e) => {
          setTpiLoading(false);
        });
    }
  }, [regionCountryList]);

  return {
    TPILoading: tpiLoading,
    TPI: multiRegionTpi,
    setTPI: setMultiRegionTpi,
    error: null,
  };
}
