import React, { useState } from "react";

import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import isEmpty from "../../validation/is-empty";
import { uploadDrawing } from "../../api/projects/ProjectDrawingsAPI";
import CostPlanningUploader from "../cost-planning-uploader/CostPlanningUploader";
import "./ProjectDrawingUploaderModal.css";
import { translate } from "../../utils/translation";

export default function ProjectDrawingUploaderModal(props) {
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { user } = props;

  const [modal, setModal] = useState(false);

  if (!window.location.href.includes("key-documents")) {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  // No Cost Plans
  if (isEmpty(CP)) {
    return null;
  }

  const data = {
    projectID: project.id,
    project: project,
    CP: CP,
    CPs: CPs,
    stage: CP.stage,
    revision: CP.version,
    setShow: setModal,
  };

  return (
    <div>
      <Modal
        // Modal Props
        title={translate("Upload Documents")}
        Component={CostPlanningUploader}
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        uploadFunction={uploadDrawing}
      />
      <div
        className="cost-planning-subtotal-open"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Upload PDF")}
      </div>
    </div>
  );
}
