import React, { useState, useEffect } from "react";

import { VALUE_ENGINEERING } from "./ValueEngineering.strings";

import "./ValueEngineeringStatusControl.css";

const boxes = [
  {
    status: VALUE_ENGINEERING.STATUS.APPROVED,
    stage: "approved",
    icon: "fas fa-check",
    title: {
      0: "Approve",
      1: "Approved",
    },
  },
  {
    status: VALUE_ENGINEERING.STATUS.FORREVIEW,
    stage: "forreview",
    icon: "fas fa-question",
    title: {
      0: "Mark for review",
      1: "Marked for review",
    },
  },
  {
    status: VALUE_ENGINEERING.STATUS.REJECTED,
    stage: "rejected",
    icon: "fas fa-times",
    title: {
      0: "Reject",
      1: "Rejected",
    },
  },
];

export const getBox = (prop, value) => boxes.find((b) => b[prop] === value);

export function BoxSet(props) {
  const { status, setStatus } = props;
  const { readonly = false } = props;

  const [selected, setSelected] = useState("");

  useEffect(() => {
    const stage = getBox("status", status)?.stage;
    setSelected(stage);
  }, [status]);

  return (
    <div className="box-set">
      {boxes.map(({ stage, icon, title }, index) => (
        <Box
          key={stage}
          stage={stage}
          icon={icon}
          title={title}
          selected={selected}
          setSelected={setSelected}
          setStatus={setStatus}
          readonly={readonly}
        />
      ))}
    </div>
  );
}

function Box({
  stage,
  icon,
  title,
  selected,
  setSelected,
  setStatus,
  readonly,
}) {
  const isSelected = stage === selected;
  let selectedClass;

  if (selected === "") {
    selectedClass = "";
  } else {
    selectedClass = isSelected ? "selected" : "not-selected";
  }

  const readonlyClass = readonly ? "readonly" : "";

  function handleClick(e) {
    if (readonly) {
      return false;
    }

    setSelected(stage);

    const status = getBox("stage", stage)?.status;
    setStatus(status);
  }

  return (
    <div
      className={`box ${stage} ${selectedClass} ${readonlyClass}`}
      title={title[+isSelected]}
      onClick={handleClick}
    >
      <i className={icon} />
    </div>
  );
}
