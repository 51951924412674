// React
import React, { useState, useEffect, useMemo } from "react";

// Components
import Dashboard from "../dashboard/Dashboard";
import { translate, tranStr } from "../../utils/translation";
import ProjectDashboardImage from "../dashboard-components/ProjectDashboardImage";
import PDCPProjectTeam from "../dashboard-components/PDCPProjectTeam";
import PDCPOverallCostSummaryBar from "./PDCPOverallCostSummaryBar";
import PDCPHighlights from "./PDCPAdviceHighlights";
import PDCPDonutChartCostSummary from "./PDCPDonutChartCostSummary";
import PDCPDesignProgressionChart from "./PDCPDesignProgressionChart";
import PDCPReconciliationChart from "./PDCPReconciliationChart";
import PDCPMetrics from "./PDCPMetrics";
import PDCPCostBreakdownDonut from "./PDCPCostBreakdownDonut";
import PDCPBenchmarkComparisonBarSingle from "./PDCPBenchmarkComparisonBarSingle";
import PDCPBenchmarkComparisonGraphSingle from "./PDCPBenchmarkComparisonGraphSingle";
import PDCPCurrency from "./PDCPCurrency";
import MemoizedMap from "../maps/Map";

// Generic Components
import DashboardRisks from "../dashboard-components/RisksAdvice";
import DashboardDecisions from "../dashboard-components/DecisionsAdvice";

// Functions
import { calculateEscalationShallow } from "../../helpers/escalation.functions";
import calculateBudget from "../../validation/calculate-budget";

import isEmpty from "../../validation/is-empty";
import PDCPValueEngineeringStatusSummary from "./PDCPValueEngineeringStatusSummary";
import PDCPValueEngineeringItemsList from "./PDCPValueEngineeringItemsList";

export default function PDCPLayout(props) {
  // Project
  const { project } = props;

  // Dashboard
  const { dashboardSettings } = props;
  const { showDashboard, setShowDashboard } = props;

  // Cost Plan
  const { CP } = props;
  const { CPs } = props;
  const { TPI } = props;

  // Create Dashboard IDs
  const SUMMARY = "SUMMARY";

  const METRICS = "METRICS";
  const COST = "COST";
  const COMPARISON = "COMPARISON";
  const RELEASES = "RELEASES";

  const BREAKDOWN = "BREAKDOWN";

  const HIGHLIGHTS = "HIGHLIGHTS";
  const DECISIONS = "DECISIONS";
  const GLOSSARY = "GLOSSARY";
  const RISKS = "RISKS";
  const DD_PROGRESSION_STAGES = "DEVELOPMENT_STAGES";
  const DD_PROGRESSION_RELEASES = "DEVELOPMENT_RELEASES";

  const IMAGE = "IMAGE";
  const MAP = "MAP";
  const TEAM = "TEAM";
  const CURRENCY = "CURRENCY";

  const VALUE_ENGINEERING_STATUS_SUMMARY = "VALUE_ENGINEERING_STATUS_SUMMARY";
  const VALUE_ENGINEERING_ITEMS_LIST = "VALUE_ENGINEERING_ITEMS_LIST";

  // Default Layout
  const column1Set = useMemo(
    () => [METRICS, COST, DD_PROGRESSION_RELEASES, DD_PROGRESSION_STAGES],
    []
  );

  const column2Set = useMemo(
    () => [
      HIGHLIGHTS,
      DECISIONS,
      GLOSSARY,
      RISKS,
      COMPARISON,
      RELEASES,
      SUMMARY,
      VALUE_ENGINEERING_STATUS_SUMMARY,
      VALUE_ENGINEERING_ITEMS_LIST,
    ],
    []
  );

  const column3Set = useMemo(() => [IMAGE, MAP, TEAM], []);

  const column4Set = useMemo(
    () => [
      CURRENCY,
      SUMMARY,

      METRICS,
      COST,

      COMPARISON,
      RELEASES,
      DD_PROGRESSION_RELEASES,
      DD_PROGRESSION_STAGES,
      HIGHLIGHTS,
      DECISIONS,
      GLOSSARY,
      RISKS,
      IMAGE,
      MAP,
      TEAM,

      VALUE_ENGINEERING_STATUS_SUMMARY,
      VALUE_ENGINEERING_ITEMS_LIST,
    ],
    []
  );

  // Dashboard State
  const [dashboardDisplay, setDashboardDisplay] = useState([]);

  useEffect(() => {
    const display = [
      <PDCPOverallCostSummaryBar
        title={translate("Overall Cost Summary")}
        dashboardID={SUMMARY}
        CP={CP}
        budgetValue={calculateBudget(CP, true)}
      />,
      <PDCPMetrics
        title={translate("Key Metrics")}
        dashboardID={METRICS}
        CP={CP}
        allMetrics={project.metrics}
      />,
      <PDCPDonutChartCostSummary
        title={translate("Project Cost Summary")}
        dashboardID={COST}
        project={project}
        CP={CP}
      />,
      <PDCPCostBreakdownDonut
        title={translate("Cost Breakdown")}
        dashboardID={BREAKDOWN}
        project={project}
        CP={CP}
      />,
      <PDCPReconciliationChart
        title={translate("Cost Plan Comparison - Stages")}
        dashboardID={COMPARISON}
        project={project}
        CP={CP}
        CPs={CPs}
        type={"STAGES"}
      />,
      <PDCPReconciliationChart
        title={translate("Cost Plan Comparison - Releases")}
        dashboardID={RELEASES}
        project={project}
        CP={CP}
        CPs={CPs}
        type={"RELEASES"}
      />,
      <DashboardDecisions
        title={translate("Decisions Required")}
        dashboardID={DECISIONS}
        decisions={CP.decisions}
        type={"decision"}
        column1={"decisions-advice-decision"}
        column2={"decisions-advice-required_by"}
        isDecision
      />,
      <DashboardDecisions
        title={translate("Contractual Glossary")}
        dashboardID={GLOSSARY}
        decisions={CP.decisions}
        type={"glossary"}
        column1={"decisions-advice-glossary-term"}
        column2={"decisions-advice-glossary-definition"}
        isGlossary
      />,
      <DashboardRisks
        title={translate("Risks / Opportunities")}
        dashboardID={RISKS}
        risks={CP.risks}
      />,
      <PDCPHighlights
        title={translate("Project Highlights")}
        dashboardID={HIGHLIGHTS}
        highlights={CP.highlights}
      />,
      <PDCPDesignProgressionChart
        title={translate("Design Development Progression - Stages")}
        dashboardID={DD_PROGRESSION_STAGES}
        project={project}
        CP={CP}
        CPs={CPs}
        height={280}
        width={700}
        type={"STAGE"}
      />,
      <PDCPDesignProgressionChart
        title={translate("Design Development Progression - Releases")}
        dashboardID={DD_PROGRESSION_RELEASES}
        project={project}
        CP={CP}
        CPs={CPs}
        height={280}
        width={700}
        type={"RELEASE"}
      />,
      <PDCPProjectTeam
        title={translate("Project Team")}
        dashboardID={TEAM}
        project={project}
      />,
      <ProjectDashboardImage
        title={translate("Project Image")}
        dashboardID={IMAGE}
        project={project}
      />,
      <MemoizedMap
        title={translate("Project Location")}
        dashboardID={MAP}
        lat={project.lat}
        lng={project.lng}
      />,
      <PDCPCurrency
        title={translate("Project Currency")}
        dashboardID={CURRENCY}
        project={project}
      />,
      <PDCPValueEngineeringStatusSummary
        key={"ProjectDashboardCostPlanningValueEngineeringStatusSummary"}
        title={translate("Value Management Summary")}
        dashboardID={VALUE_ENGINEERING_STATUS_SUMMARY}
        project={project}
        CP={CP}
      />,
      <PDCPValueEngineeringItemsList
        key={"ProjectDashboardCostPlanningValueEngineeringItemsList"}
        title={translate("Value Management Items")}
        dashboardID={VALUE_ENGINEERING_ITEMS_LIST}
        project={project}
        CP={CP}
      />,
    ];

    if (!isEmpty(CP)) {
      CP.estimates.forEach((estimate) => {
        // Benchmarks for Estimate
        const thisBenchmark = estimate.benchmark;
        const benchmarks = estimate.benchmark?.linked_benchmarks;

        // Escalation
        const futureYear = parseInt(
          new Date(thisBenchmark?.base_date).toLocaleString("default", {
            year: "numeric",
          })
        );
        calculateEscalationShallow(
          TPI,
          [thisBenchmark, ...benchmarks],
          futureYear
        );

        display.push(
          <PDCPBenchmarkComparisonBarSingle
            title={tranStr("Comparison Bar") + " " + estimate.name}
            dashboardID={"BAR" + estimate.id}
            estimate={estimate}
            benchmarks={benchmarks}
            project={project}
          />
        );

        display.push(
          <PDCPBenchmarkComparisonGraphSingle
            title={tranStr("Comparison Chart") + " " + estimate.name}
            dashboardID={"GRAPH" + estimate.id}
            estimate={estimate}
            project={project}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            futureYear={futureYear}
            TPI={TPI}
          />
        );

        const BENCHBARID = "BAR" + estimate.id;
        const BENCHGRAPHID = "GRAPH" + estimate.id;

        // Push to Dashboard
        if (!column2Set.includes(BENCHBARID)) {
          column2Set.push(BENCHBARID);
        }

        if (!column2Set.includes(BENCHGRAPHID)) {
          column2Set.push(BENCHGRAPHID);
        }

        // Also push to Dashboard Editor
        if (!column4Set.includes(BENCHBARID)) {
          column4Set.push(BENCHBARID);
        }
        if (!column4Set.includes(BENCHGRAPHID)) {
          column4Set.push(BENCHGRAPHID);
        }
      });
    }

    setDashboardDisplay(display);
  }, [CP, project, CPs, TPI, column2Set, column4Set]);

  return (
    <div>
      <Dashboard
        display={dashboardDisplay}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={project}
        // Dashboard Settings
        showDashboard={showDashboard}
        setShowDashboard={setShowDashboard}
        dashboardSettings={dashboardSettings}
        dashboardType={"COST_PLANNING"}
      />
    </div>
  );
}
