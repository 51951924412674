import React, { useState } from "react";
import PropTypes from "prop-types";

// Bring in Redux
import { connect } from "react-redux";
// Functions
import { filterBenchmarks } from "../benchmarking-project-selection/BenchmarkSelectionFilter.functions";

import ModalBenchmarkingUploader from "./BenchmarkUploadModal";
import { useBenchmarking } from "../../api/benchmarking-control/BenchmarkingControlAPI";
import BenchmarkControlTable from "./BenchmarkControlTable";
import BenchmarkingTPICityControl from "../benchmarking-tpi-select/BenchmarkTPICityControl";
import ModalBenchmarkInfoFlag from "./BenchmarkInfoFlagModal";

import { DeleteBenchmark, ExportBenchmark } from "./Benchmark.components";

import "./Benchmark.css";
import BenchmarkSelectionFilter from "../benchmarking-project-selection/BenchmarkSelectionFilter";
import { getDecoratedData } from "./BenchmarkControlTable.functions";
import { useSensitivityLevelCodes } from "../../api/benchmarking/BenchmarkingAPI";
import ExportBenchmarkDashboard from "./ExportBenchmarkDashboard";
import isEmpty from "../../validation/is-empty";
import { BA_CBS_ROLE, BA_ROLE, hasRoles } from "../../utils/roles";

/// props validation | SQ(javascript:S6774)
Benchmark.propTypes = {
  auth: PropTypes.object,
};
///
function Benchmark(props) {
  // PROPS
  const { user } = props.auth;
  // Benchmark Data
  const { benchmarking, setBenchmarking } = useBenchmarking(user);

  const [benchmarkSelectionFilters, setBenchmarkSelectionFilters] = useState(
    {}
  );
  // Filter Data

  const filteredBenchmarks = filterBenchmarks(
    null, // no Project ID
    benchmarking,
    benchmarkSelectionFilters
  );

  // Benchmarks Selected by User
  const [selectedBenchmarks, setSelected] = useState([]);
  const { sensitivityLevelCodes } = useSensitivityLevelCodes();

  if (isEmpty(sensitivityLevelCodes)) return null;

  // Data has loaded
  return (
    <div className="benchmarking-control-panel-container">
      <div className="benchmark-advanced-search-flex-backer">
        <div className="benchmark-advanced-search-flex">
          <div className="benchmark-input-container">
            <div className="benchmark-control-title">
              <h1 className="display-4" style={{ fontSize: "30px" }}>
                Benchmarking
              </h1>
            </div>
            <BenchmarkSelectionFilter
              setFilters={setBenchmarkSelectionFilters}
              benchmarkingForBenchmark={[]}
              globalBenchmark={true}
            />
          </div>
          <BenchmarkingTPICityControl
            selectedBenchmarks={selectedBenchmarks}
            setBenchmarking={setBenchmarking}
          />
        </div>
      </div>
      <div className="benchmark-table-column">
        <div className="benchmark-table-container">
          <div className="benchmark-top-row">
            {hasRoles(user.roles, BA_ROLE) && !isEmpty(filteredBenchmarks) && (
              <ExportBenchmarkDashboard
                benchmarks={getDecoratedData(
                  filteredBenchmarks,
                  sensitivityLevelCodes
                )}
                user={user}
              />
            )}
          </div>
          <div className="benchmark-table-flex customised">
            <div className="benchmark-control-table">
              <BenchmarkControlTable
                data={filteredBenchmarks}
                setBenchmarking={setBenchmarking}
                selectedBenchmarks={selectedBenchmarks}
                setSelected={setSelected}
                user={user}
                sensitivityLevelCodes={sensitivityLevelCodes}
              />
            </div>
            {!isEmpty(filteredBenchmarks) && (
              <div className="benchmark-button-flex ">
                <ModalBenchmarkingUploader setBenchmarking={setBenchmarking} />
                <DeleteBenchmark
                  selectedBenchmarks={selectedBenchmarks}
                  setBenchmarking={setBenchmarking}
                />
                {hasRoles(user.roles, BA_CBS_ROLE) && (
                  <ExportBenchmark selectedBenchmarks={selectedBenchmarks} />
                )}
                <ModalBenchmarkInfoFlag
                  selectedBenchmarks={selectedBenchmarks}
                  setBenchmarking={setBenchmarking}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(Benchmark);
