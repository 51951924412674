export function authHeader(options) {
  const idToken = localStorage.getItem("idToken");

  // Request with JSON
  if (options.authJson) {
    return {
      authorization: `Bearer ${idToken}`,
      "Content-type": "application/json",
    };
    // Request with FORM
  } else if (options.authForm) {
    return {
      authorization: `Bearer ${idToken}`,
    };
    // Request with JSON and no auth
  } else if (options.guestJson) {
    return {
      "Content-type": "application/json",
    };
  } else {
    return {};
  }
}
