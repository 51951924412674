import React from "react";

import {
  validateCurrency,
  validateDate,
  validatePercent,
} from "./PostContractTableModal.functions";

import {
  ExpandableRow,
  OneValueRow,
  DocumentLink,
} from "./PostContractModal.components";

import "./PostContractTableModal.css";

export default function PostContractProvDelaysModal(props) {
  const { selectedRow } = props;
  const R = selectedRow;

  return (
    <div className="pctm">
      <div className="pctm-container-row">
        <OneValueRow heading1={"Description"} value1={R.description} />
      </div>

      <div className="pctm-container-row">
        <ExpandableRow
          headings={[
            "Provisional Days",
            "Tendered Rate",
            "Provisional Allowance",
            "Actual Cost",
            "Actual Days",
            "Provisional Item Adjustment",
          ]}
          values={[
            R.provisional_days,
            validateCurrency(R.tendered_rate),
            validateCurrency(R.provisional_allowance),
            validateCurrency(R.actual_cost),
            R.actual_days,
            validateCurrency(R.provisional_item_adjustment),
          ]}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={[
            "Status",
            "% Complete to Date",
            "Value Complete To Date",
            "Difference",
            "Contractor Claim %",
            "Contractor Claim Value",
          ]}
          values={[
            R.status,
            validatePercent(R.percent_complete_to_date),
            validateCurrency(R.value_complete_to_date),
            validateCurrency(R.difference),
            validatePercent(R.contractor_claim_percent),
            validateCurrency(R.contractor_claim_value),
          ]}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={[
            "Value Complete Last Claim",
            "Value Complete This Claim",
            "Approved Date",
            "Recommended Date",
            "Submitted Date",
            "Action",
          ]}
          values={[
            validateCurrency(R.value_complete_last_claim),
            validateCurrency(R.value_complete_this_claim),
            validateDate(R.approved_date),
            validateDate(R.recommended_date),
            validateDate(R.submitted_date),
            R.action,
          ]}
        />
      </div>
      <div className="pctm-container-row">
        <OneValueRow
          heading1={"Reason for Difference"}
          value1={R.reasons_for_difference}
        />
      </div>
      <div className="pctm-container-row">
        <OneValueRow heading1={"Comments"} value1={R.comments} />
      </div>
      <div className="pctm-container-row">
        <DocumentLink R={R} />
      </div>
    </div>
  );
}
