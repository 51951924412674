export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_RESPONSE = "GET_RESPONSE";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_PROJECTS = "GET_PROJECTS";
export const PROJECT_LOADING = "PROJECT_LOADING";
export const GET_PROJECT = "GET_PROJECT";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const SET_PROCESSING = "SET_PROCESSING";
export const SET_SELECTED_FILE = "SET_SELECTED_FILE";
export const SAVE_FILE_ID = "SAVE_FILE_ID";
export const SET_IMAGE_DATA = "SET_IMAGE_DATA";

export const GET_SETTINGS = "GET_SETTINGS";
export const SETTINGS_LOADING = "SETTINGS_LOADING";

export const TEMPLATE = "template";
export const GENERATED = "generated";

export const TOTAL = "TOTAL";
export const AVERAGE = "AVERAGE";
