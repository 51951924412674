import React, { useEffect, useState } from "react";

import isEmpty from "../../validation/is-empty";

import { tranStr } from "../../utils/translation";

import PostContractHeadroom from "../charts/CostVsBudget";
import "./PDCPBenchmarkComparisonBar.css";

export default function PDCPBenchmarkComparisonBarSingle(props) {
  const { estimate } = props;
  const { project } = props;
  const { benchmarks } = props;

  const [averages, setAverages] = useState([]);

  useEffect(() => {
    if (!isEmpty(benchmarks)) {
      let tempAverages = [];
      benchmarks.forEach((b) => {
        tempAverages.push(Math.round(b.displayed_construction_cost_rate));
      });
      setAverages(tempAverages);
    }
  }, [benchmarks]);

  if (isEmpty(estimate)) {
    return null;
  }

  if (isEmpty(benchmarks)) {
    return null;
  }

  let benchmarkCost = Math.round(
    estimate.construction_cost / estimate.local_region_area
  );

  let average = Math.round(calculateAverage(averages));
  let displayAverage = Math.round(average);

  let firstText = tranStr("Benchmark Average");
  let secondText =
    tranStr("Current Construction Cost") + " / " + project.unit_of_measure;
  let thirdText = tranStr("Variance with Benchmark Average");

  return (
    <PostContractHeadroom
      firstValue={displayAverage}
      firstText={firstText}
      secondValue={benchmarkCost}
      secondText={secondText}
      differenceText={thirdText}
      headroom={false}
      isApproved={true}
    />
  );
}

function calculateAverage(arr) {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
}
