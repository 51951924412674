import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./ProgressClaimPaymentRecommendation.css";
import { commaToFixed } from "../../helpers/common.functions";

export default function ProgressClaimPaymentRecommendation(props) {
  const { PC } = props;

  if (isEmpty(PC)) {
    return null;
  }

  return (
    <div className="progress-claim-panel">
      <div className="pcpr-content">
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Construction Work Complete")}</div>
          </div>
          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.construction_work_complete)}
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Unfixed Materials On Site")}</div>
          </div>
          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.unfixed_materials_on_site)}
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Unfixed Materials Off Site")}</div>
          </div>
          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.unfixed_materials_off_site)}
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Variations Work Complete")}</div>
          </div>

          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.variations_work_complete)}
          </div>
        </div>

        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Provisional Quantities Work Complete")}</div>
          </div>
          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.prov_quants_work_complete)}
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Provisional Sums Work Complete")}</div>
          </div>

          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.prov_sums_work_complete)}
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Provisional Delay Days Work Complete")}</div>
          </div>

          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.extensions_of_time_work_complete)}
          </div>
        </div>
        <div className="pcpr-row-subtotal">
          <div>
            <div className="pcpr-description-cell">
              <b>{translate("Gross Total of Work Executed")}</b>
            </div>
          </div>
          <div className="pcpr-value-cell">
            <div>
              <b></b>
            </div>
            <b>{commaToFixed(PC.gross_total_work_executed_excl_tax)}</b>
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Deduct Retention")}</div>
          </div>

          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.deduct_security)}
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>{translate("Deduct Amount Previously Certified")}</div>
          </div>

          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.amount_previously_certified)}
          </div>
        </div>
        <div className="pcpr-row-subtotal">
          <div>
            <div className="pcpr-description-cell">
              <b>{translate("Subtotal of Work Executed")}</b>
            </div>
          </div>
          <div className="pcpr-value-cell">
            <div>
              <b></b>
            </div>
            <b>{commaToFixed(PC.subtotal_work_executed_excl_tax)}</b>
          </div>
        </div>
        <div className="pcpr-row">
          <div className="pcpr-description-cell">
            <div>
              {translate("Add")}
              {isEmpty(PC.tax_percent) ? " 0% " : PC.tax_percent + "% "}
              {isEmpty(PC.tax_type) ? " tax" : " " + PC.tax_type}
            </div>
          </div>

          <div className="pcpr-value-cell">
            <div></div>
            {commaToFixed(PC.add_tax)}
          </div>
        </div>
        <div className="pcpr-row-total">
          <div>
            <div className="pcpr-description-cell">
              <b>
                <div>
                  {translate("Recommended Payment Incl")}
                  {isEmpty(PC.tax_type) ? " tax" : " " + PC.tax_type}
                </div>
              </b>
            </div>
          </div>
          <div className="pcpr-value-cell">
            <div>
              <b></b>
            </div>
            <b>{commaToFixed(PC.recommended_payment)}</b>
          </div>
        </div>
      </div>
    </div>
  );
}
