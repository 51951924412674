// Load all dependencies
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

// Test: import languageEN from "./locale/en/translate.json";
// Test: import languageZH from "./locale/zh/translate.json";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources: {
    //   en: languageEN,
    //   zh: languageZH,
    // },
    backend: {
      loadPath: pathPicker(),
      queryStringParams: codePicker(),
    },
    fallbackLng: "en",
    debug: false,
    useCookie: false,
    useLocalStorage: false,
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: true, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      useSuspense: false,
      //wait: true,
    },
  });

export default i18n;

function pathPicker() {
  const solutionId = "2488061d-1876-4c40-b85c-0f3ea10aff54";

  // EXAMPLE DEV ENDPOINT: https://arcadis-i18n-api-dev.azurewebsites.net/api/v1.0/2488061d-1876-4c40-b85c-0f3ea10aff54/en/namespaces/common/?code=EjmzCv2V4mM9PmRf2zWSnWfLaZ7dQmj4W2HQgeUNT57htPh25bxT
  // EXAMPLE PRD ENDPOINT: https://arcadis-i18n-api.azurewebsites.net/api/v1.0/2488061d-1876-4c40-b85c-0f3ea10aff54/en/namespaces/common/?code=EjmzCv2V4mM9PmRf2zWSnWfLaZ7dQmj4W2HQgeUNT57htPh25bxT

  // Domain
  const host = window.location.hostname;
  if (host.includes("localhost")) {
    // Local
    return `https://arcadis-i18n-api-dev.azurewebsites.net/api/v1.0/${solutionId}/{{lng}}/namespaces/{{ns}}`;
  }
  if (host.includes("dev")) {
    // Dev Deployment
    return `https://arcadis-i18n-api-dev.azurewebsites.net/api/v1.0/${solutionId}/{{lng}}/namespaces/{{ns}}`;
  }
  if (host.includes("test")) {
    // Test Deployment
    return `https://arcadis-i18n-api-dev.azurewebsites.net/api/v1.0/${solutionId}/{{lng}}/namespaces/{{ns}}`;
  }
  if (host.includes("www")) {
    // Production Deployment
    return `https://arcadis-i18n-api.azurewebsites.net/api/v1.0/${solutionId}/{{lng}}/namespaces/{{ns}}`;
  }
}

function codePicker() {
  const code = "EjmzCv2V4mM9PmRf2zWSnWfLaZ7dQmj4W2HQgeUNT57htPh25bxT";
  // Domain
  const host = window.location.hostname;
  if (host.includes("localhost")) {
    // Local
    return { code: code };
  }
  if (host.includes("dev")) {
    // Dev Deployment
    return { code: code };
  }
  if (host.includes("test")) {
    // Test Deployment
    return { code: code };
  }
  if (host.includes("www")) {
    // Production Deployment
    return { code: code };
  }
}
