import { permAPIs } from "../api-endpoints/PermissionEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { getUsersByIDs, groupUsers } from "../users/UserAPI";
import { useState, useEffect } from "react";
import { addProjectData } from "../projects/ProjectAPI.functions";
import { addCompanyData } from "../admin/CompanyAPI";
import { AutoCloseModal } from "../../components/modal/Modal";
import isEmpty from "../../validation/is-empty";

export async function getUserList(mappingId, mappingType, groupType) {
  // PROJECT API
  const mappingIdQuery = `?mappingId=${mappingId}`;
  const mappingTypeQuery = `&mappingType=${mappingType}`;
  const url = permAPIs().GetUserList + mappingIdQuery + mappingTypeQuery;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let response = await fetch(url, config);

  if (response.ok) {
    // Will come as "ids"
    const userProjectRoles = await response.json();
    // Flatten the object to array of ids
    const ids = userProjectRoles.map((x) => x.id);
    const userData = await getUsersByIDs(ids);
    const users = addUserDataToIDs(userData, userProjectRoles);
    const usersInGroup = groupUsers(users, groupType);
    response = usersInGroup;
  } else {
    console.log("getUserList Error");
    response = [];
  }

  return response;
}

export function addUserDataToIDs(users, usersWithPermissions) {
  const updatedUsers = [];
  users.forEach((user) => {
    let match = false;
    let newData = {};

    // Try and find a match for this user
    usersWithPermissions.forEach((data) => {
      // activeDirectoryId
      if (user.id.toString() === data.id.toString()) {
        match = true;
        newData = data;
      }
    });

    // If there is a match combine the user with the data
    if (match) {
      const combinedUser = {
        ...user,
        ...newData,
      };
      updatedUsers.push(combinedUser);
    } else {
      // If there is no match simply return the plain user
      updatedUsers.push(user);
    }
  });
  return updatedUsers;
}

export async function addProjectUsers(project) {
  const users = await getUserList(project.id, "Project", "all");

  const LEAD = "PROJECT_LEADER";
  const PEER = "PEER_REVIEWER";

  const internal = groupUsers(users, "internal");
  const external = groupUsers(users, "external");

  project.users = users;
  project.cost_managers = internal;
  project.clients = external;

  users.forEach((user) => {
    if (user.roles) {
      if (user.roles.includes(PEER)) {
        project.peer_reviewer_user = user;
      }
      if (user.roles.includes(LEAD)) {
        project.project_leader_user = user;
      }
    }
  });

  return project;
}

// GET PROJECTS ACCOUNT IS ALLOCATED TO
export function useProjectsByUserId(accountID, show) {
  const [loading, setLoading] = useState(true);
  const [accountsProjects, setAccountsProjects] = useState([]);
  useEffect(() => {
    if (show) {
      setLoading(true);
      getProjectsByUserId(accountID)
        .then((projects) => {
          setAccountsProjects(projects);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [accountID, show]);

  return {
    accountsProjects: {
      data: accountsProjects,
      loading: loading,
      get: getProjectsByUserId,
      set: setAccountsProjects,
    },
  };
}

// Get all Projects available to the given user
export async function getProjectsByUserId(userGlobalId) {
  // PERMISSION API
  const url =
    permAPIs().GetMappings + "/" + userGlobalId + "?mappingType=Project";
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const projects = await response.json();

    if (!isEmpty(projects)) {
      let projectIds = projects
        .filter((p) => p.type === 1)
        .map((p) => {
          return { project_id: p.id };
        });

      // Get Project data
      const projectData0 = await addProjectData(projectIds);
      const projectData1 = await addCompanyData(projectData0);
      response = projectData1;
    } else {
      response = [];
    }
  } else {
    console.log("get ProjectsByUser Error");
    response = [];
  }

  return response;
}

export async function updatePermissions(data) {
  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const { payload } = data;
  const { mappingId } = data;
  const { mappingType } = data;

  // PERMISSION API
  const endpoint = permAPIs().UpdateMappingList;
  const mappingIdQuery = `?mappingId=${mappingId}`;
  const mappingTypeQuery = `&mappingType=${mappingType}`;
  const URL = endpoint + mappingIdQuery + mappingTypeQuery;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  const response = await fetch(URL, config);

  if (response.ok) {
    // Response OK
    await data.updateState();

    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    return data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}

export async function updateUserMappings(data) {
  const { setErrors } = data;
  const { setShow } = data;

  setErrors({
    text: "Saving...",
    type: "feedback-success",
  });

  const { payload } = data;

  // PERMISSION API
  const URL = permAPIs().UpdateUserMappings;

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(URL, config);

  if (response.ok) {
    // Response OK
    setErrors({
      text: "Saved Successfully",
      type: "feedback-success",
    });
    await AutoCloseModal(setShow);
  } else {
    response = await response.json();
    // Response Not OK
    setErrors({
      text: response.Error,
      type: "feedback-error",
    });
  }
}
