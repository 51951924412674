// React
import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Sections
import Estimates from "../estimates/Estimates";
import Commentary from "../estimate-commentary/Commentary";
import Drawings from "../drawings/Drawings";
import BenchmarkingProject from "../benchmarking-project/BenchmarkingProject";
import EstimateBreakdown from "../estimate-breakdown/EstimateBreakdown";
import Reconciliation from "../reconciliation/Reconciliation";
import CashflowContainer from "../cashflow/CashflowContainer";
import ProjectBudget from "../project-budget/ProjectBudget";
import AreaSchedules from "../cost-planning-area-schedules/AreaSchedules";
import ValueEngineerings from "../value-engineering/ValueEngineerings";

//  State
import useAccordionState from "./CostPlanningAccordionState";

// Resources
import "./CostPlanning.css";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import {
  getIsSynchronizedTPI,
  getSynchronizedTPI,
} from "../estimates/Estimates.functions";
import isEmpty from "../../validation/is-empty";

export default function CostPlanning(props) {
  // Props
  const { project, projectLoading } = props;
  const { CPs } = props;
  const { CP } = props;
  const { comparedCP, comparedCPR } = props;
  const { TPI, TPILoading } = props;
  const { LFI, LFILoading } = props;
  const { benchmarking, setBenchmarking } = props;
  const { user } = props;
  const { costReport } = props;
  // shared state
  const [isLinkedTPI, setIsLinkedTPI] = useState(false);
  const [linkedTPISelectedYear, setLinkedTPISelectedYear] = useState(0);
  const [linkedTPIPercentage, setLinkedTPIPercentage] = useState(0);

  // set initial value of isLinkedTPI from CP.estimates
  useEffect(() => {
    const isSynchronizedTPI = getIsSynchronizedTPI(CP);
    setIsLinkedTPI(isSynchronizedTPI);
  }, [CP]);

  useEffect(() => {
    if (isLinkedTPI && !isEmpty(CP) && !isEmpty(CP.estimates)) {
      const synchronizedTPI = getSynchronizedTPI(CP.estimates[0]);
      setLinkedTPISelectedYear(synchronizedTPI?.escalationDate);
      setLinkedTPIPercentage(synchronizedTPI?.escalationPercentage);
    }
  }, [isLinkedTPI, CP]);

  // Accordion State
  const { accordionState } = useAccordionState(CP);
  if (projectLoading && CPs.loading && TPILoading && LFILoading) {
    return null;
  }

  return (
    <div className="costplanning-background">
      <Switch>
        <Route
          exact
          path={"/project/:id/cost-planning/"}
          render={() => (
            <Redirect
              replace
              to={`/project/${project.id}/cost-planning/summary`}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/summary"}
          render={() => (
            <Estimates
              user={user}
              project={project}
              CPs={CPs}
              CP={CP}
              TPI={TPI}
              accordionState={accordionState}
              linkedTPISelectedYear={linkedTPISelectedYear}
              setLinkedTPISelectedYear={setLinkedTPISelectedYear}
              linkedTPIPercentage={linkedTPIPercentage}
              setLinkedTPIPercentage={setLinkedTPIPercentage}
              isLinkedTPI={isLinkedTPI}
              setIsLinkedTPI={setIsLinkedTPI}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/commentary"}
          render={() => (
            <Commentary
              user={user}
              project={project}
              CPs={CPs}
              CP={CP}
              Comments={CP.commentary}
              URLs={cpAPIs}
              CostId={CP.cost_plan_id}
              Module={"CostPlanning"}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/key-documents"}
          render={() => (
            <Drawings user={user} project={project} CPs={CPs} CP={CP} />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/breakdown"}
          render={() => (
            <EstimateBreakdown
              user={user}
              TPI={TPI}
              project={project}
              projectID={project.id}
              CPs={CPs}
              CP={CP}
              accordionState={accordionState}
              linkedTPISelectedYear={linkedTPISelectedYear}
              setLinkedTPISelectedYear={setLinkedTPISelectedYear}
              linkedTPIPercentage={linkedTPIPercentage}
              setLinkedTPIPercentage={setLinkedTPIPercentage}
              isLinkedTPI={isLinkedTPI}
              setIsLinkedTPI={setIsLinkedTPI}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/reconciliation"}
          render={() => (
            <div>
              <Reconciliation
                user={user}
                project={project}
                CP={CP}
                CPs={CPs}
                comparedCP={comparedCP}
                comparedCPR={comparedCPR}
                accordionState={accordionState}
              />
            </div>
          )}
        />
        <Route
          path={"/project/:id/cost-planning/benchmarking"}
          render={() => (
            <BenchmarkingProject
              user={user}
              project={project}
              // Cost Plan
              CP={CP}
              CPs={CPs}
              // Benchmarking
              benchmarking={benchmarking}
              setBenchmarking={setBenchmarking}
              // TPI
              TPI={TPI}
              LFI={LFI}
              accordionState={accordionState}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/cashflow"}
          render={() => (
            <CashflowContainer
              project={project}
              CPs={CPs}
              CP={CP}
              accordionState={accordionState}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/project-budget"}
          render={() => (
            <ProjectBudget
              project={project}
              CP={CP}
              comparedCP={comparedCP}
              comparedCPR={comparedCPR}
              costReport={costReport}
            />
          )}
        />
        <Route
          path={"/project/:id/cost-planning/area-schedule"}
          render={() => (
            <div>
              <AreaSchedules
                project={project}
                CP={CP}
                comparedCP={comparedCP}
                comparedCPR={comparedCPR}
              />
            </div>
          )}
        />
        <Route
          path={"/project/:id/cost-planning/value-management"}
          render={() => (
            <ValueEngineerings
              user={user}
              project={project}
              CPs={CPs}
              CP={CP}
              TPI={TPI}
              accordionState={accordionState}
            />
          )}
        />
      </Switch>
    </div>
  );
}
