import React from "react";
import { useInsightsArticleUserRead } from "../../api/general/MarketInsightsAPI";
import isEmpty from "../../validation/is-empty";

import "../layout/NavbarTop.css";
import "../market-insights/Insight.css";
import { connect } from "react-redux";

const Notification = (props) => {
  const { articleId } = props;
  const { categoryArticlesIds } = props;
  const { at } = props;
  const { auth } = props;
  const { articlesRead } = useInsightsArticleUserRead(
    auth.user.id,
    auth.user.region
  );

  if (isEmpty(articlesRead)) {
    return <></>;
  }

  let className = "";
  let unreadarticle = "";
  if (at === "navbar" && isUnreadArticlesExists(articlesRead))
    className = "navigation-link-badge-red";

  if (
    at === "article" &&
    !isEmpty(articleId) &&
    isArticleUnread(articleId, articlesRead)
  )
    className = "navigation-link-badge-article-red";

  if (at === "category") {
    if (categoryArticlesIds !== undefined && categoryArticlesIds.length === 0) {
      return <></>;
    }
    if (
      !isEmpty(categoryArticlesIds) &&
      isCategoryArticleUnread(categoryArticlesIds, articlesRead) > 0
    ) {
      unreadarticle = isCategoryArticleUnread(
        categoryArticlesIds,
        articlesRead
      );
      className = "navigation-link-badge-bigred";
    } else {
      return <></>;
    }
  }
  return className === "" ? (
    <></>
  ) : (
    <span className={className}>{unreadarticle}</span>
  );
};

// Bring in the auth state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Notification);

function isUnreadArticlesExists(articlesRead) {
  for (let key in articlesRead) {
    if (!articlesRead[key]) return true;
  }
}

function isArticleUnread(articleId, articlesRead) {
  return articlesRead[articleId] === false;
}

function isCategoryArticleUnread(categoryArticleIds, articlesRead) {
  let count = 0;
  for (let key in articlesRead) {
    for (let cat in categoryArticleIds) {
      if (key === categoryArticleIds[cat] && !articlesRead[key]) count++;
    }
  }
  return count;
}
