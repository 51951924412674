export const PROCESS_TYPE = {
  PCRA: "PCRA",
  PCA: "PCA",
};

export const PCRA_GLOSSARY = {
  AUDIT_STAGE: (processType) => {
    const stages = {
      PRODUCE: "PRODUCE",
      CHECK: "CHECK",
      REVIEW: "REVIEW",
      APPROVE: "APPROVE",
    };

    if (processType === PROCESS_TYPE.PCA) {
      delete stages["REVIEW"];
    }

    return stages;
  },
  AUDIT_STATUS: {
    PENDING: "PENDING",
    CURRENT: "CURRENT",
    PASSED: "PASSED",
    FAILED: "FAILED",
  },
  MESSAGE_STRINGS: {
    CURRENT_COMMENTS_HINT: "Please enter comments.",
  },
};

export const PCRA_MARKUP = {
  STATUS_ICON: {
    [PCRA_GLOSSARY.AUDIT_STATUS.PENDING]: "far fa-clock fa-lg",
    [PCRA_GLOSSARY.AUDIT_STATUS.PASSED]: "fas fa-check fa-lg",
    [PCRA_GLOSSARY.AUDIT_STATUS.FAILED]: "fas fa-times fa-lg",
    LOCKED: "fas fa-lock fa-lg",
  },
  CONTINUITY_CONNECTOR_TYPES: {
    BEGIN: "begin",
    CONTINUE: "continue",
    END: "end",
    SHORT: "short",
  },
  SHOW_HIDE_PAST_AUDIT_TRAIL_TOGGLE_DISPLAY: {
    [false]: {
      icon: "fas fa-angle-double-down fa-lg",
      iconText: "Show",
      displayText: "Past Audit Trail",
    },
    [true]: {
      icon: "fas fa-angle-double-up fa-lg",
      iconText: "Hide",
      displayText: "Past Audit Trail",
    },
  },
  LOCKED_REASONS: {
    QA_LOG_LOCKED_FOR_CLIENT: <span>{""}</span>, // No locked reason to be displayed for client as per PO
    COST_PLANNING_QA_LOG_LOCKED_WHEN_PROJECT_PHASE_NOT_IN_COST_PLANNING: (
      <span>
        This QA Log is read-only since the project is not in the Cost Planning
        phase.
      </span>
    ),
    POST_CONTRACT_QA_LOG_LOCKED_WHEN_PROJECT_PHASE_NOT_IN_POST_CONTRACT: (
      <span>
        This QA Log is read-only since the project is not in the Post Contract
        phase.
      </span>
    ),
    PREV_COST_PLAN_RELEASE_LOCKED_SINCE_NEWER_RELEASE_UPLOADED: (
      <span>
        The QA Log for this release is locked and cannot be edited as a newer
        release has been uploaded. The newer release has an actionable QA Log.
      </span>
    ),
    PREV_POST_CONTRACT_REPORT_LOCKED_SINCE_NEWER_REPORT_UPLOADED: (
      <span>
        The QA Log for this report is locked and cannot be edited as a newer
        report has been uploaded.
        <br />
        The newer report has an actionable QA Log.
      </span>
    ),
  },
};
