export function storeString(key, str) {
  localStorage.setItem(key, str);
}

export function storeObject(key, obj) {
  storeString(key, JSON.stringify(obj));
}

export function retrieveString(key) {
  return localStorage.getItem(key);
}

export function retrieveObject(key) {
  return JSON.parse(retrieveString(key)) || {};
}
