import React from "react";

import { PCRA_GLOSSARY } from "./PCRAGlossary.constants";
import { PCRALogBoxPast } from "./PCRALogBoxPast";
import { PCRALogBoxPending } from "./PCRALogBoxPending";
import { PCRALogBoxCurrent } from "./PCRALogBoxCurrent";

const { AUDIT_STATUS } = PCRA_GLOSSARY;
const { CURRENT, PENDING, PASSED, FAILED } = AUDIT_STATUS;

export function PCRALogBox(props) {
  const { status } = props;

  switch (status) {
    case CURRENT:
      return <PCRALogBoxCurrent {...props} />;
    case PENDING:
      return <PCRALogBoxPending {...props} />;
    case PASSED:
    case FAILED:
    default:
      return <PCRALogBoxPast {...props} />;
  }
}
