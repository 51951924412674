import isEmpty from "../../validation/is-empty";
import { useState, useEffect } from "react";

import { tranStr } from "../../utils/translation";

export function useReconciliationSwitch(CP, comparedCP, comparedCPR) {
  const [reconciliationType, setReconciliationType] = useState("STAGE");
  const [comparisonCP, setComparisonCP] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (reconciliationType === "STAGE") {
      setComparisonCP(comparedCP);
      setError(
        tranStr(
          "Stage comparison is available when there is at least one previous Cost Plan Stage."
        )
      );
    }

    if (reconciliationType === "RELEASE") {
      setComparisonCP(comparedCPR);
      setError(
        tranStr(
          "Release comparison is available when there is at least one previous Cost Plan Release."
        )
      );
    }
  }, [reconciliationType, CP, comparedCP, comparedCPR, error]);
  let estimateDeltas = [];
  if (!isEmpty(comparisonCP)) {
    // Calculate the deltas between the two selections
    estimateDeltas = generateDeltas(CP, comparisonCP);
  }

  return {
    reconciliationData: {
      error: error,
      comparisonCP: comparisonCP,
      setComparisonCP: setComparisonCP,
      reconciliationType: reconciliationType,
      setReconciliationType: setReconciliationType,
      estimateDeltas: estimateDeltas,
    },
  };
}

export function generateDeltas(CP, comparedCP) {
  const estimateDeltas = [];
  let uniqueRows = [];
  let filteredRows = [];
  if (!isEmpty(CP)) {
    if (!isEmpty(comparedCP.areaschedules)) {
      filteredRows = isEmpty(CP.areaschedules)
        ? comparedCP.areaschedules
        : comparedCP.areaschedules.concat(CP.areaschedules);
    }

    if (!isEmpty(filteredRows)) {
      // Extract a unique list of row codes between the two parts
      uniqueRows = uniqueValuesFromObjectsArray(filteredRows);

      const rowDeltas = [];

      // Compose the ROW deltas
      processRow(uniqueRows, CP, comparedCP.areaschedules, rowDeltas);
      // Compose the ESTIMATE deltas
      processEstimate(estimateDeltas, CP, comparedCP, rowDeltas);
    }
  }
  return estimateDeltas;
}

function processEstimate(estimateDeltas, estimate, comparison, rowDeltas) {
  const e = estimate;
  const CE = comparison;

  const { total, comparisonTotal, delta } = calculateTotalDelta(e, CE);

  // Compose the ESTIMATE
  estimateDeltas.push({
    id: e.cost_plan_id,
    project_id: e.project_id,
    // stage: e.stage,
    revision: e.version,
    stage_name: e.stage_name,
    stage_code: e.stage,
    deltas: rowDeltas,

    // Comparison Estimate set to 0 if undefined and it will update when set
    comparison_stage_name: CE ? CE.stage_name : "",
    comparison_stage: CE ? CE.stage : "",
    comparison_revision: CE ? CE.version : "",

    comparison_calculated_total: CE ? comparisonTotal : 0,
    calculated_total: total,

    totalDelta: delta,
    // costChange: costChange,
  });
}

function processRow(uniqueRows, e, CERows, rowDeltas) {
  uniqueRows.forEach((row) => {
    let currentRow;
    let previousRow;
    // Find the rows to compare
    if (!isEmpty(e.areaschedules)) {
      currentRow = e.areaschedules.find(
        (r) => r.description === row.description
      );
    }

    if (!isEmpty(CERows)) {
      previousRow = CERows.find((r) => r.description === row.description);
    }
    // Create Comparison
    const id = currentRow ? currentRow.id : null;
    const comparison_id = previousRow ? previousRow.id : null;
    const description = currentRow
      ? currentRow.description
      : previousRow.description;
    const subtotal = currentRow ? Math.round(currentRow.quantity) : 0;
    const currentSubtotal = currentRow ? currentRow.quantity : 0;
    const previousSubtotal = previousRow ? Math.round(previousRow.quantity) : 0;

    const delta = Math.round(currentSubtotal - previousSubtotal);

    const rowDelta = {
      id: id,
      // estimate_id: e.id,
      project_id: e.project_id,

      //code: code,
      comparison_id: comparison_id,
      description: description,
      // cost_category: cost_category,
      subtotal: subtotal,
      previousSubtotal: previousSubtotal,
      delta: delta,
    };

    // Only return values that have changed
    if (subtotal !== 0 || previousSubtotal !== 0) {
      rowDeltas.push(rowDelta);
    }
  });
}

function calculateTotalDelta(thisEstimate, comparisonEstimate) {
  let delta = 0;
  let total = 0;
  let comparisonTotal = 0;
  if (!isEmpty(thisEstimate.areaschedules)) {
    total = thisEstimate.areaschedules.reduce((a, object) => {
      return a + object.quantity;
    }, 0);
  }
  if (
    !isEmpty(comparisonEstimate) &&
    !isEmpty(comparisonEstimate.areaschedules)
  ) {
    comparisonTotal = comparisonEstimate.areaschedules.reduce((a, object) => {
      return a + object.quantity;
    }, 0);
  }

  delta = total - comparisonTotal;
  let data = { delta: delta, total: total, comparisonTotal: comparisonTotal };
  return data;
}

function uniqueValuesFromObjectsArray(array) {
  return Array.from(new Set(array.map((s) => s.description))).map(
    (description) => {
      return {
        description: array.find((s) => s.description === description)
          .description,
      };
    }
  );
}
