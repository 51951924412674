import React from "react";
import PDPCDonutChart from "../post-contract-cost-report/PDPCDonutChart";
import isEmpty from "../../validation/is-empty";

import { generateProvisionalSumsDonutData } from "./PostContractProvisionalSums.functions";

function DonutProvisionalSumSummary(props) {
  const { CR } = props;

  // No Variations return empty
  if (isEmpty(CR)) {
    return null;
  }

  let changeTypeData = generateProvisionalSumsDonutData(CR);

  return (
    <PDPCDonutChart
      data={changeTypeData}
      width={200}
      height={200}
      eRadius={90}
      iRadius={70}
      lWidth={270}
      lHeight={150}
    />
  );
}

const PDPCDonutProvisionalSumSummary = React.memo(DonutProvisionalSumSummary);

export default PDPCDonutProvisionalSumSummary;
