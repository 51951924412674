import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
// Style
import "./AdviceDecisionsEdit.css";
import { DisplayError } from "../project-control/ProjectControl";

export default function AdviceDecisionsEdit(props) {
  const { type } = props;
  const { data } = props;
  const { requiredData } = props;
  const { decisions } = props;
  const { saveFunction, saveURL } = props;
  const { labels } = props;
  const { fieldTypes } = props;
  const { note } = props;
  const { max } = props;
  const { setModal } = props;

  const [error, setError] = useState({});

  const [fields, setFields] = useState([
    {
      decision: "",
      required_by: "",
    },
  ]);
  useEffect(() => {
    if (!isEmpty(decisions)) {
      let filtered = decisions.filter((d) => {
        if (d.type === type) {
          return {
            decision: d.decision,
            required_by: d.required_by,
          };
        } else {
          return null;
        }
      });

      if (filtered.length > 0) {
        setFields(filtered);
      }
    }
  }, [decisions, type]);

  if (isEmpty(requiredData)) {
    return (
      <div className="project-control-advice">
        {translate(
          "This section is available after Cost Plans have been uploaded"
        )}
      </div>
    );
  }

  return (
    <div className="project-control-advice">
      <div className="advice-title">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {note}
        </div>
      </div>
      <FieldHeadings labels={labels} fieldTypes={fieldTypes} />
      <div className="advice-decisions-field-container">
        <DecisionFields
          fields={fields}
          setFields={setFields}
          fieldTypes={fieldTypes}
        />
      </div>
      <div className="general-row-container">
        <AddField fields={fields} max={max} setFields={setFields} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton
          data={data}
          fields={fields}
          saveURL={saveURL}
          saveFunction={saveFunction}
          setModal={setModal}
          setError={setError}
        />
      </div>
    </div>
  );
}

function AddField(props) {
  const { fields } = props;
  const { max } = props;
  const { setFields } = props;
  return (
    <div
      className="general-modal-button"
      onClick={() => {
        let temp = [...fields];

        if (temp.length >= max) {
          return;
        }

        temp.push({
          decision: "",
          required_by: "",
        });
        setFields(temp);
      }}
    >
      {translate("Add Field")}
    </div>
  );
}

function SaveButton(props) {
  const { data } = props;
  const { fields } = props;
  const { saveURL } = props;
  const { saveFunction } = props;
  const { setError } = props;
  const { setModal } = props;

  return (
    <div
      className="general-upload-button"
      onClick={() => {
        data.setError = setError;
        data.setModal = setModal;
        saveFunction(fields, data, saveURL);
      }}
    >
      {translate("Save")}
    </div>
  );
}

function FieldHeadings(props) {
  const { labels } = props;
  const { fieldTypes } = props;

  return (
    <div className="advice-field-container">
      <div className={fieldTypes.field1}>
        <b>{labels.label1}</b>
      </div>

      <div className={fieldTypes.field2}>
        <b>{labels.label2}</b>
      </div>
      <div className="advice-delete-spacer"></div>
    </div>
  );
}

function DecisionFields(props) {
  const { fields } = props;
  const { fieldTypes } = props;
  const { setFields } = props;

  return fields.map((f, i) => {
    return (
      <div key={i} className="advice-field-container">
        <input
          className={fieldTypes.field1}
          value={f.decision}
          maxLength={90}
          onChange={(e) => {
            updateField("decision", e.target.value, i, fields, setFields);
          }}
        />

        <input
          className={fieldTypes.field2}
          value={f.required_by}
          maxLength={90}
          onChange={(e) => {
            updateField("required_by", e.target.value, i, fields, setFields);
          }}
        />

        <div
          className="advice-delete-button"
          onClick={() => deleteField(i, fields, setFields)}
        >
          <i className="far fa-trash-alt" />
        </div>
      </div>
    );
  });
}

function updateField(field, value, i, array, setFields) {
  let temp = [...array];
  temp[i][field] = value;

  setFields(temp);
}

function deleteField(i, array, setFields) {
  let temp = [...array];

  temp.splice(i, 1);
  setFields(temp);
}
