import isEmpty from "../../validation/is-empty";

export function attachCompanies(accounts, projects, companies) {
  accounts.forEach((account) => {
    if (!isEmpty(companies)) {
      companies.forEach((company) => {
        company.users.forEach((user) => {
          if (user.ActiveDirectoryId === account.id) {
            account.company_name = company.name;
            account.company_logo = company.logo;
          }
        });
      });
    } else {
      account.company_name = "Loading...";
    }
  });

  projects.forEach((project) => {
    if (!isEmpty(companies)) {
      let company = companies.find(
        (c) => c.id.toString() === project.company_id
      );
      if (!isEmpty(company)) {
        project.company_name = company.name;
        project.company_logo = company.logo;
      }
    } else {
      project.company_name = "Loading...";
    }
  });

  companies.forEach((company) => {
    if (!isEmpty(company.users)) {
      company.users.forEach((companyUser) => {
        if (!isEmpty(accounts)) {
          let account = accounts.find(
            (a) => a.id === companyUser.ActiveDirectoryId
          );
          if (!isEmpty(account)) {
            companyUser.name = account.name;
            companyUser.surname = account.surname;
            companyUser.id = account.id;
            companyUser.accessKey = account.accessKey;
            companyUser.job_title = account.jobTitle;
          }
        }
      });
    }
  });
}
