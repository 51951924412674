import React from "react";

import isEmpty from "../../validation/is-empty";

import { translate } from "../../utils/translation";
import { generateRiskRegisterTypes } from "./CostReportRiskRegisterChart.functions";
import { commaToFixed } from "../../helpers/common.functions";

// Needs Refactoring
export default function CostReportRiskRegisterTable(props) {
  const { RRs } = props;

  if (isEmpty(RRs)) {
    return null;
  }

  const { types, totals } = generateRiskRegisterTypes(RRs);

  return (
    <div>
      <Table types={types} totals={totals} />
    </div>
  );
}

function Table(props) {
  const { types } = props;
  const { totals } = props;

  return (
    <div className="crbs-content">
      <TitleRow />
      {types.map((data, i) => {
        return <Row key={i} data={data} />;
      })}
      <Row data={totals} isTotal />
    </div>
  );
}

function TitleRow() {
  return (
    <div className="crbs-title-row">
      <div className="crbs-description-cell"></div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Best Case")}</b>
      </div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Most Likely")}</b>
      </div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Worst Case")}</b>
      </div>
      <div className="crbs-value-cell-heading">
        <b>{translate("Risk Analysis")}</b>
      </div>
    </div>
  );
}

function Row(props) {
  const { data } = props;
  const { isTotal } = props;

  if (isTotal) {
    return (
      <div className="crbs-total-row">
        <div className="crbs-description-cell">
          <b>{data.risk_type}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(data.best_case_value)}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(data.value)}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(data.worst_case_value)}</b>
        </div>
        <div className="crbs-value-cell">
          <div></div>
          <b>{commaToFixed(data.risk_analysis)}</b>
        </div>
      </div>
    );
  } else {
    return (
      <div className="crbs-row">
        <div className="crbs-description-cell">{data.risk_type}</div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(data.best_case_value)}
        </div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(data.value)}
        </div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(data.worst_case_value)}
        </div>
        <div className="crbs-value-cell">
          <div></div>
          {commaToFixed(data.risk_analysis)}
        </div>
      </div>
    );
  }
}
