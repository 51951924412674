import React, { useState } from "react";

import ValueEngineeringDuplicateCPModal from "./ValueEngineeringDuplicateCPModal";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

export default function ValueEngineeringDuplicateCP(props) {
  const { user, project, CPs, CP } = props;

  const [modal, setModal] = useState(false);

  // Hide if not value-management
  const subLocation = getSubLocation();

  if (
    subLocation !== "value-management" ||
    project.project_phase !== "COST_PLANNING" ||
    !hasRoles(user.roles, ["CostManager"])
  ) {
    return null;
  }

  return (
    <div>
      <div
        id="button-duplicate-cp-modal"
        className="value-engineering-upload-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Generate Cost Plan")}
      </div>
      <Modal
        title={translate("Generate Cost Plan")}
        Component={ValueEngineeringDuplicateCPModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        CP={CP}
        CPs={CPs}
        setShow={setModal}
        project={project}
      />
    </div>
  );
}

function getSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
