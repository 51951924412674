import React, { useState } from "react";

// Components
import Table from "../table/Table";
import Spinner from "../common/Spinner";

// API
import { useUsersByGroup } from "../../api/users/UserAPI";
import { columns } from "./CreateProjectSelectProjectCostManagers.functions";
import { displayUserImage } from "../../validation/image-clean";
import { tranStr } from "../../utils/translation";

export default function CreateProjectSelectProjectCostManagers(props) {
  const { show, setShow } = props;
  const { setFunction } = props;
  const { selectedData } = props;
  const { userGroup } = props;
  const { users } = useUsersByGroup(show, userGroup);

  const [selectedRow, setSelectedRow] = useState({});

  if (users.loading) {
    return <Spinner marginTop={"170px"} marginBottom={"170px"} />;
  }

  const userArray = users.data.map((u) => {
    u.image = displayUserImage(u);
    return u;
  });

  return (
    <div>
      <Table
        title={tranStr("Accounts")}
        tableArray={userArray}
        columns={columns()}
        tableSize={10}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedData}
        setChecked={setFunction}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setShow}
      />
      <div className="general-row-container-double">
        <Save
          selectedUsers={selectedData}
          setFunction={setFunction}
          setShow={setShow}
        />
      </div>
    </div>
  );
}

function Save(props) {
  const { setShow } = props;
  const { setFunction } = props;
  const { selectedUsers } = props;

  return (
    <div className="admin-modal-cancel-button-container">
      <div
        className="create-project-save-cost-managers-button"
        onClick={() => {
          setFunction(selectedUsers);
          setShow(false);
        }}
      >
        Select
      </div>
    </div>
  );
}
