import React from "react";

import isEmpty from "../../validation/is-empty";
import "./EstimateChangesTable.css";
import { commaToFixed } from "../../helpers/common.functions";
import { tranStr, translate } from "../../utils/translation";

export default function EstimateChangesTable(props) {
  const { estimate } = props;
  const { estimates } = props;
  const { project } = props;

  if (isEmpty(estimate)) {
    return null;
  }
  // The Table to display
  let table = [];

  // Generate each table section
  let changes = estimate.changes;

  if (isEmpty(changes)) {
    return null;
  }

  estimates.forEach((e) => {
    if (!isEmpty(e.changes)) {
      table.push(
        <div key={e.id}>
          <TableSubHead title={e.stage_name + ", r. " + e.revision} />
          <Table data={e.changes} project={project} />
          <TableTotal data={e.changes} project={project} />
        </div>
      );
    }
  });

  return (
    <div className="scope-design-changes-table">
      <TableHead />
      {table}
      <TableGrandTotal estimates={estimates} project={project} />
    </div>
  );
}

function TableHead() {
  let row = {
    description: tranStr("Description"),
    quantity: tranStr("Type"),
    rate: tranStr("Value"),
  };

  return (
    <div className="scope-design-changes-table-head-row">
      <div className="scope-design-changes-table-description-cell">
        <b>{row.description}</b>
      </div>
      <div className="scope-design-changes-table-previous-cell">
        <b>{row.quantity}</b>
      </div>

      <div className="scope-design-changes-table-subtotal-cell-head">
        <b>{row.rate}</b>
      </div>
    </div>
  );
}

function TableSubHead(props) {
  const { title } = props;

  let row = {
    description: title,
    quantity: "",
    rate: "",
  };

  return (
    <div className="scope-design-changes-table-subhead-row">
      <div className="scope-design-changes-table-description-cell">
        <b>{row.description}</b>
      </div>
      <div className="scope-design-changes-table-previous-cell">
        <b>{row.quantity}</b>
      </div>

      <div className="scope-design-changes-table-subtotal-cell-head">
        <b>{row.rate}</b>
      </div>
    </div>
  );
}

function TableTotal(props) {
  const { data } = props;
  const { project } = props;

  let total = calculateTotal(data);

  let valueType = "detailed-comparison-table-value-positive";
  if (total < 0) {
    valueType = "detailed-comparison-table-value-negative";
  }
  return (
    <div className="scope-design-changes-table-total-row">
      <div className="scope-design-changes-table-description-cell">
        <b>{translate("Total")}</b>
      </div>
      <div className="scope-design-changes-table-previous-cell"></div>

      <div className="scope-design-changes-table-subtotal-cell">
        <div className={valueType}>
          <b>{project.currency_symbol}</b>
        </div>
        <div className={valueType}>
          <b>{generateBrackets(commaToFixed(total))}</b>
        </div>
      </div>
    </div>
  );
}

function TableGrandTotal(props) {
  const { estimates } = props;
  const { project } = props;

  if (estimates.length <= 1) {
    return null;
  }

  let data = [];

  estimates.forEach((e) => {
    if (e.changes) {
      // If there are changes
      e.changes.forEach((c) => {
        data.push(c);
      });
    }
  });

  let total = calculateTotal(data);

  let valueType = "detailed-comparison-table-value-positive";
  if (total < 0) {
    valueType = "detailed-comparison-table-value-negative";
  }
  return (
    <div className="scope-design-changes-table-grandtotal-row">
      <div className="scope-design-changes-table-description-cell">
        <b>{translate("Grand Total")}</b>
      </div>
      <div className="scope-design-changes-table-previous-cell"></div>

      <div className="scope-design-changes-table-subtotal-cell">
        <div className={valueType}>
          <b>{project.currency_symbol}</b>
        </div>
        <div className={valueType}>
          <b>{generateBrackets(commaToFixed(total))}</b>
        </div>
      </div>
    </div>
  );
}

function Table(props) {
  const { data } = props;
  const { project } = props;

  return (
    <div>
      {data.map((row, i) => {
        // Subtotal styling for other rows
        let rowType = "scope-design-changes-table-row";

        let valueType = "detailed-comparison-table-value-positive";
        if (row.value < 0) {
          valueType = "detailed-comparison-table-value-negative";
        }

        return (
          <div key={i} className={rowType}>
            <div className="scope-design-changes-table-description-cell">
              {row.description}
            </div>
            <div className="scope-design-changes-table-previous-cell">
              {row.code}
            </div>

            <div className="scope-design-changes-table-subtotal-cell">
              <div className={valueType}>
                <b>{project.currency_symbol}</b>
              </div>
              <div className={valueType}>
                <b>{generateBrackets(commaToFixed(row.value))}</b>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function generateBrackets(string) {
  if (string.toString().includes("-")) {
    return "(" + string.split("-")[1] + ")";
  } else {
    return "+" + string;
  }
}

function calculateTotal(data) {
  let sum;

  // If there is more than one estimate sum their totals
  if (data.length > 1) {
    sum = data.reduce((a, b) => ({
      value: a.value + b.value,
    })).value;
  } else {
    if (!isEmpty(data[0])) {
      sum = data[0].value;
    } else {
      sum = 0;
    }
  }

  return sum;
}
