import React from "react";
import isEmpty from "../../validation/is-empty";

import AreaSchedulesReconciliation from "./AreaSchedulesReconciliation";
import AreaSchedulesTable from "./AreaSchedulesTable";
import "./AreaSchedules.css";

import { translate } from "../../utils/translation";


export default function AreaSchedules(props) {
  const { project } = props;
  const { CP } = props;
  const { comparedCP, comparedCPR } = props;


  if (isEmpty(CP)) {
    return (
      <div className="area-schedule">
        {translate(
          "Area Schedules are available after a Cost Plan has been uploaded."
        )}
      </div>
    );
  }

  if (isEmpty(CP.areaschedules)) {
    return (
      <div className="area-schedule">
        {translate(
          "Area Schedules have not been uploaded yet."
        )}
      </div>
    );
  }

  return (
    <div className="area-schedules-container">
      <div className="area-schedules-table-container">
        <AreaSchedulesTable project={project} CP={CP} />
      </div>
      <div className="area-schedule-recon-container">
        <AreaSchedulesReconciliation
          CP={CP}
          project={project}
          comparedCP={comparedCP}
          comparedCPR={comparedCPR}
        />
      </div>

    </div>
  );
}
