import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../../api/api-endpoints/SharedServiceEndpoints";

export async function saveEmployees(data) {
  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const url = sharedAPIs().set_employees;
  const payload = {
    companyID: data.company.id,
    employees: data.selectedUsers.map((u) => {
      return { user_id: u.id };
    }),
  };

  const config = {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    // Update the Company state
    const companiesUpdate = await data.companies.get();
    data.companies.set(companiesUpdate);
    // Set the selected Company to the updated company
    const company = companiesUpdate.find((c) => c.id === data.company.id);
    data.setCompany(company);
    data.setError({
      text: "Saved",
      type: "feedback-success",
    });
  } else {
    response = await response.text();
    data.setError({
      text: response,
      type: "feedback-error",
    });
  }
}
