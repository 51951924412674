// React
import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";

import Modal from "../modal/Modal";
import ProjectControlDrawer from "../project-control/ProjectControlDrawer";
import PostContractControls from "../project-control-post-contract/ProjectControlPostContract";

// Upload Components
import PostContractUpload from "../post-contract-upload/PostContractUpload";
import PostContractUploadRiskRegister from "../post-contract-upload/PostContractUploadRiskRegister";

// Functions
import { hasRoles } from "../../utils/roles";

// Delete Component
import {
  onDelete,
  onDeleteRiskRegister,
} from "../../api/post-contract/PostContractDeleteAPI";
import PostContractControlDelete from "./PostContractControlDelete";

import "./PostContractControl.css";
import isEmpty from "../../validation/is-empty";
import EstimateCommentaryModal from "../estimate-commentary/EstimateCommentaryModal";
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

// PCRA Components
import PostContractPCRAModalTrigger from "../post-contract-pcra/PostContractPCRAModalTrigger";

export default function PostContractControl(props) {
  const { projectID } = props;
  const { project } = props;
  const { postContract } = props;

  const { costReport } = props;
  const { progressClaim } = props;
  const { variations } = props;

  const { showCRPanel, setShowCRPanel } = props;
  const { showPCPanel, setShowPCPanel } = props;
  const { user } = props;

  const [showPostContract, setShowPostContract] = useState(false);

  const isProjectPhasePostContract = project.project_phase === "POST_CONTRACT";

  if (
    !hasRoles(user.roles, ["CostManager"]) ||
    !window.location.href.includes("post-contract")
  ) {
    return null;
  }

  return (
    <div className="post-contract-control-panel">
      <div className="post-contract-control-panel-buttons">
        {isProjectPhasePostContract && (
          <>
            <ProjectControlDrawerWrapper />
            <DashboardPanel
              showDashboard={showCRPanel}
              setShowDashboard={setShowCRPanel}
              setShow2={setShowPostContract}
              label={translate("Cost Report Panel")}
              showLocation={"cost-report"}
              disabled={isEmpty(project.post_contract_duration)}
            />
            <DashboardPanel
              showDashboard={showPCPanel}
              setShowDashboard={setShowPCPanel}
              setShow2={setShowPostContract}
              label={translate("Progress Claim Panel")}
              showLocation={"progress-claim"}
              disabled={isEmpty(project.post_contract_duration)}
            />
            <UploadButton
              project={project}
              postContract={postContract}
              showLocation={"cost-report"}
              label={translate("Upload Cost Report")}
              pcIssued={{
                cost_report_issued: true,
                progress_claim_issued: false,
                variations_issued: true,
              }}
              Component={PostContractUpload}
              disabled={isEmpty(project.post_contract_duration)}
            />
            <UploadButton
              project={project}
              postContract={postContract}
              showLocation={"progress-claim"}
              label={translate("Upload Progress Claim")}
              pcIssued={{
                cost_report_issued: false,
                progress_claim_issued: true,
                variations_issued: true,
              }}
              Component={PostContractUpload}
              disabled={isEmpty(project.post_contract_duration)}
            />
            <UploadButton
              project={project}
              postContract={postContract}
              showLocation={"variations"}
              label={translate("Upload Variations")}
              pcIssued={{
                cost_report_issued: false,
                progress_claim_issued: false,
                variations_issued: true,
              }}
              Component={PostContractUpload}
              disabled={isEmpty(project.post_contract_duration)}
            />
            <UploadButton
              project={project}
              postContract={postContract}
              showLocation={"risk-register"}
              label={translate("Upload Risk Register")}
              pcIssued={{
                cost_report_issued: false,
                progress_claim_issued: false,
                variations_issued: false,
              }}
              Component={PostContractUploadRiskRegister}
              disabled={isEmpty(project.post_contract_duration)}
            />
            <PostContractControlDelete
              postContract={postContract}
              projectID={projectID}
              postContractID={costReport.id}
              costReport={costReport}
              showLocation={"cost-report"}
              label={"Delete Cost Report"}
              isCostReport={true}
              onDelete={onDelete}
            />
            <PostContractControlDelete
              postContract={postContract}
              projectID={projectID}
              postContractID={progressClaim.id}
              showLocation={"progress-claim"}
              label={"Delete Progress Claim"}
              onDelete={onDelete}
            />
            <PostContractControlDelete
              postContract={postContract}
              projectID={projectID}
              postContractID={variations.id}
              variations={variations}
              showLocation={"variations"}
              label={"Delete Update"}
              isVariations={true}
              onDelete={onDelete}
            />
            <PostContractControlDelete
              postContract={postContract}
              projectID={projectID}
              postContractID={costReport.id}
              costReport={costReport}
              showLocation={"risk-register"}
              label={"Delete Risk Register"}
              onDelete={onDeleteRiskRegister}
            />
            {!isEmpty(costReport.cost_report) && (
              <EstimateCommentaryModal
                user={user}
                project={project}
                CPs={postContract}
                CP={costReport}
                commentNumber={processComments(costReport)}
                URLs={pcAPIs}
                CostId={costReport.cost_report.post_contract_id}
                Module="PostContract"
              />
            )}
          </>
        )}
        <PostContractPCRAModalTrigger
          user={user}
          project={project}
          postContract={postContract}
          costReport={costReport}
          progressClaim={progressClaim}
          variations={variations}
        />
      </div>
    </div>
  );

  function ProjectControlDrawerWrapper() {
    if (
      !window.location.href.includes("dashboard") &&
      !window.location.href.includes("cost-report") &&
      !window.location.href.includes("progress-claims")
    ) {
      return null;
    }

    if (isEmpty(project.post_contract_duration)) {
      return null;
    }

    let title = "";
    if (window.location.href.includes("dashboard")) {
      title = tranStr("Post Contract Control");
    }
    if (window.location.href.includes("cost-report")) {
      title = tranStr("Cost Report Control");
    }
    if (window.location.href.includes("progress-claims")) {
      title = tranStr("Progress Claim Control");
    }

    return (
      <ProjectControlDrawer
        Component={PostContractControls}
        dashboardType={"POST_CONTRACT"}
        dashboardSelect={"POST_CONTRACT"}
        user={user}
        costReport={costReport}
        progressClaim={progressClaim}
        label={title}
        show1={showPostContract}
        setShow1={setShowPostContract}
        setShow2={setShowCRPanel}
        setShow3={setShowPCPanel}
        setShow4={setShowPCPanel}
        {...props}
      />
    );
  }
}

export function DashboardPanel(props) {
  const { showDashboard } = props;
  const { setShowDashboard } = props;
  const { setShow2 } = props;
  const { label } = props;
  const { showLocation } = props;
  const { disabled } = props;

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (disabled) {
    return null;
  }

  let classType = "post-contract-control-button-dashboard";
  if (showDashboard) {
    classType = "post-contract-control-button-clicked";
  }

  return (
    <div
      className={classType}
      onClick={() => {
        setShowDashboard(!showDashboard);
        setShow2(false);
      }}
    >
      {label}
    </div>
  );
}

export function UploadButton(props) {
  const { project } = props;
  const { postContract } = props;
  const { showLocation } = props;
  const { label } = props;
  const { Component } = props;
  const { disabled } = props;

  const [modal, setModal] = useState(false);

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (disabled) {
    return null;
  }

  return (
    <div>
      <div
        className="post-contract-control-button-upload"
        onClick={() => {
          setModal(true);
        }}
      >
        {label}
      </div>
      <Modal
        title={label}
        postContract={postContract}
        projectID={project.id}
        project={project}
        Component={Component}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        {...props}
      />
    </div>
  );
}
export function processComments(CP) {
  // No Commentary so the Comment is number 1
  // The comment is the next in the list
  return isEmpty(CP) || isEmpty(CP.costreport_commentary)
    ? 1
    : CP.costreport_commentary.length + 1;
}
