import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";

import { tranStr } from "../../utils/translation";

export default function BalanceOfContingencyChart(props) {
  const { headroomToBudget, originalContingency, adjustedContingency } = props;
  const { project } = props;

  let value = [];
  let field = [
    tranStr("Adjusted Contingency"),
    tranStr("Original Contingency"),
  ];
  let unallocatedHeadroom = headroomToBudget - adjustedContingency;
  value.push(adjustedContingency);
  value.push(originalContingency);

  // Create the graph object

  const graph = {
    labels: field,
    datasets: [
      {
        label: tranStr("Contingency"),
        data: value,
        backgroundColor: "rgba(85, 87, 90,1)",
        barThickness: 10,
      },
      {
        label: tranStr("Unallocated Headroom"),
        data: [unallocatedHeadroom, 0],
        backgroundColor: "rgba(228, 97, 15,1)",
        barThickness: 10,
      },
    ],
  };
  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return (
            project.currency_symbol + comma(Math.round(tooltipItem.xLabel))
          );
        },
      },
    },

    scales: {
      xAxes: [
        {
          position: "left",
          stacked: true,
          ticks: {
            callback: function (tick, index, values) {
              return project.currency_symbol + comma(Math.round(tick));
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },

    legend: {
      display: true,
      position: "bottom",
      fullWidth: true,
      reverse: false,
    },
  };

  return (
    <div>
      <HorizontalBar data={graph} options={options} />
    </div>
  );
}
