// React
import React from "react";

//Translation
import { translate } from "../../utils/translation";

// Style
import "./AdminLocalisationSelector.css";

export default function AdminLocalisationSelector(props) {
  const { namespacesObject } = props;
  const { selectedNamespace } = props;
  const { setSelectedNamespace, setActivePage } = props;

  return (
    <div className="alc-selector">
      <div className="alc-select-container">
        <div className="alc-select-label">
          <b>{translate("Select Terminology")} </b>
        </div>

        <select
          className="alc-select"
          value={selectedNamespace}
          onChange={(e) => {
            setActivePage(1);
            setSelectedNamespace(e.target.value);
          }}
        >
          <SelectList options={namespacesObject.data} />
        </select>
      </div>
    </div>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item} className="option">
        {item}
      </option>
    );
  });
}
