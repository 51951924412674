import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import ProgressClaim from "../post-contract-progress-claim/ProgressClaim";
import PostContractVariations from "../post-contract-variations/PostContractVariations";
import PostContractProvisionalSums from "../post-contract-provisional-sums/PostContractProvisionalSums";
import PostContractClientDirects from "../post-contract-client-directs/PostContractClientDirects";
import CostReport from "../post-contract-cost-report/CostReport";
import CashflowContainer from "../post-contract-cashflow/CashflowContainer";
import PostContractTradeCosts from "../post-contract-trade-costs/PostContractTradeCosts";
import PostContractUnfixedMaterials from "../post-contract-unfixed-materials/PostContractUnfixedMaterials";
import PostContractProvisionalQuantities from "../post-contract-provisional-quantities/PostContractProvisionalQuantities";
import PostContractDelays from "../post-contract-delays/PostContractDelays";
import PostContractRiskRegister from "../post-contract-risk-register/PostContractRiskRegister";
import PostContractContingencyRegister from "../post-contract-contingency/PostContractContingency";
import PostContractClientDirectVariations from "../post-contract-client-direct-variations/PostContractClientDirectVariations";

import isEmpty from "../../validation/is-empty";
import "./PostContract.css";
import PostContractEnablingWorks from "../post-contract-enabling-works/PostContractEnablingWorks";
import PostContractEnablingWorksVariations from "../post-contract-enabling-works-variations/PostContractEnablingWorksVariations";
import PostContractConsultantFeesVariations from "../post-contract-consultant-fees-variatons/PostContractConsultantFeesVariations";
import PostContractConsultantFees from "../post-contract-consultant-fees/PostContractConsultantFees";
import Commentary from "../estimate-commentary/Commentary";
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

export default function PostContract(props) {
  const { project } = props;
  const { postContract } = props;

  const { progressClaim } = props;
  const { costReport } = props;

  const { comparisonCRs } = props;
  const { variations } = props;

  const { showCRPanel, setShowCRPanel } = props;
  const { showPCPanel, setShowPCPanel } = props;
  const { dashboardSettings } = props;
  const { user } = props;

  if (isEmpty(project)) {
    return null;
  }
  return (
    <div className="project-background">
      <Switch>
        <Route
          exact
          path={"/project/:id/post-contract/"}
          render={() => (
            <Redirect
              replace
              to={`/project/${project.id}/post-contract/cost-report`}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/cost-report"}
          render={() => (
            <CostReport
              project={project}
              costReport={costReport}
              comparisonCRs={comparisonCRs}
              dashboardSettings={dashboardSettings}
              showCRPanel={showCRPanel}
              setShowCRPanel={setShowCRPanel}
              progressClaim={progressClaim}
              previousProgressClaim={postContract.data.progressClaims}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/progress-claims"}
          render={() => (
            <ProgressClaim
              project={project}
              progressClaim={progressClaim}
              showPCPanel={showPCPanel}
              costReport={costReport}
              setShowPCPanel={setShowPCPanel}
              dashboardSettings={dashboardSettings}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/trade-costs"}
          render={() => (
            <PostContractTradeCosts trades={progressClaim.trades} />
          )}
        />
        <Route
          path={"/project/:id/post-contract/unfixed-materials"}
          render={() => (
            <PostContractUnfixedMaterials
              unfixed_materials={progressClaim.unfixed_materials}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/variations"}
          render={() => (
            <PostContractVariations
              variations={variations}
              postContract={postContract}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/provisional-sums"}
          render={() => (
            <PostContractProvisionalSums
              variations={variations}
              PSs={variations.PSs}
              postContract={postContract}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/provisional-quantities"}
          render={() => (
            <PostContractProvisionalQuantities PQs={variations.PQs} />
          )}
        />
        <Route
          path={"/project/:id/post-contract/delays"}
          render={() => (
            <PostContractDelays
              EoTs={variations.EoTs}
              PDs={variations.PDs}
              postContract={postContract}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/client-directs"}
          render={() => (
            <PostContractClientDirects
              CDs={variations.CDs}
              variations={variations}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/client-direct-variation-register"}
          render={() => (
            <PostContractClientDirectVariations
              CDVs={costReport.clientdirectvariations}
              postContract={postContract}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/cashflow"}
          render={() => (
            <CashflowContainer
              project={project}
              postContract={postContract}
              progressClaim={progressClaim}
              costReport={costReport}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/risk-register"}
          render={() => (
            <PostContractRiskRegister
              RRs={costReport.RRs}
              postContract={postContract}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/contingency"}
          render={() => (
            <PostContractContingencyRegister
              CRs={costReport.contingencies}
              postContract={postContract}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/enabling-works"}
          render={() => (
            <PostContractEnablingWorks
              EW={costReport.EW}
              variations={variations}
            />
          )}
        />
        <Route
          path={"/project/:id/post-contract/enabling-works-variations-register"}
          render={() => (
            <PostContractEnablingWorksVariations
              EWV={costReport.EWVariations}
              variations={variations}
            />
          )}
        />

        <Route
          path={"/project/:id/post-contract/consultant-fees"}
          render={() => <PostContractConsultantFees PTCF={costReport.PTCF} />}
        />
        <Route
          path={"/project/:id/post-contract/consultant-fee-variations-register"}
          render={() => (
            <PostContractConsultantFeesVariations PTCFVs={costReport.PTCFVs} />
          )}
        />
        <Route
          path={"/project/:id/post-contract/commentary"}
          render={() => (
            !isEmpty(costReport.cost_report) &&
              
            <Commentary
              project={project}
              CPs={postContract}
              CP={costReport}
              Comments={costReport.costreport_commentary}
              URLs={pcAPIs}
              CostId={costReport.cost_report.post_contract_id}
              Module={"PostContract"}
              user={user}
            />
          )}
        />
      </Switch>
    </div>
  );
}
