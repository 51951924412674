import React from "react";
import isEmpty from "../../validation/is-empty";
import "./ProgressClaimContractStatement.css";
import { translate } from "../../utils/translation";
import { commaToFixed } from "../../helpers/common.functions";

export default function ProgressClaimContractStatement(props) {
  const { PC } = props;

  return (
    <div className="progress-claim-panel">
      <div className="pccs-content">
        <div className="pccs-row">
          <div className="pccs-description-cell">
            <div>{translate("Original Contract Sum")}</div>
          </div>

          <div className="pccs-value-cell">
            <div></div>
            {commaToFixed(PC.contract_sum)}
          </div>
        </div>
        <div className="pccs-row">
          <div className="pccs-description-cell">
            <div>{translate("Less Provisional Items")}</div>
          </div>
          <div className="pccs-value-cell">
            <div></div>
            {commaToFixed(PC.prov_items)}
          </div>
        </div>
        <div className="pccs-row-subtotal">
          <div className="pccs-description-cell">
            <b>
              {translate("Original Contract Sum Excluding Provisional Items")}
            </b>
          </div>
          <div className="pccs-value-cell">
            <div>
              <b></b>
            </div>
            <b>{commaToFixed(PC.contract_sum_excl_prov_items)}</b>
          </div>
        </div>
        <div className="pccs-row">
          <div className="pccs-description-cell">
            <div>{translate("Variations")}</div>
          </div>
          <div className="pccs-value-cell">
            <div></div>
            {commaToFixed(PC.variations_submitted + PC.variations_approved)}
          </div>
        </div>
        <div className="pccs-row">
          <div className="pccs-description-cell">
            <div>{translate("Provisional Quantities")}</div>
          </div>

          <div className="pccs-value-cell">
            <div></div>
            {commaToFixed(
              PC.prov_quantities_approved + PC.prov_quantities_interim
            )}
          </div>
        </div>
        <div className="pccs-row">
          <div className="pccs-description-cell">
            <div>{translate("Provisional Sums")}</div>
          </div>

          <div className="pccs-value-cell">
            <div></div>
            {commaToFixed(PC.prov_sums_approved + PC.prov_sums_interim)}
          </div>
        </div>
        <div className="pccs-row">
          <div className="pccs-description-cell">
            <div>{translate("Provisional Delay Days")}</div>
          </div>

          <div className="pccs-value-cell">
            <div></div>
            {commaToFixed(
              PC.extensions_of_time_approved + PC.extensions_of_time_interim
            )}
          </div>
        </div>

        <div className="pccs-row-total">
          <div className="pccs-description-cell">
            <b>
              {translate("Forecast Adjusted Contract Sum Excl")}
              {isEmpty(PC.tax_type) ? " tax" : " " + PC.tax_type}
            </b>
          </div>
          <div className="pccs-value-cell">
            <div>
              <b></b>
            </div>
            <b>{commaToFixed(PC.forecast_adjusted_contract_sum_excl_tax)}</b>
          </div>
        </div>
      </div>
    </div>
  );
}
