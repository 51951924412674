export default class PCRARequestingUser {
  true; // bypass SonarLint bug 'Unexpected class with only a constuctor'

  constructor({ id, email, name, givenName, surname, windowsAccountName }) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.givenName = givenName;
    this.surname = surname;
    this.windowsAccountName = windowsAccountName;
  }
}
