import { detectDeployment } from "./Utilities";

export function cpAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev.cost-clarity.com/apis/costplan/v1.0",
    deployedService: "/apis/costplan/v1.0",
  };

  // Deployed dev "https://dev.cost-clarity.com/apis/costplan/v1.0"
  // Local dev "https://localhost:44335/v1.0"

  const D = detectDeployment(config);

  return {
    // Cost Planning
    get_estimates: `${D}/estimates/get_estimates`,
    delete_estimate: `${D}/estimates/delete_estimate`,
    update_estimate: `${D}/estimates/update_estimate`,
    save_escalation_date: `${D}/estimates/save_escalation_date`,
    save_subtotals: `${D}/estimates/save_subtotals`,
    upload_estimate: `${D}/estimates-upload/upload_estimate`,
    get_stages: `${D}/projects-stages/get_stages`,
    update_stages: `${D}/projects-stages/update_stages`,
    update_project_features: `${D}/projects-decoupled-control/update_project_features`,
    save_project_metrics: `${D}/projects-metrics/save_project_metrics`,
    save_selected_subtotals: `${D}/estimates/save_selected_subtotals`,
    save_locality_factor: `${D}/locality-factor/save_locality_factor`,

    // Area schedules
    upload_area_schedule: `${D}/area-schedule-upload/upload_area_schedule`,
    allocate_area_schedules: `${D}/area-schedule/allocate_area_schedules`,

    // Cost Planning Commentary
    commentary_delete: `${D}/estimates-commentary/commentary/delete`,
    commentary_edit: `${D}/estimates-commentary/commentary/edit`,
    commentary_create: `${D}/estimates-commentary/commentary/create`,
    commentary_upload_table: `${D}/estimates-commentary/commentary/create_table`,

    // Cost Planning Key Documents
    delete_project_drawing: `${D}/drawings/delete_project_drawing`,
    upload_project_drawings: `${D}/drawings/upload_project_drawings`,

    // Cost Planning Breakdown
    save_breakdown_comment: `${D}/estimates-breakdown-comments/save_comment`,
    delete_breakdown_comment: `${D}/estimates-breakdown-comments/delete_comment`,
    action_breakdown_comment: `${D}/estimates-breakdown-comments/action_comment`,

    // Cost Planning Benchmarking
    anonymize_estimate_benchmark: `${D}/estimates-benchmarking/anonymize_estimate_benchmark`,
    estimates_benchmarking_save: `${D}/estimates-benchmarking/save`,
    create_estimate_benchmark: `${D}/benchmarking/create_estimate_benchmark`,

    // Cost Planning Reconciliation
    comparison_commentary_set: `${D}/reconciliation/comparison_commentary_set`,
    delete_scope_design_changes: `${D}/reconciliation-upload/delete_scope_design_changes`,
    upload_scope_design_changes: `${D}/reconciliation-upload/upload_scope_design_changes`,
    comparison_commentary_get: `${D}/reconciliation/comparison_commentary_get`,

    // Cost Planning Chart Settings
    get_projects_charts: `${D}/settings/get_projects_charts`,
    set_projects_charts: `${D}/settings/set_projects_charts`,

    // Cost Planning Cashflow
    save_joined_cashflow: `${D}/estimates-cashflow/save_joined_cashflow`,
    save_split_cashflow: `${D}/estimates-cashflow/save_split_cashflow`,
    update_basis: `${D}/estimates-cashflow/update_basis`,
    save_cashflow: `${D}/estimates-cashflow/save_cashflow`,

    //Cost Planning Budget
    save_budget: `${D}/estimates-budget/save_budget`,
    allocate_cost: `${D}/estimates-budget/allocate_cost`,

    // Cost Planning Advice
    set_decisions: `${D}/projects-advice/set_decisions`,
    set_highlights: `${D}/projects-advice/set_highlights`,
    set_risks: `${D}/projects-advice/set_risks`,

    //Value Engineering
    save_value_engineering: `${D}/value-engineering/save_value_engineering`,
    save_value_engineering_items_status: `${D}/value-engineering/save_value_engineering_items_status`,
    upload_value_engineering: `${D}/value-engineering/upload_value_engineering`,
    create_duplicate: `${D}/costplan/create_duplicate`,

    //Project costplan dashboard
    save_layout: `${D}/projects-dashboard-settings/save_layout`,
    get_layout: `${D}/projects-dashboard-settings/get_layout`,
    get_cp_configs: `${D}/project-cost-plan/configurations`,

    // Project and Portfolio
    get_projects_cost_planning: `${D}/projects/get_projects_cost_planning`,
    get_portfolio_cost_planning: `${D}/projects/get_portfolio_cost_planning`,

    // Project Admin
    delete_cost_planning: `${D}/projects-decoupled-control/delete_cost_planning`,
  };
}
