import React from "react";
import { Link } from "react-router-dom";

import "../path/Path.css";

export default function InsightCategoriesPath() {
  return (
    <div className="insight-path">
      <Link to="/portfolio" className="path-link">
        {"Portfolio"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={"/insights"} className="path-link">
        {"Insights"}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={`/insights/categories`} className="path-link-current">
        {"Categories"}
      </Link>
    </div>
  );
}
