import React, { useState } from "react";
import { deleteCommentary } from "./Commentary.functions";
import Spinner from "../common/Spinner";

export default function DeleteCommentary(props) {
  const { project } = props;
  const { c } = props;
  const { setComments } = props;
  const { CPs } = props;

  const { URLs } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  if (isDeleting) {
    return <Spinner width={"50px"} marginLeft={"15px"} />;
  }

  return (
    <button
      id="deleteButton"
      className="commentary-delete-button"
      onClick={async () => {
        let result = window.confirm(
          "Confirm delete this commentary? It cannot be undone."
        );
        if (result) {
          const data = {
            cID: c.id,
            cType: c.type,
            projectID: project.id,
            setComments: setComments,
            CPs: CPs,
          };

          setIsDeleting(true);
          await deleteCommentary(data, URLs);
          CPs.set(await CPs.get(project.id, project));
          setIsDeleting(false);
        }
      }}
    >
      <i className="far fa-trash-alt"></i>
    </button>
  );
}
