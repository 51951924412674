import React from "react";
import { Line } from "react-chartjs-2";

import isEmpty from "../../validation/is-empty";
import "./CostReportTrendChart.css";
import { comma } from "../../helpers/common.functions";

import { tranStr } from "../../utils/translation";

export default function CostReportTrendChart(props) {
  const { CRs } = props;

  if (isEmpty(CRs)) {
    return null;
  }

  const reversedCRs = [...CRs].reverse();

  // Format the data
  const fields = [];

  const adjustedBudgets = [];
  const contingencies = [];
  const forecastTotals = [];
  const datasets = [];

  reversedCRs.forEach((CR) => {
    const costReportNumber = CR.cost_report.cost_report_number;

    const adjustedBudget =
      CR.cost_report.forecast_cost_excl_tax_adjusted_budget;
    const contingency = CR.cost_report.forecast_cost_excl_tax_contingency;
    const forecastTotal = CR.cost_report.forecast_cost_excl_tax_forecast_total;

    fields.push(tranStr("Cost Report") + " " + costReportNumber);
    adjustedBudgets.push(adjustedBudget);
    forecastTotals.push(forecastTotal);
    contingencies.push(contingency);
  });

  datasets.push({
    yAxisID: "forecastTotal",
    label: tranStr("Adjusted Budget"),
    data: adjustedBudgets,
    barThickness: 10,
    borderColor: colourArray(0, 1),
    backgroundColor: colourArray(0, 1),
    lineTension: 0,
    fill: false,
  });

  datasets.push({
    yAxisID: "forecastTotal",
    label: tranStr("Forecast Total"),
    data: forecastTotals,
    barThickness: 10,
    borderColor: colourArray(1, 1),
    backgroundColor: colourArray(1, 1),
    lineTension: 0,
    fill: false,
  });

  datasets.push({
    yAxisID: "contingency",
    label: tranStr("Balance of Contingency"),
    data: contingencies,
    barThickness: 10,
    borderColor: colourArray(2, 1),
    backgroundColor: colourArray(2, 1),
    lineTension: 0,
    fill: false,
  });

  // Create the graph object
  let graph = {
    labels: fields,
    datasets: datasets,
    text: fields,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },

    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem) {
          return comma(Math.round(tooltipItem.yLabel));
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: false,
        },
      ],
      yAxes: [
        {
          id: "forecastTotal",
          scaleLabel: {
            display: true,
            labelString: tranStr("Budget & Forecast Cost"),
          },
          position: "left",
          stacked: false,
          ticks: {
            callback: function (value, index, values) {
              return comma(Math.round(value));
            },
          },
        },
        {
          id: "contingency",
          scaleLabel: {
            display: true,
            labelString: tranStr("Remaining Contingency"),
          },
          position: "right",
          stacked: false,
          ticks: {
            callback: function (value, index, values) {
              return comma(Math.round(value));
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      fullWidth: false,
      reverse: false,
    },
  };

  return (
    <div className="cost-report-trend-chart-container">
      <div className="cost-report-trend-chart">
        <Line data={graph} options={options} />
      </div>
    </div>
  );
}

function colourArray(i, shade) {
  let heritage = `rgba(228, 97, 15, ${1 * shade})`;
  let aqua = `rgba(50, 98, 149, ${1 * shade})`;
  let collaboration1 = `rgba(85, 87, 90, ${1 * shade})`;
  let heritage2 = `rgba(228, 97, 15, ${0.3 * shade})`;
  let aqua2 = `rgba(50, 98, 149, ${0.3 * shade})`;
  let collaboration2 = `rgba(85, 87, 90, ${0.7 * shade})`;
  let collaboration3 = `rgba(85, 87, 90, ${0.3 * shade})`;

  let array = [
    heritage,
    aqua,
    collaboration1,
    aqua2,
    heritage2,
    collaboration2,
    collaboration3,
  ];

  let index = i % array.length;

  return array[index];
}
