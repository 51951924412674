import isEmpty from "../../validation/is-empty";
//Login logout functions
import { loginUser } from "./LoginLogout.functions";

function checkToken() {
  if (!isEmpty(localStorage.user)) {
    // Get the expiry time of the user and the current time
    const user = JSON.parse(localStorage.user);
    let currentTime = Date.now() / 1000; // Convert from milliseconds to seconds

    // Check if the token was issued 12 hours back
    const twelvehours = 43200;
    if (user.iat + twelvehours < currentTime) {
      return true;
    }
    // Log the user back in silently if the access token expiry is approaching
    else if (user.exp < currentTime) {
      // Call the silent login method only if B2c is enabled
      if (!user.isLegacyUser) {
        loginUser();
      }
      return false;
    }
  }
  return false;
}
export { checkToken };
