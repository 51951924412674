import React from "react";
import ProjectControlEditEstimateMetricsTable from "./ProjectControlEstimateMetricsTable";

import "./ProjectControlEstimateMetrics.css";

export default function ProjectControlEstimateMetricsEdit(props) {
  const { allMetrics } = props;
  const { selectedMetrics, setSelectedMetrics } = props;

  const { metricContainer } = props;
  const { setMetricContainers } = props;

  const { setShow } = props;

  return (
    <div className="project-control-metrics-selection">
      <div className="project-control-metrics-table">
        <ProjectControlEditEstimateMetricsTable
          data={allMetrics}
          selectedData={selectedMetrics}
          setSelectedData={setSelectedMetrics}
        />
      </div>

      <div className="general-button-container">
        <SaveMetricsButton
          setShow={setShow}
          // Metric Containers
          metricContainer={metricContainer}
          setMetricContainers={setMetricContainers}
          // Selected Metrics
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
        />
      </div>
    </div>
  );
}

function SaveMetricsButton(props) {
  const { metricContainer } = props;
  const { selectedMetrics, setSelectedMetrics } = props;
  const { setMetricContainers } = props;
  const { setShow } = props;

  return (
    <div
      className="general-modal-button"
      onClick={() => {
        setMetricContainers((metricsContainers) => {
          let newContainers = [];
          metricsContainers.forEach((container) => {
            if (container.id === metricContainer.id) {
              container = combineMetrics(container, selectedMetrics);
            }
            newContainers.push(container);
          });
          return newContainers;
        });
        setSelectedMetrics([]);
        setShow(false);
      }}
    >
      Select
    </div>
  );
}

function combineMetrics(metric, selectedMetrics) {
  metric.code = "";
  metric.metric = "";
  metric.quantity = 0;
  metric.unit = "";

  selectedMetrics.forEach((m) => {
    metric.code = m.code;
    metric.metric = m.metric;
    metric.quantity += m.quantity;
    metric.unit = m.unit;
  });

  metric.quantity = processMetricQuantity(metric);

  return metric;
}

function processMetricQuantity(metric) {
  // Determine the type of metric ("c" for cost "d" for design)
  let type = metric.code.split(".")[0];
  let value = 0;
  // If type is a cost metric
  if (type.toLowerCase() === "c") {
    value = round(metric.quantity, 0);
  } else {
    value = round(metric.quantity, 2);
  }
  return value;
}

function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
