import React from "react";
import PropTypes from "prop-types";

import { comma, commaToFixed } from "../../helpers/common.functions";
import { formatUOM } from "./BenchmarkingMetrics.functions";

import MetricsChart from "./MetricsChart";

/// props validation | SQ(javascript:S6774)
MetricsHorizontalLineChart.propTypes = {
  skipHighlight: PropTypes.bool,
  benchmarkControl: PropTypes.bool,
  data: PropTypes.array,
  metric: PropTypes.string,
};
///
export default function MetricsHorizontalLineChart(props) {
  const { skipHighlight } = props;
  const { benchmarkControl } = props;
  const { data } = props;

  const { metric } = props;

  return (
    <MetricsChart
      // props to pick chart
      chartType={"HorizontalLine"}
      labelTooltipCallback={(tooltipItem) =>
        formatUOM(commaToFixed(tooltipItem.yLabel), metric, false)
      }
      yAxisTicksCallback={(tick, index, values) =>
        formatUOM(comma(tick), metric, false)
      }
      // props to fill chart
      skipHighlight={skipHighlight}
      benchmarkControl={benchmarkControl}
      data={data}
    />
  );
}
