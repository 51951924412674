import React, { Component } from "react";
import isEmpty from "../../validation/is-empty";
import { Donut, Legend } from "britecharts-react";

export default class DoughnutChartBrite extends Component {
  constructor(props) {
    super(props);

    this.state = { highlightedSlice: null };
  }

  render() {
    const {
      data,
      width,
      height,
      eRadius,
      iRadius,
      lHeight,
      lWidth,
      margin,
      colorSchema,
      projectDashboard,
    } = this.props;

    if (isEmpty(data)) {
      return null;
    }

    const maxObject = data.reduce((prev, current) => {
      return prev.value > current.value ? prev : current;
    });

    let ID = 0;

    if (!isEmpty(maxObject)) {
      ID = maxObject.id;
    }

    let classType = null;
    if (projectDashboard) {
      classType = "project-dashboard-cost-summary-graph";
    }

    return (
      <div className={classType}>
        <Donut
          isAnimated={false}
          colorSchema={colorSchema || null}
          data={data}
          width={width || 425}
          height={height || 200}
          externalRadius={eRadius || 90}
          internalRadius={iRadius || 70}
          highlightSliceById={ID}
          hasFixedHighlightedSlice={true}
        />
        <Legend
          colorSchema={colorSchema || null}
          data={data}
          height={lHeight || 120}
          width={lWidth || 365}
          numberFormat={`,`}
          highlightEntryById={ID}
          margin={margin || null}
        />
      </div>
    );
  }
}
