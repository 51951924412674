import React from "react";
import BudgetDetailedDonutChart from "./ProjectBudgetDetailedChart";
import isEmpty from "../../validation/is-empty";

export default function ProjectBudgetDetailedSummary(props) {
  const { data } = props;
  const { totalBudget } = props;

  // No data available return blank
  if (isEmpty(data)) {
    return null;
  }

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.7)";
  let aqua2 = "rgba(50, 98, 149, 0.7)";
  let collaboration12 = "rgba(85, 87, 90, 0.7)";

  let colorSchema = [
    heritage,
    collaboration1,
    aqua,
    collaboration12,
    aqua2,
    heritage2,
  ];

  let margin = {
    left: -40,
    right: 10,
    top: 5,
    bottom: 5,
  };

  let budgetData = [];
  data.forEach((o, i) => {
    let field = o.description;
    if (o !== undefined) {
      if (o.budget > 0) {
        budgetData.push({
          quantity: o.budget, // display value
          value: o.budget, // value used for total calculation
          percentage: ((o.budget / totalBudget) * 100).toFixed(1) * 1,
          name: field,
          id: i + 1,
        });
      }
    }
  });

  return (
    <BudgetDetailedDonutChart
      isAnimated={false}
      data={budgetData}
      width={200}
      height={200}
      eRadius={90}
      iRadius={70}
      lWidth={270}
      lHeight={150}
      margin={margin}
      colorSchema={colorSchema}
      totalBudget={totalBudget}
    />
  );
}
