import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";

// Functions
import { hasRoles } from "../../utils/roles";

//Styles
import "./AdminNavigation.css";

export default function AdminNavigation(props) {
  let subLocation = determineSubLocation();
  const { user } = props;

  return (
    <div className="admin-navigation-tab-group">
      {accountControl(subLocation)}
      {projectControl(subLocation)}
      {companyControl(subLocation)}
      {regionControl(subLocation)}
      {feedbackControl(subLocation)}
      {demoControl(subLocation)}
      {systemDashbordControl(subLocation)}
      {localisationControl(subLocation, user)}
      {cbsControl(subLocation, user)}
    </div>
  );
}

function accountControl(location) {
  let name = "admin-navigation-section";
  if (location === "account-control") {
    name = "admin-navigation-section-selected";
  }

  return (
    <Link to={`/admin/account-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-user-friends"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Account Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function projectControl(location) {
  let name = "admin-navigation-section";
  if (location === "project-control") {
    name = "admin-navigation-section-selected";
  }

  return (
    <Link to={`/admin/project-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="far fa-folder-open"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Project Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function companyControl(location) {
  let name = "admin-navigation-section";
  if (location === "company-control") {
    name = "admin-navigation-section-selected";
  }

  return (
    <Link to={`/admin/company-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-building"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Company Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function regionControl(location) {
  let name = "admin-navigation-section";
  if (location === "location-control") {
    name = "admin-navigation-section-selected";
  }

  return (
    <Link to={`/admin/location-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-globe-americas"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Location Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function feedbackControl(location) {
  let name = "admin-navigation-section";
  if (location === "feedback-control") {
    name = "admin-navigation-section-selected";
  }

  return (
    <Link to={`/admin/feedback-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i className="far fa-comment" style={{ marginRight: "10px" }}></i>
            </div>
            {translate("Feedback Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function demoControl(location) {
  let name = "admin-navigation-section";
  if (location === "demo-control") {
    name = "admin-navigation-section-selected";
  }

  return (
    <Link to={`/admin/demo-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-laptop-code"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Demo Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function localisationControl(location, user) {
  let name = "admin-navigation-section";
  if (location === "localisation-control") {
    name = "admin-navigation-section-selected";
  }

  if (!hasRoles(user.roles, ["LanguageAdmin"])) {
    return null;
  }

  const ENABLE_TRANSLATION = true;

  if (!ENABLE_TRANSLATION) {
    return null;
  }

  return (
    <Link to={`/admin/localisation-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-language"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Localisation")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function systemDashbordControl(location) {
  let name = "admin-navigation-section";
  if (location === "system-dashboard-control") {
    name = "admin-navigation-section-selected";
  }
  return (
    <Link to={`/admin/system-dashboard-control`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="far fa-folder-open"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("System Dashboard")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function cbsControl(location, user) {
  let name = "admin-navigation-section";
  if (location === "cbs") {
    name = "admin-navigation-section-selected";
  }

  if (!hasRoles(user.roles, ["CbsAdmin"])) {
    return null;
  }

  return (
    <Link to={`/admin/cbs`} className="tab-link">
      <div className={name}>
        <div className="admin-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="fas fa-database"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("CBS Control")}
          </div>
        </div>
      </div>
    </Link>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
