import React, { useEffect, useState } from "react";

import "./PostContractUpload.css";
import { uploadPostContractRiskRegister } from "../../api/post-contract/PostContractAPI";
import isEmpty from "../../validation/is-empty";

export default function PostContractUploadRiskRegister(props) {
  const { projectID, project } = props;
  const { postContract } = props;
  const { pcIssued } = props;
  const { setShow } = props;
  const [linkedPostContractID, setLinkedPostContractID] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!isEmpty(postContract.data.costReports)) {
      setLinkedPostContractID(
        postContract.data.costReports[postContract.data.costReports.length - 1]
          .id
      );
    }
  }, [postContract]);

  const data = {
    file: file,
    project:project,
    projectID: projectID,
    linkedID: linkedPostContractID,
    postContract: postContract,
    setError: setError,
    setShow: setShow,
    pcIssued: pcIssued,
  };

  return (
    <div className="pcu-container">
      <div className="general-row-container">
        <FileTray file={file} />
      </div>
      <div className="general-row-container">
        <UploadFile setFile={setFile} />
      </div>
      <SelectProgressClaim
        postContract={postContract}
        linkedPostContractID={linkedPostContractID}
        setLinkedPostContractID={setLinkedPostContractID}
        pcIssued={pcIssued}
      />
      <div className="general-row-container">
        <Error error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <Upload uploadFile={uploadPostContractRiskRegister} data={data} />
      </div>
    </div>
  );
}

function SelectProgressClaim(props) {
  const { postContract } = props;
  const { linkedPostContractID } = props;
  const { setLinkedPostContractID } = props;
  const { pcIssued } = props;

  if (pcIssued.progress_claim_issued) {
    return null;
  }

  const selectList = [...postContract.data.costReports].reverse();

  return (
    <div className="general-row-container">
      <div className="pcu-select-container">
        <div className="pcu-select-label">Linked Cost Report</div>
        <select
          className="pcu-select-list"
          value={linkedPostContractID}
          onChange={(e) => {
            setLinkedPostContractID(e.target.value);
          }}
        >
          <SelectList options={selectList} />
        </select>
      </div>
    </div>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item.id} className="option">
        {"Cost Report " + item.cost_report.cost_report_number}
      </option>
    );
  });
}

function UploadFile(props) {
  const { setFile } = props;

  return (
    <label className="general-modal-button">
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      Attach File
    </label>
  );
}

function FileTray(props) {
  const { file } = props;
  let fileName = "No File Added";

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pcu-file-display">
      <b>File:</b> {fileName}
    </div>
  );
}

function Upload(props) {
  const { uploadFile } = props;
  const { data } = props;
  return (
    <div
      className="general-upload-button"
      onClick={async () => {
        uploadFile(data);
      }}
    >
      Upload
    </div>
  );
}

function Error(props) {
  const { error } = props;
  return (
    <div className="pcu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
