import React from "react";

import {
  validateCurrency,
  validatePercent,
  validateDate,
} from "./PostContractTableModal.functions";

import {
 // Description,
  ExpandableRow,
  OneValueRow,
  DocumentLink,
} from "./PostContractModal.components";

import "./PostContractTableModal.css";

export default function PostContractProvisionalSumsModal(props) {
  const { selectedRow } = props;
  const R = selectedRow;

  return (
    <div className="pctm">
      <div className="pctm-container-row">
      <OneValueRow
          heading1={"Description"}
          value1={R.description}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={["Provisional Sum", "Actual Cost"]}
          values={[
            validateCurrency(R.provisional_sum),
            validateCurrency(R.actual_cost),
          ]}
        />
        <ExpandableRow
          headings={["Prov Sum Adjustment"]}
          values={[validateCurrency(R.provisional_sum_adjustment)]}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={["% Complete to Date", "Value Complete to Date"]}
          values={[
            validatePercent(R.percent_complete_to_date),
            validateCurrency(R.value_complete_to_date),
          ]}
        />
        <ExpandableRow
          headings={[
            "Contractor Claim %",
            "Contractor Claim Value",
            "Difference",
          ]}
          values={[
            validatePercent(R.contractor_claim_percent),
            validateCurrency(R.contractor_claim_value),
            validateCurrency(R.difference),
          ]}
        />
      </div>
      <div className="pctm-container-row">
        <OneValueRow
          heading1={"Reasons for Difference"}
          value1={R.reasons_for_difference}
        />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={["Status", "Action With"]}
          values={[R.status, R.action]}
        />
        <ExpandableRow
          headings={["Submitted Date", "Recommended Date", "Approved Date"]}
          values={[
            validateDate(R.submitted_date),
            validateDate(R.recommended_date),
            validateDate(R.approved_date),
          ]}
        />
      </div>
      <div className="pctm-container-row">
        <OneValueRow heading1={"Comments"} value1={R.comments} />
      </div>
      <div className="pctm-container-row">
        <DocumentLink R={R} />
      </div>
    </div>
  );
}
