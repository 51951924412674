import React, { useState } from "react";

import { hasRoles } from "../../utils/roles";
import Modal from "../modal/Modal";
import CreateProject from "../project-create/CreateProject";
import { translate } from "../../utils/translation";
import { projectAPIs } from "../../api/api-endpoints/ProjectEndpoints";

export default function PortfolioProjectsCreateButton(props) {
  const { user } = props;
  const { setProjects } = props;
  const { portfolioId } = props;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (portfolioId !== "all-projects") {
    return null;
  }

  return (
    <div>
      <div
        className="create-project-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Create Project")}
      </div>
      <Modal
        title={"Create Project"}
        setProjects={setProjects}
        Component={CreateProject}
        modal={modal}
        setModal={setModal}
        // Component Props
        urlMethod={"POST"}
        isProjectCreation={true}
        // PROJECT API
        urlEndpoint={projectAPIs().create_project}
        user={user}
      />
    </div>
  );
}
