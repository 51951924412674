import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function submitEdit(data) {
  const payload = JSON.stringify({
    estimate_id: data.estimate_id,
    project_id: data.project_id,
    name: data.name,
    date: data.date,
    stage: data.stage,
    revision: data.revision,
    is_included: data.isIncluded,
    part: data.part,
  });

  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  // COST PLAN API
  const url = cpAPIs().update_estimate;

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    // Update Cost Plan
    let cp = await data.CPs.get(data.project_id, data.project);
    data.CPs.set(cp);

    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    // close modal after timeout
    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}
