import React from "react";
import { translate } from "../../utils/translation";
import { comma } from "../../helpers/common.functions";

import isEmpty from "../../validation/is-empty";
import { Donut, Legend } from "britecharts-react";

import "./ProgressClaimPaymentOverviewChart.css";

export default function ProgressClaimPaymentOverviewChart(props) {
  const { data } = props;
  const { width, height } = props;
  const { eRadius, iRadius } = props;
  const { lHeight, lWidth } = props;
  const { margin } = props;
  const { colorSchema } = props;

  if (isEmpty(data)) {
    return null;
  }

  const maxObject = data.reduce((prev, current) => {
    return prev.value > current.value ? prev : current;
  });

  let ID = 0;

  if (!isEmpty(maxObject)) {
    ID = maxObject.id;
  }

  return (
    <div className="pcpo-chart-container">
      <Donut
        isAnimated={false}
        colorSchema={colorSchema || null}
        data={data}
        width={width || 425}
        height={height || 200}
        externalRadius={eRadius || 90}
        internalRadius={iRadius || 70}
        highlightSliceById={ID}
        hasFixedHighlightedSlice={true}
      />
      <div className="pcpo-chart-legend">
        <Legend
          colorSchema={colorSchema || null}
          data={data}
          height={lHeight || 120}
          width={lWidth || 365}
          numberFormat={","}
          highlightEntryById={ID}
          margin={margin || null}
        />
        {projectGraphTotal(comma(calculateTotal(data)))}
      </div>
    </div>
  );
}

function projectGraphTotal(value) {
  return (
    <div className="pcpo-chart-total">
      <div className="pcpo-chart-total-label">
        <b>{translate("Total")}</b>
      </div>
      <div className="pcpo-chart-total-value">
        <b>{value}</b>
      </div>
    </div>
  );
}

function calculateTotal(selection) {
  let sum = 0;
  selection.forEach((item) => {
    sum += item.value;
  });
  return Math.round(sum);
}
