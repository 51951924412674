import React from "react";
import isEmpty from "../../validation/is-empty";
import CostVsBudget from "../charts/CostVsBudget";
import { translate } from "../../utils/translation";

import "./PDCPBar.css";

export default function PDCPOverallCostSummaryBar(props) {
  let secondValue = 0;
  let { budgetValue } = props;
  const { CP } = props;

  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return null;
  }

  secondValue = CP.calculated_total;

  secondValue = parseInt(secondValue);
  budgetValue = parseInt(budgetValue);
  let firstText = translate("Approved Project Budget");
  let secondText = translate("Anticipated Cost (Excl. Tax)");
  let thirdText = translate("Variance with Approved Budget");

  // Try to prove that the budget is approved, by default it is approved
  let isApproved = false;
  if (!isEmpty(CP.budgets)) {
    CP.budgets.forEach((budget) => {
      if (budget.is_approved) {
        isApproved = true;
      }
    });
  }

  return (
    <CostVsBudget
      secondValue={secondValue}
      firstValue={budgetValue}
      secondText={secondText}
      firstText={firstText}
      differenceText={thirdText}
      headroom={false}
      isApproved={isApproved}
    />
  );
}
