import React from "react";
import PropTypes from "prop-types";

import BenchmarkCostM2 from "../charts/BenchmarkCostM2";
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

/// props validation | SQ(javascript:S6774)
ProjectComparison.propTypes = {
  caller: PropTypes.string,
  skipHighlight: PropTypes.bool,
  unitOfMeasure: PropTypes.string,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  dashboard: PropTypes.bool,
  isEscalated: PropTypes.bool,
  futureYear: PropTypes.number,
};
///
// Cost Per M2
export default function ProjectComparison(props) {
  const { caller, skipHighlight } = props;

  const {
    unitOfMeasure,
    thisBenchmark,
    benchmarks,
    dashboard,
    isEscalated,
    futureYear,
  } = props;

  let field = [];
  let value = [];

  // Fill the graph arrays with the benchmark projects
  benchmarks.forEach((b) => {
    field.push(b.project_name_short);
    value.push(Math.round(b.displayed_construction_cost_rate));
  });

  // Calculate the average of the benchmarks
  let avg = average(value);
  let avgs = [];

  // Add the average to an array equal to the size of the graph
  value.forEach(() => {
    avgs.push(avg);
  });

  // Add the cost plan to the front of the graph
  let displayName = thisBenchmark.project_name;
  if (displayName.length > 12) {
    displayName = thisBenchmark.project_name.slice(0, 9) + "...";
  }

  if (caller === "COST_PLANNING") {
    displayName = tranStr("Cost Plan");
  }

  field.unshift(displayName);
  value.unshift(Math.round(thisBenchmark.displayed_construction_cost_rate));
  avgs.unshift(avg);

  if (isEmpty(benchmarks)) {
    return null;
  }

  if (dashboard) {
    return (
      <div className="pdcp-benchmark-comparison-graph">
        <BenchmarkCostM2
          caller={caller}
          skipHighlight={skipHighlight}
          unitOfMeasure={unitOfMeasure}
          field={field}
          value={value}
          avgs={avgs}
          dash={true}
        />
      </div>
    );
  } else {
    let titleText = "Benchmark Comparison";
    if (isEscalated) {
      titleText =
        tranStr("Benchmark Comparison - Escalated to Base Date of Estimate") +
        ` (${futureYear})`;
    }

    return (
      <div className="benchmarking-chart-box-costm2">
        {title(titleText)}
        <div className="benchmarking-chart-container">
          <BenchmarkCostM2
            caller={caller}
            skipHighlight={skipHighlight}
            unitOfMeasure={unitOfMeasure}
            field={field}
            value={value}
            avgs={avgs}
            dash={true}
          />
        </div>
      </div>
    );
  }
}

function average(arr) {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
}

function title(theTitle) {
  return (
    <h1
      className="display-4"
      style={{
        fontSize: "20px",
        marginBottom: "20px",
        marginTop: "10px",
        marginLeft: "10px",
      }}
    >
      {theTitle}
    </h1>
  );
}
