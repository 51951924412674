import React from "react";
import { IMPACT_RATING_SCALE } from "./OptionsAnalysisAPI.constants";

import "./OptionsAnalysisImpactRating.css";

function getImpactRatingDisplayText(impactRatingValue) {
  const impactRating =
    Object.values(IMPACT_RATING_SCALE)[+impactRatingValue] ||
    IMPACT_RATING_SCALE.UNDEFINED;

  const { display, value } = impactRating;
  return `${value} - ${display}`;
}

export default function OptionsAnalysisImpactRating(props) {
  const { value } = props;

  return (
    <div className={`impact-rating-cell impact-rating-${value}`}>
      {getImpactRatingDisplayText(value)}
    </div>
  );
}
