import isEmpty from "../../validation/is-empty";

export function designDevelopmentProgressionByStages(CP, CPs) {
  const currentStage = CP.stage;

  const designContingencies = [];
  const totals = [];
  const costChanges = [];
  const field = [];

  // Extract the dataset to analyse
  CPs.data.forEach((e) => {
    // When it's less than the current stage, get the last revision
    if (e.stage < currentStage) {
      let latestVersion = {};
      if (!isEmpty(e.versions)) {
        latestVersion = e.versions[0];
      }

      let thisRevision = "";
      if (!isEmpty(latestVersion.version)) {
        thisRevision = ", r. " + latestVersion.version;
      }

      field.push(e.stage_name + thisRevision);

      designContingencies.push(
        latestVersion.calculated_total_design_contingency
      );
      totals.push(latestVersion.calculated_total);
    }
    // When it's the current stage, get the currently selected revision
    if (e.stage === currentStage) {
      field.push(e.stage_name + ", r. " + CP.version);

      designContingencies.push(CP.calculated_total_design_contingency);
      totals.push(CP.calculated_total);
    }
  });

  // Cost Change (Z) is the difference between totals (Y) between A and B = Y
  // Minus the delta in contingency (X) between A and B = X
  // Y - X = Z
  for (let i = 0; i < totals.length; i++) {
    if (i === 0) {
      costChanges.push(0);
    } else {
      let contingencyDelta =
        designContingencies[i] - designContingencies[i - 1];
      let totalDelta = totals[i] - totals[i - 1];
      costChanges.push(totalDelta - contingencyDelta);
    }
  }

  return {
    designContingencies,
    totals,
    costChanges,
    field,
  };
}

export function designDevelopmentProgressionByRevisions(CP, CPs) {
  const designContingencies = [];
  const totals = [];
  const costChanges = [];
  const field = [];

  // Extract the dataset to analyse
  CPs.data.forEach((e) => {
    if (e.stage === CP.stage) {
      const versions = [...e.versions].reverse();

      versions.forEach((version) => {
        if (version.version <= CP.version) {
          // When it's less than the current stage, get the last revision
          field.push(version.stage_name + ", r. " + version.version);
          designContingencies.push(version.calculated_total_design_contingency);
          totals.push(version.calculated_total);
        }
      });
    }
  });

  // Cost Change (Z) is the difference between totals (Y) between A and B = Y
  // Minus the delta in contingency (X) between A and B = X
  // Y - X = Z
  for (let i = 0; i < totals.length; i++) {
    if (i === 0) {
      costChanges.push(0);
    } else {
      let contingencyDelta =
        designContingencies[i] - designContingencies[i - 1];
      let totalDelta = totals[i] - totals[i - 1];
      costChanges.push(totalDelta - contingencyDelta);
    }
  }

  return {
    designContingencies,
    totals,
    costChanges,
    field,
  };
}
