import isEmpty from "../../validation/is-empty";
import { OrderByDescending } from "../../helpers/common.functions";

export function generateChangeTypeData(variations) {
  let variationTotal = 0;
  const data = [];

  if (isEmpty(variations)) {
    return data;
  }

  variations.forEach((e) => {
    variationTotal += e.variation_value;
  });

  const changeTypes = Array.from(
    new Set(variations.map((v) => v.change_type))
  ).map((t) => {
    return { field: t, counts: 0, value: 0 };
  });

  changeTypes.forEach((changeType) => {
    variations.forEach((v) => {
      if (v.change_type === changeType.field) {
        changeType.value += v.variation_value;
        changeType.counts += 1;
      }
    });

    if (changeType.field.length === 0) {
      changeType.field = "Unallocated";
    }
  });

  changeTypes.sort(OrderByDescending("value"));

  changeTypes.forEach((o, i) => {
    if (o !== undefined) {
      if (o.value !== 0) {
        data.push({
          quantity: o.value.toFixed(0), // display value
          value: o.value, // value used for total calculation
          percentage: ((o.value / variationTotal) * 100).toFixed(1),
          name: o.field,
          counts: o.counts,
          id: i + 1,
        });
      }
    }
  });

  return data;
}
