import React, { useState } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { hasRoles } from "../../utils/roles";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import Spinner from "../common/Spinner";

export default function ReconciliationScopeDesignChangesDeleteButton(props) {
  const { estimateID } = props;
  const { projectID } = props;
  const { project } = props;
  const { CPs } = props;
  const { user } = props;
  const { reconciliationData } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (reconciliationData.reconciliationType === "RELEASE") {
    return null;
  }

  if (isDeleting) {
    return <Spinner width={"50px"} marginLeft={"15px"} />;
  }

  return (
    <button
      className="reconciliation-parts-delete-button"
      onClick={async () => {
        const confirm = window.confirm(
          `Confirm delete the Scope Design Changes table? This cannot be undone.`
        );

        if (!confirm) {
          return;
        }

        setIsDeleting(true);
        await onDelete(estimateID, projectID, CPs, project);
        setIsDeleting(false);
      }}
    >
      <i className="fas fa-trash"></i>
    </button>
  );
}

async function onDelete(estimateID, projectID, CPs, project) {
  // COST PLAN API
  const url = cpAPIs().delete_scope_design_changes;

  const payload = {
    estimateID: estimateID,
    projectID: projectID,
  };
  const config = {
    method: "DELETE",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Delete scope design changes Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    return console.log(erMessage);
  }
  let data = await CPs.get(projectID, project);
  // Set the new data with the getters and setters
  CPs.set(data);
}
