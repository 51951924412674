import React from "react";
import isEmpty from "../../validation/is-empty";

import ReconciliationToggle from "../reconciliation/ReconciliationToggle";
import { useReconciliationSwitch } from "./AreaSchedulesReconciliation.functions";

import CostPlanningClusteredBarChart from "../cost-planning-comparison/CostPlanningClusteredBarChart";
import CostPlanningDetailedComparisonTable from "../cost-planning-comparison/CostPlanningDetailedComparisonTable";

import "./AreaSchedulesReconciliation.css";

// Functions
import { translate } from "../../utils/translation";


export default function AreaSchedulesReconciliation(props) {
    const { project } = props;
    const { CP } = props;
    const { comparedCP, comparedCPR } = props;

    const { reconciliationData } = useReconciliationSwitch(
        CP,
        comparedCP,
        comparedCPR
    );

    return (
        <div className="area-schedule-reconciliation">
            <div className="area-schedule-recon-section-title ">
                <div className="recon-section-title">
                    <h1 className="display-4">{translate("Compare Area schedules Between")}</h1>
                    <ReconciliationToggle
                        state={reconciliationData.reconciliationType}
                        setState={reconciliationData.setReconciliationType}
                        on={"STAGE"}
                    />
                </div>
            </div>
            <AreaScheduleDetailedComparison
                e={reconciliationData.estimateDeltas}
                project={project}
                reconciliationData={reconciliationData}
            />
        </div>
    );

}

function AreaScheduleDetailedComparison(props) {
    const { e } = props;
    const { reconciliationData } = props;
    const { project } = props;

    if (isEmpty(reconciliationData.comparisonCP)) {
        return (
            <div className="estimate-reconciliation">{reconciliationData.error}</div>
        );
    }


    return (
        <div className="area-schedule-detailed-comparison">
            <div>
                <CostPlanningClusteredBarChart project={project} deltas={e[0]} />
            </div>
            <div>
                <CostPlanningDetailedComparisonTable
                    project={project}
                    e={e[0]}
                    reconciliationData={reconciliationData}
                />
            </div>
        </div>
    );

}


