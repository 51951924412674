// React
import React, { useState } from "react";

//Components
import SystemDashboardToggle from "./SystemDashboardToggle";
import { useSystemDashboardProject } from "../../api/projects/ProjectAPI";
import SystemDashboardCostPlanTable from "./SystemDashboardCostPlanTable";
import SystemDashboardCostReportTable from "./SystemDashboardCostReportTable";
import ExportSystemDashboard from "./ExportSystemDashboard";
import "./SystemDashboard.css";

// Functions
import { countReports } from "./SystemDashboard.functions";

export default function SystemDashboard() {
  const [inputMode, setInputMode] = useState("COSTPLAN");
  const { project, projectLoading } = useSystemDashboardProject();
  const { totalCostPlans, totalCostReports } = countReports(project);

  const [setSelected] = useState([]);

  if (projectLoading) {
    return <div>Loading Project Report data. This may take a while...</div>;
  }

  return (
    <div className="alc-container">
      {/* Toggle between CostPlan and costReport */}
      <SystemDashboardToggle
        inputMode={inputMode}
        setInputMode={setInputMode}
      />
      <div className="alc-column">
        {inputMode === "COSTPLAN" && (
          <div>
            <div className="toggle-button-text-container">
              <p>
                <b>Project Cost Plan</b>
              </p>
              <p>
                <b>Total Cost Plans: {totalCostPlans}</b>
              </p>
              <SystemDashboardCostPlanTable
                data={project}
                dataLoading={projectLoading}
                setSelectedUsers={setSelected}
              />
              <ExportSystemDashboard projects={project} inputMode={inputMode} />
            </div>
          </div>
        )}
        {inputMode === "COSTREPORT" && (
          <div>
            <div className="toggle-button-text-container">
              <p>
                <b>Project Cost Report</b>
              </p>
              <p>
                <b>Total Cost Reports: {totalCostReports}</b>
              </p>
              <SystemDashboardCostReportTable
                data={project}
                dataLoading={projectLoading}
                setSelectedUsers={setSelected}
              />
              <ExportSystemDashboard projects={project} inputMode={inputMode} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
