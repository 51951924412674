import React from "react";
import Profile from "./Profile";

import Modal from "../modal/Modal";

import "./ProfileModal.css";

export default function ProfileModal(props) {
  const { user, show, setShow, projectName } = props;
  const showHideClassName = show
    ? "modal profile-display-block"
    : "modal profile-display-none";

  return (
    <div className={showHideClassName}>
      <section className="profile-modal-main">
        <Modal
          // Modal Props
          title={"User Profile"}
          Component={Profile}
          modal={show}
          setModal={setShow}
          // Component Props
          setShow={setShow}
          show={show}
          user={user}
          projectName={projectName}
        />

        <div className="profile-modal-button-flexbox"></div>
      </section>
    </div>
  );
}
