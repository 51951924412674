import React, { useState } from "react";

import isEmpty from "../../validation/is-empty";
import { translate, tranStr } from "../../utils/translation";
import "./OptionsAnalysisUploader.css";

export default function OptionsAnalysisUploader(props) {
  const { data } = props;
  const { uploadFunction } = props;
  const { isOptionsAnalysis } = props;

  const [file, setFile] = useState(null);
  const [error, setError] = useState({});
  const [optionsStageId, setOptionsStageId] = useState("");

  const optionsStages = data?.options?.optionsAnalysisStages;

  data.file = file;
  data.setError = setError;
  data.error = error;

  const isStagesNotConfigured = isEmpty(optionsStages);
  const isStageNotSelected = isEmpty(optionsStageId);

  const isDisabled = isStagesNotConfigured;

  return (
    <div className="pdu-container">
      <div className="general-row-container">
        <OptionsAnalysisLink isOptionsAnalysis={isOptionsAnalysis} />
      </div>
      <div className="general-row-container">
        <OptionsStageSelector
          data={data}
          optionsStages={optionsStages}
          optionsStageId={optionsStageId}
          setOptionsStageId={setOptionsStageId}
        />
      </div>
      <div className="general-row-container">
        <UploadDisabledWarning
          isStagesNotConfigured={isStagesNotConfigured}
          isStageNotSelected={isStageNotSelected}
        />
      </div>
      <div className="general-row-container">
        <OptionsAnalysisUploaderFileTray file={file} isDisabled={isDisabled} />
      </div>
      <div className="general-row-container">
        <UploadOptionsAnalysisFile setFile={setFile} isDisabled={isDisabled} />
      </div>
      <div className="general-row-container">
        <OptionsAnalysisUploadDataError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <OptionsAnalysisUploadData
          data={data}
          uploadFunction={uploadFunction}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
}

function OptionsStageSelector(props) {
  const { optionsStageId, setOptionsStageId } = props;
  const { data } = props;
  const { optionsStages } = props;

  const handleOptionsStageChange = (e) => {
    const selectedOptionsStageId = e.target.value;
    setOptionsStageId(selectedOptionsStageId);
    data.stageId = selectedOptionsStageId;
  };

  return (
    <div className="options-analysis-upload-control-row">
      <div className="options-analysis-upload-control-label">
        {translate("Select Options Analysis Stage")}:
      </div>
      <select
        value={optionsStageId}
        className="options-analysis-upload-control-input"
        onChange={handleOptionsStageChange}
      >
        <option value="">-- Select Options Stage --</option>
        {!isEmpty(optionsStages) && (
          <OptionsStageSelect optionsStages={optionsStages} />
        )}
      </select>
    </div>
  );
}

function UploadOptionsAnalysisFile(props) {
  const { setFile } = props;
  const { isDisabled } = props;

  return (
    <label className={`general-modal-button ${isDisabled ? "disabled" : ""}`}>
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
      />
      {translate("Attach File")}
    </label>
  );
}

function OptionsStageSelect({ optionsStages }) {
  return optionsStages?.map(function (optionsStage) {
    if (optionsStage.code || optionsStage.name) {
      return (
        <option key={optionsStage.id} value={optionsStage.id}>
          ({optionsStage.code}) {optionsStage.name}
        </option>
      );
    } else {
      return null;
    }
  });
}

function UploadDisabledWarning(props) {
  const { isStagesNotConfigured } = props;

  return (
    <div className="upload-disabled-warning">
      {isStagesNotConfigured && (
        <>
          <div>
            <b>{tranStr("Please configure stages")}</b>
          </div>
          <span>{tranStr(`using Options Control > Configure Analyses`)}</span>
        </>
      )}
    </div>
  );
}

function OptionsAnalysisUploaderFileTray(props) {
  const { isDisabled } = props;
  if (isDisabled) {
    return null;
  }

  const { file } = props;

  let fileName = tranStr("No File Added");

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="pdu-file-display">
      <b>{translate("File")}:</b> {fileName}
    </div>
  );
}

function OptionsAnalysisUploadData(props) {
  const { data } = props;
  const { uploadFunction } = props;
  const { isDisabled } = props;

  return (
    <div
      className={`general-upload-button ${isDisabled ? "disabled" : ""}`}
      disabled={true}
      onClick={async () => {
        uploadFunction(data);
      }}
    >
      {translate("Upload")}
    </div>
  );
}

export function OptionsAnalysisUploadDataError(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

function OptionsAnalysisLink(props) {
  const { isOptionsAnalysis } = props;

  if (!isOptionsAnalysis) {
    return null;
  }

  return (
    <div className="cost-planning-upload-control-row">
      <div className="cost-planning-upload-control-label">
        {translate("Options Analysis Upload Template")}:
      </div>
      <a
        className="cost-planning-upload-document-button"
        href={
          "https://stcostclarityprd001.blob.core.windows.net/1-guides/Cost Clarity - Options Analysis Upload Template v0.2.xlsx"
        }
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {translate("Download")}
      </a>
    </div>
  );
}
