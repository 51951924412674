import { authHeader } from "../../_helpers/auth-header";

export async function postCommentary(d, URLs) {
  const data = d;

  let enteredTitle = data.title;

  if (data.showTitle) {
    enteredTitle = data.customTitle;
  }

  const costidfield =
    data.Module === "PostContract" ? "post_contract_id" : "cost_plan_id";
  let payload = JSON.stringify({
    id: data.commentary_id,
    project_id: data.project_id,
    [costidfield]: data.CostId,
    ...(data.Module !== "PostContract" && {
      stage: data.stage,
    }),
    ...(data.Module !== "PostContract" && {
      revision: data.revision,
    }),
    title: enteredTitle,
    text: data.text,
    part: data.part,
    type: "TEXT",
  });

  // COST PLAN API
  const url = URLs().commentary_edit;

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    data.CPs.set(await data.CPs.get(data.project_id, data.project));
    data.setShow(false);
  } else {
    // Response Not OK
    console.log("Network Error");
  }
}
