import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../modal/Modal";
import isEmpty from "../../validation/is-empty";

export async function onSubmit(data) {
  const { project } = data;
  const estimateMetrics = [];
  if (!isEmpty(data.CP.estimates)) {
    data.CP.estimates.forEach((estimate) => {
      const { benchmark } = estimate;
      const { metrics } = benchmark;
      const data = { estimate_id: estimate.id, metrics: metrics };
      estimateMetrics.push(data);
    });
  }
  console.log("estimateMetrics", estimateMetrics);
  const payload = {
    project_id: data.project_id,
    cost_plan_id: data.costPlanId,
    revision: data.revision, //new revision
    unit_of_measure: project.unit_of_measure,
    benchmark_metrics: estimateMetrics,
    // Project Data
    company_id: project.company_id,
    city: project.city,
    country: project.country,
    currency: project.currency,
    measurement_system: project.measurement_system,
    procurement: project.procurement,
    project_title: project.title,
    job_number: project.job_number,
    project_type: project.type,
    quality: project.quality,
    region: project.region,
    sector: project.sector,
    sub_sector: project.sub_sector,
    tpi_city: project.tpi_city,
  };

  data.setError({
    text: tranStr("Generating..."),
    type: "feedback-success",
  });

  let url = cpAPIs().create_duplicate;

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  try {
    let response = await fetch(url, config);
    if (response.ok) {
      response = await response.json();
      // Update Cost Plan
      let cp = await data.CPs.get(data.project_id, data.project);
      data.CPs.set(cp);
      data.setError({
        text: tranStr("Create Complete"),
        type: "feedback-success",
      });

      // close modal after timeout
      AutoCloseModal(data.setShow);

      return response;
    } else {
      console.log("Error in Create");
      data.setError({
        text: tranStr("Create Failed"),
        type: "feedback-error",
      });
    }
  } catch (e) {
    console.log("Error ", e);
    data.setError({
      text: tranStr("Create Failed"),
      type: "feedback-error",
    });
  }

  return [];
}
export function calculateCostPlan(CPs, stage, revision) {
  let stageData = CPs.data.filter((x) => x.stage === stage)[0];
  if (!isEmpty(stageData) && !isEmpty(stageData.versions)) {
    let CP = stageData.versions.filter((x) => x.version === revision - 1)[0];
    if (!isEmpty(CP)) {
      return CP.cost_plan_id;
    }
  }
}
