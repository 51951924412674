import React from "react";

import ReconciliationToggle from "../reconciliation/ReconciliationToggle";
import { useReconciliationSwitch } from "./ProjectBudgetReconciliation.functions";
import CostPlanningClusteredBarChart from "../cost-planning-comparison/CostPlanningClusteredBarChart";
import isEmpty from "../../validation/is-empty";

import "./ProjectBudgetReconciliation.css";

// Functions
import { translate } from "../../utils/translation";

export default function ProjectBudget(props) {
  const { project } = props;
  const { CP } = props;
  const { comparedCP, comparedCPR } = props;

  const { reconciliationData } = useReconciliationSwitch(
    CP,
    comparedCP,
    comparedCPR
  );

  return (
    <div className="project-budget-reconciliation">
      <div className="budget-recon-section-title">
        <div className="recon-section-title">
          <h1 className="display-4">{translate("Compare Budget Between")}</h1>
          <ReconciliationToggle
            state={reconciliationData.reconciliationType}
            setState={reconciliationData.setReconciliationType}
            on={"STAGE"}
          />
        </div>
      </div>
      <BudgetDetailedComparison
        e={reconciliationData.estimateDeltas}
        project={project}
        reconciliationData={reconciliationData}
      />
    </div>
  );
}

function BudgetDetailedComparison(props) {
  const { e } = props;
  const { reconciliationData } = props;
  const { project } = props;

  if (isEmpty(reconciliationData.comparisonCP)) {
    return (
      <div className="estimate-reconciliation">{reconciliationData.error}</div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="detailed-comparison">
        <div className="detailed-comparison-container">
          <CostPlanningClusteredBarChart project={project} deltas={e[0]} />
        </div>
      </div>
    </div>
  );
}
