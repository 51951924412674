import { authHeader } from "../../_helpers/auth-header";
import { AutoCloseModal } from "../../components/modal/Modal";
import { tranStr } from "../../utils/translation";

export async function saveCostPlanDecisions(fields, data, url) {
  const { CP } = data;
  const { CPs } = data;
  const { projectID } = data;
  const { project } = data;
  const { type } = data;

  const { setError, setModal } = data;

  fields.forEach((f) => {
    f.stage = CP.stage;
    f.revision = CP.version;
    f.type = type;
    delete f.id;
  });

  const payload = JSON.stringify({
    decisions: fields,
    projectID: projectID,
    stage: CP.stage,
    revision: CP.version,
    cost_plan_id: CP.cost_plan_id,
    type: type,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  setError({
    text: tranStr("Saving..."),
    type: "project-stages-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    CPs.set(await CPs.get(projectID, project));

    setError({
      text: tranStr("Saved Successfully"),
      type: "project-stages-success",
    });

    AutoCloseModal(setModal);
  } else {
    // Response Not OK
    setError({
      text: tranStr("Save Failed"),
      type: "project-stages-error",
    });
  }
}
