import { getInsights } from "../../api/general/MarketInsightsAPI";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../../api/api-endpoints/SharedServiceEndpoints";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";

export async function deleteArticle(articleID, categoryID, setInsights) {
  const url = sharedAPIs().delete_article;
  const payload = { MarketInsightId: categoryID, InsightArticleId: articleID };

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };
  let erMessage = "Delete article Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    return console.log(erMessage);
  }

  setInsights(await getInsights());
  window.location.href = "/insights/category/" + categoryID + "/articles";
}

export async function editArticle(data, setFunctions) {
  let title = data.title;
  let articleDate = data.articleDate;
  let articleType = data.articleType;
  let selectedArticle = data.selectedArticle;
  let docLink = data.docLink;

  if (isEmpty(title)) {
    return setFunctions.setError({
      text: "Please complete Title",
      type: "feedback-error",
    });
  }

  if (articleType === "link") {
    if (isEmpty(docLink)) {
      return setFunctions.setError({
        text: "Please enter document link",
        type: "feedback-error",
      });
    }
  }

  //Check if the date is valid
  if (articleDate === null) {
    return setFunctions.setError({
      text: "Invalid Date",
      type: "feedback-error",
    });
  } else {
    setFunctions.setError({
      text: "Submitting...",
      type: "feedback-success",
    });
  }

  let splitDate = articleDate.split("/");
  let convertedDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
  let timeStamp = convertedDate.getTime();

  let field = JSON.stringify({
    id: selectedArticle.id,
    topicID: selectedArticle.market_insight_id,
    title: title,
    articleType: articleType,
    docLink: docLink,
    timestamp: timeStamp,
    region: data.region,
    country: data.country,
    global: data.global,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("image", data.image);
  formData.append("field", field);

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = sharedAPIs().update_article;
  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    setFunctions.setFilePreview(null);
    setFunctions.setInsights(await getInsights());
    setFunctions.setError({
      text: "Updated Successfully",
      type: "feedback-success",
    });
    setFunctions.setShow(false);
  } else {
    if (response.status === 415) {
      setFunctions.setError({
        text: "Invalid File Type or URL",
        type: "feedback-error",
      });
    } else {
      setFunctions.setError({
        text: "Connection Error",
        type: "feedback-error",
      });
    }
  }
}
export async function editCategory(
  image,
  form,
  setError,
  setShow,
  selectedTopic,
  setInsights
) {
  if (isEmpty(form)) {
    return setError({
      text: "Please complete Title",
      type: "feedback-error",
    });
  } else {
    setError({
      text: "Updating...",
      type: "feedback-success",
    });
  }

  const formData = new FormData();
  formData.append("image", image);
  formData.append("field", form);
  formData.append("category", selectedTopic.id);

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = sharedAPIs().update_insight;
  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    setInsights(await getInsights());
    setError({
      text: "Created Successfully",
      type: "feedback-success",
    });
    setShow(false);
  } else {
    return setError({
      text: "Connection Error",
      type: "feedback-error",
    });
  }
}
export async function deleteCategory(categoryID, setInsights) {
  const url = sharedAPIs().delete_category;
  const payload = categoryID;

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let erMessage = "Delete category Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    return console.log(erMessage);
  }

  setInsights(await getInsights());
  window.location.href = "/insights/categories";
}
export async function uploadInsight(
  image,
  form,
  setError,
  setShow,
  setInsights
) {
  if (isEmpty(form)) {
    return setError({
      text: "Please complete Title",
      type: "feedback-error",
    });
  }

  if (image === null) {
    return setError({
      text: "Please upload image",
      type: "feedback-error",
    });
  } else {
    setError({
      text: "Creating...",
      type: "feedback-success",
    });
  }

  const formData = new FormData();
  formData.append("image", image);
  formData.append("field", form);

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = sharedAPIs().upload_insight;

  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    setInsights(await getInsights());
    setError({
      text: "Created Successfully",
      type: "feedback-success",
    });
    await AutoCloseModal(setShow);
  } else {
    try {
      response = await response.json();
      return setError({
        text: response.message,
        type: "feedback-error",
      });
    } catch (e) {
      return setError({
        text: "Connection Error",
        type: "feedback-error",
      });
    }
  }
}

export async function uploadArticle(data) {
  const {
    file,
    image,
    title,
    articleType,
    docLink,
    selectedTopic,
    user,
    setError,
    setInsights,
    setShow,
    region,
    country,
    global,
  } = data;

  if (isEmpty(title)) {
    return setError({
      text: "Please complete Title",
      type: "feedback-error",
    });
  }

  if (image === null) {
    return setError({
      text: "Please upload image",
      type: "feedback-error",
    });
  }
  if (articleType === "file") {
    if (file === null) {
      return setError({
        text: "Please upload file",
        type: "feedback-error",
      });
    }
  }
  if (articleType === "link") {
    if (isEmpty(docLink)) {
      return setError({
        text: "Please enter document link",
        type: "feedback-error",
      });
    }
  }
  setError({
    text: "Creating...",
    type: "feedback-success",
  });

  let field = JSON.stringify({
    title: title,
    topicID: selectedTopic.id,
    articleType: articleType,
    docLink: docLink,
    author_name: user.name,
    author_surname: user.surname,
    author_email: user.email,
    author_title: user.jobTitle,
    region: region,
    country: country,
    timestamp: Date.now(),
    global: global,
  });

  const formData = new FormData();
  formData.append("file", file);
  formData.append("image", image);
  formData.append("field", field);

  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const url = sharedAPIs().upload_article;
  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    setInsights(await getInsights());
    setError({
      text: "Created Successfully",
      type: "feedback-success",
    });
    await AutoCloseModal(setShow);
  } else {
    try {
      response = await response.json();
      if (response.status === 415) {
        return setError({
          text: "Invalid URL",
          type: "feedback-error",
        });
      }
      return setError({
        text: response.message,
        type: "feedback-error",
      });
    } catch (e) {
      return setError({
        text: "Connection Error",
        type: "feedback-error",
      });
    }
  }
}

export async function upsert_insights_read(data) {
  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }
  let dataObj = {
    userId: data.userId,
    articleId: data.articleId,
  };

  const url = sharedAPIs().upsert_insights_read;
  const config = {
    method: "POST",
    body: JSON.stringify(dataObj),
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = await fetch(url, config);
  if (response.ok) {
    return await response.json();
  }
}

// Use here until we can combine with Nagaraj's global solution
const MODAL_AUTO_CLOSE_TIMEOUT = 2000;

async function AutoCloseModal(setModal) {
  setTimeout(() => setModal(false), MODAL_AUTO_CLOSE_TIMEOUT);
}
