// React
import React, { useState } from "react";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import Spinner from "../common/Spinner";

export default function PostContractControlDelete(props) {
  const { postContractID } = props;
  const { postContract } = props;
  const { projectID } = props;
  const { variations, costReport } = props;
  const { label } = props;
  const { showLocation } = props;
  const { isVariations, isCostReport } = props;
  const { onDelete } = props;

  const [loading, setLoading] = useState(false);

  if (!window.location.href.includes(showLocation)) {
    return null;
  }

  if (isEmpty(postContractID)) {
    return null;
  }

  // If displayed on variations page, only display if it is a variation update
  if (isVariations) {
    if (
      !(
        variations.cost_report_issued === false &&
        variations.progress_claim_issued === false
      )
    ) {
      return null;
    }
  }

  // Only Display delete for cost report if it is an actual issued cost report
  if (isCostReport) {
    if (!costReport.cost_report_issued) {
      return null;
    }
  }

  const data = {
    postContractID: postContractID,
    postContract: postContract,
    projectID: projectID,
  };

  return (
    <DeleteButton
      data={data}
      label={label}
      loading={loading}
      setLoading={setLoading}
      onDelete={onDelete}
    />
  );
}

function DeleteButton(props) {
  const { loading, setLoading } = props;
  const { onDelete } = props;
  const { label } = props;
  const { data } = props;

  if (loading) {
    return <Spinner width={50}></Spinner>;
  } else {
    return (
      <div
        className="post-contract-control-button-delete"
        onClick={() => {
          setLoading(true);
          onDelete(data).then(() => {
            setLoading(false);
          });
        }}
      >
        {translate(label)}
      </div>
    );
  }
}
