import React, { useState } from "react";
import { deleteEstimate } from "../../api/estimates/EstimateAPI";
import Spinner from "../common/Spinner";
import { hasRoles } from "../../utils/roles";

export default function DeleteEstimateButton(props) {
  const { project } = props;
  const { CPs } = props;
  const { estimate_id, project_id } = props;
  const { user } = props;
  const { estimateName } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (isDeleting) {
    return <Spinner width={"50px"} marginLeft={"15px"} />;
  }

  return (
    <button
      className="estimate-delete-button"
      id={"estimate-delete-button"}
      onClick={async () => {
        const confirm = window.confirm(
          `Confirm delete ${estimateName}? This cannot be undone.`
        );

        if (!confirm) {
          return;
        }

        setIsDeleting(true);
        await deleteEstimate(estimate_id, project_id);
        let data = await CPs.get(project.id, project);

        // Set the new data with the getters and setters
        CPs.set(data);
        setIsDeleting(false);
      }}
    >
      <i className="far fa-trash-alt"></i>
    </button>
  );
}
