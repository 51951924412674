import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { bmAPIs } from "../api-endpoints/BenchmarkingEndpoints";

export function useCbs(selectedCbs) {
  const [loading, setLoading] = useState(true);
  const [cbs, setCbs] = useState({});

  useEffect(() => {
    setLoading(true);
    getCbs(selectedCbs)
      .then((res) => {
        setCbs(res);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error");
        setLoading(false);
      });
  }, [selectedCbs]);

  return {
    cbs: {
      data: cbs,
      loading: loading,
      set: setCbs,
      get: getCbs,
    },
  };
}

async function getCbs(selectedCbs) {
  const url = bmAPIs().get_cbs;
  const config = {
    method: "POST",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    body: JSON.stringify([selectedCbs]),
  };

  let erMessage = "Get CBS error";
  let response = await fetch(url, config);

  if (response.ok) {
    const cbsList = await response.json();
    if (cbsList.data.length > 0) {
      response = cbsList.data[0];
    } else {
      response = {};
    }
  } else {
    response = {};
    console.log(erMessage);
  }
  return response;
}

export function useCbsCodes(isNotPublished) {
  const [loading, setLoading] = useState(true);
  const [CBS, setCBS] = useState({});

  useEffect(() => {
    setLoading(true);
    getCbsCodes(isNotPublished)
      .then((res) => {
        setCBS(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [isNotPublished]);

  return {
    cbsCodes: {
      data: CBS.data,
      loading: loading,
      set: setCBS,
      get: getCbsCodes,
    },
  };
}

async function getCbsCodes(isNotPublished) {
  const url = bmAPIs().get_cbs_type_codes + "?isNotPublished=" + isNotPublished;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return CBS;
  } else {
    response = {};
  }
  return response;
}

export async function createCbs(cbsName) {
  const url = bmAPIs().create_cbs + "?typeCode=" + cbsName;

  const config = {
    method: "POST",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();
    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function updateCbsPublish(cbsId, isPublished) {
  const url =
    bmAPIs().update_cbs_publish +
    "?cbsId=" +
    cbsId +
    "&isPublished=" +
    isPublished;

  const config = {
    method: "PUT",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();
    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function deleteCbs(payload) {
  const url = bmAPIs().delete_cbs;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    body: JSON.stringify(payload),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();
    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

// Elements

export async function addElement(cbsId, code, description, unit) {
  const url =
    bmAPIs().add_element +
    "?cbsId=" +
    cbsId +
    "&elementCode=" +
    code +
    "&elementDescription=" +
    description +
    "&elementUnit=" +
    unit;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();
    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function editElement(cbsId, elementId, code, description, unit) {
  const url =
    bmAPIs().edit_element +
    "?cbsId=" +
    cbsId +
    "&elementId=" +
    elementId +
    "&elementCode=" +
    code +
    "&elementDescription=" +
    description +
    "&elementUnit=" +
    unit;

  const config = {
    method: "PUT",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function moveElement(cbsId, elementId, moveIndex) {
  const url =
    bmAPIs().move_element +
    "?cbsId=" +
    cbsId +
    "&elementId=" +
    elementId +
    "&moveIndex=" +
    moveIndex;

  const config = {
    method: "PUT",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function deleteElement(cbsId, elementId) {
  const url =
    bmAPIs().delete_element + "?cbsId=" + cbsId + "&elementId=" + elementId;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

// Category Items

export async function addCategoryItem(cbsId, categoryId) {
  const url =
    bmAPIs().add_category_item +
    "?cbsId=" +
    cbsId +
    "&categoryId=" +
    categoryId;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function editCategoryItem(
  cbsId,
  categoryId,
  categoryItemId,
  categoryItemDescription,
  categoryItemCodesAdded,
  categoryItemCodesRemoved
) {
  const url = bmAPIs().edit_category_item;

  const payload = {
    cbsId: cbsId,
    categoryId: categoryId,
    categoryItemId: categoryItemId,
    description: categoryItemDescription,
    codesAdded: categoryItemCodesAdded,
    codesRemoved: categoryItemCodesRemoved,
  };

  const config = {
    method: "PUT",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
    body: JSON.stringify(payload),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}

export async function deleteCategoryItem(cbsId, categoryId, categoryItemId) {
  const url =
    bmAPIs().delete_category_item +
    "?cbsId=" +
    cbsId +
    "&categoryId=" +
    categoryId +
    "&categoryItemId=" +
    categoryItemId;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    const CBS = await response.json();

    return {
      success: true,
      message: CBS.message,
    };
  } else {
    const CBS = await response.json();
    return {
      success: false,
      message: CBS.message,
    };
  }
}
