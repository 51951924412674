import React, { useState } from "react";
import UploadChanges from "./UploadChanges";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

import "./ModalChangesUploader.css";

export default function ModalChangesUploader(props) {
  const { CPs } = props;
  const { project_id } = props;
  const { project } = props;
  const { estimate_id } = props;
  const { user } = props;
  const { reconciliationData } = props;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (reconciliationData.reconciliationType === "RELEASE") {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={translate("Upload Scope Design Changes")}
        Component={UploadChanges}
        modal={modal}
        setModal={setModal}
        // Component Props
        projectID={project_id}
        project={project}
        estimateID={estimate_id}
        CPs={CPs}
        setShow={setModal}
      />
      <button
        className="modalchangesuploader-popup-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-upload"></i>
      </button>
    </div>
  );
}
