import React, { useState, useEffect } from "react";
import { comma } from "../../helpers/common.functions";
import { translate, tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { saveCostPlanBudget } from "../../api/cost-planning/ProjectBudgetAPI";
import { DisplayError } from "../project-control/ProjectControl";

import "../dashboard-components/AdviceDecisionsEdit.css";
import "./ProjectBudgetConfiguration.css";

export default function ProjectBudgetConfiguration(props) {
  const { data } = props;
  const { setModal } = props;

  const [error, setError] = useState({});

  let fieldTypes = {
    field1: "budget-description",
    field2: "budget-data",
    field3: "budget-approval",
  };
  let labels = {
    label1: tranStr("Budget Description"),
    label2: tranStr("Budget Value"),
    label3: tranStr("Approved"),
  };

  let total = 0;
  let client_approved = false;
  let budgets = null;

  if (!isEmpty(data.CP.budgets)) {
    // If budget items exist then use them
    budgets = data.CP.budgets;
  } else if (
    data.comparedCPR !== undefined &&
    !isEmpty(data.comparedCPR.budgets)
  ) {
    // If budget items don't exist we can use the previous CP Release budget items
    // The previous CP release is always comparedCPR
    budgets = data.comparedCPR.budgets;
  } else if (
    data.comparedCP !== undefined &&
    !isEmpty(data.comparedCP.budgets)
  ) {
    // If previous CPR doesn't have budgets, the last CP from previous CP stage is always comparedCP
    budgets = data.comparedCP.budgets;
  }

  const [fields, setFields] = useState([
    {
      description: "",
      budget: "",
      is_approved: false,
      CostAllocations: [],
    },
  ]);

  if (!isEmpty(fields)) {
    let approved = true;
    fields.forEach((x) => {
      if (!isEmpty(x.budget)) {
        //calculate total budget
        total += parseFloat(x.budget);
        //client approved is tru only if all the budget items are approved
        approved = approved && x.is_approved;
      }
    });
    client_approved = approved;
  }
  useEffect(() => {
    if (!isEmpty(budgets)) {
      let filtered = budgets.map((d) => {
        return {
          description: d.description,
          budget: d.budget,
          is_approved: !isEmpty(d.is_approved) ? d.is_approved : false,
          CostAllocations: d.CostAllocations,
        };
      });

      if (filtered.length > 0) {
        setFields(filtered);
      }
    }
  }, [budgets]);
  return (
    <div className="project-control-advice">
      <div className="advice-title">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {`Add budget breakdown items below. Click Add Field to add more budget items.`}
        </div>
      </div>
      <FieldHeadings labels={labels} fieldTypes={fieldTypes} />
      <div className="advice-decisions-field-container">
        <DecisionFields
          fields={fields}
          setFields={setFields}
          fieldTypes={fieldTypes}
        />
      </div>

      {/* Total */}
      <div className="budget-total-container">
        <div className={fieldTypes.field1}>
          <b>Total</b>
        </div>
        <div className="budget-total-data">
          <b>{!isEmpty(total) ? comma(total) : 0}</b>
        </div>

        <input
          className={"budget-total-approval"}
          type="checkbox"
          checked={client_approved}
          onChange={(e) => {
            fields.forEach((_, i) => {
              updateField("is_approved", e.target.checked, i, fields);
            });
          }}
          title="Client approved"
        />
      </div>
      <div className="general-row-container">
        <AddField fields={fields} setFields={setFields} />
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <SaveButton
          data={data}
          fields={fields}
          setError={setError}
          setModal={setModal}
        />
      </div>
    </div>
  );
}

function AddField(props) {
  const { fields } = props;
  const { setFields } = props;

  return (
    <div
      className="general-modal-button"
      onClick={() => {
        let temp = [...fields];

        temp.push({
          description: "",
          budget: "",
          is_approved: false,
        });
        setFields(temp);
      }}
    >
      {translate("Add Field")}
    </div>
  );
}

function SaveButton(props) {
  const { data } = props;
  const { fields } = props;
  const { setError } = props;
  const { setModal } = props;

  return (
    <div
      className="general-upload-button"
      onClick={() => {
        data.setError = setError;
        data.setModal = setModal;
        saveCostPlanBudget(fields, data);
      }}
    >
      {translate("Save")}
    </div>
  );
}

function FieldHeadings(props) {
  const { labels } = props;
  const { fieldTypes } = props;

  return (
    <div className="advice-field-container">
      <div className={fieldTypes.field1}>
        <b>{labels.label1}</b>
      </div>

      <div className="budget-heading-value">
        <b>{labels.label2}</b>
      </div>
      <div className="budget-heading-approval">
        <b>{labels.label3}</b>
      </div>
      <div className={fieldTypes.field3}>
        <div className="advice-delete-spacer"></div>
      </div>
    </div>
  );
}
function DecisionFields(props) {
  const { fields } = props;
  const { setFields } = props;
  const { fieldTypes } = props;

  return fields.map((f, i) => {
    return (
      <div key={i} className="advice-field-container">
        <input
          className={fieldTypes.field1}
          value={f.description}
          maxLength={90}
          onChange={(e) => {
            updateField("description", e.target.value, i, fields, setFields);
          }}
        />

        <input
          className={fieldTypes.field2}
          type="number"
          value={f.budget}
          maxLength={90}
          onChange={(e) => {
            updateField("budget", e.target.value, i, fields, setFields);
          }}
        />
        <input
          className={fieldTypes.field3}
          type="checkbox"
          checked={f.is_approved}
          onChange={(e) => {
            updateField("is_approved", e.target.checked, i, fields, setFields);
          }}
        />
        <div
          className="advice-delete-button"
          onClick={() => deleteField(i, fields, setFields)}
        >
          <i className="far fa-trash-alt" />
        </div>
      </div>
    );
  });
}

function updateField(field, value, i, array, setFields) {
  let temp = [...array];
  temp[i][field] = value;

  setFields(temp);
}

function deleteField(i, array, setFields) {
  let temp = [...array];

  temp.splice(i, 1);
  setFields(temp);
}
