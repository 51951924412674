import React, { useState, useRef, useEffect } from "react";
import { renderToString } from "react-dom/server";
import {
  mapOrder,
  findDraggedColumn,
  updateColumns,
} from "./Dashboard.functions";

import DashboardDrawer from "./DashboardDrawer";
import DashboardEditor from "../dashboard/DashboardEditor";

import "./Dashboard.css";
import isEmpty from "../../validation/is-empty";

export default function Dashboard(props) {
  const { display } = props;
  const { column1Set, column2Set, column3Set, column4Set } = props;
  const { showDashboard, setShowDashboard } = props;
  const { project, dashboardType } = props;
  const { dashboardSettings } = props;

  // The DashboardID being dragged
  const dragID = useRef();

  // Default Columns
  const [column1, setColumn1] = useState(column1Set);
  const [column2, setColumn2] = useState(column2Set);
  const [column3, setColumn3] = useState(column3Set);

  // Apply Saved Settings
  useEffect(() => {
    let columnSet = {};
    dashboardSettings.data.forEach((setting) => {
      if (setting.dashboard_type === dashboardType) {
        columnSet = setting.layout;
      }
    });
    // If there are settings available
    if (!isEmpty(columnSet)) {
      setColumn1(columnSet.column1);
      setColumn2(columnSet.column2);
      setColumn3(columnSet.column3);
    }
  }, [dashboardType, dashboardSettings.data]);

  const columns = {
    column1: column1,
    column2: column2,
    column3: column3,
    setColumn1: setColumn1,
    setColumn2: setColumn2,
    setColumn3: setColumn3,
  };

  return (
    <div className="dashboard">
      <Column
        display={display}
        column={column1}
        dragID={dragID}
        columnID={"column1"}
        columns={columns}
        setColumn={setColumn1}
      />
      <Column
        display={display}
        column={column2}
        dragID={dragID}
        columnID={"column2"}
        columns={columns}
        setColumn={setColumn2}
      />
      <Column
        display={display}
        column={column3}
        dragID={dragID}
        columnID={"column3"}
        columns={columns}
        setColumn={setColumn3}
      />
      <DashboardDrawer
        Component={DashboardEditor}
        dragID={dragID}
        display={display}
        columns={columns}
        column={column4Set}
        show={showDashboard}
        setShow={setShowDashboard}
        project={project}
        dashboardType={dashboardType}
        dashboardSettings={dashboardSettings}
      />
    </div>
  );
}

export function Column(props) {
  const { display, column } = props;
  const { columns, setColumn } = props;
  const { dragID } = props;

  // Determine the content to be displayed
  let components = mapOrder(display, column, "dashboardID");

  return (
    <div className={"dashboard-column"}>
      <Slot
        top={true}
        dragID={dragID}
        columns={columns}
        setColumn={setColumn}
      />
      {components.map((component, i) => {
        const { dashboardID } = component.props;
        const { title } = component.props;

        // Do not render empty components
        let stringElement = renderToString(component);
        if (stringElement === "") {
          return null;
        }

        return (
          <div key={i}>
            <DashboardPanel
              dragID={dragID}
              dashboardID={dashboardID}
              title={title}
              component={component}
            />
            <Slot
              top={false}
              dragID={dragID}
              columns={columns}
              setColumn={setColumn}
              dashboardID={dashboardID}
            />
          </div>
        );
      })}
    </div>
  );
}

function DashboardPanel(props) {
  const { dragID, dashboardID, title, component } = props;

  let panelType = "dashboard-panel";
  let titleType = "dashboard-title";
  if (dashboardID === "IMAGE" || dashboardID === "MAP") {
    panelType = "dashboard-panel-hidden-title";
    titleType = "dashboard-title-hidden";
  }

  return (
    <div className={panelType}>
      <Title
        dragID={dragID}
        dashboardID={dashboardID}
        title={title}
        titleType={titleType}
      />
      {component}
    </div>
  );
}

function Title(props) {
  let { title } = props;
  const { dragID, dashboardID } = props;
  const { titleType } = props;

  if (dashboardID === "IMAGE" || dashboardID === "MAP") {
    title = "";
  }

  return (
    <div
      className={titleType}
      draggable="true"
      onDragStart={() => {
        dragID.current = dashboardID;
      }}
    >
      <h1 className="display-4">
        <b>{title}</b>
      </h1>
    </div>
  );
}

function Slot(props) {
  const { columns, setColumn, top, dragID } = props;
  const { dashboardID } = props;
  const [highlight, setHighlight] = useState(false);

  let type = "dashboard-slot";
  if (highlight) {
    type = "dashboard-slot-highlight";
  }

  return (
    <div
      className={type}
      onDrop={(e) => {
        e.preventDefault();
        let remove = findDraggedColumn(columns, dragID);
        updateColumns(setColumn, remove, dragID, top, dashboardID);
        setHighlight(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setHighlight(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setHighlight(false);
      }}
    ></div>
  );
}
