import { useState, useEffect } from "react";
import { getAllProjects, getProject } from "../projects/ProjectAPI";

export function useProjects() {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    setLoading(true);
    getAllProjects()
      .then((projectsRes) => {
        setProjects(projectsRes);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    projects: {
      data: projects,
      loading: loading,
      get: getAllProjects,
      set: setProjects,
    },
  };
}

export function useAdminProject(projectID, show) {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});
  useEffect(() => {
    if (show) {
      setLoading(true);
      getProject(projectID)
        .then((projectRes) => {
          setProject(projectRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [projectID, show]);

  return {
    project: {
      data: project,
      loading: loading,
      get: getProject,
      set: setProject,
    },
  };
}
