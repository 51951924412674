import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";

import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../modal/Modal";

export async function onSend(breakdown, CPs, project, setError) {
  const url = cpAPIs().save_breakdown_comment;
  let user = JSON.parse(localStorage.user);

  const field = JSON.stringify({
    estimate_id: breakdown.estimate_id,
    breakdown_id: breakdown.id,
    text: breakdown.comment,
    author_id: user.id,
    project_id: breakdown.projectID,
  });

  const config = {
    method: "POST",
    body: field,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  if (breakdown.comment === "") {
    return setError({
      text: tranStr("Comment cannot be blank"),
      type: "ebc-error",
    });
  }

  setError({
    text: tranStr("Sending..."),
    type: "ebc-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    setError({
      text: tranStr("Send Failed"),
      type: "ebc-error",
    });
    return console.log("Network response was not ok.");
  }

  // Update to the latest data
  let data = await CPs.get(project.id, project);
  // Set the new data with the getters and setters
  CPs.set(data);

  setError({
    text: tranStr("Sent"),
    type: "ebc-success",
  });

  // PROJECT UPDATE
  let projectData = await project.get(project.id);
  project.set({
    ...projectData,
    set: project.set,
    get: project.get,
  });
}

export async function onDelete(commentID, CPs, project, breakdown) {
  const url = cpAPIs().delete_breakdown_comment;
  const field = JSON.stringify({
    commentID: commentID,
    projectID: breakdown.projectID,
    breakdownID: breakdown.id,
  });

  const config = {
    method: "POST",
    body: field,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  breakdown.setError({
    text: tranStr("Deleting..."),
    type: "ebc-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    breakdown.setError({
      text: tranStr("Delete Failed"),
      type: "ebc-error",
    });
    return console.log("Network response was not ok.");
  }

  // COST PLAN UPDATE
  let data = await CPs.get(breakdown.projectID, project);
  CPs.set(data);

  breakdown.setError({
    text: tranStr("Deleted"),
    type: "ebc-success",
  });

  // PROJECT UPDATE
  let projectData = await project.get(project.id);
  project.set({
    ...projectData,
    set: project.set,
    get: project.get,
  });
}

export async function onAction(breakdown, CPs, project) {
  const url = cpAPIs().action_breakdown_comment;
  const field = JSON.stringify({
    projectID: breakdown.projectID,
    breakdownID: breakdown.id,
  });

  const config = {
    method: "POST",
    body: field,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  breakdown.setError({
    text: tranStr("Closing Out..."),
    type: "ebc-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    breakdown.setError({
      text: tranStr("Closing Out Failed"),
      type: "ebc-error",
    });
    return console.log("Network response was not ok.");
  }

  // COST PLAN UPDATE
  let data = await CPs.get(breakdown.projectID, project);
  CPs.set(data);

  breakdown.setError({
    text: tranStr("Closed Out"),
    type: "ebc-success",
  });

  AutoCloseModal(breakdown.setModal);

  // PROJECT UPDATE
  let projectData = await project.get(project.id);
  project.set({
    ...projectData,
    set: project.set,
    get: project.get,
  });
}

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}
