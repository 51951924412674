import React from "react";
import PropTypes from "prop-types";
import generateXLSX from "../../utils/spreadsheets";
import { getRoleBasedColumns } from "./BenchmarkControlTable.functions";
import { tranStr } from "../../utils/translation";

ExportBenchmarkDashboard.propTypes = {
  benchmarks: PropTypes.array,
  user: PropTypes.object,
};

// API
export default function ExportBenchmarkDashboard(props) {
  const { benchmarks } = props;
  const { user } = props;

  let benchmarkColumns = getRoleBasedColumns(user).filter(
    (x) => x.type !== "CHECK"
  );

  const headings = benchmarkColumns.map((header) => header.heading);
  const keys = benchmarkColumns.map((header) => header.key);
  const data = [];

  benchmarks.forEach((bm) => {
    data.push(keys.map((key) => bm[key]));
  });

  data.unshift(headings);
  return (
    <div className="general-row-container">
      <input
        type="button"
        className="general-upload-button custom-export-all"
        value={tranStr("Export All")}
        onClick={() => {
          generateXLSX(tranStr("Benchmark Projects Details"), data);
        }}
        title="Exports all Benchmarks"
      />
    </div>
  );
}
