// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview

export const b2cPoliciesPrd = {
  names: {
    signUpSignIn: "B2C_1A_GLOBAL_signup_signin",
    forgotPassword: "B2C_1A_PasswordReset",
  },
  clientId: "6ed49b5e-7736-4226-9792-5bcf7605d5b6",
  authorities: {
    knownAuthorities:
      "https://arcadisb2c.b2clogin.com/ArcadisB2C.onmicrosoft.com",
    signUpSignIn: {
      authority:
        "https://arcadisb2c.b2clogin.com/ArcadisB2C.onmicrosoft.com/B2C_1A_GLOBAL_signup_signin",
    },
    forgotPassword: {
      authority:
        "https://arcadisb2c.b2clogin.com/ArcadisB2C.onmicrosoft.com/B2C_1A_PasswordReset",
    },
  },
};

export const b2cPoliciesDev = {
  names: {
    signUpSignIn: "B2C_1A_GLOBAL_signup_signin",
    forgotPassword: "B2C_1A_PasswordReset",
  },
  clientId: "efa6b885-299b-4b69-9ef6-759ad2eabf55",
  authorities: {
    knownAuthorities:
      "https://arcadisb2cdev.b2clogin.com/ArcadisB2CDEV.onmicrosoft.com",
    signUpSignIn: {
      authority:
        "https://arcadisb2cdev.b2clogin.com/ArcadisB2CDEV.onmicrosoft.com/B2C_1A_GLOBAL_signup_signin",
    },
    forgotPassword: {
      authority:
        "https://arcadisb2cdev.b2clogin.com/ArcadisB2CDEV.onmicrosoft.com/B2C_1A_PasswordReset",
    },
  },
};
