import React from "react";
import Spinner from "../../common/Spinner";
import { Bar } from "react-chartjs-2";
import Card from "./ChartCard";
import { getCSSColorPalette } from "../../../actions/cssColors";
import {
  roundWithPrecision,
  minNumber,
  maxNumber,
} from "../../../utils/maths.functions";
import { comma } from "../../../helpers/common.functions";

export default function OptioneeringBaselineBarChart(props) {
  const { optionsAnalysisItems, labelClassName } = props;
  const { baseline } = props;
  const { CPs } = props;

  const lineData = [];
  const percentageChangeData = [];

  // Calculate percentage change for each item
  optionsAnalysisItems.forEach((item) => {
    const costImpact = parseFloat(item.costImpact);
    const percentageChange = ((baseline + costImpact) / baseline) * 100;
    percentageChangeData.push(roundWithPrecision(percentageChange, 1));
    lineData.push(100);
  });

  const minimum = minNumber(percentageChangeData);
  const maximum = maxNumber(percentageChangeData);

  const min = Math.floor((minimum + 1) / 10) * 10 - 10;
  const max = Math.ceil((maximum + 1) / 10) * 10;

  const labels = optionsAnalysisItems.map((item) => item.summary);

  const CSS_COLORS = getCSSColorPalette();

  const data = {
    labels: labels,
    datasets: [
      {
        label: "% Change",
        data: percentageChangeData,
        backgroundColor: CSS_COLORS["--fluidity1"],
        borderWidth: 1,
        barThickness: 10,
        order: 2,
      },
      {
        label: "Baseline",
        data: lineData,

        backgroundColor: "grey",
        borderColor: "grey",
        borderWidth: 3,
        type: "line",
        fill: false,
        order: 1,
        tooltips: false,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            min: min,
            max: max,
            stepSize: 20,
            callback: (value) => value + "%",
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: function (tooltipItem) {
          if (tooltipItem.datasetIndex < 1) {
            return (
              comma(tooltipItem.yLabel) +
              "% change to Baseline of " +
              comma(Math.round(baseline))
            );
          } else {
            return "Baseline of " + comma(Math.round(baseline));
          }
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    width: 700,
    height: 150,
  };

  return (
    <Card
      label={"Percentage Change of Cost Impact to Baseline"}
      labelClassName={labelClassName}
    >
      {CPs.loading && <Spinner width={150} />}
      {!CPs.loading && (
        <div>
          <div
            style={{
              width: `${options.width}px`,
              height: `${options.height}px`,
            }}
          >
            <Bar data={data} options={options} />
          </div>
        </div>
      )}
    </Card>
  );
}
