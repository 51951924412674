import { detectDeployment } from "./Utilities";

export function pcraAPIs() {
  const config = {
    serviceAPI: true,
    localService:
      "https://dev.cost-clarity.com/postcontract/apis/pcraservice/v1.0/Pcra",
    deployedService: "/postcontract/apis/pcraservice/v1.0/Pcra",
  };

  // Local dev "https://localhost:41335/v1.0/Pcra"
  // Deployed dev "https://dev.cost-clarity.com/postcontract/apis/pcraservice/v1.0/Pcra"

  const D = detectDeployment(config);

  return {
    // PCRA
    read_audit_log: `${D}/ReadAuditLog`,
    update_audit_log: `${D}/UpdateAuditLog`,
  };
}
