import React from "react";

import PDPCLayout from "./PDPCLayout";

import "./PDPC.css";

export default function PDPC(props) {
  // Props
  const { project } = props;
  const { dashboardType } = props;
  const { dashboardSettings } = props;
  const { showDashboardDrawer, setShowDashboardDrawer } = props;

  // Post Contract
  const { costReport } = props;
  const { comparisonCRs } = props;

  // Check project Phase
  if (dashboardType !== "POST_CONTRACT") {
    return null;
  }

  return (
    <div className="project-dashboard">
      <div className="project-dashboard-area">
        <PDPCLayout
          project={project}
          costReport={costReport}
          comparisonCRs={comparisonCRs}
          // Dashboard Drawer
          showDashboard={showDashboardDrawer}
          setShowDashboard={setShowDashboardDrawer}
          // Dashboard Settings
          dashboardSettings={dashboardSettings}
        />
      </div>
    </div>
  );
}
