import { tranStr } from "../../utils/translation";
import { humanize } from "../../validation/humanize";

export function constructionColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 575,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Contract Value"),
      key: "contract_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Items"),
      key: "provisional_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contract Excl Prov Items"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Contractor Claim %"),
      key: "contractor_claim_percent",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Contractor Claim Value"),
      key: "contractor_claim_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Difference"),
      key: "difference",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Sub Heading"),
      key: "sub_heading",
      type: "TEXT",
      width: 100,
      isFiltered: true,
      isTotalled: false,
    },
    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function unfixedMaterialsColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 670,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Value"),
      key: "value",
      type: "CURRENCY",
      width: 100,
      isTotalled: true,
      isFiltered: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: true,
      isTotalled: false,
      generateTotal: (tableArray) => {
        return divideBy(
          tableArray,
          "value_complete_to_date",
          "variation_value"
        );
      },
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isTotalled: true,
      isFiltered: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contractor Claim %"),
      key: "contractor_claim_percent",
      type: "PERCENT",
      width: 80,
      isFiltered: true,
      isTotalled: false,
      generateTotal: (tableArray) => {
        return divideClaimByVariation(tableArray);
      },
    },
    {
      heading: tranStr("Contractor Claim Value"),
      key: "contractor_claim_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Difference"),
      key: "difference",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function variationColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 270,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Value"),
      key: "variation_value",
      type: "CURRENCY",
      width: 100,
      isTotalled: true,
      isFiltered: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: true,
      isTotalled: false,
      generateTotal: (tableArray) => {
        return divideClaimByVariation(tableArray);
      },
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isTotalled: true,
      isFiltered: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contractor Claim %"),
      key: "contractor_claim_percent",
      type: "PERCENT",
      width: 80,
      isFiltered: true,
      isTotalled: false,
      generateTotal: (tableArray) => {
        return divideClaimByVariation(tableArray);
      },
    },
    {
      heading: tranStr("Contractor Claim Value"),
      key: "contractor_claim_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Difference"),
      key: "difference",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
      formatFunction: (value) => {
        return humanize(value);
      },
    },
    {
      heading: tranStr("Action With"),
      key: "action",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Submitted Date"),
      key: "submitted_date",
      type: "DATE",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Recommended Date"),
      key: "recommended_date",
      type: "DATE",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Approved Date"),
      key: "approved_date",
      type: "DATE",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Critical"),
      key: "is_critical",
      type: "CRITICAL",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function pqColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 350,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Provisional Qty"),
      key: "provisional_quantity",
      type: "QUANTITY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Unit"),
      key: "provisional_unit",
      type: "TEXT",
      width: 100,
      isFiltered: false,
    },
    {
      heading: tranStr("Tendered Rate"),
      key: "tendered_rate",
      type: "CURRENCY",
      width: 100,
      isFiltered: false,
    },
    {
      heading: tranStr("Provisional Total Cost"),
      key: "provisional_total_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Actual Quantity"),
      key: "actual_quantity",
      type: "QUANTITY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Actual Cost"),
      key: "actual_cost",
      type: "QUANTITY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Adjustment"),
      key: "provisional_quantity_adjustment",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Contractor Claim Value"),
      key: "contractor_claim_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function psColumns() {
  return [
    {
      heading: tranStr("No."),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 370,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Provisional Sum"),
      key: "provisional_sum",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
      isTotalled: false,
      generateTotal: (tableArray) => {
        return divideBy(tableArray, "value_complete_to_date", "actual_cost");
      },
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contractor Claim %"),
      key: "contractor_claim_percent",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Contractor Claim Value"),
      key: "contractor_claim_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Difference"),
      key: "difference",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Submitted Date"),
      key: "submitted_date",
      type: "DATE",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Recommended Date"),
      key: "recommended_date",
      type: "DATE",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Approved Date"),
      key: "approved_date",
      type: "DATE",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Critical"),
      key: "is_critical",
      type: "CRITICAL",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function cdColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 410,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Consultant"),
      key: "consultant",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Client Cost Type"),
      key: "client_cost_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },

    {
      heading: tranStr("Budget"),
      key: "budget",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Contract Value Excl Prov Items"),
      key: "contract_value_excl_prov_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Items"),
      key: "provisional_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contract Value Incl Prov Items"),
      key: "contract_value_incl_prov_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Variations"),
      key: "variations",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Actual Cost"),
      key: "provisional_item_actual_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Adjustment"),
      key: "provisional_item_adjustment",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Total Cost"),
      key: "total_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Balance of Contingency"),
      key: "balance_of_contingency",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Expenditure To Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
  ];
}

export function PTCFColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 410,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Project Team Consultant"),
      key: "consultant",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Consultant Type"),
      key: "consultant_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },

    {
      heading: tranStr("Budget"),
      key: "budget",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Contract Value Excl Prov Items"),
      key: "contract_value_excl_prov_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Items"),
      key: "provisional_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contract Value Incl Prov Items"),
      key: "contract_value_incl_prov_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Variations"),
      key: "variations",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Actual Cost"),
      key: "provisional_item_actual_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Adjustment"),
      key: "provisional_item_adjustment",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Total Cost"),
      key: "total_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Balance of Contingency"),
      key: "balance_of_contingency",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Expenditure To Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
  ];
}

export function delaysColumns() {
  return [
    {
      heading: tranStr("No."),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 350,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Provisional Days"),
      key: "provisional_days",
      type: "QUANTITY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Tendered Rate"),
      key: "tendered_rate",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Provisional Allowance"),
      key: "provisional_allowance",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Actual Cost"),
      key: "actual_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Actual Days"),
      key: "actual_days",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Adjustment"),
      key: "provisional_item_adjustment",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete To Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Difference"),
      key: "difference",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function eoTColumns() {
  return [
    {
      heading: tranStr("No."),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 600,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Project Phase"),
      key: "project_phase",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Tendered Rate"),
      key: "tendered_rate",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("EOT Days"),
      key: "eot_days",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Actual Cost"),
      key: "actual_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete To Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Difference"),
      key: "difference",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Reasons"),
      key: "reasons_for_difference",
      type: "REASONS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function rrColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 300,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Risk Type"),
      key: "risk_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },

    {
      heading: tranStr("Consequence Rating"),
      key: "consequence_rating",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Likelihood Rating"),
      key: "likelihood_rating",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Time Impact"),
      key: "time_impact",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Best Case"),
      key: "best_case_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Most Likely"),
      key: "most_likely_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Worst Case"),
      key: "worst_case_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Risk Analysis"),
      key: "risk_analysis",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Action With"),
      key: "action",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Critical"),
      key: "is_critical",
      type: "CRITICAL",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function contingenciesColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 430,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Contingency Allowance"),
      key: "contingency_allowance",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Action With"),
      key: "action",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function cdVColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 450,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Consultant"),
      key: "consultant",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Client Cost Type"),
      key: "client_cost_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Value"),
      key: "value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Action With"),
      key: "action",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function EWVColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 450,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Contract"),
      key: "contract",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Contract Type"),
      key: "contract_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Value"),
      key: "variation_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Action With"),
      key: "action",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

export function PTFCVColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 410,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Project Team Consultant"),
      key: "consultant",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Consultant Type"),
      key: "consultant_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Value"),
      key: "variation_value",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("% Complete to Date"),
      key: "percent_complete_to_date",
      type: "PERCENT",
      width: 80,
      isFiltered: false,
    },
    {
      heading: tranStr("Value Complete to Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Status"),
      key: "status",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Action With"),
      key: "action",
      type: "STATUS",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Notes"),
      key: "comments",
      type: "COMMENTS",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
    {
      heading: tranStr("Link"),
      key: "document_link",
      type: "LINK",
      width: 60,
      isFiltered: false,
      isTotalled: false,
    },
  ];
}

function calculateTotal(tableArray, key) {
  let total = 0;
  tableArray.forEach((row) => {
    total += row[key];
  });

  return total.toString();
}

function divideClaimByVariation(tableArray) {
  return divideBy(tableArray, "contractor_claim_value", "variation_value");
}

function divideBy(tableArray, column1, column2) {
  let total1 = 0;
  let total2 = 0;
  tableArray.forEach((row) => {
    total1 += row[column1];
    total2 += row[column2];
  });

  let total = (total1 / total2) * 100;

  if (isNaN(total) || total === Infinity || total === -Infinity) {
    return 0;
  } else {
    return total;
  }
}

export function ewColumns() {
  return [
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
      isTotalled: true,
      generateTotal: () => {
        return "Total";
      },
    },
    {
      heading: tranStr("Contract"),
      key: "contract",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Contract Type"),
      key: "contract_type",
      type: "TEXT",
      width: 100,
      isFiltered: true,
    },

    {
      heading: tranStr("Budget"),
      key: "budget",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Contract Value Excl Prov Items"),
      key: "contract_value_excl_prov_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Items"),
      key: "provisional_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Contract Value Incl Prov Items"),
      key: "contract_value_incl_prov_items",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Variations"),
      key: "variations",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Actual Cost"),
      key: "provisional_item_actual_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Provisional Item Adjustment"),
      key: "provisional_item_adjustment",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Total Cost"),
      key: "total_cost",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },

    {
      heading: tranStr("Balance of Contingency"),
      key: "balance_of_contingency",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
    {
      heading: tranStr("Expenditure To Date"),
      key: "value_complete_to_date",
      type: "CURRENCY",
      width: 100,
      isFiltered: true,
      isTotalled: true,
      generateTotal: (tableArray, key) => {
        return calculateTotal(tableArray, key);
      },
    },
  ];
}
