// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// API
import { updatePermissions } from "../../api/permissions/PermissionsAPI";

// Components
import { ImageDisplay } from "../images/ImageDisplay";
import Modal from "../modal/Modal";

// Functions
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { displayUserImage } from "../../validation/image-clean";
import { processPermissionsPayload } from "./ProjectPermissions.functions";

export function Submit(prop) {
  const { data } = prop;
  return (
    <button
      className="pciu-submit-button"
      onClick={async () => {
        data.payload = processPermissionsPayload(data);
        await updatePermissions(data);
      }}
    >
      {translate("Save")}
    </button>
  );
}

ModalMultiple.propTypes = {
  Component: PropTypes.func.isRequired,
  setFunction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  selectedData: PropTypes.array.isRequired,
  error: PropTypes.func,
  userGroup: PropTypes.func.isRequired,
};

export function ModalMultiple(props) {
  const { Component, setFunction, label } = props;
  const { selectedData, error } = props;
  const { userGroup } = props;

  const [modal, setModal] = useState(false);

  return (
    <div className="pciu-col">
      <div className="pciu-row">
        <Modal
          // Modal Props
          title={label}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          selectedData={selectedData}
          setFunction={setFunction}
          label={label}
          userGroup={userGroup}
        />
        <button
          className="pciu-modal-button"
          onClick={() => {
            setModal(true);
          }}
        >
          {label}
        </button>
        <DisplaySelected selectedData={selectedData} />
      </div>
      <DisplayError error={error} />
    </div>
  );
}

DisplaySelected.propTypes = {
  selectedData: PropTypes.array.isRequired,
};

function DisplaySelected(props) {
  const { selectedData } = props;

  if (isEmpty(selectedData)) {
    return null;
  }

  return (
    <div className="pciu-row">
      <div className="pciu-selection-number">
        <b>{selectedData.length}</b>
      </div>
      <div className="pciu-name">Selected</div>
    </div>
  );
}

function DisplayError(prop) {
  const { error } = prop;

  if (isEmpty(error)) {
    return null;
  }

  return <div className="pciu-modal-error">{error}</div>;
}

export function DisplayErrors(prop) {
  const { error } = prop;
  return (
    <div className="feedback-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

export function ModalSingle(prop) {
  const { Component, setFunction, label } = prop;
  const { user, error } = prop;

  const [modal, setModal] = useState(false);

  return (
    <div className="pciu-col">
      <div className="pciu-row">
        <Modal
          // Modal Props
          title={label}
          Component={Component}
          modal={modal}
          setModal={setModal}
          // Component Props
          show={modal}
          setShow={setModal}
          setFunction={setFunction}
          label={label}
        />
        <button
          className="pciu-modal-button"
          onClick={() => {
            setModal(true);
          }}
        >
          {label}
        </button>
        <DisplayNameUser user={user} />
      </div>
      <DisplayError error={error} />
    </div>
  );
}

function DisplayNameUser(prop) {
  const { user } = prop;

  if (isEmpty(user)) {
    return null;
  }

  return (
    <div className="pciu-row">
      <div className="pciu-name"></div>
      <ImageDisplay
        image={displayUserImage(user)}
        imageWidth={"30px"}
        imageHeight={"30px"}
        borderRadius={"15px"}
        isBezierDisabled={true}
      />
      <div className="pciu-name">{user.name}</div>
      <div className="pciu-name">{user.surname}</div>
    </div>
  );
}
