import React, { useState } from "react";

// Components
import PortfolioEditSelectProjectsTable from "../portfolio-control/PortfolioEditSelectProjectsTable";
import Spinner from "../common/Spinner";
import { Save, DisplayErrors } from "./AccountProfileEditProjects.components";

export default function AccountProfileEditProjects(prop) {
  const { setShow } = prop;
  const { accountsProjects } = prop;
  const { account } = prop;
  const { projects } = prop;

  const [selectedProjects, setSelectedProjects] = useState(
    accountsProjects.data
  );
  const [errors, setErrors] = useState({});

  if (projects.loading) {
    return <Spinner marginTop={"170px"} marginBottom={"170px"} />;
  }

  const data = {
    errors: errors,
    setErrors: setErrors,
    setShow: setShow,
    payload: {
      userId: account.id,
      mappingType: "Project",
      mappingIds: selectedProjects.map((x) => x.id),
      companyIds: selectedProjects.map((x) => x.company_id),
    },
  };

  return (
    <div className="account-profile-edit-projects">
      <PortfolioEditSelectProjectsTable
        data={projects.data}
        dataLoading={projects.loading}
        selectedData={selectedProjects}
        setShow={setShow}
        setSelectedUsers={setSelectedProjects}
      />
      <div className="general-row-container">
        <DisplayErrors errors={errors} setErrors={setErrors} />
      </div>

      <div className="general-row-container-double">
        <Save
          account={account}
          selectedProjects={selectedProjects}
          accountsProjects={accountsProjects}
          data={data}
        />
      </div>
    </div>
  );
}
