import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import Spinner from "../common/Spinner";
import ProfileModal from "../profile/ProfileModal";
import ProfileCard from "../profile-card/ProfileCard";
import "./ProjectTeam.css";

export default function ProjectTeam(props) {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { project } = props;

  if (!isEmpty(project)) {
    return (
      <div className="project-team-background">
        <div className="project-team-area">
          <div className="project-team-container">
            <ProjectTeamSubTitle title={"Project Leader"} />
            <ProfileCard
              user={project.project_leader_user}
              show={show}
              setShow={setShow}
              setSelectedUser={setSelectedUser}
            />
            <ProjectTeamSubTitle title={"Cost Manager"} />
            <ProfileCard
              user={project.peer_reviewer_user}
              show={show}
              setShow={setShow}
              setSelectedUser={setSelectedUser}
            />
            <ProjectTeamCostManagers
              managers={project.cost_managers}
              project_leader={project.project_leader_user}
              peer_reviewer={project.peer_reviewer_user}
              show={show}
              setShow={setShow}
              setSelectedUser={setSelectedUser}
            />
            <ProfileModal
              user={selectedUser}
              show={show}
              setShow={setShow}
              projectName={project.title}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="project-team-background">
      <div className="project-team-area">
        <div className="project-team-container">
          <Spinner marginTop={"30%"} marginLeft={"345px"} />
        </div>
      </div>
    </div>
  );
}

function ProjectTeamCostManagers(props) {
  const {
    managers,
    project_leader,
    peer_reviewer,
    show,
    setShow,
    setSelectedUser,
  } = props;

  if (isEmpty(managers) || isEmpty(project_leader) || isEmpty(peer_reviewer)) {
    return null;
  }

  let filteredManagers = managers.filter((m) => {
    if (m.id !== project_leader.id && m.id !== peer_reviewer.id) {
      return m;
    } else {
      return null;
    }
  });
  return (
    <div>
      <ProjectTeamSubTitle title={"Team"} />
      <div>
        {filteredManagers.map((m) => {
          return (
            <div key={m.id}>
              <ProfileCard
                user={m}
                show={show}
                setShow={setShow}
                setSelectedUser={setSelectedUser}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ProjectTeamSubTitle(props) {
  const { title } = props;
  return (
    <div className="project-team-client-title">
      <h1 className="display-4" style={{ fontSize: "25px" }}>
        {title}
      </h1>
    </div>
  );
}
