import React, { useState, useEffect } from "react";
import { tranStr } from "../../utils/translation";
import { comma } from "../../helpers/common.functions";

import Spinner from "../common/Spinner";

import "./OptionsAnalysisCostBaselineForm.css";
import { saveBaselineCP } from "./OptionsAnalysisCostBaseline.functions";
import { OptionsAnalysisUploadDataError } from "./OptionsAnalysisUploader";

export default function OptionsAnalysisCostBaselineForm(props) {
  const { CPs } = props;
  const { setModal } = props;
  const { activeStage } = props;
  const { project, getOptions, setOptions } = props;
  const [error, setError] = useState({});

  const [baselineId, setBaselineId] = useState("");

  useEffect(() => {
    setBaselineId(activeStage?.baselineCostPlanId);
  }, [activeStage]);

  if (CPs.loading) {
    return (
      <div className="options-analysis-cost-baseline-form-container spinner">
        <Spinner />
      </div>
    );
  }
  let data = {
    costplan_id: baselineId,
    active_stage_id: activeStage?.id,
    project,
    setModal,
    getOptions,
    setOptions,
    setError,
  };
  return (
    <div className="options-analysis-cost-baseline-form-container">
      <p>{tranStr("Select a Cost Plan and Release")}</p>
      <div className="general-row-container">
        <SelectCostPlanBaseline
          baselineId={baselineId}
          setBaselineId={setBaselineId}
          CPs={CPs}
        />
      </div>
      <div className="general-row-container">
        <OptionsAnalysisUploadDataError error={error} setError={setError} />
      </div>
      <div className="general-row-container">
        <div
          id="button-accept-baseline"
          className="general-upload-button"
          onClick={() => {
            saveBaselineCP(data);
          }}
        >
          Accept
        </div>
      </div>
    </div>
  );
}

function SelectCostPlanBaseline(props) {
  const { baselineId, setBaselineId } = props;
  const { CPs } = props;

  const options = [<option key="1">{tranStr("Select a Cost Plan")}</option>];
  CPs.data.forEach((stage) => {
    stage.versions.forEach((version) => {
      const label =
        "Stage: " +
        version.stage +
        " | Release: " +
        version.version +
        " | Total Cost: " +
        comma(Math.round(calculateTotalCost(version)));
      options.push(
        <option key={version.cost_plan_id} value={version.cost_plan_id}>
          {label}
        </option>
      );
    });
  });

  return (
    <select
      value={baselineId}
      onChange={(e) => {
        setBaselineId(e.target.value);
      }}
    >
      {options}
    </select>
  );
}

export function calculateTotalCost(CP) {
  let totalCost = 0;
  CP?.estimates?.forEach((estimate) => {
    totalCost += estimate.calculated_total;
  });
  return totalCost;
}
