import React from "react";
import isEmpty from "../../validation/is-empty";
import { displayProjectImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

import "./ProjectsList.css";

export default function ProjectsList(props) {
  const { projects } = props;

  if (isEmpty(projects)) {
    return null;
  }

  const display = [];
  const URL = window.location.origin;

  projects.forEach((project) => {
    display.push(
      <a
        key={project.id}
        className="pd-projects-list-row"
        href={URL + "/project/" + project.id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageDisplay
          image={displayProjectImage(project)}
          imageWidth={"40px"}
          imageHeight={"25px"}
          isBezierDisabled={true}
        />

        <div className="pd-projects-list-title">{project.title}</div>
      </a>
    );
  });

  return <div className="pd-projects-list">{display}</div>;
}
