import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import CostVsBudget from "../charts/CostVsBudget";

export default function CostReportClientDirectCostsSummary(props) {
  let costValue = 0;
  let budgetValue = 0;

  let { costReport } = props;
  let CR = costReport;
  if (isEmpty(CR) || isEmpty(costReport)) {
    return null;
  }

  costValue = CR.anticipated_final_direct_costs;
  budgetValue = CR.client_direct_costs_budget;
  costValue = parseInt(costValue);
  budgetValue = parseInt(budgetValue);
  //Forecast Total Cost
  let costText = translate("Anticipated Final Direct Costs");
  //Original Budget
  let budgetText = translate("Client Direct Costs Budget");
  //Adjustments to provisional items
  let differenceText = translate("Headroom to Budget");

  return (
    <CostVsBudget
      secondValue={costValue}
      firstValue={budgetValue}
      secondText={costText}
      firstText={budgetText}
      differenceText={differenceText}
      headroom={true}
      isApproved={true}
    />
  );
}
