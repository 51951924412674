import isEmpty from "../../validation/is-empty";

export function filterData(tableData, filters, filterColumns) {
  if (isEmpty(tableData)) {
    return [];
  }
  //if there are filters
  if (!isEmpty(filters)) {
    return tableData.filter((row) => {
      let valcheck = true;
      //iterate through all filters
      for (let filter of filters) {
        //check what type of column is getting filtered
        const c = filterColumns.filter((x) => x.key === filter.column);
        let filterValue = filter.text;
        let rowValue = row[filter.column];
        //if it is currency validate min or max
        if (isNumerical(c)) {
          valcheck = numericalCheck(valcheck, filter, rowValue, filterValue);
        } else {
          valcheck = textCheck(rowValue, filterValue);
        }
      }
      if (valcheck) {
        return row;
      } else {
        return null;
      }
    });
  } else {
    return tableData;
  }
}

function isNumerical(c) {
  return (
    c[0].type === "CURRENCY" ||
    c[0].type === "PERCENT" ||
    c[0].type === "QUANTITY"
  );
}

function validateFilterMinNumber(value) {
  return value ? parseInt(value) : 0;
}
function validateFilterMaxNumber(value) {
  return value ? parseInt(value) : Infinity;
}

function textCheck(rowValue, filterValue) {
  if (rowValue === null) return false;
  return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}

function numericalCheck(valcheck, filter, rowValue, filterValue) {
  if (filter.minOrMax === "min") {
    return valcheck && rowValue * 1 >= validateFilterMinNumber(filterValue);
  } else {
    return valcheck && rowValue * 1 <= validateFilterMaxNumber(filterValue);
  }
}
