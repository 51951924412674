import React from "react";
import isEmpty from "../../validation/is-empty";

import CostPlanComparisonChart from "../charts/CostPlanComparisonChart";

import {
  estimateBreakupsByStages,
  estimateBreakupsByRevisions,
} from "../charts/CostPlanComparisonChart.functions";

import "./PDCPReconciliationChart.css";

export default function PDCPReconciliationChart(props) {
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { type } = props;
  const { dashboardID } = props;

  if (isEmpty(CP)) {
    return null;
  }

  let estimatesCategories = [];
  let fields = [];
  let budgets = [];

  if (type === "RELEASES") {
    const { estimateBreakups, field, budget } = estimateBreakupsByRevisions(
      CPs,
      CP
    );

    estimatesCategories = estimateBreakups;
    fields = field;
    budgets = budget;
  }

  if (type === "STAGES") {
    const { estimateBreakups, field, budget } = estimateBreakupsByStages(
      CPs,
      CP
    );

    estimatesCategories = estimateBreakups;
    fields = field;
    budgets = budget;
  }

  if (fields.length < 2) {
    return null;
  }

  return (
    <div className="pdcp-reconciliation-chart">
      <CostPlanComparisonChart
        key={dashboardID}
        project={project}
        estimateBreakups={estimatesCategories}
        field={fields}
        budget={budgets}
        height={120}
      />
    </div>
  );
}
