import React, { useMemo } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";

import { useMultiRegionTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { useBenchmarksWithRows } from "../../api/benchmarking/BenchmarkingAPI";
import { useCurrencyExchange } from "../../api/admin/CurrencyExchangeAPI";

import Spinner from "../common/Spinner";

import BenchmarkCreateAnalysisConfiguration from "./BenchmarkCreateAnalysisConfiguration.class";

import BenchmarkSection from "../benchmarking-project/BenchmarkSection";
import BenchmarkCreateAnalysisDisclaimer from "./BenchmarkCreateAnalysisDisclaimer";

import {
  linkBenchmarks,
  prepareOnBenchmarkAnonymityToggle,
} from "./BenchmarkCreate.functions";

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysis.propTypes = {
  disclaimerDisplay: PropTypes.object,
  title: PropTypes.string,
  configuration: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(BenchmarkCreateAnalysisConfiguration),
  ]),
  selectedBenchmarkIds: PropTypes.array.isRequired,
  LFI: PropTypes.array.isRequired,
  regionCountryList: PropTypes.array.isRequired,
};
///
export default function BenchmarkCreateAnalysis(props) {
  const { disclaimerDisplay } = props;
  const { title } = props;
  const { configuration } = props;
  const { selectedBenchmarkIds } = props;
  const { LFI, regionCountryList } = props;

  // commented attributes are already included in configuration
  // uncomment attributes on a need-basis for future expansion
  const {
    // sector,
    // subsector,
    // quality,
    // projectType,
    currency,
    unitOfMeasure,
    currencySymbol,
    region,
    country,
    city,
    baseDate,
    // latitude,
    // longitude,
    currencyFactor,
    isLocalRegionFactorEnabled,
  } = configuration;

  // get TPI
  const { TPILoading, TPI } = useMultiRegionTPI(regionCountryList);

  // get currency exchange
  const { currencyExchange } = useCurrencyExchange();

  // consider the first of benchmarkIds as that of the reference benchmark (thisBenchmark)
  const refBenchmarkIds = useMemo(
    () => selectedBenchmarkIds.slice(0, 1),
    [selectedBenchmarkIds]
  );
  // collect the rest of the benchmarkIds
  const restBenchmarkIds = useMemo(
    () => selectedBenchmarkIds.slice(1),
    [selectedBenchmarkIds]
  );

  // fetch the reference benchmark
  const {
    loading: refBenchmarksLoading,
    benchmarksWithRows: refBenchmarks,
    setBenchmarksWithRows: setRefBenchmarks,
  } = useBenchmarksWithRows(refBenchmarkIds);

  // fetch the rest of the benchmarks
  const {
    loading: restBenchmarksLoading,
    benchmarksWithRows: restBenchmarks,
    setBenchmarksWithRows: setRestBenchmarks,
  } = useBenchmarksWithRows(restBenchmarkIds);

  // handle loading scenarios
  if (
    TPILoading ||
    refBenchmarksLoading ||
    restBenchmarksLoading ||
    currencyExchange.loading
  ) {
    return <Spinner />;
  }

  // loading completed

  const refBenchmark = refBenchmarks?.[0];

  // link benchmarks to thisBenchmark
  linkBenchmarks({
    thisBenchmark: refBenchmark,
    benchmarks: restBenchmarks,
  });

  const showDisclaimer = !isEmpty(refBenchmark) && !isEmpty(restBenchmarks);

  const onBenchmarkAnonymityToggle = prepareOnBenchmarkAnonymityToggle({
    refBenchmarks,
    setRefBenchmarks,
    restBenchmarks,
    setRestBenchmarks,
  });

  return (
    <div className="master-benchmarking">
      <BenchmarkCreateAnalysisDisclaimer
        disclaimerDisplay={disclaimerDisplay}
        show={showDisclaimer}
      />
      <BenchmarkSection
        caller={"MASTER_BENCHMARKING"}
        title={title}
        unitOfMeasure={unitOfMeasure}
        currencySymbol={currencySymbol}
        region={region}
        country={country}
        city={city}
        thisBenchmark={refBenchmark}
        benchmarks={restBenchmarks}
        setBenchmarks={setRestBenchmarks}
        baseDate={baseDate}
        onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
        // Inflation Escalation
        TPI={TPI}
        // Locality Escalation
        LFI={LFI}
        isLocalRegionFactorEnabled={isLocalRegionFactorEnabled}
        is_locality_factor_active={false} // Cost Plan Locality Factor Override Toggle
        locality_factor={null} // Cost Plan Locality Factor Override Value
        // Currency Escalation
        isGlobalBenchmarking={currencyFactor}
        currencyExchange={currencyExchange.data}
        currency={currency}
      />
    </div>
  );
}
