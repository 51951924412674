import React, { createContext, useMemo } from "react";

import PCRALogModal from "../pcra/PCRALogModal";

export const PCRALogRootContext = createContext();

export default function PCRALogRoot(props) {
  const { user } = props;
  const { data, setData } = props;
  const { onAction } = props;
  const { isLocked, lockedReason } = props;

  const PCRALogRootContextProviderValue = useMemo(
    function () {
      return {
        user,
        data,
        setData,
        onAction,
        isLocked: isLocked || data.isLocked,
        lockedReason,
      };
    },
    [user, data, setData, onAction, isLocked, lockedReason]
  );

  return (
    <PCRALogRootContext.Provider value={PCRALogRootContextProviderValue}>
      <PCRALogModal />
    </PCRALogRootContext.Provider>
  );
}
