import React, { useState } from "react";

// Components
import Table from "../table/Table";
import PostContractEnablingWorksModal from "../post-contract-table-modals/PostContractEnablingWorksModal";
import Modal from "../modal/Modal";

// Functions
import { ewColumns } from "../post-contract-tables/PostContractTable.functions";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

// Styles
import "./PostContractEnablingWorks.css";

export default function PostContractEnablingWorks(props) {
  const { EW } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  // No data available so return null
  if (isEmpty(EW)) {
    return null;
  }

  return (
    <div>
      <div className="pct-spacer" />
      <Table
        title={"Enabling Works"}
        tableArray={EW}
        columns={ewColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractEnablingWorksModal}
        modal={modal}
        setModal={setModal}
        columns={ewColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
