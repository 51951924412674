import React, { useEffect, useState } from "react";
import DoughnutChartBrite from "../charts/DoughnutChartBrite";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { comma } from "../../helpers/common.functions";
import {
  calculateTotal,
  generateCostCategorySubTotalData,
  SubTotalsExists,
} from "./CostPlanningDashboard.functions";
import SubTotalSelection from "./SubTotalSelection";

import "./CostPlanningDashboard.css";
import ChartsToggle from "../charts-toggle/ChartsToggle";
import { connect } from "react-redux";
import { useProjectSettings } from "../../api/projects/ProjectSettingsAPI";
import { hasRoles } from "../../utils/roles";
import {
  benchmarkCategories,
  createEscalationCategory,
  createEscalationPercentages,
} from "../estimates/EstimatesBenchmarkDonutComponent.functions";
import { AVERAGE } from "../../actions/types";

function CostPlanningDashboard(props) {
  const { estimates, CPs } = props;
  const { calculatedTotal } = props;
  const { subTotalChart } = props;
  const { user } = props.auth;
  const { projectId, project } = props;

  let title = translate("Project Cost Summary");

  const { settings } = useProjectSettings(projectId);
  const [off, setOff] = useState(false);
  const setting = "hide_on_cost_comparison";

  let costCategoryData = [];
  let combinedEstimateSubTotal = 0;

  useEffect(() => {
    if (!isEmpty(settings)) {
      if (!isEmpty(settings.find((s) => s.setting === setting))) {
        setOff(settings.find((s) => s.setting === setting).state);
      }
    }
  }, [settings]);

  let box;

  if (!off) {
    box = "costplanning-toggle-chart-box";
  } else if (off && !hasRoles(user.roles, ["CostManager"])) {
    box = "costplanning-chart-box-disabled";
  } else if (off && hasRoles(user.roles, ["CostManager"])) {
    box = "costplanning-chart-box-hidden";
  }

  if (subTotalChart) {
    let costdata = generateCostCategorySubTotalData(estimates);
    costCategoryData = costdata.data;
    combinedEstimateSubTotal = costdata.combinedEstimateSubTotal;
    title = translate("Sub-Total Summary");
  } else {
    const benchmarks = estimates.map((e) => e.benchmark);
    costCategoryData = benchmarkCategories(
      benchmarks,
      "Cost Categories",
      AVERAGE
    );
    // Cost Escalation needs to be calculated and added if it exists
    const escalationCategory = createEscalationCategory(estimates);
    if (escalationCategory.value > 0) {
      costCategoryData.push(escalationCategory);
    }

    // Recalculate the percentages to account for cost escalation
    createEscalationPercentages(costCategoryData);

    box = "";
  }

  if (isEmpty(estimates)) {
    return null;
  }

  const heritage = "rgba(228, 97, 15, 1)";
  const aqua = "rgba(50, 98, 149, 1)";
  const collaboration1 = "rgba(85, 87, 90, 1)";
  const heritage2 = "rgba(228, 97, 15, 0.7)";
  const aqua2 = "rgba(50, 98, 149, 0.7)";
  const collaboration12 = "rgba(85, 87, 90, 0.7)";

  const colorSchema = [
    heritage,
    collaboration1,
    aqua,
    collaboration12,
    aqua2,
    heritage2,
  ];

  const margins = {
    left: -20,
    right: 10,
    top: 5,
    bottom: 5,
  };
  let estimate = estimates[0];

  costCategoryData =costCategoryData.filter(item=>(item.value!==0));
  // check if the user is a cost manager and there are unselected subtotals
  let display =
    hasRoles(user.roles, ["CostManager"]) && SubTotalsExists(estimate);
  return (
    <>
      {/* display the chart container if there is chart data or if the user is a cost manager and there are unselected subtotals */}
      {(costCategoryData.length > 0 || display) && (
        <div className={box}>
          <div className="doughnut-chart-container">
            <div
              className={box === "" ? "doughnut-chart-box-cp-dashboard1" : ""}
            >
              <div
                className={
                  box === "" ? "" : "costplanning-chart-box-cp-dashboard2"
                }
              >
                <h1 className="display-4" style={{ fontSize: "20px" }}>
                  {title}
                </h1>
                {subTotalChart && (
                  <div className="costplanning-subtotal-popup">
                    <SubTotalSelection
                      projectId={projectId}
                      estimate={estimate}
                      CPs={CPs}
                      project={project}
                      user={user}
                    />
                    <ChartsToggle
                      user={user}
                      off={off}
                      setOff={setOff}
                      setting={setting}
                      project_id={projectId}
                    />
                  </div>
                )}
              </div>
              <div className="doughnut-chart-item">
                <DoughnutChartBrite
                  data={costCategoryData}
                  iRadius={70}
                  colorSchema={colorSchema}
                  width={390}
                  margin={margins}
                />
              </div>
              {!subTotalChart && (
                <GraphTotal
                  data={costCategoryData}
                  GraphTotalTitle={"Total Project Cost (Excl. Tax)"}
                  calculatedTotal={calculatedTotal}
                />
              )}
              {subTotalChart && (
                <GraphTotal
                  data={costCategoryData}
                  GraphTotalTitle={"Project Sub Total Cost"}
                  calculatedTotal={combinedEstimateSubTotal}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function GraphTotal(props) {
  const { data } = props;
  const { calculatedTotal } = props;
  const { GraphTotalTitle } = props;
  let value = calculateTotal(data);

  // Calculate Difference
  const difference = calculatedTotal - value;

  // Difference is less than positive 5 it is rounding only
  if (difference <= 5) {
    value = calculatedTotal;
  }

  // Difference is less than negative -5 it is rounding only
  if (difference >= -5) {
    value = calculatedTotal;
  }

  return (
    <div className="doughnut-chart-total">
      <div className="doughnut-chart-total-label">
        <b>{translate(GraphTotalTitle)}</b>
      </div>
      <div className="doughnut-chart-total-value">
        <b>{comma(Math.round(value))}</b>
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(CostPlanningDashboard);
