import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { projectUpdate } from "../../api/projects/ProjectAPI";

import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import CheckboxesVertical from "../checkboxes/CheckboxesVertical";
import { tranStr, translate } from "../../utils/translation";
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

import { DisplayError } from "../admin-companies/CompanyCreate.components";
import { AutoCloseModal } from "../modal/Modal";

import "./PostContractToggleFeatures.css";

/// props validation | SQ(javascript:S6774)
PostContractToggleFeatures.propTypes = {
  project: PropTypes.object,
  setModal: PropTypes.func,
};
///
export default function PostContractToggleFeatures(props) {
  // Props
  const { project } = props;
  const { setModal } = props;

  // Feature Selection
  const [postContractFeatures, setPostContractFeatures] = useState([]);

  const [error, setError] = useState({});

  // Two useEffects are used simply to make the code more readable
  useEffect(() => {
    if (!isEmpty(project)) {
      // Set toggle Features
      let tempPostContractFeatures = [];
      if (project.enable_draft_cost_reports) {
        tempPostContractFeatures.push({
          label: tranStr("Draft Cost Reports"),
          value: "Draft Cost Reports",
        });
      }
      if (project.enable_pc_trade_costs) {
        tempPostContractFeatures.push({
          label: tranStr("Trade Costs"),
          value: "Trade Costs",
        });
      }
      if (project.enable_pc_unfixed_materials) {
        tempPostContractFeatures.push({
          label: tranStr("Unfixed Materials"),
          value: "Unfixed Materials",
        });
      }
      if (project.enable_pc_variations) {
        tempPostContractFeatures.push({
          label: tranStr("Variations"),
          value: "Variations",
        });
      }
      if (project.enable_pc_provisional_sums) {
        tempPostContractFeatures.push({
          label: tranStr("Provisional Sums"),
          value: "Provisional Sums",
        });
      }
      if (project.enable_pc_provisional_quantities) {
        tempPostContractFeatures.push({
          label: tranStr("Provisional Quantities"),
          value: "Provisional Quantities",
        });
      }

      setFeaturesState(tempPostContractFeatures, setPostContractFeatures);
    }
  }, [project]);

  // Two useEffects are used simply to make the code more readable
  useEffect(() => {
    if (!isEmpty(project)) {
      // Set toggle Features
      let tempPostContractFeatures = [];

      if (project.enable_pc_delays) {
        tempPostContractFeatures.push({
          label: tranStr("Delays / EoTs"),
          value: "Delays / EoTs",
        });
      }
      if (project.enable_pc_client_directs) {
        tempPostContractFeatures.push({
          label: tranStr("Client Directs"),
          value: "Client Directs",
        });
      }
      if (project.enable_pc_risk_register) {
        tempPostContractFeatures.push({
          label: tranStr("Risk Register"),
          value: "Risk Register",
        });
      }
      if (project.enable_pc_cashflow) {
        tempPostContractFeatures.push({
          label: tranStr("Cashflow"),
          value: "Cashflow",
        });
      }

      setFeaturesState(tempPostContractFeatures, setPostContractFeatures);
    }
  }, [project]);

  // Two useEffects are used simply to make the code more readable
  useEffect(() => {
    if (!isEmpty(project)) {
      // Set toggle Features
      let tempPostContractFeatures = [];

      if (project.enable_pc_client_direct_variations) {
        tempPostContractFeatures.push({
          label: tranStr("Client Direct Variations"),
          value: "Client Direct Variations",
        });
      }
      if (project.enable_pc_contingency) {
        tempPostContractFeatures.push({
          label: tranStr("Contingency"),
          value: "Contingency",
        });
      }
      if (project.enable_pc_enabling_works) {
        tempPostContractFeatures.push({
          label: tranStr("Enabling Works"),
          value: "Enabling Works",
        });
      }
      if (project.enable_pc_enabling_works_variations) {
        tempPostContractFeatures.push({
          label: tranStr("EW Variations"),
          value: "Enabling Works Variations",
        });
      }
      if (project.enable_pc_project_team_consultant_fees) {
        tempPostContractFeatures.push({
          label: tranStr("Project Team Consultant Fees"),
          value: "Project Team Consultant Fees",
        });
      }
      if (project.enable_pc_project_team_consultant_fees_variations) {
        tempPostContractFeatures.push({
          label: tranStr("CF Variations"),
          value: "Project Team Consultant Fees Variations",
        });
      }
      if (project.enable_pc_tax) {
        tempPostContractFeatures.push({
          label: tranStr("Tax"),
          value: "Tax",
        });
      }

      setFeaturesState(tempPostContractFeatures, setPostContractFeatures);
    }
  }, [project]);

  const data = {
    URL: pcAPIs().update_project_features,

    projectData: {
      projectID: project.id,
      postContractFeatures: enabledFeatures(postContractFeatures),
    },
    completeAction: async () => {
      await projectUpdate(project);
    },
    setModal,
    setError,
  };

  // Return
  return (
    <div className="project-control-features">
      <ProjectControlSettings
        label={""}
        features={postContractFeatures}
        setFeatures={setPostContractFeatures}
        options={estimateFeatureOptions()}
      />
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>
      <SaveButton data={data} />
    </div>
  );

  function estimateFeatureOptions() {
    const labelValuePairs = [
      [tranStr("Draft Cost Reports"), "Draft Cost Reports"],
      [tranStr("Trade Costs"), "Trade Costs"],
      [tranStr("Unfixed Materials"), "Unfixed Materials"],
      [tranStr("Variations"), "Variations"],
      [tranStr("Provisional Sums"), "Provisional Sums"],
      [tranStr("Provisional Quantities"), "Provisional Quantities"],
      [tranStr("Delays / EoTs"), "Delays / EoTs"],
      [tranStr("Client Directs"), "Client Directs"],
      [tranStr("CD Variations"), "Client Direct Variations"],
      [tranStr("Enabling Works"), "Enabling Works"],
      [tranStr("EW Variations"), "Enabling Works Variations"],
      [tranStr("Consultant Fees"), "Project Team Consultant Fees"],
      [tranStr("CF Variations"), "Project Team Consultant Fees Variations"],
      [tranStr("Risk Register"), "Risk Register"],
      [tranStr("Cashflow"), "Cashflow"],
      [tranStr("Contingency"), "Contingency"],
      [tranStr("Tax"), "Tax"],
    ];

    const options = labelValuePairs.map((item) => ({
      label: item[0],
      value: item[1],
    }));

    return options;
  }

  function enabledFeatures(array) {
    let features = {
      enable_draft_cost_reports: false,
      enable_pc_trade_costs: true,
      enable_pc_unfixed_materials: true,
      enable_pc_variations: true,
      enable_pc_provisional_sums: true,
      enable_pc_provisional_quantities: true,
      enable_pc_delays: true,
      enable_pc_client_directs: true,
      enable_pc_client_direct_variations: true,
      enable_pc_enabling_works: true,
      enable_pc_enabling_works_variations: true,
      enable_pc_project_team_consultant_fees: true,
      enable_pc_project_team_consultant_fees_variations: true,
      enable_pc_risk_register: true,
      enable_pc_cashflow: true,
      enable_pc_contingency: true,
      enable_pc_tax: true,
    };

    features.enable_draft_cost_reports = checkIncludes(
      array,
      "Draft Cost Reports"
    );

    features.enable_pc_trade_costs = checkIncludes(array, "Trade Costs");
    features.enable_pc_unfixed_materials = checkIncludes(
      array,
      "Unfixed Materials"
    );
    features.enable_pc_variations = checkIncludes(array, "Variations");
    features.enable_pc_provisional_sums = checkIncludes(
      array,
      "Provisional Sums"
    );
    features.enable_pc_provisional_quantities = checkIncludes(
      array,
      "Provisional Quantities"
    );
    features.enable_pc_delays = checkIncludes(array, "Delays / EoTs");
    features.enable_pc_client_directs = checkIncludes(array, "Client Directs");
    features.enable_pc_client_direct_variations = checkIncludes(
      array,
      "Client Direct Variations"
    );

    features.enable_pc_enabling_works = checkIncludes(array, "Enabling Works");
    features.enable_pc_enabling_works_variations = checkIncludes(
      array,
      "Enabling Works Variations"
    );

    features.enable_pc_project_team_consultant_fees = checkIncludes(
      array,
      "Project Team Consultant Fees"
    );

    features.enable_pc_project_team_consultant_fees_variations = checkIncludes(
      array,
      "Project Team Consultant Fees Variations"
    );

    features.enable_pc_risk_register = checkIncludes(array, "Risk Register");
    features.enable_pc_cashflow = checkIncludes(array, "Cashflow");

    features.enable_pc_contingency = checkIncludes(array, "Contingency");

    features.enable_pc_tax = checkIncludes(array, "Tax");

    return features;
  }
}

/// props validation | SQ(javascript:S6774)
sendData.propTypes = {
  data: PropTypes.object,
};
///
async function sendData(data) {
  const url = data.URL;
  const payload = data.projectData;
  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  data.setError({
    text: tranStr("Saving..."),
    type: "project-stages-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    data.setError({
      text: tranStr("Saved Successfully"),
      type: "project-stages-success",
    });

    data.completeAction();

    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    data.setError({
      text: tranStr("Save Failed"),
      type: "project-stages-error",
    });
  }
}

function SaveButton(props) {
  const { data } = props;
  return (
    <div className="general-button-container">
      <div
        className="general-upload-button"
        onClick={() => {
          sendData(data);
        }}
      >
        {translate("Save")}
      </div>
    </div>
  );
}

function ProjectControlSettings(props) {
  const { features, setFeatures } = props;
  const { options } = props;
  const { label } = props;

  return (
    <div className="project-control-settings">
      <div className="project-control-settings-label">
        <b>{label}</b>
      </div>
      <CheckboxesVertical
        array={options}
        setFunction={setFeatures}
        selection={features}
      />
    </div>
  );
}

function checkIncludes(array, value) {
  return array.some((e) => e.value === value);
}

function setFeaturesState(tempPostContractFeatures, setPostContractFeatures) {
  setPostContractFeatures((features) => {
    let tempList = tempPostContractFeatures;
    features.forEach((feature) => {
      tempList.push(feature);
    });
    return tempList;
  });
}
