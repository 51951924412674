import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useBenchmarkingDisclaimerManager } from "./BenchmarkingDisclaimerTrigger.functions";
import { ButtonWithModal } from "./BenchmarkCreate.components";

import "./BenchmarkingDisclaimerTrigger.css";

/// props validation | SQ(javascript:S6774)
BenchmarkingDisclaimerTrigger.propTypes = {
  auth: PropTypes.object,
};
///
export default function BenchmarkingDisclaimerTrigger(props) {
  const { user } = props.auth;
  const [manager] = useBenchmarkingDisclaimerManager({ user });

  const { disclaimerAccepted, setShow } = manager;

  useEffect(() => {
    if (!disclaimerAccepted) {
      setShow(true);
    }
  }, [disclaimerAccepted, setShow]);

  return (
    <div className="benchmarking-disclaimer-trigger">
      <ButtonWithModal {...manager} />
    </div>
  );
}
