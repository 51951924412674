import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export default function CostPlanningClusteredBarChart(props) {
    const { deltas, project } = props;

    if (isEmpty(deltas)) {
        return null;
    }

    const field = [];
    const value = [];
    const delta = [];

    deltas.deltas.forEach((row) => {
        if (row !== undefined) {
            field.push(row.description);
            value.push(row.subtotal);
            delta.push(row.subtotal - row.delta);
        }
    });

    const datasets = [];

    if (deltas.comparison_stage_name !== "") {
        datasets.push({
            label: `${deltas.comparison_stage_name}, r. ${deltas.comparison_revision}`,
            data: delta,
            barThickness: 10,
            backgroundColor: "rgba(85, 87, 90, 0.7)",
        });
    }

    datasets.push({
        label: `${deltas.stage_name}, r. ${deltas.revision}`,
        data: value,
        barThickness: 10,
        backgroundColor: "rgba(228, 97, 15, 1)",
    });

    const graph = {
        labels: field,
        datasets: datasets,
        text: field,
    };

    const options = {
        animation: {
            duration: 3000,
        },
        maintainAspectRatio: true,
        responsive: true,
        resizeDelay: 5000,
        plugins: {
            datalabels: {
                display: false,
            },
        },
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem, chart) {
                    return project.currency_symbol + comma(tooltipItem.xLabel);
                },
            },
        },
        stacked: true,
        scales: {
            xAxes: [
                {
                    position: "left",
                    ticks: {
                        callback: function (tick, index, values) {
                            return project.currency_symbol + comma(tick);
                        },
                        min: 0,
                    },
                },
            ],
        },
        legend: {
            display: true,
            position: "top",
            fullWidth: true,
            reverse: false,
        },
    };

    const height = 30 * (datasets.length > 0 ? datasets[0].data.length : 1) + 100;

    return (
        <div className="recon-cost-comparison-graph">
            <h1 className="display-4" style={{ fontSize: "20px" }}>
                {translate("Cost Comparison")}
            </h1>
            <div>
                <HorizontalBar
                    data={graph}
                    options={options}
                    width={700}
                    responsive={true}
                    updateMode="resize"
                    maintainAspectRatio={false}
                    height={height}
                />
            </div>
        </div>
    );
}
