import React, { useState, useRef } from "react";

import isEmpty from "../../validation/is-empty";

import TableHead from "./EstimateBreakdownTableHead";
import Table from "./EstimateBreakdownTableRows";

import "./EstimateBreakdownTable.css";

export default function EstimateBreakdownTable(props) {
  const { data } = props;
  const { CPs } = props;
  const { project } = props;
  const { estimateID } = props;
  let { selectedRow } = props;
  let { filtered, notificationsOnly = false } = props;

  let tableClass = "ebt";
  const [search, setSearch] = useState("");

  const headCounter = useRef(1);
  const lineCounter = useRef(1);
  if (isEmpty(data)) {
    return null;
  }

  if (filtered) {
    tableClass = "ebt-filtered";
  }

  return (
    <div className={tableClass}>
      <TableHead
        projectID={project.id}
        selectedRow={selectedRow}
        search={search}
        estimateID={estimateID}
        setSearch={setSearch}
        enableLineRefs={project.enable_cp_line_references}
        filtered={filtered}
      />
      <Table
        data={data}
        search={search}
        projectID={project.id}
        CPs={CPs}
        project={project}
        selectedRow={selectedRow}
        enableLineRefs={project.enable_cp_line_references}
        lineCounter={lineCounter}
        headCounter={headCounter}
        filtered={filtered}
        notificationsOnly={notificationsOnly}
      />
    </div>
  );
}
