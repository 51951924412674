import React, { useEffect } from "react";

import isEmpty from "../../validation/is-empty";

import "./PostContractSelector.css";

import CostReportSelector from "./PostContractCostReportSelector";
import ProgressClaimSelector from "./PostContractProgressClaimSelector";
import RegisterSelector from "./PostContractCostReportRegisterSelector";

export default function PostContractSelector(props) {
  const { project } = props;
  const { postContract } = props;
  const { progressClaim, setProgressClaim } = props;
  const { costReport, setCostReport } = props;
  const { setComparisonCRs } = props;
  const { variations, setVariations } = props;
  const { dashboardType } = props;

  const allprogressClaims = postContract.data.progressClaims;
  const allcostReports = postContract.data.costReports;
  const allvariations = postContract.data.variations;

  // Set Defaults
  useEffect(() => {
    if (!isEmpty(allprogressClaims)) {
      const latestPC = allprogressClaims[allprogressClaims.length - 1];
      setProgressClaim(latestPC);
    } else {
      setProgressClaim({});
    }

    if (!isEmpty(allcostReports)) {
      const latestCR = allcostReports[allcostReports.length - 1];
      setCostReport(latestCR);
    } else {
      setCostReport({});
    }

    if (!isEmpty(allvariations)) {
      const latestVR = allvariations[allvariations.length - 1];
      setVariations(latestVR);
    } else {
      setVariations({});
    }
  }, [
    allprogressClaims,
    allcostReports,
    allvariations,
    setProgressClaim,
    setVariations,
    setCostReport,
  ]);

  if (
    window.location.href.includes("dashboard") &&
    dashboardType !== "POST_CONTRACT"
  ) {
    return null;
  }

  if (window.location.href.includes("cost-planning")) {
    return null;
  }

  return (
    <div className="post-contract-selector">
      <CostReportSelector
        project={project}
        data={postContract.data.costReports}
        costReport={costReport}
        setCostReport={setCostReport}
        setComparisonCRs={setComparisonCRs}
        setVariations={setVariations}
      />
      <ProgressClaimSelector
        project={project}
        data={postContract.data.progressClaims}
        progressClaim={progressClaim}
        setProgressClaim={setProgressClaim}
        setVariations={setVariations}
      />
      <RegisterSelector
        project={project}
        data={postContract.data.variations}
        postContract={postContract}
        costReport={costReport}
        progressClaim={progressClaim}
        register={variations}
        setRegister={setVariations}
        registerIssued={variations.variations_issued}
        registerType={"Variations"}
        visibleLocations={[
          "variations",
          "provisional-sums",
          "provisional-quantities",
          "client-directs",
          "delays",
          "client-direct-variation-register",
          "enabling-works",
          "consultant-fees",
          "contingency",
          "commentary"
        ]}
      />
    </div>
  );
}
