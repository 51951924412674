import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import "./ValueEngineeringTableRowDetailModal.css";

import isEmpty from "../../validation/is-empty";
import { comma, FormatDateTime } from "../../helpers/common.functions";

import ValueEngineeringItem from "./ValueEngineeringItem.class";
import { saveValueEngineeringItems } from "./ValueEngineering.functions";

import { SAVE_MESSAGES } from "../estimates/EstimatesTPISaveMessages";
import { VALUE_ENGINEERING } from "./ValueEngineering.strings";
import { getBox } from "./ValueEngineeringStatusControl";
import { AutoCloseModal } from "../modal/Modal";

// API
import { useCbs } from "../../api/cbs/CbsAPI";

const ProjectContext = createContext();

const FLAG_SAVE_PENDING = "flag_save_pending";
const FLAG_MARKED_FOR_DELETION = "flag_marked_for_deletion";

export default function ValueEngineeringTableRowDetailModal(props) {
  const { project, CPs, CP, estimate } = props;
  const { data } = props;
  const { veItems, setVeItems, resetVeItems } = props;
  const { selectedVeItemId, setSelectedVeItemId } = props;
  const { isReadonly = false } = props;
  const { isProjectPhaseCostPlanning } = props;
  const { setModal } = props;

  const { cbs } = useCbs(estimate?.benchmark?.standard);
  const elementCodes = [{ label: "Select Element Code", value: "" }];
  cbs?.data?.elementCodes?.forEach((c) => {
    elementCodes.push({
      id: c.id,
      label: c.code + " - " + c.description,
      value: c.code,
    });
  });

  const ProjectContextProviderValue = useMemo(
    function () {
      return {
        project,
        CPs,
      };
    },
    [project, CPs]
  );

  const [savePending, setSavePending] = useState(false);

  return (
    <ProjectContext.Provider value={ProjectContextProviderValue}>
      <div className="ve-table-row-detail-modal-wrapper">
        <div className="ve-table-row-detail-modal-left-pane">
          <VETableRowDetailModalLeftPaneList
            veItems={veItems}
            selectedVeItemId={selectedVeItemId}
            setSelectedVeItemId={setSelectedVeItemId}
          />
          {!isReadonly && (
            <VETableRowDetailModalLeftPaneFooter
              veItems={veItems}
              setVeItems={setVeItems}
              setSelectedVeItemId={setSelectedVeItemId}
              setSavePending={setSavePending}
            />
          )}
        </div>
        <div className="ve-table-row-detail-modal-right-pane">
          <VETableRowDetailModalRightPaneContents
            veItems={veItems}
            setVeItems={setVeItems}
            selectedVeItemId={selectedVeItemId}
            isReadonly={isReadonly}
            setSavePending={setSavePending}
            elementCodes={elementCodes}
          />
          {!isEmpty(veItems) && !isReadonly && (
            <VETableRowDetailModalRightPaneFooter
              data={data}
              project={project}
              CP={CP}
              estimate={estimate}
              veItems={veItems}
              setVeItems={setVeItems}
              resetVeItems={resetVeItems}
              selectedVeItemId={selectedVeItemId}
              setSelectedVeItemId={setSelectedVeItemId}
              savePending={savePending}
              setSavePending={setSavePending}
              setModal={setModal}
            />
          )}
          {!isProjectPhaseCostPlanning && (
            <VETableRowDetailModalRightPaneFooterMessage
              message={
                VALUE_ENGINEERING.WARNINGS
                  .VM_NOT_AVAILABLE_WHEN_NOT_IN_COST_PLANNING
              }
            />
          )}
        </div>
      </div>
    </ProjectContext.Provider>
  );
}

function VETableRowDetailModalLeftPaneList(props) {
  const { veItems } = props;
  const { selectedVeItemId, setSelectedVeItemId } = props;

  return (
    <>
      <div className="ve-table-row-detail-modal-left-pane-list">
        <VETableRowDetailModalLeftPaneListItems
          veItems={veItems}
          selectedVeItemId={selectedVeItemId}
          setSelectedVeItemId={setSelectedVeItemId}
        />
      </div>
      <VETableRowDetailModalLeftPaneListItemTotal veItems={veItems} />
    </>
  );
}

function VETableRowDetailModalLeftPaneListItems(props) {
  const { veItems } = props;
  const { selectedVeItemId, setSelectedVeItemId } = props;

  return (
    <>
      {veItems.map((row) => {
        const { id, title, value, status } = row;

        const key = `ve-table-row-detail-modal-left-pane-list_${id}`;

        const isSelected = id === selectedVeItemId;
        const isSavePending = row[FLAG_SAVE_PENDING];
        const isMarkedForDeletion = row[FLAG_MARKED_FOR_DELETION];

        let className = "";
        isSelected && (className += " selected");
        isSavePending && (className += ` ${FLAG_SAVE_PENDING}`);
        isMarkedForDeletion && (className += ` ${FLAG_MARKED_FOR_DELETION}`);

        return (
          <VETableRowDetailModalLeftPaneListItem
            key={key}
            className={className}
            id={id}
            title={title}
            value={value}
            status={status}
            setSelectedVeItemId={setSelectedVeItemId}
            showIcon={!isMarkedForDeletion}
          />
        );
      })}
    </>
  );
}

function VETableRowDetailModalLeftPaneListItem(props) {
  const { className } = props;
  const { id, title, value, status } = props;
  const { setSelectedVeItemId } = props;
  const { showIcon } = props;

  const box = getBox("status", status);
  const icon = showIcon ? box?.icon : "";
  const statusClass = showIcon ? box?.stage : "";

  return (
    <div
      className={`ve-table-row-detail-modal-vm-item ${className} ${statusClass}`}
      onClick={() => setSelectedVeItemId(id)}
    >
      <b className="vm-item-title">
        {title || VALUE_ENGINEERING.TITLE_PLACEHOLDER}
      </b>
      <span className="vm-item-value">{comma(value)}</span>
      <VERowDetailFieldIcon icon={icon} iconClass={statusClass} />
    </div>
  );
}

function VETableRowDetailModalLeftPaneListItemTotal(props) {
  const { veItems } = props;

  let total = 0;
  const statusList = [];

  veItems.forEach((row) => {
    // compute value total
    if (!row[FLAG_MARKED_FOR_DELETION]) {
      total += parseFloat(row?.value || 0);
      statusList.push(row?.status);
    }
  });

  // compute common status
  // if status of all non-deleted rows are same, the commonStatus will have that status, otherwise null
  const statusSet = new Set(statusList);
  const commonStatus = statusSet.size === 1 ? [...statusSet][0] : null;

  return (
    <VETableRowDetailModalLeftPaneListItem
      className={"vm-items-total"}
      title={"Total"}
      value={total}
      status={commonStatus}
      setSelectedVeItemId={() => null}
      showIcon={true}
    />
  );
}

const VALUE_FIELD_TYPES = {
  TEXTBOX: "textbox",
  TEXTAREA: "textarea",
  DROPDOWN: "dropdown",
  DROPDOWNLABELS: "dropdownlabels",
  DEFAULT: "default",
};

const nameof = (variable) => Object.keys(variable)[0];

function VETableRowDetailModalRightPaneContents(props) {
  const { veItems, setVeItems } = props;
  const { selectedVeItemId } = props;
  const { setSavePending } = props;
  const { isReadonly } = props;
  const { elementCodes } = props;

  if (isEmpty(veItems) || selectedVeItemId === null) {
    return (
      <VETableRowDetailModalRightPaneNoContent
        icon={"fas fa-hand-holding-usd"}
        text={VALUE_ENGINEERING.WARNINGS.VM_NOT_YET_ADDED}
      />
    );
  }

  const index = veItems.findIndex((row) => row.id === selectedVeItemId);
  const row = veItems[index];

  if (row[FLAG_MARKED_FOR_DELETION]) {
    return (
      <VETableRowDetailModalRightPaneNoContent
        icon={"far fa-trash-alt"}
        text={VALUE_ENGINEERING.WARNINGS.VM_ITEM_DELETED}
      />
    );
  }

  return (
    <div className="ve-table-row-detail-modal-vm-item-contents">
      <VETableRowDetailModalVEItemForm
        veItems={veItems}
        setVeItems={setVeItems}
        setSavePending={setSavePending}
        isReadonly={isReadonly}
        index={index}
        row={row}
        elementCodes={elementCodes}
      />
    </div>
  );
}

function VETableRowDetailModalRightPaneNoContent(props) {
  const { icon, text } = props;

  return (
    <div className="ve-item-not-selected">
      <i className={`${icon} fa-10x`} />
      <div className="text">{text}</div>
    </div>
  );
}

function VETableRowDetailModalVEItemForm(props) {
  const { veItems, setVeItems } = props;
  const { setSavePending } = props;
  const { isReadonly } = props;
  const { index, row } = props;
  const { elementCodes } = props;

  const updateField = useCallback(
    function updateField({ name, value }) {
      const _veItems = [...veItems];
      setSavePending(true);

      const _veItem = _veItems[index];
      _veItem[name] = value;
      _veItem[FLAG_SAVE_PENDING] = true;

      setVeItems(_veItems);
    },
    [index, veItems, setVeItems, setSavePending]
  );

  const {
    REF,
    TITLE,
    DESCRIPTION,
    WORK_PACKAGE,
    LOCATION,
    VALUE,
    STATUS,
    WBS_CODE,
    ACTION,
    ACTION_OWNER,
    PROGRAMME_IMPACT,
    ENVIRONMENTAL_IMPACT,
    LAST_UPDATED_BY,
    LAST_UPDATED_DATE,
  } = VALUE_ENGINEERING.FIELDS;

  const {
    title,
    description,
    work_package,
    location,
    value,
    status,
    wbs_code,
    action,
    action_owner,
    programme_impact,
    environmental_impact,
    last_updated_by,
    last_updated_date,
  } = row;

  const ref = index + 1;
  const box = getBox("status", status);
  // box contains one of the values of 'const boxes' from ValueEngineeringStatusControl depending on status

  return (
    <>
      <div className="field-row">
        <VETableRowDetailField
          isReadonly={true}
          label={REF}
          value={ref}
          fieldClass={"ref"}
        />
        <VETableRowDetailField
          name={nameof({ title })}
          isReadonly={isReadonly}
          label={TITLE}
          value={title}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          name={nameof({ description })}
          isReadonly={isReadonly}
          label={DESCRIPTION}
          value={description}
          fieldClass={"description"}
          valueFieldType={VALUE_FIELD_TYPES.TEXTAREA}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          name={nameof({ work_package })}
          isReadonly={isReadonly}
          label={WORK_PACKAGE}
          value={work_package}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
        <VETableRowDetailField
          name={nameof({ location })}
          isReadonly={isReadonly}
          label={LOCATION}
          value={location}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          name={nameof({ value })}
          isReadonly={isReadonly}
          label={VALUE}
          value={value}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
        <VETableRowDetailField
          name={nameof({ status })}
          isReadonly={isReadonly}
          label={STATUS}
          value={status}
          valueList={Object.values(VALUE_ENGINEERING.STATUS)}
          icon={box?.icon}
          iconClass={box?.stage}
          valueFieldType={VALUE_FIELD_TYPES.DROPDOWN}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          name={nameof({ wbs_code })}
          isReadonly={isReadonly}
          label={WBS_CODE}
          value={wbs_code}
          valueList={elementCodes}
          valueFieldType={VALUE_FIELD_TYPES.DROPDOWNLABELS}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          name={nameof({ action })}
          isReadonly={isReadonly}
          label={ACTION}
          value={action}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
        <VETableRowDetailField
          name={nameof({ action_owner })}
          isReadonly={isReadonly}
          label={ACTION_OWNER}
          value={action_owner}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          name={nameof({ programme_impact })}
          isReadonly={isReadonly}
          label={PROGRAMME_IMPACT}
          value={programme_impact}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
        <VETableRowDetailField
          name={nameof({ environmental_impact })}
          isReadonly={isReadonly}
          label={ENVIRONMENTAL_IMPACT}
          value={environmental_impact}
          valueFieldType={VALUE_FIELD_TYPES.TEXTBOX}
          updateField={updateField}
        />
      </div>
      <div className="field-row">
        <VETableRowDetailField
          isReadonly={true}
          label={LAST_UPDATED_BY}
          value={last_updated_by}
        />
        <VETableRowDetailField
          isReadonly={true}
          label={LAST_UPDATED_DATE}
          value={FormatDateTime(last_updated_date)}
        />
      </div>
      <div className="field-row spacer"></div>
    </>
  );
}

function VETableRowDetailField(props) {
  const { name } = props;
  const { label, value, valueList } = props;
  const { fieldClass } = props;
  const { labelClass, valueClass } = props;
  const { icon, iconClass } = props;
  const { valueFieldType = VALUE_FIELD_TYPES.DEFAULT } = props;
  const { isReadonly } = props;
  const { updateField } = props;

  const onChange = (e) =>
    updateField({
      name,
      value: e.target.value,
    });

  const readonlyClass = isReadonly ? "readonly" : "";

  return (
    <div className={`ve-item-field ${fieldClass} ${readonlyClass}`}>
      <div className={`field-label ${labelClass}`}>
        <b>{label}</b>
      </div>
      <div className={`field-value ${valueClass}`}>
        {icon && <VERowDetailFieldIcon icon={icon} iconClass={iconClass} />}
        <VERowDetailFieldValue
          valueFieldType={valueFieldType}
          value={value}
          valueList={valueList}
          isReadonly={isReadonly}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

function VERowDetailFieldIcon(props) {
  const { icon, iconClass } = props;
  return <i className={`ve-item-field-icon ${icon} ${iconClass}`} />;
}

function VERowDetailFieldValue(props) {
  const { valueFieldType, value, valueList = [] } = props;
  const { isReadonly } = props;
  const { onChange } = props;

  const component = {
    [VALUE_FIELD_TYPES.TEXTBOX]: (
      <input
        type="textbox"
        value={value}
        className="text"
        onChange={onChange}
      />
    ),
    [VALUE_FIELD_TYPES.TEXTAREA]: (
      <textarea value={value} className="text" onChange={onChange} />
    ),
    [VALUE_FIELD_TYPES.DROPDOWN]: (
      <select className="text" value={value} onChange={onChange}>
        {valueList.map((v) => (
          <option key={v} value={v} className="option">
            {v}
          </option>
        ))}
      </select>
    ),
    [VALUE_FIELD_TYPES.DROPDOWNLABELS]: (
      <select className="text" value={value} onChange={onChange}>
        {valueList.map((v) => (
          <option key={v.id} value={v.value} className="option">
            {v.label}
          </option>
        ))}
      </select>
    ),
    [VALUE_FIELD_TYPES.DEFAULT]: <div className="text">{value}</div>,
  };

  return component[isReadonly || !valueFieldType ? "default" : valueFieldType];
}

function VETableRowDetailModalLeftPaneFooter(props) {
  const { veItems, setVeItems } = props;
  const { setSelectedVeItemId } = props;
  const { setSavePending } = props;

  return (
    <div className="ve-table-row-detail-modal-footer left-pane">
      <AddVEItemButton
        veItems={veItems}
        setVeItems={setVeItems}
        setSelectedVeItemId={setSelectedVeItemId}
        setSavePending={setSavePending}
      />
    </div>
  );
}

function VETableRowDetailModalRightPaneFooter(props) {
  const { data } = props;
  const { veItems, setVeItems, resetVeItems } = props;
  const { selectedVeItemId } = props;
  const { savePending, setSavePending } = props;
  const { setModal } = props;
  const { project, CP, estimate } = props;
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage(savePending ? SAVE_MESSAGES.NOT_SAVED : SAVE_MESSAGES.NONE);
  }, [savePending]);

  return (
    <div className="ve-table-row-detail-modal-footer right-pane">
      <DeleteVEItemButton
        veItems={veItems}
        setVeItems={setVeItems}
        selectedVeItemId={selectedVeItemId}
        setSavePending={setSavePending}
      />
      <div className="message">{message}</div>
      <DiscardChangesButton
        savePending={savePending}
        setSavePending={setSavePending}
        resetVeItems={resetVeItems}
      />
      <SaveVEItemsButton
        data={data}
        project={project}
        CP={CP}
        estimate={estimate}
        veItems={veItems}
        setMessage={setMessage}
        setModal={setModal}
      />
    </div>
  );
}

function VETableRowDetailModalRightPaneFooterMessage(props) {
  const { message } = props;

  return (
    <div className="ve-table-row-detail-modal-footer message-only right-pane">
      <div className="message">{message}</div>
    </div>
  );
}

function ActionButton(props) {
  const { id, className, tooltip, onClick, icon, label } = props;

  return (
    <div
      id={id}
      className={`button ${className}`}
      title={tooltip}
      onClick={onClick}
    >
      {icon && <i className={icon} />}
      {label && <span className="text">{label}</span>}
    </div>
  );
}

function AddVEItemButton(props) {
  const { veItems, setVeItems } = props;
  const { setSelectedVeItemId } = props;
  const { setSavePending } = props;

  function addVEItem() {
    const _veItem = new ValueEngineeringItem();
    _veItem[FLAG_SAVE_PENDING] = true;

    const _veItems = [...veItems, _veItem];
    setSavePending(true);

    setVeItems(_veItems);
    setSelectedVeItemId(_veItem.id);
  }

  return (
    <ActionButton
      id={"ve-table-add-vm"}
      className={"general-modal-button"}
      tooltip={VALUE_ENGINEERING.ACTIONS.ADD_VM_ITEM.TOOLTIP}
      onClick={addVEItem}
      icon={"fas fa-plus"}
      label={VALUE_ENGINEERING.ACTIONS.ADD_VM_ITEM.LABEL}
    />
  );
}

function DeleteVEItemButton(props) {
  const { veItems, setVeItems } = props;
  const { selectedVeItemId } = props;
  const { setSavePending } = props;

  function deleteVeItem() {
    const _veItems = [...veItems];
    const index = _veItems.findIndex((row) => row.id === selectedVeItemId);

    const _veItem = _veItems[index];
    _veItem[FLAG_MARKED_FOR_DELETION] = true;
    _veItem[FLAG_SAVE_PENDING] = false;

    setSavePending(true);
    setVeItems(_veItems);
  }

  return (
    <ActionButton
      id={"ve-table-delete-vm"}
      className={"advice-delete-button"}
      tooltip={VALUE_ENGINEERING.ACTIONS.DELETE_VM_ITEM.TOOLTIP}
      onClick={deleteVeItem}
      icon={"far fa-trash-alt"}
      label={VALUE_ENGINEERING.ACTIONS.DELETE_VM_ITEM.LABEL}
    />
  );
}

function DiscardChangesButton(props) {
  const { savePending, setSavePending } = props;
  const { resetVeItems } = props;

  if (!savePending) {
    return <></>;
  }

  function discardChanges() {
    resetVeItems();
    setSavePending(false);
  }

  return (
    <ActionButton
      id={"ve-table-discard-changes"}
      className={"general-modal-button"}
      tooltip={VALUE_ENGINEERING.ACTIONS.DISCARD_CHANGES.TOOLTIP}
      onClick={discardChanges}
      icon={"fas fa-undo"}
      label={VALUE_ENGINEERING.ACTIONS.DISCARD_CHANGES.LABEL}
    />
  );
}

function SaveVEItemsButton(props) {
  const { CP, estimate } = props;
  const { veItems } = props;
  const { setMessage } = props;
  const { setModal } = props;

  const { project, CPs } = useContext(ProjectContext);

  async function saveVeItems() {
    setMessage(SAVE_MESSAGES.SAVING);

    const data = {
      project_id: project?.id,
      cost_plan_id: CP?.cost_plan_id,
      estimate_id: estimate?.id,
    };

    const { response, errorMessage } = await saveValueEngineeringItems({
      data,
      veItems,
    });

    if (errorMessage.text) {
      setMessage(SAVE_MESSAGES.ERROR_MESSAGE(errorMessage.text));
      return;
    }

    if (response.ok) {
      await CPs.set(await CPs.get(project.id, project));
      setMessage(SAVE_MESSAGES.SAVED);

      AutoCloseModal(setModal);
    } else {
      setMessage(SAVE_MESSAGES.SAVE_FAILED);
    }

    setTimeout(() => {
      setMessage(SAVE_MESSAGES.NONE);
    }, 1000);
  }

  return (
    <ActionButton
      id={"ve-table-save-vm"}
      className={"general-upload-button"}
      tooltip={VALUE_ENGINEERING.ACTIONS.SAVE_VM_ITEMS.TOOLTIP}
      onClick={saveVeItems}
      icon={"fas fa-save"}
      label={VALUE_ENGINEERING.ACTIONS.SAVE_VM_ITEMS.LABEL}
    />
  );
}
