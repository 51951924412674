import isEmpty from "../validation/is-empty";

export function escalateFer(cData, benchmark, rate) {
  const { currencyData } = cData;
  const { currency } = currencyData;
  const { currencyExchange } = currencyData;
  const bmCurrency = benchmark.currency;

  if (isEmpty(currency) || isEmpty(currencyExchange)) {
    return { ferRate: rate, ferPercent: 0 };
  }

  let escalatedRate;
  let escalatedPercent;

  if (!isEmpty(bmCurrency)) {
    const exchangeRate = currencyExchange?.find(
      (ce) => ce.code === bmCurrency
    )?.rate;

    const targetRate = currencyExchange?.find(
      (ce) => ce.code === currency
    )?.rate;

    escalatedRate = Math.round((targetRate / exchangeRate) * rate);
    escalatedPercent = (targetRate / exchangeRate) * 100 - 100;
  }

  if (isNaN(escalatedRate)) {
    escalatedRate = rate;
  }

  return { ferRate: escalatedRate, ferPercent: escalatedPercent };
}
