import React from "react";
import { GENERATED, TEMPLATE } from "../../actions/types";
import { translate } from "../../utils/translation";
import "./CashflowTable.css";

const PostContractCashflowToggle = (props) => {
  const { cashflowBasis, setCashflowBasis } = props;
  //// const { projectID } = props;

  let templateToggle, generatedToggle;

  if (cashflowBasis === TEMPLATE) {
    templateToggle = "cashflow-toggle-button-in-left";
    generatedToggle = "cashflow-toggle-button-out-right";
  } else {
    templateToggle = "cashflow-toggle-button-out-left";
    generatedToggle = "cashflow-toggle-button-in-right";
  }

  return (
    <div className="cashflow-toggle">
      <div
        className={templateToggle}
        onClick={() => {
          setCashflowBasis(TEMPLATE);
        }}
      >
        {translate("Template")}
      </div>
      <div
        className={generatedToggle}
        onClick={() => {
          setCashflowBasis(GENERATED);
        }}
      >
        {translate("Generated")}
      </div>
    </div>
  );
};

export default PostContractCashflowToggle;
