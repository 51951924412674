import React from "react";
import isEmpty from "../../validation/is-empty";
import PDPCDonutChart from "../post-contract-cost-report/PDPCDonutChart";

import { generateRiskRegisterTypes } from "./CostReportRiskRegisterChart.functions";

import "../cost-planning/CostPlanningDashboard.css";

// Needs Refactoring
export default function CostReportRiskRegisterChart(props) {
  const { RRs } = props;

  if (isEmpty(RRs)) {
    return null;
  }

  const { data } = generateRiskRegisterTypes(RRs);

  return (
    <PDPCDonutChart
      data={data}
      width={200}
      height={200}
      eRadius={90}
      iRadius={70}
      lWidth={270}
      lHeight={150}
    />
  );
}
