import React from "react";
import { translate } from "../../utils/translation";
import { Link } from "react-router-dom";

// Styles
import "./EstimateBreakdownButtons.css";

export default function EstimateBreakdownButtons(props) {
  const { projectID } = props;
  // Display on Estimate Breakdown only
  if (
    window.location.href.includes("cost-planning/key-documents") ||
    window.location.href.includes("cost-planning/dashboard")
  ) {
    return null;
  }

  return (
    <div className="ebb">
      <div className="ebb-row">
        {window.location.href.includes("cost-planning") &&
          !window.location.href.includes("cost-planning/summary") && (
            <Link
              to={`/project/${projectID}/cost-planning/summary`}
              className="ebb-button"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {translate("Back to Summary")}
            </Link>
          )}
        {window.location.href.includes("post-contract") &&
          !window.location.href.includes("post-contract/cost-report") && (
            <Link
              to={`/project/${projectID}/post-contract/cost-report`}
              className="ebb-button"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {translate("Back to Cost Report")}
            </Link>
          )}
      </div>
      <div className="ebb-row">
        <div
          className="ebb-button"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          {translate("Back to Top")}
        </div>
      </div>
    </div>
  );
}
