import React from "react";

export function PCRAStatusSummaryItem(props) {
  const { stage, status, isLast } = props;
  const stageAbbrevation = stage.charAt(0).toUpperCase();

  let icon;
  switch (status) {
    case "PENDING":
      icon = "far fa-clock fa-xs";
      break;
    case "CURRENT":
      icon = "fas fa-ellipsis-h fa-xs";
      break;
    case "PASSED":
      icon = "fas fa-check fa-xs";
      break;

    default:
      icon = "";
  }

  return (
    <>
      <div className={`stage-icon ${stage}`}>
        <div className="text">{stageAbbrevation}</div>
        <i className={icon} />
      </div>
      {!isLast && <div className={`stage-connector-line ${status}`}></div>}
    </>
  );
}
