const isEmpty = value =>
  value === undefined ||
  value === null ||
  // If it's an object, and the keys are 0, it's an empty object
  (typeof value === "object" && Object.keys(value).length === 0) ||
  // Empty string
  (typeof value === "string" && value.trim().length === 0);

// With react we are using ES6 module, not common JS,
// so we use export default
export default isEmpty;
