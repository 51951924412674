export function formatUOM(value, metric, convertToPercentage) {
  if (metric.toLowerCase().includes("area")) {
    return value + "m²";
  }

  if (value.toString().toLowerCase().includes("0.") && convertToPercentage) {
    return value * 100 + "%";
  }

  return value;
}
