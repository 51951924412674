import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function uploadAreaSchedule(data) {
  if (data.file === null) {
    return data.setError({
      text: tranStr("Please attach file"),
      type: "feedback-error",
    });
  }

  data.setError({
    text: tranStr("Creating..."),
    type: "feedback-success",
  });

  let field = JSON.stringify({
    project_id: data.projectID,
    cost_plan_id: data.CP.cost_plan_id,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // COST PLAN API
  const url = cpAPIs().upload_area_schedule;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
    // Response OK
    data.CPs.set(await data.CPs.get(data.projectID, data.project));
    data.setError({
      text: response.message,
      type: "feedback-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    response = await response.json();
    return data.setError({
      text: response.message,
      type: "feedback-error",
    });
  }
}

export async function allocateCost(fields, data, difference) {
  const { CPs, cost_plan_id } = data;
  const { projectID } = data;
  const { project } = data;
  const { estimate_id } = data;
  const { url } = data;

  if (difference !== 0) {
    return data.setError({
      text: "All of the cost estimate total must be allocated before submitting",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const payload = {
    allocation_items: fields,
    estimate_id: estimate_id,
    cost_plan_id: cost_plan_id,
    projectID: projectID,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    CPs.set(await CPs.get(projectID, project));
    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });

    // close modal after timeout
    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}
