import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import isEmpty from "../../validation/is-empty";
import "chartjs-plugin-datalabels";
import { comma } from "../../helpers/common.functions";

export default function PortfolioComparisonChart(props) {
  const { dashboardID } = props;
  const { graphData } = props;
  const { graphLabels } = props;
  const { unit } = props;
  const { max } = props;

  if (isEmpty(graphData)) {
    return null;
  }

  // Create the options object
  const options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { color: "black" } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return (
            comma(tooltipItem.xLabel) +
            unit +
            " - " +
            chart.datasets[tooltipItem.datasetIndex].label
          );
        },
      },
    },
    stacked: true,
    scales: {
      xAxes: [
        {
          position: "left",
          ticks: {
            callback: function (value, index, values) {
              return comma(value) + unit;
            },
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          ticks: {
            callback: function (value, index, values) {
              let displayName = value;
              const limit = 25;
              const elipsis = limit - 3;
              if (displayName.length > limit) {
                displayName = displayName.slice(0, elipsis) + "...";
              }
              return displayName;
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      fullWidth: true,
      reverse: false,
    },
  };

  // Conditionally add the max scale
  if (max) {
    options.scales.xAxes[0].ticks["max"] = max;
  }

  // Compute the height
  let height = 100;
  if (graphLabels.length > 2) {
    height = graphLabels.length * 30;
  }

  if (graphLabels.length > 10) {
    height = graphLabels.length * 25;
  }

  return (
    <div key={dashboardID} height={height}>
      <HorizontalBar
        key={dashboardID}
        data={graphData}
        options={options}
        responsive={true}
        maintainAspectRatio={false}
      />
    </div>
  );
}
