// React
import React, { useState, useEffect } from "react";

// API
import { useCbs, useCbsCodes, updateCbsPublish } from "../../api/cbs/CbsAPI";

// Components
import { CbsCategories } from "./CbsCategories";
import { CbsElements } from "./CbsElements";
import { CbsCategoryControlPanel } from "./CbsCategoryControlPanel";
import { CbsDropDown } from "./CbsDropdown";
import { CbsAdminModal } from "./CbsAdminModal";
import isEmpty from "../../validation/is-empty";

// Functions
import { hasRoles } from "../../utils/roles";

// Styling
import "./CbsAdmin.css";

export default function CbsAdmin(props) {
  const { user } = props;

  // List of CBS
  const isNotPublished = true;
  const { cbsCodes } = useCbsCodes(isNotPublished);

  // Selected CBS
  const [selectedCbs, setSelectedCbs] = useState("");
  const { cbs } = useCbs(selectedCbs);

  // Selected Categories and Elements
  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  // If the selected CBS changes reset the selected elements
  useEffect(() => {
    setSelectedElements([]);
    setSelectedCategory({});
  }, [selectedCbs]);

  // RBAC
  if (!hasRoles(user.roles, ["CbsAdmin"])) {
    return null;
  }

  return (
    <div className="cbs-admin">
      <div className="cbs-panel">
        <div className="cbs-intro-row">
          <div className="cbs-intro">
            <div>Begin by selecting a CBS or creating a new one.</div>
          </div>
        </div>
        <div className="cbs-intro-row">
          <div className="cbs-intro-container">
            <CbsDropDown
              id={"cbs-admin-dropdown"}
              placeholder={"Select CBS"}
              get={selectedCbs}
              set={setSelectedCbs}
              data={cbsCodes.data}
            />
          </div>
          <div className="cbs-intro-container">
            <CbsAdminModal
              cbsCodes={cbsCodes}
              setSelectedCbs={setSelectedCbs}
              cbs={cbs}
            />
          </div>
        </div>
      </div>
      <CbsPublish cbs={cbs} />
      <CbsInfo cbs={cbs} setSelectedCategory={setSelectedCategory} />
      <div className="cbs-content-row">
        <CbsElements
          cbs={cbs}
          selectedCategory={selectedCategory}
          selectedElements={selectedElements}
          setSelectedElements={setSelectedElements}
        />
        <CbsCategories
          cbs={cbs}
          selectedCategory={selectedCategory}
          selectedElements={selectedElements}
          setSelectedElements={setSelectedElements}
        />
      </div>
    </div>
  );
}

function CbsPublish(props) {
  const { cbs } = props;

  if (isEmpty(cbs?.data)) {
    return null;
  }

  let text = "";
  if (cbs?.data?.isPublished) {
    text = "This CBS is published and cannot be changed.";
  } else {
    text = "Publish this CBS to make it available.";
  }

  return (
    <div className="cbs-panel">
      <div className="cbs-intro-row">
        <div className="cbs-publish-container">
          <div className="cbs-typecode">{cbs?.data?.typeCode}</div>
        </div>
        <div className="cbs-publish-container">
          <div className="cbs-publish">
            <div className="cbs-publish-text"></div>
            <PublishButton cbs={cbs} />
            <div className="cbs-publish-text">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PublishButton(props) {
  const { cbs } = props;
  const [message, setMessage] = useState("");

  let classType = "";
  let buttonText = "";
  if (cbs?.data?.isPublished) {
    classType = "general-delete-button-flat";
    buttonText = "Published";
  } else {
    classType = "general-upload-button";
    buttonText = "Publish";
  }

  return (
    <div>
      <div
        id="cbs-admin-publish-button"
        className={classType}
        onClick={async () => {
          const cbsId = cbs?.data?.id;
          const typeCode = cbs?.data?.typeCode;

          let result = await updateCbsPublish(cbsId, true);
          if (result.success) {
            cbs.set(await cbs.get(typeCode));
          } else {
            setMessage(result.message);
          }
        }}
      >
        {buttonText}
      </div>
      <div>{message}</div>
    </div>
  );
}

function CbsInfo(props) {
  const { cbs } = props;
  const { setSelectedCategory } = props;

  if (isEmpty(cbs?.data)) {
    return null;
  }

  return (
    <div className="cbs-content-row">
      <div className="cbs-content-container">
        <div className="cbs-content-subheading">Elemental Breakdown</div>
        <div className="cbs-content-text">
          This is the breakdown of elements which define the cost breakdown
          structure.
        </div>
        <div className="cbs-content-element-headings">
          <div className="cbs-content-element-code">Code</div>
          <div className="cbs-content-element-description">Description</div>
          <div className="cbs-content-element-unit">Unit</div>
        </div>
      </div>
      <div className="cbs-content-container">
        <div className="cbs-content-subheading">Categories</div>
        <div className="cbs-content-text">
          This is the list of categories used for grouping the elemental
          breakdown codes. Select one of the categories to view the list of
          category items and its grouped codes.
        </div>
        <div className="cbs-element-row">
          <CbsCategoryControlPanel
            setSelectedCategory={setSelectedCategory}
            cbs={cbs}
          />
        </div>
      </div>
    </div>
  );
}
