import React from "react";
import PropTypes from "prop-types";

import ProjectComparison from "../benchmarking-project/ProjectComparison";
import isEmpty from "../../validation/is-empty";

import "./PDCPBenchmarkComparisonGraph.css";

/// props validation | SQ(javascript:S6774)
PDCPBenchmarkComparisonGraphSingle.propTypes = {
  project: PropTypes.any,
  estimate: PropTypes.any,
  benchmarks: PropTypes.array,
  thisBenchmark: PropTypes.object,
  futureYear: PropTypes.any,
};
///
export default function PDCPBenchmarkComparisonGraphSingle(props) {
  const { project } = props;
  const { estimate } = props;
  const { benchmarks } = props;
  const { thisBenchmark } = props;
  const { futureYear } = props;

  if (isEmpty(estimate)) {
    return null;
  }

  if (isEmpty(benchmarks)) {
    return null;
  }

  return (
    <ProjectComparison
      unitOfMeasure={project.unit_of_measure}
      thisBenchmark={thisBenchmark}
      benchmarks={benchmarks}
      futureYear={futureYear}
      dashboard
      isEscalated
    />
  );
}
