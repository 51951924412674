import React from "react";

import isEmpty from "../../validation/is-empty";
import CommentaryTable from "../post-contract-commentary/PCCommentaryTable";

export default function PDPCCommentary(props) {
  const { costReport, commentType } = props;
  const { commentary } = costReport;

  if (isEmpty(commentary)) {
    return null;
  }
  let cr_commentary = commentary.filter((x) => x.type === commentType);
  return <CommentaryTable commentary={cr_commentary} />;
}
