import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export function generatePaymentOverviewData(PC) {
  let fields = [
    {
      field: tranStr("Construction Work Complete"),
      value: PC.construction_work_complete,
    },
    {
      field: tranStr("Unfixed Materials"),
      value: PC.unfixed_materials_on_site + PC.unfixed_materials_off_site,
    },
    {
      field: tranStr("Variations Work Complete"),
      value: PC.variations_work_complete,
    },
    {
      field: tranStr("Provisional Quantities Work Complete"),
      value: PC.prov_quants_work_complete,
    },
    {
      field: tranStr("Provisional Sums Work Complete"),
      value: PC.prov_sums_work_complete,
    },
    {
      field: tranStr("Provisional Delay Days Work Complete"),
      value: PC.extensions_of_time_work_complete,
    },
  ];

  return fields.map((o, i) => {
    return {
      quantity: isEmpty(o.value) ? "" : o.value.toFixed(0), // display value
      value: o.value, // value used for total calculation
      percentage: (
        (o.value / PC.gross_total_work_executed_excl_tax) *
        100
      ).toFixed(1),
      name: o.field,
      id: i + 1,
    };
  });
}

export function calculateTotal(selection) {
  let sum = 0;
  selection.forEach((item) => {
    sum += item.value;
  });
  return Math.round(sum);
}
