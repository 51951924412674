import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";
import "./EstimatesTotal.css";

// Needs Refactoring
export default function EstimatesTotal(props) {
  const { project, CP } = props;
  const { isBreakdown } = props;
  const { selectedRow } = props;

  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return null;
  }

  if (CP.estimates.length < 2) {
    return null;
  }

  if (isBreakdown) {
    if (selectedRow !== "breakdown") {
      return null;
    }
  }

  return (
    <div className="estimate-total">
      <div className="estimates-total-label">{CP.calculated_total_name}</div>
      <div className="estimates-total-costs">
        <Detail CP={CP} project={project} />
        <div className="estimates-total-total">
          {comma(CP.calculated_total)}
        </div>
      </div>
    </div>
  );
}

function Detail(props) {
  const { CP, project } = props;
  if (
    isEmpty(CP.calculated_local_region_area_total) ||
    isEmpty(project.unit_of_measure) ||
    isEmpty(CP.calculated_total_rate)
  ) {
    return null;
  }

  return (
    <div className="estimates-total-details">
      <div className="estimates-total-gfa">
        {comma(CP.calculated_local_region_area_total)}
      </div>
      <div className="estimates-total-unit">{project.unit_of_measure}</div>
      <div className="estimates-total-rate">
        {commaToFixed(CP.calculated_total_rate)}
      </div>
    </div>
  );
}
