import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";

// Components
import Table from "../table/Table";
import PostContractClientDirectsModal from "../post-contract-table-modals/PostContractClientDirectsModal";
import Modal from "../modal/Modal";

// Visualisations
import PostContractTableContainer from "../post-contract-tables/PostContractTableContainer";
import CostReportClientDirectCostsSummary from "../post-contract-cost-report/CostReportClientDirectCostsSummary";

// Functions
import { cdColumns } from "../post-contract-tables/PostContractTable.functions";
import isEmpty from "../../validation/is-empty";

// Styles
import "./PostContractClientDirects.css";

export default function PostContractClientDirects(props) {
  const { CDs } = props;
  const { variations } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  // No data available so return null
  if (isEmpty(CDs)) {
    return null;
  }

  // Visualisations
  const visuals = [
    <CostReportClientDirectCostsSummary
      title={translate("Client Direct Costs Summary")}
      costReport={variations.cost_report}
    />,
  ];

  return (
    <div>
      <PostContractTableContainer Components={visuals} />
      <div className="pct-spacer" />
      <Table
        title={tranStr("Client Directs")}
        tableArray={CDs}
        columns={cdColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractClientDirectsModal}
        modal={modal}
        setModal={setModal}
        columns={cdColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
