import isEmpty from "../../validation/is-empty";
import { getBenchmarksWithRows } from "../benchmarking/BenchmarkingAPI";
import { getUsersByIDs } from "../users/UserAPI";

export function userIDsFromBreakdown(CPs) {
  const userIDs = new Set();
  // Stage
  CPs.forEach((CPStage) => {
    if (!isEmpty(CPStage.versions)) {
      // Revision
      CPStage.versions.forEach((CP) => {
        // Estimate
        CP.estimates.forEach((estimate) => {
          // Rows
          estimate.rows.forEach((row) => {
            // Breakdown
            row.breakdown.forEach((breakdown) => {
              // Breakdown Comments
              breakdown.comments.forEach((comment) => {
                userIDs.add(comment.author_id);
              });
            });
          });
        });
      });
    }
  });
  return Array.from(userIDs);
}

export async function addBreakdownUsersCostPlanning(CPs) {
  const userIDs = userIDsFromBreakdown(CPs);
  let users = [];
  if (!isEmpty(userIDs)) {
    users = await getUsersByIDs(userIDs);
  }

  // Stage
  CPs.forEach((CPStage) => {
    // Revision
    CPStage.versions.forEach((CP) => {
      // Estimate
      CP.estimates.forEach((estimate) => {
        // Rows
        estimate.rows.forEach((row) => {
          // Breakdown
          row.breakdown.forEach((breakdown) => {
            // Breakdown Comments
            breakdown.comments.forEach((comment) => {
              users.forEach((user) => {
                // activeDirectoryId
                if (comment.author_id === user.id) {
                  comment.user = user;
                }
              });

              if (isEmpty(comment.user)) {
                comment.user = {
                  name: "Unknown",
                  surname: "Unknown",
                  email: "Unknown",
                };
              }
            });
          });
        });
      });
    });
  });

  return CPs;
}

export function benchmarkIDsFromCostPlan(CPs) {
  let benchmarkIDs = [];
  // Stage
  CPs.forEach((CPStage) => {
    // Revision
    CPStage.versions.forEach((CP) => {
      // Estimate
      CP.estimates.forEach((estimate) => {
        benchmarkIDs.push({ benchmark_id: estimate.id });
      });
    });
  });

  return benchmarkIDs;
}

export async function addBenchmarksCostPlanning(CPs) {
  const benchmarkIDs = benchmarkIDsFromCostPlan(CPs);
  const benchmarks = await getBenchmarksWithRows(benchmarkIDs);

  // Stage
  CPs.forEach((CPStage) => {
    // Revision
    CPStage.versions.forEach((CP) => {
      // Estimate
      CP.estimates.forEach((estimate) => {
        const benchmark = benchmarks.find((b) => b.id === estimate.id);

        estimate.benchmark = benchmark;
      });
    });
  });

  return CPs;
}
