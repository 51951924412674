import React, { useState, useEffect } from "react";
import { translate } from "../../utils/translation";
import { useLocations } from "../../api/admin/RegionManagementAPI";
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/is-empty";

import {
  updateCity,
  updateRegion,
  updateCountry,
  deletelocation,
  insertlocation,
  submitLocations,
} from "./LocationManagement.functions";

import "./LocationManagement.css";

export default function LocationManagement() {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { locations } = useLocations(true);
  const [displayLocations, setLocations] = useState([]);

  useEffect(() => {
    if (!isEmpty(locations) && !isEmpty(locations.data)) {
      setLocations(locations.data);
    }
  }, [locations, locations.data]);

  let saved = "submitted-false-locations";
  if (submitted) {
    saved = "submitted-true-locations";
  }

  let spinner = "spinner-false-locations";
  if (loading) {
    spinner = "spinner-true-locations";
  }

  let displayError = "error-false-locations";
  if (error) {
    displayError = "error-true-locations";
  }

  return (
    <div>
      {isEmpty(locations.data) && locations.loading ? (
        <div className="edit-locations-container">
          <Spinner marginTop={"15%"} />
        </div>
      ) : (
        locationDisplay()
      )}
    </div>
  );

  function locationDisplay() {
    return (
      <div className="edit-locations-container">
        <p>
          Edit the Region, Country and City list below. Blanks are not
          permitted.
        </p>
        {locationFields()}
      </div>
    );
  }

  function locationFields() {
    return (
      <div>
        <div className="flex-container-locations">
          <div className="input-locations-row">
            <div className="input-location-name">
              <b>{translate("Region")}</b>
            </div>
            <div className="input-location-name">
              <b>{translate("Country")}</b>
            </div>
            <div className="input-location-name">
              <b>{translate("City")}</b>
            </div>
          </div>
          <GenerateFields
            locations={displayLocations}
            setLocations={setLocations}
          />
          <div className="locations-save-control-flex">
            <div className="locations-save-control-flex-button">
              <div
                className="general-upload-button"
                id={"locations-submit-button"}
                onClick={(e) => {
                  submitLocations(
                    e,
                    displayLocations,
                    setSubmitted,
                    setLoading,
                    setError,
                    setLocations
                  );
                }}
              >
                {translate("Save Changes")}
              </div>
            </div>
            <div className={saved}>{translate("Saved")}</div>
            <div className={displayError}>{translate("Failed")}</div>
            <div className={spinner}>
              <Spinner width={"30px"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function GenerateFields(props) {
  const { locations, setLocations } = props;
  if (isEmpty(locations) || locations.length === 0) return null;
  return locations.map((s, i) => {
    let disabled = false;
    let hidden = false;

    return (
      <div key={s.id} className="input-locations-row">
        <input
          id={"region-input-" + i}
          className="input-location-name"
          disabled={disabled}
          value={s.region}
          onChange={(e) => {
            updateRegion(e.target.value, i, locations, setLocations);
          }}
        />
        <input
          id={"country-input-" + i}
          className="input-location-name"
          disabled={disabled}
          value={s.country}
          onChange={(e) => {
            updateCountry(e.target.value, i, locations, setLocations);
          }}
        />
        <input
          id={"city-input-" + i}
          className="input-location-name"
          disabled={disabled}
          value={s.city}
          onChange={(e) => {
            updateCity(e.target.value, i, locations, setLocations);
          }}
        />
        <div
          id={"insert-button-" + i}
          className="locations-insert"
          hidden={hidden}
          onClick={() => insertlocation(i, locations, setLocations)}
        >
          {translate("Insert")}
        </div>
        <div
          id={"delete-button-" + i}
          className="locations-delete"
          hidden={hidden}
          onClick={() => deletelocation(s.id, locations, setLocations)}
        >
          {translate("Delete")}
        </div>
      </div>
    );
  });
}
