import React from "react";

import "./EstimatesTPISaveMessages.css";

function message(text, className) {
  return (
    <div className={className}>
      <b>{text}</b>
    </div>
  );
}

export const SAVE_MESSAGES = {
  MESSAGE: (text) => message(text),
  ERROR_MESSAGE: (text) => message(text, "estimates-TPI-save-message-fail"),
  NONE: message(""),
  NOT_SAVED: message("Change Not Saved", "estimates-TPI-save-message-fail"),
  SAVE_FAILED: message("Save Failed", "estimates-TPI-save-message-fail"),
  SAVING: message("Saving...", "estimates-TPI-save-message-neutral"),
  SAVED: message("Saved", "estimates-TPI-save-message-success"),
};
