import React from "react";

//powerbi libraries
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

// Functions
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/is-empty";

const Report = (props) => {
  const { reportStatus } = props;
  const { reportDetails } = props;
  const { reportLoading } = props;

  // Snapshot is being generated now please check back soon
  if (reportStatus.data.started && !reportStatus.data.completed) {
    return null;
  }

  // Snapshot is being generated now please check back soon
  if (reportStatus.data.down) {
    return <div>The Report Service is down. Please try again later.</div>;
  }

  // Report Error
  if (
    reportStatus.data.completed &&
    isEmpty(reportDetails) &&
    reportStatus.data.error &&
    !reportLoading
  ) {
    return (
      <div>
        There was an error with your last report. Please click{" "}
        <div className="export-report-export-text">Create New Report</div> to
        try again.
      </div>
    );
  }

  // No snapshot exists
  if (
    !reportStatus.data.completed &&
    isEmpty(reportDetails) &&
    !reportStatus.data.error &&
    !reportLoading
  ) {
    return (
      <div>
        No report found. Please click{" "}
        <div className="export-report-export-text">Create New Report</div> to
        create one.
      </div>
    );
  }

  // Snapshot exists
  if (
    reportStatus.data.completed &&
    isEmpty(reportDetails) &&
    !reportStatus.data.error &&
    !reportLoading
  ) {
    return (
      <div>
        A report has been found. You can click{" "}
        <div className="export-report-view-text">Open Report</div> to view it,
        or <div className="export-report-export-text">Create New Report</div> to
        create a new one.
      </div>
    );
  }

  if (isEmpty(reportDetails) && reportLoading) {
    return <Spinner width={"100px"} />;
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual and qna
        id: reportDetails.reportID, //fetch by calling the API
        //source: API w.r.t report ID
        embedUrl: reportDetails.embedUrl,
        //source: API w.r.t report ID
        accessToken: reportDetails.token,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              visible: true,
            },
            pageNavigation: {
              visible: true,
            },
            commands: {
              parameterPanel: {
                enabled: true,
                expanded: true,
              },
            },
          },
        },
      }}
      eventHandlers={
        new Map([
          [
            "loaded",
            function () {
              console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered");
            },
          ],
          [
            "error",
            function (event) {
              console.log(event.detail);
            },
          ],
        ])
      }
      cssClassName={"report-style-class"}
      getEmbeddedComponent={async (embeddedReport) => {
        window.report = embeddedReport;
      }}
    />
  );
};

export default Report;
