import isEmpty from "../validation/is-empty";
import { SET_CURRENT_USER } from "./types";

import jwt_decode from "jwt-decode";

export async function createUserObject(profile, idToken) {
  localStorage.setItem("idToken", idToken);

  // Decode token to get user data
  const account = jwt_decode(idToken, { complete: true }); // Complete returns full token

  // Add B2C specific claims to userObject
  profile.exp = account.exp; // B2C expiry is 1 hour
  profile.iat = account.iat;
  profile.roles = account.roles;

  // Set expiry to local storage
  localStorage.setItem("user", JSON.stringify(profile));

  return profile;
}

// Login AD User - Get User Token
export const loginClient = (user) => (dispatch) => {
  // Set current user in Redux
  dispatch(setCurrentUser(user));
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("idToken");

  //if spinner is activated, remove the local storage item that starts it
  if (!isEmpty(localStorage.ADLoading)) {
    localStorage.removeItem("ADLoading");
  }
  dispatch(setCurrentUser({})); // Set current user to {} which will set isAuthenticated to false
};
