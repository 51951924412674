import React from "react";
import isEmpty from "../../validation/is-empty";

export default function Commentary(props) {
  const { fields, setFields, crType } = props;
  const { subHeading, mainHeading } = props;
  return (
    <div>
      <div className="clarification-title">
        <div className="display-4" style={{ fontSize: "20px" }}>
          {mainHeading}
        </div>
      </div>
      <FieldHeadings />
      <div className="post-contract-clarifications-table">
        {DecisionFields()}
      </div>
      <div className="general-row-container">
        <AddField />
      </div>
    </div>
  );

  //Display sub heading
  function FieldHeadings() {
    return (
      <div className="clarification-field-container">
        <div className="clarification-highlight">
          <b>{subHeading}</b>
        </div>

        <div className="clarification-delete-spacer"></div>
      </div>
    );
  }

  //Add Comment Field button
  function AddField() {
    return (
      <div
        className="general-modal-button"
        onClick={() => {
          let temp = [...fields];

          // Limit 20 fields
          if (temp.filter((x) => x.type === crType).length >= 20) {
            return;
          }
          //Adding empty field to the array
          temp.push({
            comment: "",
            type: crType,
          });
          setFields(temp);
        }}
      >
        Add Comment Field
      </div>
    );
  }

  function DecisionFields() {
    //If there is no commentary of this type, add an empty field
    if (isEmpty(fields.filter((f) => f.type === crType))) {
      let temp = [...fields];
      temp.push({
        comment: "",
        type: crType,
      });
      setFields(temp);
    }
    return fields.map((f, i) => {
      if (f.type === crType) {
        return (
          <div key={i} className="clarification-field-container">
            <input
              className="clarification-highlight"
              value={f.comment}
              maxLength={150}
              onChange={(e) => {
                updateField("comment", e.target.value, i, fields);
              }}
            />

            <div
              className="clarification-delete-button"
              onClick={() => deleteField(i, fields)}
            >
              <i className="far fa-trash-alt" />
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  function updateField(field, value, i, array) {
    let temp = [...array];
    temp[i][field] = value;
    setFields(temp);
  }

  function deleteField(i, array) {
    let temp = [...array];

    temp.splice(i, 1);
    setFields(temp);
  }
}
