// React
import React from "react";

// Metrics
import MetricItem from "../dashboard-components/MetricItem";

// Functions
import { translate } from "../../utils/translation";

// Style
import "./PortfolioMetrics.css";

export default function PortfolioMetrics(props) {
  const { portfolioDashboard } = props;

  return (
    <div className="portfolio-metrics-banner-row">
      <div className="portfolio-metrics-banner">
        <div className="display-4">Key Metrics</div>
        <MetricItem
          value={portfolioDashboard.totals.portfolioCost}
          text={translate("Anticipated Final Cost")}
          type={1}
        />
        <MetricItem
          value={portfolioDashboard.budget.portfolioVariance}
          text={translate("Headroom to Total Budget")}
          type={2}
        />
        <MetricItem
          value={portfolioDashboard.totals.portfolioProjects}
          text={translate("Projects in Programme")}
          type={3}
        />
      </div>
    </div>
  );
}
