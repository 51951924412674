import React from "react";
import { summarizeJourneys } from "./PCRA.functions";
import { PCRAStatusSummaryItem } from "./PCRAStatusSummaryItem";

import "./PCRAStatusSummary.css";

export function PCRAStatusSummary(props) {
  const { pcraLog } = props;

  const journeys = pcraLog && summarizeJourneys(pcraLog);

  if (!journeys) {
    return <></>;
  }

  const latestJourney = journeys[0]; // journeys are in reverse chronological order

  const { processType } = pcraLog;

  return (
    <div className={`pcra-status-summary ${processType}`}>
      <div className="arrow-up"></div>
      <div className="summary-bar">
        {latestJourney.logs.map((log, index) => {
          const { stageDisplay: stage, statusDisplay: status } = log;
          return (
            <PCRAStatusSummaryItem
              key={log.id}
              stage={stage}
              status={status}
              isLast={index === latestJourney.logs.length - 1}
            />
          );
        })}
      </div>
    </div>
  );
}
