export function generateCostCategoryData(benchmarks, cbs) {
  const categoryGroup = cbs?.data?.categoryGroups?.find(
    (g) => g.categoryName === "On Costs"
  );

  const codes = [];

  categoryGroup?.categoryItems?.forEach((categoryItem) => {
    codes.push(...categoryItem.codes);
  });

  const costsList = ["Prelims", "OH&P", "Fees"];
  const data = costsList.map((category) => {
    return { label: category, data: [] };
  });
  const benchmarksTitles = [];

  // Attached Benchmarks
  benchmarks.forEach((bmark) => {
    let netConstructionCost = 0;
    let prelims = 0;
    let ohnp = 0;
    let fees = 0;

    // Add Up Net Construction Cost
    bmark.rows.forEach((row) => {
      if (codes.includes(row.code)) netConstructionCost += row.total;
    });

    // Calculate Net Construction Cost
    netConstructionCost = bmark.construction_cost - netConstructionCost;

    // Add Up On Costs
    bmark.rows.forEach((row) => {
      prelims += addUpKey(row, categoryGroup, "Prelims");
      ohnp += addUpKey(row, categoryGroup, "OH&P");
      fees += addUpKey(row, categoryGroup, "Fees");
    });

    let isProjectValid = false;
    // Calculate Metrics
    if (prelims > 0) {
      data[0].data.push(((prelims / netConstructionCost) * 100).toFixed(1));
      isProjectValid = true;
    }
    if (ohnp > 0) {
      data[1].data.push(((ohnp / netConstructionCost) * 100).toFixed(1));
      isProjectValid = true;
    }
    if (fees > 0) {
      data[2].data.push(((fees / netConstructionCost) * 100).toFixed(1));
      isProjectValid = true;
    }

    if (isProjectValid) {
      benchmarksTitles.push(bmark.project_name_short);
    }
  });

  return { data, benchmarksTitles };
}

function addUpKey(row, categoryGroup, value) {
  let total = 0;
  const onCostCodes = categoryGroup?.categoryItems?.find(
    (g) => g.description === value
  );

  if (onCostCodes.codes.includes(row.code)) {
    total += row.total;
  }

  return total;
}
