//Translations
import { tranStr } from "../../utils/translation";
import { humanize } from "../../validation/humanize";
import { ImageDisplay } from "../images/ImageDisplay";

export function projectColumns() {
  return [
    {
      heading: tranStr(""),
      key: "image",
      type: "IMAGE",
      width: 42,
      customComponent: (value) => {
        return (
          <ImageDisplay
            image={value}
            imageWidth={"30px"}
            imageHeight={"20px"}
            isBezierDisabled={true}
          />
        );
      },
    },
    {
      heading: tranStr("Title"),
      key: "title",
      type: "DESCRIPTION",
      width: 190,
      isFiltered: true,
    },
    {
      heading: tranStr("Job Number"),
      key: "job_number",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Client Company"),
      key: "company_name",
      type: "DESCRIPTION",
      width: 160,
      isFiltered: true,
    },
    {
      heading: tranStr("Sector"),
      key: "sector",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Subsector"),
      key: "sub_sector",
      type: "DESCRIPTION",
      width: 180,
      isFiltered: true,
    },
    {
      heading: tranStr("Address"),
      key: "address",
      type: "DESCRIPTION",
      width: 290,
      isFiltered: true,
    },

    {
      heading: tranStr("Region"),
      key: "region",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Country"),
      key: "country",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("TPI Location"),
      key: "tpi_city",
      type: "DESCRIPTION",
      width: 100,
      isFiltered: true,
    },
    {
      heading: tranStr("Created"),
      key: "time_created",
      type: "DATE",
      width: 120,
      formatFunction: (value) => {
        return parseInt(value);
      },
    },
    {
      heading: tranStr("Project Phase"),
      key: "project_phase",
      type: "DESCRIPTION",
      width: 120,
      isFiltered: true,
      formatFunction: (value) => {
        return humanize(value);
      },
    },
  ];
}
