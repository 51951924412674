import React from "react";

// Style
import "./PostContractSelectorDrawer.css";

export default function PostContractSelectorDrawer(props) {
  const { Component } = props;
  const { show, setShow } = props;
  const { dashboardSelect, dashboardType } = props;
  const { height } = props;

  if (dashboardSelect !== "DASHBOARD") {
    if (dashboardSelect !== dashboardType) {
      return null;
    }
  }

  let button = "post-contract-selector-drawer-button";
  if (show) {
    button = "post-contract-selector-drawer-button-clicked";
  }

  return (
    <div>
      <div
        className={button}
        onClick={() => {
          setShow(!show);
        }}
      >
        <i className="fas fa-cog"></i>
        {/* <i className="fas fa-pencil-alt"></i> */}
      </div>

      {show && (
        <div
          className="post-contract-selector-drawer-content"
          style={{ height: height }}
        >
          <Component {...props} />
        </div>
      )}
    </div>
  );
}
