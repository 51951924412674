// React
import React from "react";
import isEmpty from "../../validation/is-empty";

// Functions
import { TableCell } from "./TableBodyCells";

export function TableBody(props) {
  const { columns, tableArray } = props;
  const { setModal } = props;
  const { setSelectedRow, selectedRow, isClickToClose } = props;
  const { checked, setChecked, checkedProp } = props;
  const { pageSize } = props;

  const table = [];

  tableArray.forEach((row) => {
    const cells = [];
    let rowType = "pct-row";
    if (selectedRow.id === row.id) {
      rowType = "pct-row-selected";
    }
    const rowId = "table-row-id-" + row.id;

    // The configured columns array for this dataset
    columns.forEach((cell) => {
      const displayCellOnClick = () => {
        if (isClickToClose) {
          setModal(false);
        } else {
          setModal(true);
        }
        setSelectedRow(row);
      };

      let value = row[cell.key];

      // A custom function passed as a column prop to format this cell specific to a dataset
      if (cell.formatFunction) {
        value = cell.formatFunction(row[cell.key]);
      }

      if (!isEmpty(value)) {
        value = value.toString();
      }

      // Select the correct cell based on the column configuration
      cells.push(
        <TableCell
          id={rowId + "-" + cell.key}
          key={cell.key}
          value={value}
          cell={cell}
          checked={checked}
          setChecked={setChecked}
          checkedProp={checkedProp}
          tableArray={tableArray}
          setModal={displayCellOnClick}
        />
      );
    });
    table.push(
      <div id={rowId} className={rowType} key={row.id}>
        {cells}
      </div>
    );
  });

  // Blank Rows
  if (table.length < pageSize) {
    const blankRows = pageSize - table.length;
    let rowType = "pct-row";

    for (let i = 0; i < blankRows; i++) {
      const cells = [];
      const rowId = "blank-row-id-" + i;
      columns.forEach((cell) => {
        cells.push(
          <TableCell
            id={rowId + "-" + cell.key}
            key={cell.key}
            value={""}
            cell={cell}
            checked={checked}
            setChecked={setChecked}
            checkedProp={checkedProp}
            tableArray={tableArray}
            setModal={() => null}
          />
        );
      });

      const row = (
        <div id={rowId} key={rowId} className={rowType}>
          {cells}
        </div>
      );

      table.push(row);
    }
  }

  return table;
}
