import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";
import ExportCashflowCostPlanning from "../export/ExportCashflowCostPlanning";

import "./CashflowTable.css";

export default function CashflowTable(props) {
  const { data, total } = props;

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className="cashflow-table">
      <TableHead data={data} total={total} />
      <Table data={data} />
      <TableTotal total={total} />
    </div>
  );
}

function TableHead(props) {
  const { data, total } = props;

  let row = {
    column1: "Date",
    column2: "Forecast Monthly Cost",
    column3: "Forecast Cumulative",
  };

  return (
    <div className="cashflow-table-head-row">
      <div className="cashflow-table-description-cell">
        <b>{row.column1}</b>
      </div>
      <div className="cashflow-table-quantity-cell ">
        <b>{row.column2}</b>
      </div>
      <div className="cashflow-table-unit-cell">
        <b>{row.column3}</b>
        <ExportCashflowCostPlanning cashflow={data} total={total} />
      </div>
    </div>
  );
}

function TableTotal(props) {
  const { total } = props;

  return (
    <div className="cashflow-table-total-row">
      <div className="cashflow-table-description-cell">
        <b>Total</b>
      </div>
      <div className="cashflow-table-quantity-cell ">
        <div></div>
        <b>{comma(Math.round(total))}</b>
      </div>
      <div className="cashflow-table-unit-cell">
        <b></b>
      </div>
    </div>
  );
}

function Table(props) {
  const { data } = props;

  return (
    <div>
      {data.map((row, i) => {
        if (row.quantity <= 0) {
          return null;
        }

        // Subtotal styling for other rows
        let rowType = "cashflow-table-row";

        return (
          <div key={i} className={rowType}>
            <div className="cashflow-table-description-cell">{row.date}</div>
            <div className="cashflow-table-quantity-cell ">
              <div></div>
              <div>{comma(Math.round(row.forecastMonthly))}</div>
            </div>
            <div className="cashflow-table-unit-cell">
              <div></div>
              <div>{comma(Math.round(row.forecastCumulative))}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
