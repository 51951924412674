import React from "react";

import Estimate from "./Estimate";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import EstimatesTPITotal from "../estimates/EstimatesTPITotal";
import EstimatesTotalAccordion from "../estimates/EstimatesTotalAccordion";

import "./Estimates.css";

export default function Estimates(props) {
  const { project } = props;
  const { user } = props;
  const { CPs } = props;
  const { CP } = props;
  const { TPI } = props;
  const { accordionState } = props;
  const { isLinkedTPI, setIsLinkedTPI } = props;
  const { linkedTPISelectedYear, setLinkedTPISelectedYear } = props;
  const { linkedTPIPercentage, setLinkedTPIPercentage } = props;

  // Loading Complete Cost Plan Empty
  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return (
      <div className="estimates-container">
        <div className="estimate-box">
          {translate("No Cost Plan Summaries have been uploaded.")}
        </div>
      </div>
    );
  }

  const display = [];

  // Estimates Included in the Total
  CP.estimates.forEach((e, i) => {
    if (e.is_included) {
      display.push(
        <Estimate
          project={project}
          accordionState={accordionState}
          user={user}
          CPs={CPs}
          CP={CP}
          TPI={TPI}
          e={e}
          key={e.id}
          isLinkedTPI={isLinkedTPI}
          setIsLinkedTPI={setIsLinkedTPI}
          linkedTPISelectedYear={linkedTPISelectedYear}
          setLinkedTPISelectedYear={setLinkedTPISelectedYear}
          linkedTPIPercentage={linkedTPIPercentage}
          setLinkedTPIPercentage={setLinkedTPIPercentage}
        />
      );
    }
  });

  // The Total including Total TPI
  display.push(
    <EstimatesTotalAccordion
      project={project}
      CP={CP}
      key={-1}
      isBreakdown={false}
      selectedRow={null}
      accordionState={accordionState}
    >
      <EstimatesTPITotal
        key={-2}
        estimates={CP.estimates}
        project={project}
        user={user}
        TPI={TPI}
        CPs={CPs}
        isBreakdown={false}
        selectedRow={null}
        isLinkedTPI={isLinkedTPI}
        setIsLinkedTPI={setIsLinkedTPI}
        linkedTPISelectedYear={linkedTPISelectedYear}
        setLinkedTPISelectedYear={setLinkedTPISelectedYear}
        linkedTPIPercentage={linkedTPIPercentage}
        setLinkedTPIPercentage={setLinkedTPIPercentage}
      />
    </EstimatesTotalAccordion>
  );

  // Estimates Excluded from the Total
  CP.estimates.forEach((e, i) => {
    if (!e.is_included) {
      display.push(
        <Estimate
          project={project}
          accordionState={accordionState}
          user={user}
          CPs={CPs}
          CP={CP}
          TPI={TPI}
          e={e}
          key={e.id}
          isLinkedTPI={isLinkedTPI}
          setIsLinkedTPI={setIsLinkedTPI}
          linkedTPISelectedYear={linkedTPISelectedYear}
          setLinkedTPISelectedYear={setLinkedTPISelectedYear}
          linkedTPIPercentage={linkedTPIPercentage}
          setLinkedTPIPercentage={setLinkedTPIPercentage}
        />
      );
    }
  });

  // Loading Complete
  return (
    <div className="estimates-container">
      <div className="estimate-table-and-total-container">{display}</div>
    </div>
  );
}
