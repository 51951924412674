import React from "react";
import { VALUE_ENGINEERING } from "./ValueEngineering.strings";

export default function ValueEngineeringDisclaimer(props) {
  const { estimateCount } = props;

  return (
    <div className="ve-disclaimer">
      <div>
        {estimateCount === 1
          ? VALUE_ENGINEERING.DISCLAIMER.LINE_1_FOR_SINGLE_TABLE
          : VALUE_ENGINEERING.DISCLAIMER.LINE_1_FOR_MULTIPLE_TABLES}
      </div>
      <div>{VALUE_ENGINEERING.DISCLAIMER.LINE_2}</div>
    </div>
  );
}
