import isEmpty from "../../validation/is-empty";
import { projectUpdate } from "../../api/projects/ProjectAPI";
import { authHeader } from "../../_helpers/auth-header";
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

export async function submit(data) {
  const url = pcAPIs().save_post_contract_duration;

  if (isEmpty(data.startMonth)) {
    data.setError({
      text: "Empty Month",
      type: "project-stages-error",
    });
  }
  if (isEmpty(data.startYear)) {
    data.setError({
      text: "Empty Year",
      type: "project-stages-error",
    });
  }
  if (isEmpty(data.duration)) {
    data.setError({
      text: "Empty Duration",
      type: "project-stages-error",
    });
  }

  // Need to check if we need any validation for CashflowBasis

  data.setError({
    text: "Saving...",
    type: "project-stages-success",
  });

  const payload = {
    projectID: data.projectID,
    post_contract_start_month: data.startMonth,
    post_contract_start_year: data.startYear,
    post_contract_duration: data.duration,
    post_contract_cashflow_basis: data.cashflowBasis,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try{
    response = await fetch(url, config);
  }
 catch(e){
    console.log("Save post contract duration error")
 }

  if (response.ok) {
    response = await response.json();
    data.setError({
      text: "Saved",
      type: "project-stages-success",
    });
    projectUpdate(data.project);
    data.postContract.set(await data.postContract.get(data.projectID));
    data.setShow(false);
  } else {
    let error = await response.json();
    data.setError({
      text: error.error,
      type: "project-stages-error",
    });
    console.log("Network Error");
    response = [];
  }

  return response;
}
