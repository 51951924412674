export function updateName(value, i, newStages, setNewStages) {
  let tempStages = newStages;
  tempStages[i].name = value;
  setNewStages(tempStages.map((s) => s));
}

export function updateStage(value, i, newStages, setNewStages) {
  if (value <= 10) {
    let tempStages = newStages;
    tempStages[i].number = value;
    setNewStages(tempStages.map((s) => s));
  }
}

export function deleteStage(i, newStages, setNewStages) {
  let temp = newStages;
  temp.splice(i, 1);
  setNewStages(temp.map((x) => x));
}

export function updateCode(value, i, newStages, setNewStages) {
  if (value.length <= 4) {
    let tempStages = newStages;
    tempStages[i].code = value;
    setNewStages(tempStages.map((s) => s));
  }
}
