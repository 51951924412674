import React, { useContext } from "react";
import isEmpty from "../../validation/is-empty";
import Spinner from "../common/Spinner";

import "./PCRALogModal.css";

import { PCRALog } from "./PCRALog";

import { PCRALogRootContext } from "./PCRALogRoot";

export default function PCRALogModal(props) {
  const context = useContext(PCRALogRootContext);

  const { data } = context;

  if (isEmpty(data)) {
    return null;
  }

  const { processType } = data;

  return (
    <div className={`pcra-log-container-root ${processType}`}>
      {data.loading && <Spinner />}
      {!data.loading && <PCRALog />}
    </div>
  );
}
