import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import TenderPriceIndexingCalculatorChart from "./TenderPriceIndexingCalculatorChart";
import Modal from "../modal/Modal";
import { comma } from "../../helpers/common.functions";

import "./TenderPriceIndexingCalculator.css";

export default function TenderPriceIndexingCalculator(props) {
  const { TPI } = props;

  let subLocation = determineSubLocation();

  const [modal, setModal] = useState(false);

  if (subLocation !== "tender-price-index") {
    return null;
  }

  return (
    <div>
      <div
        className="tpi-control-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-calculator" />
      </div>
      <Modal
        // Modal Props
        title={"Cost Escalation Calculator"}
        Component={TenderPriceIndexingCalculatorModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        TPI={TPI}
      />
    </div>
  );
}

function TenderPriceIndexingCalculatorModal(props) {
  const { TPI } = props;
  const [cost, setCost] = useState(0);
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [location, setLocation] = useState(TPI.locations[0]);
  const [percentage, setPercentage] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!isEmpty(TPI)) {
      setMinYear(TPI.years[0]);
      setMaxYear(TPI.years[TPI.years.length - 1]);
      setLocation(TPI.locations[0]);
    }
  }, [TPI]);

  // Calculate Percentage
  useEffect(() => {
    let TPICity = location;

    // Extract the Current index and Future index

    let futureIndex;
    TPI.raw.forEach((row) => {
      if (row.year === minYear && row.location === TPICity) {
        setCurrentIndex(row.value);
      }

      if (row.year === maxYear && row.location === TPICity) {
        futureIndex = row.value;
      }
    });
    let percent = round((futureIndex / currentIndex) * 100 - 100, 2);

    if (isNaN(percent)) {
      percent = 0;
    }

    setPercentage(percent);
  }, [currentIndex, location, minYear, maxYear, TPI.raw]);

  // Format the cost to remove any commas
  let newCost = 0;
  if (cost) {
    newCost = cost.replaceAll(",", "");
  }

  return (
    <div className="tpi-calc">
      <TenderPriceIndexingCalculatorChart
        TPI={TPI}
        selectedLocations={[location]}
        maxYear={maxYear}
        minYear={minYear}
        cost={newCost}
        currentIndex={currentIndex}
      />
      <div className="tpi-calc-container">
        <div className="tpi-calc-slider-row">
          <div className="tpi-calc-slider-label">Select Location / TPI</div>
          <Select value={location} set={setLocation} options={TPI.locations} />
        </div>
        <div className="tpi-calc-slider-row">
          <div className="tpi-calc-slider-label">Construction Cost</div>
          <input
            className="tpi-calc-cost-input"
            type="text"
            value={cost}
            onInput={(e) => {
              const removeNonNumeric = (num) =>
                num.toString().replace(/\D/g, "");

              setCost(comma(removeNonNumeric(e.target.value)));
            }}
          ></input>
        </div>
      </div>
      <MultiSlider
        TPI={TPI}
        maxYear={maxYear}
        setMaxYear={setMaxYear}
        minYear={minYear}
        setMinYear={setMinYear}
      />
      <div className="tpi-calc-container">
        <div className="tpi-calc-slider-row">
          <div className="tpi-calc-slider-label">Escalated Cost</div>
          <CalculatedResult cost={newCost} percentage={percentage} />
        </div>
      </div>
    </div>
  );
}

function CalculatedResult(props) {
  const { cost, percentage } = props;

  let value = parseInt(cost);
  let inflation = parseInt((value * percentage) / 100);
  let costPlusInflation = Math.round(value + inflation);

  if (isNaN(costPlusInflation)) {
    costPlusInflation = 0;
  }

  return (
    <div className="tpi-calc-result">
      <b>${comma(costPlusInflation)}</b>
    </div>
  );
}

function Select(props) {
  const { value, set, options } = props;

  return (
    <select
      className="tpi-calc-select"
      value={value}
      onChange={(e) => {
        set(e.target.value);
      }}
    >
      <SelectList options={options} />
    </select>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item} className="option">
        {item}
      </option>
    );
  });
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}

function MultiSlider(props) {
  const { TPI } = props;
  const { minYear, setMinYear } = props;
  const { maxYear, setMaxYear } = props;

  return (
    <div className="tpi-calc-container">
      <div className="tpi-calc-slider-row">
        <div className="tpi-calc-slider-label">Construction Start</div>
        <input
          className="tpi-admin-dashboard-slider"
          type="range"
          min={TPI.years[0]}
          max={TPI.years[TPI.years.length - 1]}
          value={minYear}
          onChange={(e) => {
            setMinYear(parseInt(e.target.value));
          }}
        />
        <div className="tpi-admin-dashboard-slider-label">
          <b>{minYear}</b>
        </div>
      </div>

      <div className="tpi-calc-slider-row">
        <div className="tpi-calc-slider-label">Construction Finish</div>
        <input
          className="tpi-admin-dashboard-slider"
          type="range"
          min={TPI.years[0]}
          max={TPI.years[TPI.years.length - 1]}
          value={maxYear}
          onChange={(e) => {
            setMaxYear(parseInt(e.target.value));
          }}
        />
        <div className="tpi-admin-dashboard-slider-label">
          <b>{maxYear}</b>
        </div>
      </div>
    </div>
  );
}

function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
