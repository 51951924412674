// React
import React from "react";

// API
import { useCompanies } from "../../api/projects/ProjectCreateAPI";

// Components
import Spinner from "../common/Spinner";
import CreateProjectSelectClientCompanyTable from "./CreateProjectSelectClientCompanyTable";

// Functions
import isEmpty from "../../validation/is-empty";

export default function CreateProjectSelectClientCompany(props) {
  const { show, setShow } = props;
  const { setFunction } = props;

  const { companies } = useCompanies(show);

  if (isEmpty(companies.data) || companies.loading) {
    return <Spinner marginTop={"170px"} marginBottom={"170px"} />;
  }

  return (
    <CreateProjectSelectClientCompanyTable
      data={companies.data}
      dataLoading={companies.loading}
      setShow={setShow}
      setFunction={setFunction}
    />
  );
}
