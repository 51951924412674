import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma, commaToFixed } from "../../helpers/common.functions";
import "./ValueEngineeringsTotal.css";

export default function ValueEngineeringsTotal(props) {
    const { project, CP, isBreakdown, selectedRow } = props;

    if (isEmpty(CP) || isEmpty(CP.estimates)) {
        return null;
    }

    if (CP.estimates.length < 2) {
        return null;
    }

    if (isBreakdown && selectedRow !== "breakdown") {
        return null;
    }

    return (
        <div className="value-engineering-total">
            <div className="value-engineerings-total-label">{CP.calculated_total_name}</div>
            <div className="value-engineerings-total-costs">
                <Detail
                    calculated_local_region_area_total={CP.calculated_local_region_area_total}
                    unit_of_measure={project.unit_of_measure}
                    calculated_total_rate={CP.calculated_total_rate}
                />
                <div className="value-engineerings-total-total">
                    {comma(CP.calculated_total)}
                </div>
            </div>
        </div>
    );
}

function Detail(props) {
    const { calculated_local_region_area_total, unit_of_measure, calculated_total_rate } = props;

    if (
        isEmpty(calculated_local_region_area_total) ||
        isEmpty(unit_of_measure) ||
        isEmpty(calculated_total_rate)
    ) {
        return null;
    }

    return (
        <div className="value-engineerings-total-details">
            <div className="value-engineerings-total-gfa">
                {comma(calculated_local_region_area_total)}
            </div>
            <div className="value-engineerings-total-unit">{unit_of_measure}</div>
            <div className="value-engineerings-total-rate">
                {commaToFixed(calculated_total_rate)}
            </div>
        </div>
    );
}
