import React, { useMemo } from "react";
import ProjectCostPlanSelectors from "../project/ProjectCostPlanSelectors";

export default function CorrespondenceCommentsHeader(props) {
  const { project, CPs } = props;
  const { CP, setCP } = props;

  // create a dummy function that will sink its arguments
  const sink = useMemo(() => () => null, []);
  // same as -- useMemo(function() { return function() { return null; }; }, []);

  const dashboardType = "CORRESPONDENCE";

  return (
    <div className="correspondence-comments-header">
      <div className="correspondence-project-title">{project.title}</div>
      <ProjectCostPlanSelectors
        CPs={CPs}
        CP={CP}
        setCP={setCP}
        setComparedCP={sink} // this function is not being used in Correspondence
        setComparedCPR={sink} // this function is not being used in Correspondence
        dashboardType={dashboardType}
      />
    </div>
  );
}
