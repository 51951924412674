import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { processTime } from "../../components/post-contract/PostContract.functions";

// API
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

const NO_DETAILS_ERROR =
  "Please complete Post Contract Details form under Dashboard > Post Contract Control > Post Contract Details";

export async function getPostContract(projectID) {
  // POST CONTRACT API
  const url = pcAPIs().get_post_contract + "/" + projectID;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Post Contract Error";

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("getPostContract error");
  }

  if (response.ok) {
    response = await response.json();

    if (isEmpty(response.data)) {
      response = {
        data: [],
        variations: [],
        costReports: [],
        progressClaims: [],
      };
    }
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function usePostContract(paramID, project) {
  const [postContractLoading, setLoading] = useState(true);
  const [postContract, setPostContract] = useState({});

  useEffect(() => {
    if (!isEmpty(project)) {
      setLoading(true);
      getPostContract(paramID)
        .then((postContractRes) => {
          setPostContract(postContractRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [paramID, project]);

  useEffect(() => {
    if (!isEmpty(postContract)) {
      processTime(postContract.data, project); // Format dates
      processTime(postContract.costReports, project); // Format dates
      processTime(postContract.progressClaims, project); // Format dates
      processTime(postContract.variations, project); // Format dates
    }
  }, [project, postContract]);

  return {
    postContract: {
      data: postContract,
      loading: postContractLoading,
      projectID: paramID,
      get: getPostContract,
      set: setPostContract,
    },
  };
}

export async function uploadPostContractRiskRegister(data) {
  if (data.file === null) {
    return data.setError({
      text: "Please attach file",
      type: "feedback-error",
    });
  }
  if (isEmpty(data.project.post_contract_duration)) {
    return data.setError({
      text: NO_DETAILS_ERROR,
      type: "feedback-error",
    });
  }
  data.setError({
    text: "Creating...",
    type: "feedback-success",
  });

  const field = JSON.stringify({
    project_id: data.projectID,
    postContractID: data.linkedID,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // POST CONTRACT API
  const url = pcAPIs().upload_risk_register;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Upload risk register error");
  }

  if (response.ok) {
    // Response OK
  } else {
    return data.setError({
      text: "Upload Error",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Created Successfully",
    type: "feedback-success",
  });

  data.postContract.set(await data.postContract.get(data.projectID));
  data.setShow(false);
}

export async function uploadPostContract(data) {
  if (data.file === null) {
    return data.setError({
      text: "Please attach file",
      type: "feedback-error",
    });
  }
  if (isEmpty(data.project.post_contract_duration)) {
    return data.setError({
      text: NO_DETAILS_ERROR,
      type: "feedback-error",
    });
  }
  data.setError({
    text: "Creating...",
    type: "feedback-success",
  });

  const field = JSON.stringify({
    project_id: data.projectID,
    cost_report_issued: data.pcIssued.cost_report_issued,
    progress_claim_issued: data.pcIssued.progress_claim_issued,
    variations_issued: data.pcIssued.variations_issued,
    linked_progress_claim_post_contract_id: data.linkedID,
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", field);

  // POST CONTRACT API
  const url = pcAPIs().upload_post_contract;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Upload post contract error");
  }

  if (response.ok) {
    // Response OK
    data.setError({
      text: "Created Successfully",
      type: "feedback-success",
    });

    data.postContract.set(await data.postContract.get(data.projectID));
    return data.setShow(false);
  } else {
    return data.setError({
      text: "Upload Error",
      type: "feedback-error",
    });
  }
}
