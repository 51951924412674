import React from "react";
import isEmpty from "../../validation/is-empty";
import CostVsBudget from "../charts/CostVsBudget";
import { translate } from "../../utils/translation";

export default function CostReportBalanceOfContingencyBar(props) {
  let budgetValue = 0;
  let costValue = 0;

  let { costReport } = props;
  let CR = costReport;
  if (isEmpty(CR) || isEmpty(costReport)) {
    return null;
  }

  costValue = CR.forecast_cost_excl_tax_forecast_total;
  budgetValue = CR.forecast_cost_excl_tax_adjusted_budget;
  costValue = parseInt(costValue);
  budgetValue = parseInt(budgetValue);
  //Forecast Total Cost
  let costText = translate("Forecast Total Cost");
  //Original Budget
  let budgetText = translate("Adjusted Budget");
  //Adjustments to provisional items
  let differenceText = translate("Headroom to Budget");

  return (
    <CostVsBudget
      firstValue={budgetValue}
      secondValue={costValue}
      firstText={budgetText}
      secondText={costText}
      differenceText={differenceText}
      headroom={true}
      isApproved={true}
    />
  );
}
