import React from "react";
import PropTypes from "prop-types";

import BenchmarkDoughnutChart from "../charts/BenchmarkDoughnutChart";
import { translate } from "../../utils/translation";
import { benchmarkCategories } from "../estimates/EstimatesBenchmarkDonutComponent.functions";
import { AVERAGE } from "../../actions/types";
import "./AverageCostCategories.css";

/// props validation | SQ(javascript:S6774)
AverageCostCategories.propTypes = {
  thisBenchmark: PropTypes.object,
  title: PropTypes.string,
  benchmarks: PropTypes.array,
  caller: PropTypes.string,
  isLocalRegionFactorEnabled: PropTypes.bool,
};
///
export default function AverageCostCategories(props) {
  const { thisBenchmark } = props;
  const { title, caller, isLocalRegionFactorEnabled } = props;
  const { benchmarks } = props;

  const estimateCategories = benchmarkCategories(
    [thisBenchmark],
    "Element Categories",
    AVERAGE
  );
  const comparisonCategories = benchmarkCategories(
    benchmarks,
    "Element Categories",
    AVERAGE
  );
  let lraText = isLocalRegionFactorEnabled
    ? "Local Region Area Costs"
    : "Construction Costs";
  return (
    <div className={"benchmarking-chart-box-dashboard"}>
      <div className="benchmarking-donut-chart-heading">
        <h1 className="display-4" style={{ fontSize: "16px" }}>
          {caller !== "MASTER_BENCHMARKING"
            ? translate(
                "Project Comparison vs Benchmark Average Construction Costs"
              )
            : translate("Benchmark Average " + lraText)}
        </h1>
      </div>
      <div className="benchmarking-chart">
        <BenchmarkDoughnutChart
          title={title}
          data={estimateCategories}
          data2={comparisonCategories}
          benchmarks={benchmarks}
          thisBenchmark={thisBenchmark}
          caller={caller}
        />
      </div>
    </div>
  );
}
