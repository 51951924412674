import React from "react";

import "./CreateBenchmarkForm.css";

import {
  Field,
  Select,
  SelectFilter,
  Label,
  DisplayError,
} from "../project-create/CreateProject.components";
import {
  generateCities,
  sectorList,
  subsectorList,
  qualityList,
  typeList,
  procurementList,
} from "../project-create/CreateProject.functions";
import {
  RegionSelect,
  CountrySelect,
  CitySelect,
} from "../locations/LocationSelect";

import { translate, tranStr } from "../../utils/translation";

// API
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { useLocations } from "../../api/admin/RegionManagementAPI";

import "../project-create/CreateProject.css";

export default function CreateBenchmarkFormDetails(props) {
  const { projectName, setProjectName } = props;
  const { client, setClient } = props;
  const { baseDate, setBaseDate } = props;
  const { area, setArea } = props;
  const { areaDescription, setAreaDescription } = props;
  const { conCost, setConCost } = props;
  const { unitOfMeasure, setUnitOfMeasure } = props;
  const { procurement, setProcurement } = props;
  const { projectType, setProjectType } = props;
  const { quality, setQuality } = props;
  const { sector, setSector } = props;
  const { subsector, setSubsector } = props;
  const { standard, setStandard } = props;
  const { cbsCodes } = props;
  const { errors } = props;

  const { region, setRegion } = props;
  const { country, setCountry } = props;
  const { city, setCity } = props;
  const { TPICity, setTPICity } = props;

  const { formIsEnabled } = props;

  // API
  const { locations } = useLocations(true);
  // TPI API
  const { TPI } = useTPI(true, region, country, false);

  if (!formIsEnabled) {
    return null;
  }

  let standardOptions = [{ label: "Select CBS", value: "" }];
  cbsCodes?.data?.forEach((c) => {
    standardOptions.push({ label: c, value: c });
  });

  const citiesList = generateCities(TPI);

  return (
    <div className="create-project-panel">
      <h1>{"Benchmark Details"}</h1>
      <div>
        <div>All fields are mandatory</div>
      </div>
      <Field
        label={translate("Project Name")}
        placeholder={tranStr("Benchmark 1")}
        value={projectName}
        onChange={setProjectName}
        error={errors.ProjectName}
      />

      <div className="create-project-location-wrapper">
        <Field
          label={translate("Client")}
          placeholder={tranStr("Client Company Name")}
          value={client}
          onChange={setClient}
          error={errors.Client}
          classType={"create-project-location-input"}
        />
        <Select
          label={translate("Cost Breakdown Structure")}
          placeholder={tranStr("CBS")}
          value={standard}
          options={standardOptions}
          onChange={setStandard}
          error={errors.Standard}
          classType={"create-project-location-input"}
        />
      </div>
      <div className="create-project-location-wrapper">
        <Field
          label={translate("Construction Cost")}
          placeholder={tranStr("21000000")}
          value={conCost}
          onChange={setConCost}
          error={errors.ConstructionCost}
          classType={"create-project-location-input"}
        />
        <Field
          label={translate("Local Region Area Type")}
          placeholder={tranStr("GFA")}
          value={areaDescription}
          onChange={setAreaDescription}
          error={errors.LocalRegionAreaDescription}
          classType={"create-project-location-input"}
        />
      </div>

      <div className="create-project-location-wrapper">
        <Field
          label={translate("Local Region Area")}
          placeholder={tranStr("4117")}
          value={area}
          onChange={setArea}
          error={errors.LocalRegionArea}
          classType={"create-project-location-input"}
        />

        <Field
          label={translate("Area Unit of Measure")}
          placeholder={tranStr("m2")}
          value={unitOfMeasure}
          onChange={setUnitOfMeasure}
          error={errors.UnitOfMeasure}
          classType={"create-project-location-input"}
        />
      </div>
      <div className="create-project-location-wrapper">
        <Field
          label={translate("Base Date")}
          placeholder={tranStr("1 April 2023")}
          value={baseDate}
          onChange={setBaseDate}
          error={errors.BaseDate}
          classType={"create-project-location-input"}
        />
        <Select
          label={translate("Project Type")}
          placeholder={tranStr("Type")}
          value={projectType}
          options={typeList()}
          onChange={setProjectType}
          error={errors.ProjectType}
          classType={"create-project-location-input"}
        />
      </div>

      <div className="create-project-location-wrapper">
        <Select
          label={translate("Sector")}
          placeholder={tranStr("Sector")}
          value={sector}
          options={sectorList()}
          onChange={setSector}
          error={errors.Sector}
          classType={"create-project-location-input"}
        />
        <SelectFilter
          label={translate("Subsector")}
          placeholder={tranStr("Subsector")}
          value={subsector}
          options={subsectorList()}
          onChange={setSubsector}
          error={errors.SubSector}
          filter={sector}
          classType={"create-project-location-input"}
        />
      </div>
      <div className="create-project-location-wrapper">
        <Select
          label={translate("Project Quality")}
          placeholder={tranStr("Quality")}
          value={quality}
          options={qualityList()}
          onChange={setQuality}
          error={errors.Quality}
          classType={"create-project-location-input"}
        />
        <Select
          label={translate("Procurement Method")}
          placeholder={tranStr("Procurement Method")}
          value={procurement}
          options={procurementList()}
          onChange={setProcurement}
          error={errors.ProcurementMethod}
          classType={"create-project-location-input"}
        />
      </div>
      <div className="create-project-location-wrapper">
        <div className="create-project-input-row">
          <Label label={translate("Region")} />
          <RegionSelect
            value={region}
            set={setRegion}
            options={locations.data}
            classType={"create-project-location-select"}
            setCountry={setCountry}
            setCity={setCity}
          />
          <DisplayError error={errors.Region} />
        </div>
        <div className="create-project-input-row">
          <Label label={translate("Country")} />
          <CountrySelect
            value={country}
            set={setCountry}
            options={locations.data}
            classType={"create-project-location-select"}
            region={region}
            setCity={setCity}
          />
          <DisplayError error={errors.Country} />
        </div>
      </div>
      <div className="create-project-location-wrapper">
        <div className="create-project-input-row">
          <Label label={translate("City")} />
          <CitySelect
            value={city}
            set={setCity}
            options={locations.data}
            classType={"create-project-location-select"}
            country={country}
          />
          <DisplayError error={errors.City} />
        </div>
        <Select
          label={translate("Location for TPI Purposes")}
          value={TPICity}
          options={citiesList}
          onChange={setTPICity}
          error={errors.TpiCity}
          classType={"create-project-location-select"}
        />
      </div>
    </div>
  );
}
