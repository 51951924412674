import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";
import { translate, tranStr } from "../../utils/translation";

import "./ReconciliationHighlightsBar.css";

export default function ReconciliationOverallReconciliationSummary(props) {
  const { project } = props;
  const { CP } = props;
  const { comparedCP } = props;

  return (
    <div className="recon-highlights-bar-container">
      <CurrentAgainstPrevious
        project={project}
        costPlan={CP}
        previousCostPlan={comparedCP}
      />
    </div>
  );
}

function CurrentAgainstPrevious(props) {
  const { costPlan, previousCostPlan } = props;
  const { project } = props;

  // No Estimates uploaded
  if (isEmpty(costPlan.estimates)) {
    return (
      <div className="recon-highlights-bar-link">
        <div className="recon-highlights-bar">{costTitle}</div>
      </div>
    );
  }

  let cost = Math.round(total(costPlan.estimates));
  let previous = Math.round(total(previousCostPlan.estimates));

  return (
    <div
      //to={`/project/${project.id}/cost-planning/reconciliation`}
      className="recon-highlights-bar-link"
    >
      <div className="recon-highlights-bar">
        {costTitle}
        <div className="recon-highlights-cost">
          <div className="recon-highlights-cost-item">
            <div className="recon-highlights-cost-text">
              {translate("Previous Cost Plan")}
            </div>
            <div className="recon-highlights-cost-number">
              {project.currency_symbol + comma(previous)}
            </div>
          </div>
          <div className="recon-highlights-cost-item">
            <div className="recon-highlights-cost-text">
              <b>{translate("Current Cost Plan")}</b>
            </div>
            <div className="recon-highlights-cost-number">
              <b>{project.currency_symbol + comma(cost)}</b>
            </div>
          </div>
          <div className="recon-highlights-cost-item">
            <div className="recon-highlights-cost-text">
              {translate("Variance")}
            </div>
            <div className="recon-highlights-cost-number">
              {generateBrackets(
                comma(cost - previous),
                project.currency_symbol
              )}
            </div>
          </div>
        </div>

        <div className="stacked-budget-bar">
          <Bar />
          <CostBar cost={cost} budget={previous} />
          <Line margin={"210px"} />
          <CostBarText project={project} cost={cost} budget={previous} />
        </div>
      </div>
    </div>
  );
}

function Bar() {
  let barWidth = 300;
  let barColor = "rgb(145, 143, 146, 1)";
  return (
    <div
      className="budget-bar"
      style={{ width: barWidth, backgroundColor: barColor }}
    ></div>
  );
}

function CostBar(props) {
  const { cost, budget } = props;
  let ratio = (cost / budget) * 210;
  let values =
    "" +
    ratio * 0 +
    ";" +
    +(ratio * 0.25) +
    ";" +
    ratio * 0.5 +
    ";" +
    ratio * 0.75 +
    ";" +
    ratio * 1 +
    "";
  if (ratio === Infinity) {
    ratio = 0;
  }
  let barColor = "rgb(228, 97, 15, 1)";
  return (
    <div className="cost-bar">
      <svg className="cost-bar-svg">
        <rect height={15} width={ratio} fill={barColor}>
          <animate
            attributeType="CSS"
            attributeName="width"
            from="0"
            to="1"
            values={values}
            keyTimes="0; 0.25; 0.50; 0.75; 1"
            dur="0.7s"
          />
        </rect>
      </svg>
    </div>
  );
}

function CostBarText(props) {
  const { cost, budget } = props;
  const { project } = props;

  let ratio = Math.round((cost / budget) * 100);
  let text, value;

  if (ratio > 100) {
    value = ratio - 100 + "%";
    text = tranStr("Above previous") + " ";
  } else {
    value = 100 - ratio + "%";
    text = tranStr("Below previous") + " ";
  }

  return (
    <div className="budget-label">
      <div className="budget-percentage">{value}</div>
      <div className="budget-text">{text} </div>
      <div className="budget-value">
        <b>{project.currency_symbol + comma(Math.round(budget))}</b>
      </div>
    </div>
  );
}

function Line(props) {
  return (
    <div className="budget-line" style={{ marginLeft: props.margin }}></div>
  );
}

function total(array) {
  let sum = 0;

  array.forEach((e) => {
    if (e.is_included) {
      sum += e.calculated_total;
    }
  });

  return sum;
}

let costTitle = (
  <div className="recon-highlights-cost-title">
    <h1
      className="display-4"
      style={{
        fontSize: "20px",
        marginBottom: "20px",
        marginTop: "10px",
        marginLeft: "10px",
      }}
    >
      {translate("Overall Reconciliation Summary")}
    </h1>
  </div>
);

function generateBrackets(string, currency_symbol) {
  if (string.toString().includes("-")) {
    return currency_symbol + "(" + string.split("-")[1] + ")";
  } else {
    return currency_symbol + string;
  }
}
