import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      heading: tranStr(""),
      key: "logo",
      type: "IMAGE",
      width: 140,
      customComponent: (value) => {
        return (
          <div className="company-management-logo-container">
            <img
              className="company-management-logo"
              src={value}
              alt=""
              title="Cost Clarity Image"
            />
          </div>
        );
      },
    },
    {
      heading: tranStr("Company Name"),
      key: "name",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Email Domain"),
      key: "email_domain",
      type: "DESCRIPTION",
      width: 300,
    },
    {
      heading: tranStr("Head Office Address"),
      key: "address",
      type: "DESCRIPTION",
      width: 300,
    },

    {
      heading: tranStr("Head Office Region"),
      key: "region",
      type: "STATUS",
      width: 300,
      isFiltered: true,
    },
    {
      heading: tranStr("Global"),
      key: "is_global",
      type: "NUMBER",
      width: 60,
    },
  ];
}
