import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Table from "../table/Table";

// Functions
import { tranStr } from "../../utils/translation";
import { columns } from "./PortfolioEditSelectProjectsTable.functions";
import isEmpty from "../../validation/is-empty";
import { displayProjectImage } from "../../validation/image-clean";

PortfolioEditSelectProjectsTable.propTypes = {
  data: PropTypes.array,
  dataLoading: PropTypes.bool,
  selectedData: PropTypes.array,
  setSelectedUsers: PropTypes.func,
};

export default function PortfolioEditSelectProjectsTable(props) {
  const { data, dataLoading } = props;
  const { selectedData } = props;
  const { setSelectedUsers } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(data) || dataLoading) {
    return <div></div>;
  }

  const projectsArray = data.map((u) => {
    u.image = displayProjectImage(u);
    return u;
  });

  return (
    <Table
      title={tranStr("Select Projects")}
      tableArray={projectsArray}
      columns={columns()}
      tableSize={10}
      isTitleDisabled={true}
      isTotalDisabled={true}
      // Checked Selection
      checked={selectedData}
      setChecked={setSelectedUsers}
      // Row Selection
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      // Modal Control
      modal={modal}
      setModal={setModal}
    />
  );
}
