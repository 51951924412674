import React, { useState } from "react";

import PostContractSelectorOptions from "./PostContractSelectorOptions";
import PostContractSliderTicks from "./PostContractSliderTicks";
import { tranStr } from "../../utils/translation";

import isEmpty from "../../validation/is-empty";

export default function PostContractProgressClaimSelector(props) {
  const { data } = props;
  const { progressClaim } = props;
  const { setProgressClaim } = props;
  const { setVariations } = props;

  const [maxReports, setMaxReports] = useState(10);

  if (isEmpty(progressClaim)) {
    return null;
  }

  // Progress Claim Page
  if (
    !window.location.href.includes("progress-claim") &&
    !window.location.href.includes("trade-costs") &&
    !window.location.href.includes("unfixed-materials") &&
    !window.location.href.includes("cashflow")
  ) {
    return null;
  }

  const { length } = data;
  let minValue = length - maxReports < 0 ? 0 : length - maxReports;
  let maxValue = length - 1;
  let currentValue = data.findIndex((e) => e.id === progressClaim.id);

  return (
    <div className="post-contract-register-selector">
      <PostContractSelectorOptions
        maxReports={maxReports}
        setMaxReports={setMaxReports}
        enableFilter={false}
        height={50}
      />
      <div>
        <div className="post-contract-stages-label">
          {tranStr("Progress Claim") +
            " " +
            progressClaim.progress_claim.progress_claim_number}
        </div>
        <input
          className="post-contract-stages-slider"
          type="range"
          min={minValue}
          max={maxValue}
          value={currentValue}
          onChange={(e) => {
            let index = parseInt(e.target.value);
            setProgressClaim(data[index]);
            setVariations(data[index]);
          }}
        />
        <PostContractSliderTicks
          minValue={minValue}
          maxValue={maxValue}
          currentValue={currentValue}
          pcaLog={data.map(({ progress_claim }) => progress_claim.pcra_log)}
        />
      </div>
    </div>
  );
}
