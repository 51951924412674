import { authHeader } from "../../_helpers/auth-header";
import { bmAPIs } from "../api-endpoints/BenchmarkingEndpoints";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function updateBenchmarkComparisons(data) {
  const { setError } = data;

  setError({
    text: tranStr("Saving..."),
    type: "feedback-success",
  });
  // COST PLAN API
  const url = bmAPIs().update_benchmarks_comparisons;

  const payload = {
    ProjectId: data.projectId,
    BenchmarkId: data.benchmarkId,
    BenchmarkComparisonIds: data.benchmarkComparisonIds,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    // Response OK
    // Update Cost Plan
    let cp = await data.CPs.get(data.projectId);
    data.CPs.set(cp);
    setError({
      text: tranStr("Saved Successfully"),
      type: "feedback-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    setError({
      text: tranStr("Save Failed"),
      type: "feedback-error",
    });
  }
}

export function saveBenchmarksSelection({
  selection,
  projectID,
  estimateID,
  CPs,
  setModal,
  setError,
}) {
  const selectedIDs = selection.map((s) => s.id);

  const benchmarkComparisonIds = [];
  selectedIDs.forEach((selectedId) => {
    benchmarkComparisonIds.push({
      BenchmarkId: selectedId,
      IsAnonymous: true,
    });
  });

  const data = {
    CPs: CPs,
    setModal: setModal,
    setError: setError,

    // Data
    projectId: projectID,
    benchmarkId: estimateID,
    benchmarkComparisonIds: benchmarkComparisonIds,
  };

  updateBenchmarkComparisons(data);
}

export function saveBenchmarkComparisons({ CPs, project, ...props }) {
  const { benchmarkId, linkedBenchmarksIds, comparisonId, isAnonymous } = props;

  const benchmarkComparisonIds = [];
  linkedBenchmarksIds.forEach((linkedBenchmarkId) => {
    if (linkedBenchmarkId.benchmarkId === comparisonId) {
      // Update the state of the changed Benchmark Comparison
      benchmarkComparisonIds.push({
        BenchmarkId: linkedBenchmarkId.benchmarkId,
        IsAnonymous: isAnonymous,
      });
      // Simply Re-add the state of the other Benchmark Comparisons
    } else {
      benchmarkComparisonIds.push({
        BenchmarkId: linkedBenchmarkId.benchmarkId,
        IsAnonymous: linkedBenchmarkId.isAnonymous,
      });
    }
  });

  const data = {
    CPs: CPs,
    setModal: () => null,
    setError: () => null,

    // Data
    projectId: project.id,
    benchmarkId: benchmarkId,
    benchmarkComparisonIds: benchmarkComparisonIds,
  };

  updateBenchmarkComparisons(data);
}
