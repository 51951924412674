// React
import React from "react";
import PropTypes from "prop-types";

// Functions
import { TableCell } from "./TableBodyCells";
import isEmpty from "../../validation/is-empty";

TableFilteredTotal.propTypes = {
  columns: PropTypes.array,
  tableArray: PropTypes.array,
  filters: PropTypes.array,
  isTotalDisabled: PropTypes.bool,
};

export function TableFilteredTotal(props) {
  const { columns, tableArray } = props;
  const { filters } = props;
  const { isTotalDisabled } = props;

  if (isTotalDisabled) {
    return null;
  }

  if (isEmpty(filters)) {
    return null;
  }

  const displayRow = [];

  columns.forEach((cell, i) => {
    let value = "";
    if (cell.isTotalled) {
      value = cell.generateTotal(tableArray, cell.key);
      if (cell.type === "DESCRIPTION") {
        value = "Filtered Total";
      }
    }

    displayRow.push(
      <TableCell
        key={cell.key}
        value={value}
        width={cell.width}
        cell={cell}
        setModal={() => null}
      />
    );
  });

  return (
    <b>
      <div className={"pct-row-page-total"}>{displayRow}</div>
    </b>
  );
}

TableTotal.propTypes = {
  columns: PropTypes.array,
  tableArray: PropTypes.array,
  isTotalDisabled: PropTypes.bool,
};

export function TableTotal(props) {
  const { columns, tableArray } = props;
  const { isTotalDisabled } = props;

  if (isTotalDisabled) {
    return null;
  }

  const displayRow = [];

  columns.forEach((cell) => {
    let value = "";
    if (cell.isTotalled) {
      value = cell.generateTotal(tableArray, cell.key);
    }

    displayRow.push(
      <TableCell
        key={cell.key}
        value={value}
        width={cell.width}
        cell={cell}
        setModal={() => null}
      />
    );
  });

  return (
    <b>
      <div className={"pct-row-total"}>{displayRow}</div>
    </b>
  );
}
