import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom-end"  {...props} classes={{ popper: className }}  />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "625px",
      backgroundColor: "var(--collaboration4) ",
      border: "1px solid var(--collaboration1)",
      color: "var(--collaboration1)",
      fontSize:'inherit',
     // boxShadow:'2px 5px var(--fluidity1)'
    },
 
  });

  export default CustomWidthTooltip;