import React from "react";
import { Link } from "react-router-dom";

import InsightCategoriesPath from "../path/InsightCategoriesPath";

import "./MarketInsights.css";
import isEmpty from "../../validation/is-empty";
import Spinner from "../common/Spinner";
import Notification from "../common/Notification";
import "./Article.css";

export default function MarketInsights(props) {
  const { insights } = props;
  const { insightsLoading } = props;

  let display = [];

  if (isEmpty(insights) && !insightsLoading) {
    return <div>No Insights Categories Found</div>;
  }

  if (isEmpty(insights) && insightsLoading) {
    return <Spinner marginLeft={"625px"} marginTop={"200px"} />;
  }

  insights.forEach((insight) => {
    display.push(<Card key={insight.id} insight={insight} />);
  });

  return (
    <div>
      <InsightCategoriesPath />
      <div className="market-insights-display">{display}</div>
    </div>
  );
}

function Card(props) {
  const { insight } = props;

  let res = insight.marketInsightArticles.map(function (d) {
    return d.id;
  });

  return (
    <Link
      key={insight.id}
      to={`/insights/category/${insight.id}/articles`}
      className="market-insights-card-link"
    >
      <div
        key={insight.id}
        className="market-insights-card"
        style={{
          backgroundImage: `url(${insight.image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "rgba(248, 247, 216, 0.7)",
        }}
      >
        <div className="articles-card-unread-category-notification">
          <Notification at="category" categoryArticlesIds={res} />
        </div>
        <div className="market-insights-card-title-row">
          <Heading text={insight.title} fontSize={"25px"} />
        </div>
      </div>
    </Link>
  );
}

function Heading(props) {
  const { text } = props;
  const { fontSize } = props;
  return (
    <h1 className="display-4" style={{ fontSize: fontSize }}>
      {text}
    </h1>
  );
}
