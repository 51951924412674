import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import { determineSubLocation } from "../estimate-commentary/EstimateCommentary.functions";
import { hasRoles } from "../../utils/roles";
import EstimateBudget from "./ProjectBudgetConfiguration";
import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";

export default function ProjectBudgetModal(props) {
  const { user } = props;
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { comparedCP } = props.comparedCP;
  const { comparedCPR } = props.comparedCPR;
  const [modal, setModal] = useState(false);

  // No Cost Plans
  if (isEmpty(CP)) {
    return null;
  }

  // Hide if not Commentary
  let subLocation = determineSubLocation();
  if (subLocation !== "project-budget") {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        Component={EstimateBudget}
        title={translate("Cost Plan (Release) Budget")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Budget Props
        data={{
          CP: CP,
          CPs: CPs,
          projectID: project.id,
          project: project,
          comparedCP: comparedCP,
          comparedCPR: comparedCPR,
        }}
      />
      <div
        className="cost-planning-subtotal-open"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Configure Budget")}
      </div>
    </div>
  );
}
