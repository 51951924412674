import React from "react";
import { Bar } from "react-chartjs-2";
import Card from "./ChartCard";
import { getCSSColorPalette } from "../../../actions/cssColors";
import { comma } from "../../../helpers/common.functions";

export default function OptioneeringBarChart(props) {
  const { optionsAnalysisItems, labelClassName } = props;

  const labels = optionsAnalysisItems.map((item) => item.summary);
  const costImpactData = optionsAnalysisItems.map((item) =>
    parseFloat(item.costImpact)
  );

  const CSS_COLORS = getCSSColorPalette();

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Cost Impact",
        data: costImpactData,
        backgroundColor: CSS_COLORS["--fluidity1"],
        borderWidth: 1,
        barThickness: 10,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Cost Impact for each Option",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (tick) {
              return comma(tick);
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: function (tooltipItem) {
          return comma(tooltipItem.yLabel);
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    width: 700,
    height: 170,
  };

  return (
    <Card label={"Cost Impact for Each Option"} labelClassName={labelClassName}>
      <div>
        <div
          style={{ width: `${options.width}px`, height: `${options.height}px` }}
        >
          <Bar data={data} options={options} />
        </div>
      </div>
    </Card>
  );
}
