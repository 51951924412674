import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

const barThickness = 10;

export function processCostCategoriesChart(portfolioDashboard) {
  const { costCategories } = portfolioDashboard;
  const dataSetArray = [];

  let colors = [
    { color: "heritage1", value: "rgba(228, 97, 15, 1)" },
    { color: "aqua1", value: "rgba(50, 98, 149, 1)" },
    { color: "collaboration1", value: "rgba(85, 87, 90, 1)" },
    { color: "heritage2", value: "rgba(228, 97, 15, 0.7)" },
    { color: "aqua2", value: "rgba(50, 98, 149, 0.7)" },
  ];

  if (
    !isEmpty(costCategories.costCategoryData) &&
    !isEmpty(costCategories.masterCategories)
  ) {
    costCategories.masterCategories.forEach((element) => {
      //Iterate through every project
      costCategories.costCategoryData.forEach((x) => {
        let costCategory = x.projectCostCategories.find(
          (y) => y.field === element.field
        );
        if (!isEmpty(costCategory)) {
          element.value.push(costCategory.value);
        } else {
          element.value.push(0);
        }
      });
    });
    //build the graph dataset
    costCategories.masterCategories.forEach((element, i) =>
      dataSetArray.push({
        label: element.field,
        data: element.value,
        barThickness,
        backgroundColor: colors[i % colors.length].value,
      })
    );

    portfolioDashboard.costCategories.graphData = dataSetArray;
  }
}

export function processBudgetChart(portfolioDashboard) {
  const { budget } = portfolioDashboard;
  // Push budget data into chart format
  budget.budgetValues.forEach((o, i) => {
    if (o !== undefined) {
      if (o.value > 0) {
        budget.graphData.push({
          quantity: o.value.toFixed(0), // display value
          value: o.value, // value used for total calculation
          percentage: ((o.value / budget.portfolioBudget) * 100).toFixed(1),
          name: o.field,
          total: budget.portfolioBudget,
          id: i + 1,
        });
      }
    }
  });
}

export function processTotalsChart(portfolioDashboard) {
  const { totals } = portfolioDashboard;
  let values = [];

  values.push(totals.costPlanningCost);
  values.push(totals.postContractCost);

  totals.graphLabels = ["Cost Planning", "Post Contract"];
  totals.graphData.push({
    label: tranStr("Total Project Value"),
    data: values,
    backgroundColor: "rgba(228, 97, 15,1)",
    barThickness: barThickness,
  });
}

export function processAreaChart(portfolioDashboard) {
  const { metrics } = portfolioDashboard;

  const values = [];
  const labels = [];

  metrics.projectAreas.forEach((p) => {
    if (p.projectArea > 0) {
      labels.push(p.projectTitle);
      values.push(p.projectGia);
    }
  });

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient1 = ctx.createLinearGradient(0, 0, 400, 0);
    gradient1.addColorStop(0, "rgba(228, 97, 15,0.1)");
    gradient1.addColorStop(1, "rgba(228, 97, 15,1)");
    return {
      labels: labels,
      datasets: [
        {
          label: tranStr("Gross Internal Floor Area"),
          data: values,
          backgroundColor: gradient1,
          barThickness: barThickness,
        },
      ],
    };
  };

  metrics.projectAreasGraphData = data;
  metrics.projectAreasGraphLabels = labels;
}

export function processServicesMetricsChart(portfolioDashboard) {
  const { metrics } = portfolioDashboard;

  const values = [];
  const labels = [];

  metrics.servicesMetrics.forEach((m) => {
    labels.push(m.projectTitle + " - " + m.estimateName);
    values.push(m.quantity);
  });

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient1 = ctx.createLinearGradient(0, 0, 400, 0);
    gradient1.addColorStop(0, "rgba(50, 98, 149,0.1)");
    gradient1.addColorStop(1, "rgba(50, 98, 149,1)");
    return {
      labels: labels,
      datasets: [
        {
          label: tranStr("Engineering Services Cost / m2"),
          data: values,
          backgroundColor: gradient1,
          barThickness: barThickness,
        },
      ],
    };
  };

  metrics.servicesGraphData = data;
  metrics.servicesGraphLabels = labels;
}

export function processBenchmarksChart(portfolioDashboard) {
  const { benchmarking } = portfolioDashboard;

  // The list of values
  const labels = [];
  const graphEstimateValues = [];
  const graphBenchmarkAverages = [];

  // The graph datasets

  benchmarking.averages.forEach((average) => {
    labels.push(average.projectName + " - " + average.estimateName);
    graphEstimateValues.push(average.estimateRate);
    graphBenchmarkAverages.push(average.averageRate);
  });

  // Push the lists of values each as a dataset
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient1 = ctx.createLinearGradient(0, 0, 400, 0);
    gradient1.addColorStop(0, "rgba(228, 97, 15,0.1)");
    gradient1.addColorStop(1, "rgba(228, 97, 15,1)");

    const gradient2 = ctx.createLinearGradient(0, 0, 400, 0);
    gradient2.addColorStop(0, "rgba(85, 87, 90,0.1)");
    gradient2.addColorStop(1, "rgba(85, 87, 90,1)");

    return {
      labels: labels,
      datasets: [
        {
          label: tranStr("Current Construction Cost / m2"),
          data: graphEstimateValues,
          barThickness: barThickness,
          backgroundColor: gradient1,
        },
        {
          label: tranStr("Benchmark Average"),
          data: graphBenchmarkAverages,
          barThickness: barThickness,
          backgroundColor: gradient2,
        },
      ],
    };
  };

  benchmarking.graphData = data;
  benchmarking.graphLabels = labels;
}

export function processProgressChart(portfolioDashboard) {
  const { postContract } = portfolioDashboard;

  // The list of values
  const labels = [];
  const graphProgramElapsed = [];
  const graphPercentExpended = [];

  postContract.progress.forEach((project) => {
    labels.push(project.projectTitle);
    graphProgramElapsed.push(project.programElapsed);
    graphPercentExpended.push(project.forecastFinalCostApprovedToDate);
  });

  // Push the lists of values each as a dataset
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient1 = ctx.createLinearGradient(0, 0, 400, 0);
    gradient1.addColorStop(0, "rgba(50, 98, 149,0.1)");
    gradient1.addColorStop(1, "rgba(50, 98, 149,1)");

    const gradient2 = ctx.createLinearGradient(0, 0, 400, 0);
    gradient2.addColorStop(0, "rgba(85, 87, 90,0.1)");
    gradient2.addColorStop(1, "rgba(85, 87, 90,1)");

    return {
      labels: labels,
      datasets: [
        {
          label: tranStr("Program Elapsed"),
          data: graphProgramElapsed,
          barThickness: barThickness,
          backgroundColor: gradient1,
        },
        {
          label: tranStr("Percent of Anticipated Cost Expended to Date"),
          data: graphPercentExpended,
          barThickness: barThickness,
          backgroundColor: gradient2,
        },
      ],
    };
  };

  postContract.graphData = data;
  postContract.graphLabels = labels;
}
