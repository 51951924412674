import isEmpty from "../../validation/is-empty";
import validateFilter from "../../validation/filtering";

export function companyFilter(array, filter) {
  if (isEmpty(array)) {
    return [];
  }

  return array.filter((a) => {
    const name = validateFilter(a.name);
    const region = validateFilter(a.region);
    const isGlobal = validateFilter(a.is_global);

    // The Search Filter
    if (
      name.includes(filter.name.toLowerCase()) &&
      (region.includes(filter.region.toLowerCase()) || isGlobal)
    ) {
      return a;
    } else {
      return null;
    }
  });
}
