import React from "react";
import PropTypes from "prop-types";

import { Bar, HorizontalBar, Line } from "react-chartjs-2";

import { getCSSColorPalette } from "../../actions/cssColors";

const CHARTS = {
  VerticalBar: Bar,
  HorizontalBar: HorizontalBar,
  HorizontalLine: Line,
};

/// props validation | SQ(javascript:S6774)
MetricsChart.propTypes = {
  skipHighlight: PropTypes.bool,
  benchmarkControl: PropTypes.bool,
  data: PropTypes.array,
  avgs: PropTypes.array,
  chartType: PropTypes.string,
  labelTooltipCallback: PropTypes.func,
  yAxisTicksCallback: PropTypes.func,
};
///
export default function MetricsChart(props) {
  const { skipHighlight } = props;
  const { benchmarkControl } = props;
  const { data, avgs } = props;

  const { chartType } = props;
  const { labelTooltipCallback, yAxisTicksCallback } = props;

  const CSS_COLORS = getCSSColorPalette();

  const highlightColor = CSS_COLORS["--heritage"];
  const baseColor = CSS_COLORS["--fluidity1"];

  // Take all cost plans for this project
  let field = [];
  let value = [];

  data.forEach((b) => {
    field.push(b.project_name);
    value.push(b.quantity);
  });

  const colorArray = value.map((v, i) =>
    (i === 0 && !skipHighlight) || benchmarkControl ? highlightColor : baseColor
  );

  // Create the graph object
  const graph = {
    labels: field,
    datasets: [
      {
        label: "Quantity",
        data: value,
        backgroundColor: colorArray,
        borderColor: colorArray,
        // for Bar and HorizontalBar
        barThickness: 5,
        // for Line
        lineTension: 0,
        fill: false,
      },
    ],
    text: field,
  };

  if (avgs) {
    graph.datasets.push({
      label: "Average",
      data: avgs,
      backgroundColor: "gray",
      borderColor: "gray",
      borderWidth: 2,
      type: "line",
      fill: false,
    });
  }

  // Create the options object
  const options = {
    animation: {
      duration: 3000,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: labelTooltipCallback,
      },
    },

    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback: yAxisTicksCallback,
            stepSize: 1,
          },
        },
      ],
    },
    legend: {
      display: false,
      position: "bottom",
      fullWidth: false,
      reverse: false,
    },
  };

  // ticks-stepSize is relevant only for Line chart; remove it for other types
  if (chartType !== "HorizontalLine") {
    delete options.scales.yAxes[0].ticks.stepSize;
  }

  const Chart = CHARTS[chartType];

  return (
    <div className="metrics-chart">
      <Chart data={graph} options={options} height={250} />
    </div>
  );
}
