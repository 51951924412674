export const HOME_GLOSSARY = {
  TILES: {
    HOME_PAGE: [
      {
        title: "Project and Portfolio Reporting",
        imagePath: "/home/Project and Portfolio Reporting.jpg",
        linkTo: "/portfolio/all-projects/projects/",
      },
      {
        title: "CCM Benchmarking Service",
        imagePath: "/home/CCM Benchmarking Service.jpg",
        linkTo: "/home/benchmarking/",
      },
    ],
    BENCHMARKING_HOME_PAGE: [
      {
        title: "Create Benchmark Analysis",
        imagePath: "/home/benchmarking/Create Benchmark Analysis.jpg",
        linkTo: "/benchmarking/create",
      },
      {
        title: "View Benchmark Data",
        imagePath: "/home/benchmarking/View Benchmark Data.jpg",
        linkTo: "/benchmarking",
      },
      {
        title: "Upload Benchmark Project",
        imagePath: "/home/benchmarking/Upload Benchmark Project.jpg",
        linkTo: "/benchmarking",
      },
    ],
  },
};
