import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";

// COMPANIES
export function useAdminCompanies() {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAdminCompanies()
      .then((res) => {
        setCompanies(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return {
    companies: {
      data: companies,
      loading: loading,
      get: getAdminCompanies,
      set: setCompanies,
    },
  };
}

export async function getAdminCompanies() {
  const url = sharedAPIs().get_companies_and_employee_ids;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let erMessage = "Get Company Projects error";

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}
