import React from "react";
import { useProject } from "../../api/projects/ProjectAPI";
import ProjectComments from "./ProjectComments";
import ProjectNotSelected from "./ProjectNotSelected";

export default function CorrespondenceModalRightPane(props) {
  const { user } = props;
  const { selectedProjectId } = props;
  const { notificationsOnly } = props;
  const { setCommentsLoading } = props;

  const { project } = useProject(selectedProjectId, user.id); // activeDirectoryId
  const isProjectSelected = selectedProjectId !== 0 && project !== undefined;

  if (!isProjectSelected) {
    return <ProjectNotSelected />;
  }

  return (
    <ProjectComments
      user={user}
      project={project}
      selectedProjectId={selectedProjectId}
      notificationsOnly={notificationsOnly}
      setCommentsLoading={setCommentsLoading}
    />
  );
}
