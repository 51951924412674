import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";
import { projectUpdate } from "../../api/projects/ProjectAPI";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";

export async function saveCashflowSettings(payload, project, setError) {
  let url = pcAPIs().save_cashflow;

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  setError({
    text: "Saving...",
    type: "cashflow-feedback-success",
  });

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("saveCashflowSettings error");
  }

  if (response.ok) {
    // Response OK
  } else {
    setError({
      text: "Save Failed",
      type: "cashflow-feedback-error",
    });
    return console.log("Network response was not ok.");
  }
  setError({
    text: "Saved Successfully",
    type: "cashflow-feedback-success",
  });

  // Update the state
  projectUpdate(project);
}

export function combineGeneratedCashflows(
  forecastCashflow,
  cashflowActualsMonthly,
  cashflowActualsCumulative,
  cashflowContractorMonthly,
  cashflowContractorCumulative
) {
  const cashflow = {
    data: [],
  };

  if (!isEmpty(forecastCashflow)) {
    forecastCashflow.forEach((month, i) => {
      // Add Actuals
      if (!isEmpty(cashflowActualsMonthly)) {
        month.actualMonthly = cashflowActualsMonthly[i];
      }
      if (!isEmpty(cashflowActualsCumulative)) {
        month.actualCumulative = cashflowActualsCumulative[i];
      }

      // Add Contractor
      if (!isEmpty(cashflowContractorMonthly)) {
        month.contractorMonthly = cashflowContractorMonthly[i];
      }
      if (!isEmpty(cashflowContractorCumulative)) {
        month.contractorCumulative = cashflowContractorCumulative[i];
      }

      cashflow.data.push(month);
    });
  }

  return cashflow;
}

export function combineCashflows(
  cashflowDescription,
  cashflowForecastMonthly,
  cashflowForecastCumulative,
  cashflowActualsMonthly,
  cashflowActualsCumulative,
  cashflowContractorMonthly,
  cashflowContractorCumulative
) {
  const cashflow = {
    data: [],
  };

  let month = {};

  cashflowDescription.forEach((description, i) => {
    month = {};
    //Description
    month.date = description;

    //Add Forecast
    month.forecastMonthly = cashflowForecastMonthly[i];
    month.forecastCumulative = cashflowForecastCumulative[i];

    // Add Actuals
    month.actualMonthly = cashflowActualsMonthly[i];
    month.actualCumulative = cashflowActualsCumulative[i];

    // Add Contractor
    month.contractorMonthly = cashflowContractorMonthly[i];
    month.contractorCumulative = cashflowContractorCumulative[i];

    cashflow.data.push(month);
  });

  return cashflow;
}

export function setCashflowTotals(
  cashflow,
  totalForecast,
  totalContractor,
  totalActuals
) {
  cashflow.totalForecast = totalForecast;
  cashflow.totalContractor = totalContractor;
  cashflow.totalActuals = totalActuals;
}

export function getCost(estimates) {
  // Determine Cost
  let totalCost = 0;

  estimates.forEach((e) => {
    totalCost += e.construction_cost + e.escalationAllowance;
  });

  return totalCost;
}

export function normalDist(Z, M, SD) {
  // Adapted from
  // https://www.math.ucla.edu/~tom/distributions/normal.html

  let prob = 0;
  if (SD < 0) {
    return 0;
  }

  if (SD === 0) {
    if (Z >= M) {
      prob = 1;
    }
  } else {
    prob = normalcdf((Z - M) / SD);
    prob = Math.round(100000 * prob) / 100000;
  }

  return prob;
}

function normalcdf(X) {
  //HASTINGS.  MAX ERROR = .000001
  let T = 1 / (1 + 0.2316419 * Math.abs(X));
  let D = 0.3989423 * Math.exp((-X * X) / 2);
  let prob =
    D *
    T *
    (0.3193815 +
      T * (-0.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
  if (X > 0) {
    prob = 1 - prob;
  }
  return prob;
}
