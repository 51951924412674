import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import OptionsAnalysisCharts from "./OptionsAnalysisCharts";
import OptionsAnalysisTable from "./OptionsAnalysisTable";
import OptionsAnalysisModal from "./OptionsAnalysisModal";

import "./OptionsAnalysis.css";

export default function OptionsAnalysis(props) {
  const { CPs } = props;
  const { project, user } = props;
  const { options, getOptions, setOptions, optionsLoading } = props;
  const { activeStage, setActiveStage } = props;

  const [modal, setModal] = useState(false);

  const isOptionsConfigured =
    !isEmpty(options) && options.optionsAnalysisStages.length > 0;

  // Set the active stage only once immediately when the component is loaded
  useEffect(() => {
    if (isOptionsConfigured) {
      setActiveStage(
        options.optionsAnalysisStages[options.optionsAnalysisStages.length - 1]
      );
    }
  }, [options, setActiveStage, isOptionsConfigured]);

  if (isEmpty(project) || (optionsLoading && isOptionsConfigured)) {
    return (
      <div className="estimates-container">
        <div>{translate("Loading...")}</div>
      </div>
    );
  }

  if (!optionsLoading && !isOptionsConfigured) {
    return (
      <div className="estimates-container">
        <div>{translate("No Options Analyses have been configured.")}</div>
        <div>
          {translate(
            "Start by navigating to Options Control > Configure Analyses."
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="options-analysis-container">
      {activeStage && (
        <>
          <OptionsAnalysisCharts
            CPs={CPs}
            project={project}
            user={user}
            activeStage={activeStage}
            getOptions={getOptions}
            setOptions={setOptions}
          />
          <OptionsAnalysisTable
            activeStage={activeStage}
            setModal={setModal}
            project={project}
            user={user}
            options={options}
            getOptions={getOptions}
            setOptions={setOptions}
          />
          <OptionsAnalysisModal
            modal={modal}
            setModal={setModal}
            project={project}
            options={options}
            getOptions={getOptions}
            setOptions={setOptions}
            user={user}
            stage={activeStage}
          />
        </>
      )}
    </div>
  );
}
