import React, { useState, useEffect } from "react";
import { translate } from "../../utils/translation";

export default function ProjectFilter(props) {
  const { setFilters } = props;

  // Advanced Search Inputs
  const [title, setTitle] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [clientCompany, setClientCompany] = useState("");
  const [sectorSubSector, setSectorSubsector] = useState("");
  const [regionCountryCity, setRegionCountryCity] = useState("");
  const [searchTags, setSearchTags] = useState("");

  useEffect(() => {
    setFilters({
      title: title,
      jobNumber: jobNumber,
      clientCompany: clientCompany,
      sectorSubSector: sectorSubSector,
      regionCountryCity: regionCountryCity,
      searchTags: searchTags,
    });
  }, [
    setFilters,
    title,
    jobNumber,
    clientCompany,
    sectorSubSector,
    regionCountryCity,
    searchTags,
  ]);

  // Display on Account Control Only
  if (!window.location.href.includes("project-control")) {
    return null;
  }

  return (
    <div className="account-filters-container">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Project Filters")}
      </h1>
      <PortfolioInputSingle
        placeholder={translate("Project Title")}
        value={title}
        onChange={setTitle}
        icon={<i className="fas fa-pen-fancy"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Job Number")}
        value={jobNumber}
        onChange={setJobNumber}
        icon={<i className="fas fa-hashtag"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Client Company")}
        value={clientCompany}
        onChange={setClientCompany}
        icon={<i className="far fa-building"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Sector / Subsector")}
        value={sectorSubSector}
        onChange={setSectorSubsector}
        icon={<i className="fas fa-th-large"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Region / Country / City")}
        value={regionCountryCity}
        onChange={setRegionCountryCity}
        icon={<i className="fas fa-globe-asia"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Tags")}
        value={searchTags}
        onChange={setSearchTags}
        icon={<i className="fas fa-solid fa-tag"></i>}
      />
    </div>
  );
}

export function PortfolioInputSingle(props) {
  const { value, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "account-filter-input-icon";
  if (focus || value !== "") iconType = "account-filter-input-icon-focus";

  return (
    <div className="account-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="account-filter-input-col">
        {placeholder}
        <div className="account-filter-input-row">
          <input
            className="account-filter-search-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}
