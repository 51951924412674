import React from "react";
import { translate } from "../../utils/translation";
import {
  updateName,
  updateStage,
  updateCode,
  deleteStage,
} from "./OptionsAnalysisFieldsHelper";

export default function OptionsAnalysisGenerateFields(props) {
  const { newStages, setNewStages } = props;

  return newStages.map((s, i) => {
    const key = `newStages_${i}`;
    return (
      <div key={key} className="input-stages-row">
        <input
          id="input-stages-nr"
          className="input-stages-code"
          value={s.number}
          onChange={(e) => {
            updateStage(e.target.value, i, newStages, setNewStages);
          }}
        />
        <input
          id="input-stages-id"
          className="input-stages"
          value={s.name}
          onChange={(e) => {
            updateName(e.target.value, i, newStages, setNewStages);
          }}
        />
        <input
          className="input-stages-code"
          value={s.code}
          onChange={(e) => {
            updateCode(e.target.value, i, newStages, setNewStages);
          }}
        />
        <div
          className="stages-delete"
          id="stages-delete-btn"
          onClick={() => deleteStage(i, newStages, setNewStages)}
        >
          {translate("Delete")}
        </div>
      </div>
    );
  });
}
