import { getOrCreateUserProfile } from "../../api/users/UserAPI";
import isEmpty from "../../validation/is-empty";

import { createUserObject } from "../../actions/authActions";

export async function handleResponse(
  tokenResponse,
  setLoading,
  setStatus,
  loginClient
) {
  if (tokenResponse !== null) {
    setLoading(true);
    const idToken = tokenResponse.idToken;
    if (idToken) {
      let profile = {};
      try {
        profile = await getOrCreateUserProfile(idToken); // Get Profile
      } catch (e) {
        localStorage.removeItem("ADLoading");
        setLoading(false);
        setStatus(profile);
      }
      // Do not set user data if server returned undefined
      if (isEmpty(profile)) {
        localStorage.removeItem("ADLoading");
        setLoading(false);
        setStatus({ error: true, message: "Invalid User" });
        // Successful
      } else {
        localStorage.removeItem("ADLoading");
        setLoading(false);
        const user = await createUserObject(profile, idToken);
        loginClient(user); // Set Cost Clarity User Object to localstorage/redux
      }
    }
  } else {
    // If the user presses back button from global b2c page
    if (!isEmpty(localStorage.ADLoading)) {
      localStorage.removeItem("ADLoading");
      setLoading(false);
    }
  }
}

export function handleError(error) {
  //If there is any type of error, stop the spinner
  if (!isEmpty(localStorage.ADLoading)) {
    localStorage.removeItem("ADLoading");
  }
  if (error.message.includes("user_cancelled")) {
    return {
      error: true,
      message: "Cancelled Login",
    };
  } else if (error.message.includes("access_denied")) {
    return {
      error: true,
      message: "Access Denied",
    };
  } else if (error.message.includes("interaction_in_progress")) {
    return {
      error: true,
      message: "Interaction In Progress",
    };
  } else {
    return {
      error: true,
      message: error.message,
    };
  }
}
