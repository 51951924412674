import React from "react";
import PropTypes from "prop-types";

import { Toggle } from "./BenchmarkingProjectToggle";

/// props validation | SQ(javascript:S6774)
SectionTitleControl.propTypes = {
  isElementalUnitRate: PropTypes.bool,
  setIsElementalUnitRate: PropTypes.func,
  title: PropTypes.any,
};
///
export default function SectionTitleControl(props) {
  const { isElementalUnitRate, setIsElementalUnitRate } = props;
  const { title } = props;

  return (
    <div className="benchmarking-dashboard-title-container">
      <div className="section-title">
        <h1 className="display-4">{title}</h1>
      </div>
      <Toggle
        isElementalUnitRate={isElementalUnitRate}
        setIsElementalUnitRate={setIsElementalUnitRate}
      />
    </div>
  );
}
