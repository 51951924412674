import { tranStr } from "../../utils/translation";

export function riskColumns() {
  return [
    {
      heading: tranStr("Project"),
      key: "project_title",
      type: "DESCRIPTION",
      width: 150,
    },
    {
      heading: tranStr("Risk"),
      type: "DESCRIPTION",

      key: "risk_opportunity",
      width: 248,
    },
    {
      heading: tranStr("Level"),
      type: "DESCRIPTION",

      key: "weighting",
      width: 100,
      Cell: ({ row, original }) => {
        if (original.weighting === 3) {
          return tranStr("High");
        }

        if (original.weighting === 2) {
          return tranStr("Medium");
        }

        if (original.weighting === 1) {
          return tranStr("Low");
        }

        if (original.weighting === 0) {
          return tranStr("");
        }

        return "";
      },
    },
  ];
}

export function decisionColumns() {
  return [
    {
      heading: tranStr("Project"),
      type: "DESCRIPTION",

      key: "project_title",
      width: 150,
    },
    {
      heading: tranStr("Decision"),
      type: "DESCRIPTION",

      key: "decision",
      width: 248,
    },
    {
      heading: tranStr("Date"),
      type: "DESCRIPTION",

      key: "required_by",
      width: 100,
    },
  ];
}
