import React from "react";
import PaymentOverviewChart from "./ProgressClaimPaymentOverviewChart";
import isEmpty from "../../validation/is-empty";

import { generatePaymentOverviewData } from "./ProgressClaimPaymentOverview.functions";

import "../cost-planning/CostPlanningDashboard.css";

// Needs Refactoring
export default function ProgressClaimPaymentOverview(props) {
  const { PC } = props;
  let costCategoryData = generatePaymentOverviewData(PC);

  if (isEmpty(PC)) {
    return null;
  }

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.7)";
  let aqua2 = "rgba(50, 98, 149, 0.7)";
  let collaboration12 = "rgba(85, 87, 90, 0.7)";

  let colorSchema = [
    collaboration1,
    heritage,
    aqua,
    collaboration12,
    aqua2,
    heritage2,
  ];

  let margin = {
    left: -40,
    right: 10,
    top: 5,
    bottom: 5,
  };

  return (
    <PaymentOverviewChart
      data={costCategoryData}
      width={200}
      height={200}
      eRadius={90}
      iRadius={70}
      lWidth={300}
      lHeight={150}
      margin={margin}
      colorSchema={colorSchema}
      projectDashboard={true}
    />
  );
}
