import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import CostVsBudget from "../charts/CostVsBudget";

export default function PCProvisionalSumsSummaryBar(props) {
  let budgetValue = 0;
  let costValue = 0;

  let { costReport } = props;
  let CR = costReport;
  if (isEmpty(CR) || isEmpty(costReport)) {
    return null;
  }

  costValue = CR.provisional_sums_forecast_total;
  budgetValue = CR.provisional_sums_original_budget;
  budgetValue = parseInt(budgetValue);
  costValue = parseInt(costValue);
  //Forecast Total Cost
  let costText = translate("Provisional Sum Forecast Cost");
  //Original Budget
  let budgetText = translate("Provisional Sums Original Budget");
  //Adjustments to provisional items
  let differenceText = translate("Provisional Sums Adjustment");

  return (
    <CostVsBudget
      firstValue={budgetValue}
      secondValue={costValue}
      firstText={budgetText}
      secondText={costText}
      differenceText={differenceText}
      headroom={false}
      isApproved={true}
    />
  );
}
