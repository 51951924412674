export function generateRiskRegisterTypes(RRs) {
  // Create Unique list of Types
  const types = Array.from(new Set(RRs.map((r) => r.risk_type))).map((r) => {
    return {
      risk_type: r,
      value: 0,
      best_case_value: 0,
      worst_case_value: 0,
      risk_analysis: 0,
    };
  });

  // Create a total object
  const totals = {
    risk_type: "Total",
    value: 0,
    best_case_value: 0,
    worst_case_value: 0,
    risk_analysis: 0,
  };

  // Add totals to the unique lists
  RRs.forEach((risk_register) => {
    types.forEach((type) => {
      if (risk_register.risk_type === type.risk_type) {
        type.value += risk_register.most_likely_value;
        type.best_case_value += risk_register.best_case_value;
        type.worst_case_value += risk_register.worst_case_value;
        type.risk_analysis += risk_register.risk_analysis;
      }
    });

    totals.value += risk_register.most_likely_value;
    totals.best_case_value += risk_register.best_case_value;
    totals.worst_case_value += risk_register.worst_case_value;
    totals.risk_analysis += risk_register.risk_analysis;
  });

  // Remove any 0 values
  const removedZeros = types.filter((type) => type.value > 0);

  // Format into graph object
  let data = removedZeros.map((o, i) => {
    return {
      quantity: o.value, // display value
      value: o.value, // value used for total calculation
      percentage: ((o.value / totals.value) * 100).toFixed(1),
      name: o.risk_type,
      id: i + 1,
    };
  });

  return { data: data, types: types, totals: totals };
}
