import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import Table from "../table/Table";
import {
  delaysColumns,
  eoTColumns,
} from "../post-contract-tables/PostContractTable.functions";
import PostContractProvDelaysModal from "../post-contract-table-modals/PostContractProvDelaysModal";
import PostContractEoTModal from "../post-contract-table-modals/PostContractEoTModal";
import Modal from "../modal/Modal";
import { tranStr, translate } from "../../utils/translation";

export default function PostContractDelays(props) {
  const { EoTs } = props;
  const { PDs } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  // No data available so return null
  if (isEmpty(PDs) && isEmpty(EoTs)) {
    return null;
  }

  return (
    <div>
      <div className="pct-spacer" />
      <Table
        title={tranStr("Provisional Delay Days")}
        tableArray={PDs}
        columns={delaysColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractProvDelaysModal}
        modal={modal}
        setModal={setModal}
        columns={delaysColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
      <div className="pct-spacer" />
      <Table
        title={tranStr("Extensions of Time Records")}
        tableArray={EoTs}
        columns={eoTColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal2}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractEoTModal}
        modal={modal2}
        setModal={setModal2}
        columns={eoTColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
