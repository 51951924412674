import React from "react";
import { authHeader } from "../../_helpers/auth-header";
import { hasRoles } from "../../utils/roles";
import "./EstimatesTPI.css";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";

import { SAVE_MESSAGES } from "./EstimatesTPISaveMessages";

export default function EstimatesTPISaveEscalation(props) {
  const { estimateIDs, futureYear, percentage } = props;
  const { message, setMessage } = props;
  const { user } = props;
  const { project, CPs } = props;
  const { isLinkedTPI: isSynchronizedTPI } = props;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  let validFutureDate = "02 02 " + futureYear; //choosing 2nd month as it will otherwise change the year for US dates

  return (
    <div className="estimates-TPI-save-container">
      <button
        className="estimates-TPI-save-button"
        onClick={async () => {
          await onClickSave(
            // COST PLAN API
            cpAPIs().save_escalation_date,
            estimateIDs,
            Date.parse(validFutureDate),
            percentage,
            setMessage,
            project,
            CPs,
            isSynchronizedTPI
          );
        }}
      >
        <i className="fas fa-save" />
      </button>
      <div className="estimates-TPI-save-message">
        <b>{message}</b>
      </div>
    </div>
  );
}

async function onClickSave(
  url,
  estimateIDs,
  futureYear,
  percentage,
  setMessage,
  project,
  CPs,
  isSynchronizedTPI
) {
  setMessage(SAVE_MESSAGES.SAVING);

  const payload = JSON.stringify({
    projectID: project.id,
    estimateIDs,
    futureYear,
    percentage,
    is_synchronized_tpi: isSynchronizedTPI,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    setMessage(SAVE_MESSAGES.SAVED);
    await reloadProjectData(CPs, project);
  } else {
    setMessage(SAVE_MESSAGES.SAVE_FAILED);
    console.log("Network response was not ok.");
  }

  return response;
}

async function reloadProjectData(CPs, project) {
  let data = await CPs.get(project.id, project);
  CPs.set(data);
}
