import isEmpty from "../validation/is-empty";

// Escalate Costs to the Base Date of the Estimate
export function escalateTpi(cData, benchmark, rate) {
  // Conversion Data
  const { inflationData } = cData;
  const { TPI } = inflationData;
  const { futureYear } = inflationData;

  let currentIndex;
  let futureIndex;
  let currentYear;
  let escalatedRate = rate; // Escalated rate will be the base rate by default
  let benchmarkCity = benchmark.city;
  let escalatedPercent = 0;

  if (!isEmpty(TPI)) {
    // If a tpi_city override exists use instead
    if (benchmark.tpi_city) {
      benchmarkCity = benchmark.tpi_city;
    }

    const date = benchmark.base_date;

    currentYear = parseInt(
      new Date(date).toLocaleString("default", {
        year: "numeric",
      })
    );

    TPI.raw.forEach((row) => {
      if (row.year === currentYear && row.location === benchmarkCity) {
        currentIndex = row.value;
      }

      if (row.year === futureYear && row.location === benchmarkCity) {
        futureIndex = row.value;
      }
    });

    escalatedRate = Math.round((futureIndex / currentIndex) * rate);
    escalatedPercent = (futureIndex / currentIndex) * 100 - 100;

    if (isNaN(escalatedRate)) {
      escalatedRate = rate;
    }

    if (isNaN(escalatedPercent)) {
      escalatedPercent = 0;
    }
  }

  return { tpiRate: escalatedRate, tpiPercent: escalatedPercent };
}
