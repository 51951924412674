import React from "react";
import isEmpty from "../../validation/is-empty";
import { commaToFixed } from "../../helpers/common.functions";

import "./CostReportCriticalList.css";

export default function CostReportCriticalList(props) {
  const { data } = props;
  const { valueKey } = props;

  // No data
  if (isEmpty(data)) {
    return null;
  }

  const critical = data.filter((v) => v.is_critical);

  // No Critical data
  if (isEmpty(critical)) {
    return null;
  }

  const display = critical.map((v) => {
    return (
      <div key={v.id} className="cost-report-critical-variations-row">
        <div className="cost-report-critical-variations-symbol-red"></div>
        <div className="cost-report-critical-variations-ref">{v.ref}</div>
        <div className="cost-report-critical-variations-description">
          {v.description}
        </div>
        <div className="cost-report-critical-variations-value">
          {commaToFixed(v[valueKey])}
        </div>
      </div>
    );
  });

  return <div className="cost-report-critical-variations">{display}</div>;
}
