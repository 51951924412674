import React from "react";
import PDCPDonutChart from "./PDCPDonutChart";
import isEmpty from "../../validation/is-empty";
import {
  benchmarkCategories,
  createEscalationCategory,
  createEscalationPercentages,
} from "../estimates/EstimatesBenchmarkDonutComponent.functions";
import { TOTAL } from "../../actions/types";

function DonutChartCostSummary(props) {
  const { CP } = props;

  // No Cost Plans Uploaded return blank
  if (isEmpty(CP) || isEmpty(CP.estimates)) {
    return null;
  }

  let costCategoryData = [];
  const estimates = CP.estimates.filter((e) => e.is_included);
  const benchmarks = estimates.map((e) => e.benchmark);

  // Get the Cost Categories
  costCategoryData = benchmarkCategories(benchmarks, "Cost Categories", TOTAL);

  // Cost Escalation needs to be calculated and added if it exists
  const escalationCategory = createEscalationCategory(estimates);
  if (escalationCategory.value > 0) {
    costCategoryData.push(escalationCategory);
  }
costCategoryData = costCategoryData.filter(item => (item.value!== 0));

  // Recalculate the percentages to account for cost escalation
  createEscalationPercentages(costCategoryData);

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.7)";
  let aqua2 = "rgba(50, 98, 149, 0.7)";
  let collaboration12 = "rgba(85, 87, 90, 0.7)";

  let colorSchema = [
    heritage,
    collaboration1,
    aqua,
    collaboration12,
    aqua2,
    heritage2,
  ];

  let margin = {
    left: -40,
    right: 10,
    top: 5,
    bottom: 5,
  };

  return (
    <PDCPDonutChart
      data={costCategoryData}
      width={200}
      height={200}
      eRadius={90}
      iRadius={70}
      lWidth={270}
      lHeight={150}
      margin={margin}
      colorSchema={colorSchema}
      projectDashboard={true}
      CP={CP}
    />
  );
}

const PDCPDonutChartCostSummary = React.memo(DonutChartCostSummary);

export default PDCPDonutChartCostSummary;
