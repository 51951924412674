import React, { useState, useEffect } from "react";
import isEmpty from "../../../validation/is-empty";
import { translate } from "../../../utils/translation";
import "../location-factors/LocationFactors.css";
import { saveCurrencyExchanges } from "./CurrencyExchange.functions";
import PropTypes from "prop-types";

/// props validation | SQ(javascript:S6774)
CurrencyExchangeModal.propTypes = {
  currencies: PropTypes.array,
  currencyExchange: PropTypes.object,
};

export function CurrencyExchangeModal(props) {
  const { currencies } = props;
  const { currencyExchange } = props;

  const [error, setError] = useState({});

  const [fields, setFields] = useState([
    {
      name: "",
      code: "",
      rate: "",
      username: "",
      time: "",
    },
  ]);

  useEffect(() => {
    if (!isEmpty(currencies)) {
      let filtered = currencies.map((d) => {
        return {
          name: d.name,
          code: d.code,
          rate: d.rate,
          username: d.username,
          time: d.time,
        };
      });
      if (filtered.length > 0) {
        setFields(filtered);
      }
    }
  }, [currencies]);

  if (isEmpty(currencies)) {
    return <div>{translate("There are no currencies available")}</div>;
  }
  const data = {
    fields: fields,
    setError: setError,
    currencyExchange: currencyExchange,
  };
  return (
    <div className="tpi-calc location-contianer">
      <div className="location-container">{Fields()}</div>
      <ErrorCom error={error} setError={setError} />

      <div className="general-button-container">
        <SaveButton data={data} />
      </div>
      {currencies[0].username && (
        <div className="location-author-info">{`Last updated by ${currencies[0].username} on ${currencies[0].time}`}</div>
      )}
    </div>
  );

  function Fields() {
    return fields.map((f, i) => {
      return (
        <div key={f.code} className="location-factor-field-container">
          <div className="location-field">{`${f.name} (${f.code})`}</div>
          <input
            type="number"
            step="any"
            className="location-factor"
            value={f.rate}
            maxLength={90}
            onChange={(e) => {
              updateField("rate", e.target.value, i, fields);
            }}
            placeholder="Exchange Rate"
          />
        </div>
      );
    });
  }

  function updateField(field, value, i, array) {
    let temp = [...array];
    temp[i][field] = value;
    setFields(temp);
  }
}

/// props validation | SQ(javascript:S6774)
SaveButton.propTypes = {
  data: PropTypes.object,
};
function SaveButton(props) {
  const { data } = props;
  return (
    <input type="button"
      key="currencysave"
      id={"button-save-location-factor"}
      className="general-upload-button"
      onClick={async () => {
        await saveCurrencyExchanges(data);
      }}
      value="Save"
    >
      
    </input>
  );
}
/// props validation | SQ(javascript:S6774)
ErrorCom.propTypes = {
  error: PropTypes.object,
};
function ErrorCom(props) {
  const { error } = props;
  return (
    <div className="pdu-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
