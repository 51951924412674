import React from "react";

import Cashflow from "./Cashflow";
import isEmpty from "../../validation/is-empty";

import "./CashflowContainer.css";
import { translate } from "../../utils/translation";

export default function CashflowContainer(props) {
  const { project } = props;
  const { postContract } = props;
  const { costReport } = props;
  const { progressClaim } = props;

  if (isEmpty(project) || isEmpty(costReport) || isEmpty(progressClaim)) {
    return (
      <div>
        {translate(
          "Cashflow is available after a Progress Claim has been uploaded"
        )}
      </div>
    );
  }

  const forecastBasis = Math.round(
    progressClaim.progress_claim.forecast_adjusted_contract_sum_excl_tax
  );

  //Control Base: Template

  const { cashflowDescription } = progressClaim.progress_claim;

  const { cashflowForecastMonthly } = progressClaim.progress_claim;
  const { cashflowForecastCumulative } = progressClaim.progress_claim;
  const { cashflowForecastTotal } = progressClaim.progress_claim;

  const { cashflowActualsMonthly } = progressClaim.progress_claim;
  const { cashflowActualsCumulative } = progressClaim.progress_claim;
  const { cashflowActualsTotal } = progressClaim.progress_claim;

  const { cashflowContractorMonthly } = progressClaim.progress_claim;
  const { cashflowContractorCumulative } = progressClaim.progress_claim;
  const { cashflowContractorTotal } = progressClaim.progress_claim;

  //Control Base: Generated
  const { cashflowGeneratedDescription } = progressClaim.progress_claim;

  const { cashflowGeneratedForecastMonthly } = progressClaim.progress_claim;
  const { cashflowGeneratedForecastCumulative } = progressClaim.progress_claim;
  const { cashflowGeneratedForecastTotal } = progressClaim.progress_claim;

  const { cashflowGeneratedActualsMonthly } = progressClaim.progress_claim;
  const { cashflowGeneratedActualsCumulative } = progressClaim.progress_claim;
  const { cashflowGeneratedActualsTotal } = progressClaim.progress_claim;

  const { cashflowGeneratedContractorMonthly } = progressClaim.progress_claim;
  const { cashflowGeneratedContractorCumulative } =
    progressClaim.progress_claim;
  const { cashflowGeneratedContractorTotal } = progressClaim.progress_claim;

  return (
    <div className="post-contract-cashflow-container">
      <Cashflow
        project={project}
        postContract={postContract}
        totalCost={forecastBasis}
        // Cashflow Data
        cashflow_start_month={project.post_contract_start_month}
        cashflow_start_year={project.post_contract_start_year}
        cashflow_standard_deviation={
          project.post_contract_cashflow_standard_deviation
        }
        cashflow_program={project.post_contract_duration}
        // Template
        cashflowDescription={cashflowDescription}
        cashflowForecastMonthly={cashflowForecastMonthly}
        cashflowForecastCumulative={cashflowForecastCumulative}
        cashflowActualsMonthly={cashflowActualsMonthly}
        cashflowActualsCumulative={cashflowActualsCumulative}
        cashflowContractorMonthly={cashflowContractorMonthly}
        cashflowContractorCumulative={cashflowContractorCumulative}
        cashflowContractorTotal={cashflowContractorTotal}
        cashflowActualsTotal={cashflowActualsTotal}
        cashflowForecastTotal={cashflowForecastTotal}
        // Generated
        cashflowGeneratedDescription={cashflowGeneratedDescription}
        cashflowGeneratedForecastMonthly={cashflowGeneratedForecastMonthly}
        cashflowGeneratedForecastCumulative={
          cashflowGeneratedForecastCumulative
        }
        cashflowGeneratedForecastTotal={cashflowGeneratedForecastTotal}
        cashflowGeneratedActualsMonthly={cashflowGeneratedActualsMonthly}
        cashflowGeneratedActualsCumulative={cashflowGeneratedActualsCumulative}
        cashflowGeneratedContractorMonthly={cashflowGeneratedContractorMonthly}
        cashflowGeneratedContractorCumulative={
          cashflowGeneratedContractorCumulative
        }
        cashflowGeneratedContractorTotal={cashflowGeneratedContractorTotal}
        cashflowGeneratedActualsTotal={cashflowGeneratedActualsTotal}
      />
    </div>
  );
}
