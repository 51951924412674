import React from "react";

import isEmpty from "../../validation/is-empty";

import ReconciliationContent from "./ReconciliationContent";
import ReconciliationToggle from "./ReconciliationToggle";

import "./Reconciliation.css";

import { useReconciliationSwitch } from "./Reconciliation.functions";
import { translate } from "../../utils/translation";

export default function Reconciliation(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;
  const { comparedCP, comparedCPR } = props;
  const { accordionState } = props;

  const { reconciliationData } = useReconciliationSwitch(
    CPs,
    CP,
    comparedCP,
    comparedCPR
  );

  if (isEmpty(CP)) {
    return null;
  }

  return (
    <div>
      <div className="recon-section-title">
        <h1 className="display-4">{translate("Compare")}</h1>
        <ReconciliationToggle
          state={reconciliationData.reconciliationType}
          setState={reconciliationData.setReconciliationType}
          on={"STAGE"}
        />
      </div>
      <ReconciliationContent
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
        comparedCP={reconciliationData.comparisonCP}
        accordionState={accordionState}
        reconciliationData={reconciliationData}
      />
    </div>
  );
}
