import React from "react";

import { hasRoles } from "../../utils/roles";

import { deleteTPI } from "./TenderPriceIndex.functions";

export default function TenderPriceIndexingDelete(props) {
  const { setTPI } = props;
  const { user } = props;

  const { region } = props;
  const { country } = props;

  let subLocation = determineSubLocation();

  if (subLocation !== "tender-price-index") {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  return (
    <div>
      <div
        id="tpi-delete-button"
        className="tpi-control-button"
        onClick={() => {
          deleteTPI(setTPI, region, country);
        }}
      >
        <i className="fas fa-trash" />
      </div>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
