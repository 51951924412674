import { translate } from "../../utils/translation";

export const CORRESPONDENCE = {
  TITLE: translate("Correspondence"),
  SHOW_CLOSED_OUT_COMMENTS_TOGGLE_SWITCH_LABEL: translate(
    "Show Closed-Out Comments"
  ),
  PROJECTS_LIST_SECTION_PENDING_LABEL: translate("Pending"),
  PROJECTS_LIST_SECTION_CLOSED_OUT_LABEL: translate("Closed-Out"),
  REQUIRE_PROJECT_SELECTION_HINT: translate(
    "Select a project from the left pane to view correspondence."
  ),
  NO_COMMENTS_FOUND: translate("No breakdown comments found for estimate."),
  ALL_COMMENTS_CLOSED_OUT: translate(
    "All breakdown comments for this estimate are closed-out."
  ),
};
