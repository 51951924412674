import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./CostReportDetails.css";

export default function CostReportDetails(props) {
  const { CR } = props;
  const { variations } = props;

  if (isEmpty(CR)) {
    return null;
  }

  let work_completed_to_date = "";
  // Validation
  if (!isEmpty(CR.work_completed_to_date)) {
    work_completed_to_date =
      CR.work_completed_to_date.day +
      " " +
      CR.work_completed_to_date.month +
      " " +
      CR.work_completed_to_date.year;
  }

  let total_months = 0;
  let elapsed_percent = 0;
  // Validation
  if (!isEmpty(CR.time)) {
    total_months = CR.time.total_months;
  }
  if (!isEmpty(CR.time)) {
    elapsed_percent = CR.time.elapsed_percent;
  }

  return (
    <div className="cost-report-details">
      <CostReportItem
        value={CR.contractor_name_abbreviated}
        text={translate("Contractor")}
        type={"cost-report-details-box1"}
      />
      <CostReportItem
        value={CR.cost_report_number}
        text={translate("Cost Report")}
        type={"cost-report-details-box2"}
      />
      <CostReportItem
        value={variations ? variations.length : 0}
        text={translate("No of Variations")}
        type={"cost-report-details-box3"}
      />
      <CostReportItem
        value={work_completed_to_date}
        text={translate("Work Completed To")}
        type={"cost-report-details-box1"}
      />
      <CostReportItem
        value={total_months}
        text={translate("Contract Duration")}
        type={"cost-report-details-box2"}
      />
      <CostReportItem
        value={elapsed_percent}
        text={translate("Period Elapsed")}
        type={"cost-report-details-box3"}
      />
    </div>
  );
}

function CostReportItem(props) {
  const { value, text, type } = props;

  return (
    <div className="cost-report-details-item">
      <div className={type}>
        <b>{value}</b>
      </div>
      <div className="cost-report-details-label">{text}</div>
    </div>
  );
}
