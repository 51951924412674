import React from "react";

import {
  validateCurrency,
  validatePercent,
} from "./PostContractTableModal.functions";

import {
  //Description,
  ExpandableRow,
  OneValueRow,
  DocumentLink,
} from "./PostContractModal.components";

import "./PostContractTableModal.css";

export default function PostContractTradeCostsModal(props) {
  const { selectedRow } = props;
  const R = selectedRow;

  return (
    <div className="pctm">
      <div className="pctm-container-row">
        <OneValueRow heading1={"Description"} value1={R.description} />
      </div>
      <div className="pctm-container-row">
        <ExpandableRow
          headings={[
            "Contract Value",
            "Provisional Items",
            "Contract Excl Prov Items",
          ]}
          values={[
            validateCurrency(R.contract_value),
            validateCurrency(R.provisional_items),
            validateCurrency(R.contract_value_excl_prov_items),
          ]}
        />
        <ExpandableRow
          headings={["% Complete to Date", "Value Complete to Date"]}
          values={[
            validateCurrency(R.percent_complete_to_date),
            validateCurrency(R.value_complete_to_date),
          ]}
        />
      </div>

      <div className="pctm-container-row">
        <ExpandableRow
          headings={[
            "Contractor Claim %",
            "Contractor Claim Value",
            "Difference",
          ]}
          values={[
            validatePercent(R.contractor_claim_percent),
            validateCurrency(R.contractor_claim_value),
            validateCurrency(R.difference),
          ]}
        />
        <ExpandableRow
          headings={["Value Complete Last Claim", "Value Complete This Claim"]}
          values={[
            validateCurrency(R.value_complete_last_claim),
            validateCurrency(R.value_complete_this_claim),
          ]}
        />
      </div>
      <div className="pctm-container-row">
        <OneValueRow
          heading1={"Reasons for Difference"}
          value1={R.reasons_for_difference}
        />
      </div>

      <div className="pctm-container-row">
        <OneValueRow heading1={"Comments"} value1={R.comments} />
      </div>
      <div className="pctm-container-row">
        <DocumentLink R={R} />
      </div>
    </div>
  );
}
