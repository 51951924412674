import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { CommentNotification as ProjectCommentNotification } from "../portfolio-projects/ProjectItem";

export default function ProjectNavigationPostContract(props) {
  const {
    projectID,
    show,
    setShow,
    setCPShow,
    setSustainabilityShow,
    project,
  } = props;
  let section = window.location.href.includes("post-contract");

  let name = "project-navigation-section";
  if (section) {
    name = "project-navigation-section-selected";
  }

  const tabs = [
    {
      name: "Cost Report",
      key: "cost-report",
      url: `/project/${projectID}/post-contract/cost-report`,
    },
    {
      name: "Progress Claims",
      key: "progress-claims",
      url: `/project/${projectID}/post-contract/progress-claims`,
    },
    {
      name: "Commentary",
      key: "commentary",
      url: `/project/${projectID}/post-contract/commentary`,
    },
    {
      name: "Trade Costs",
      key: "trade-costs",
      url: `/project/${projectID}/post-contract/trade-costs`,
    },
    {
      name: "Unfixed Materials",
      key: "unfixed-materials",
      url: `/project/${projectID}/post-contract/unfixed-materials`,
    },
    {
      name: "Variations",
      key: "variations",
      url: `/project/${projectID}/post-contract/variations`,
    },
    {
      name: "Provisional Sums",
      key: "provisional-sums",
      url: `/project/${projectID}/post-contract/provisional-sums`,
    },
    {
      name: "Provisional Quantities",
      key: "provisional-quantities",
      url: `/project/${projectID}/post-contract/provisional-quantities`,
    },
    {
      name: "Delays / EoTs",
      key: "delays",
      url: `/project/${projectID}/post-contract/delays`,
    },
    {
      name: "Client Directs",
      key: "client-directs",
      url: `/project/${projectID}/post-contract/client-directs`,
    },
    {
      name: "CD Variations",
      key: "client-direct-variation-register",
      url: `/project/${projectID}/post-contract/client-direct-variation-register`,
    },
    {
      name: "Enabling Works",
      key: "enabling-works",
      url: `/project/${projectID}/post-contract/enabling-works`,
    },
    {
      name: "EW Variations",
      key: "enabling-works-variations-register",
      url: `/project/${projectID}/post-contract/enabling-works-variations-register`,
    },

    {
      name: "Consultant Fees",
      key: "consultant-fees",
      url: `/project/${projectID}/post-contract/consultant-fees`,
    },

    {
      name: "CF Variations",
      key: "consultant-fee-variations-register",
      url: `/project/${projectID}/post-contract/consultant-fee-variations-register`,
    },
    {
      name: "Risk Register",
      key: "risk-register",
      url: `/project/${projectID}/post-contract/risk-register`,
    },
    {
      name: "Cashflow",
      key: "cashflow",
      url: `/project/${projectID}/post-contract/cashflow`,
    },
    {
      name: "Contingency",
      key: "contingency",
      url: `/project/${projectID}/post-contract/contingency`,
    },
  ];

  return (
    <div className="project-navigation-tab-item-group">
      <div
        className="project-navigation-tab-link"
        onClick={() => {
          setShow((state) => !state);
          setCPShow(() => false);
          setSustainabilityShow(() => false);
        }}
      >
        <div className={name}>
          <div className="project-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i
                  className="fas fa-file-contract"
                  style={{ marginRight: "10px" }}
                ></i>
              </div>
              {translate("Post Contract")}
            </div>
            {show && (
              <div className="icon">
                <i className="far fa-minus-square" />
              </div>
            )}
            {!show && (
              <div className="icon">
                <i className="far fa-plus-square" />
              </div>
            )}
          </div>
        </div>
      </div>

      {tabs.map((t) => {
        if (show) {
          return (
            <Tab
              key={t.name}
              project={project}
              t={t}
              url={t.url}
              section={section}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

function Tab(props) {
  const { project, t, url } = props;
  const { section } = props;

  const projectProperties = {
    "Trade Costs": "enable_pc_trade_costs",
    "Unfixed Materials": "enable_pc_unfixed_materials",
    Variations: "enable_pc_variations",
    "Provisional Sums": "enable_pc_provisional_sums",
    "Provisional Quantities": "enable_pc_provisional_quantities",
    "Delays / EoTs": "enable_pc_delays",
    "Client Directs": "enable_pc_client_directs",
    "CD Variations": "enable_pc_client_direct_variations",
    "Enabling Works": "enable_pc_enabling_works",
    "EW Variations": "enable_pc_enabling_works_variations",
    "Consultant Fees": "enable_pc_project_team_consultant_fees",
    "CF Variations": "enable_pc_project_team_consultant_fees_variations",
    "Risk Register": "enable_pc_risk_register",
    Cashflow: "enable_pc_cashflow",
    Contingency: "enable_pc_contingency",
  };
  const projectProperty = projectProperties[t.name];
  if (projectProperty && !project[projectProperty]) {
    return null;
  }

  let type = "cost-planning-tab-item";

  const path = window.location.href;
  const pathString = path.substring(path.lastIndexOf("/") + 1);

  if (section && pathString === t.key) {
    type = "cost-planning-tab-item-selected";
  }

  return (
    <Link key={t.name} to={url} className="cost-planning-tab-link">
      <div className={type}>
        <div className="cost-planning-tab-link-text">{translate(t.name)}</div>
        <CommentNotification project={project} t={t.name} />
      </div>
    </Link>
  );
}

function CommentNotification(props) {
  const { project } = props;
  const { t } = props;

  if (isEmpty(project)) {
    return null;
  }

  if (t !== "Summary" && t !== "Breakdown") {
    return null;
  }

  if (project.notify_estimate_comment < 1) {
    return null;
  }

  return (
    <ProjectCommentNotification
      notification={project.notify_estimate_comment}
    />
  );
}
