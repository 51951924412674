import React from "react";

import "./CheckboxesVertical.css";
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

export default function CheckboxesVertical(props) {
  const { array, setFunction, selection } = props;

  if (isEmpty(array)) {
    return null;
  }

  return (
    <div className="checkboxes-vertical">
      {array.map((item, i) => {
        return (
          <label key={i} className="checkboxes-vertical-container">
            <div className="checkbox-label">{item.label}</div>
            <input
              key={i}
              type="checkbox"
              name={item.value}
              value={item.value}
              checked={selection.some((e) => e.value === item.value)}
              onChange={(e) => {
                const selected = {
                  label: tranStr(e.target.name),
                  value: e.target.name,
                };
                const checked = e.target.checked;

                // If checking this option
                if (checked) {
                  // Set the locations to current selection + the selected
                  setFunction((selectedItems) => [...selectedItems, selected]);
                }

                // If unchecking the option
                if (!checked) {
                  // Set the locations to the current selection
                  setFunction((selectedItems) =>
                    // Filtering out the seleted
                    selectedItems.filter((x) => x.value !== selected.value)
                  );
                }
              }}
            />
            <span className="checkmark"></span>
          </label>
        );
      })}
    </div>
  );
}
