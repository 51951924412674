// Import React
import React, { useState } from "react";
import Modal from "../modal/Modal";
import { CategoryEditModal } from "./CategoryEditModal";
import { hasRoles } from "../../utils/roles";

import "./CategoryDelete.css";
import "./CategoryEdit.css";

export default function CategoryEdit(props) {
  const { selectedTopic } = props;
  const { setInsights } = props;
  const { user } = props;

  let subLocation = determineSubLocation();

  const [modal, setModal] = useState(false);

  if (subLocation !== "articles") {
    return null;
  }

  if (!hasRoles(user.roles, ["Admin"])) {
    return null;
  }

  return (
    <div className="insights-control-panel-button-container">
      <div
        className="category-delete-button"
        id={"insight-category-edit"}
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-cog" />
      </div>
      <span className="tooltiptext">Edit Category</span>
      <Modal
        // Modal Props
        title={"Edit Category"}
        Component={CategoryEditModal}
        modal={modal}
        setModal={setModal}
        // Component Props
        selectedTopic={selectedTopic}
        show={modal}
        setShow={setModal}
        setInsights={setInsights}
      />
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
