import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./PostContractProvisionalSumsSummary.css";
import { commaToFixed } from "../../helpers/common.functions";

//Provisional Sums
export default function PostContractProvisionalSumsSummary(props) {
  const { CR } = props;

  if (isEmpty(CR)) {
    return null;
  }

  return (
    <div className="provisional-sum-status">
      <div className="provisional-sum-status-table">
        <Row
          symbol={<div className="provisional-sum-status-symbol-red" />}
          title={CR.provisional_sums_forecast_label}
          count={CR.provisional_sums_forecast_count}
          value={CR.provisional_sums_forecast}
        />

        <Row
          symbol={<div className="provisional-sum-status-symbol-yellow" />}
          title={CR.provisional_sums_submitted_label}
          count={CR.provisional_sums_submitted_count}
          value={CR.provisional_sums_submitted}
        />
        <Row
          symbol={<div className="provisional-sum-status-symbol-green" />}
          title={CR.provisional_sums_approved_label}
          count={CR.provisional_sums_approved_count}
          value={CR.provisional_sums_approved}
        />
        <Row
          symbol={<div className="provisional-sum-status-symbol-total" />}
          title={"Total"}
          count={
            CR.provisional_sums_forecast_count +
            CR.provisional_sums_submitted_count +
            CR.provisional_sums_approved_count
          }
          value={
            CR.provisional_sums_approved +
            CR.provisional_sums_submitted +
            CR.provisional_sums_forecast
          }
          isBold={true}
        />
      </div>
    </div>
  );
}

function Row(props) {
  const { symbol, title, count, value } = props;
  const { isBold } = props;

  if (isBold) {
    return (
      <div className="provisional-sum-status-row-total">
        <div className="provisional-sum-status-label">
          <b>{symbol}</b>
          <b>{translate(title)}</b>
        </div>
        <div className="provisional-sum-status-count">
          <b>{count}</b>
        </div>
        <div className="provisional-sum-status-value">
          <div>
            <b>{commaToFixed(value)}</b>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="provisional-sum-status-row">
      <div className="provisional-sum-status-label">
        {symbol}
        {translate(title)}
      </div>
      <div className="provisional-sum-status-count">{count}</div>
      <div className="provisional-sum-status-value">
        <div>{commaToFixed(value)}</div>
      </div>
    </div>
  );
}
