import React from "react";

import { PCRA_GLOSSARY, PCRA_MARKUP } from "./PCRAGlossary.constants";
const { AUDIT_STATUS } = PCRA_GLOSSARY;
const { CURRENT, PENDING, PASSED, FAILED } = AUDIT_STATUS;

export function PCRALogAction(props) {
  const { stage, status, currentComments } = props;
  const { handleStageAction } = props;

  if (status === PENDING) {
    return <i></i>;
  }

  if (status === CURRENT) {
    return (
      <>
        <i
          className={PCRA_MARKUP.STATUS_ICON[FAILED]}
          onClick={async (e) =>
            await handleStageAction(e, {
              stage,
              status: FAILED,
              comments: currentComments,
            })
          }
        ></i>
        <i
          className={PCRA_MARKUP.STATUS_ICON[PASSED]}
          onClick={async (e) =>
            await handleStageAction(e, {
              stage,
              status: PASSED,
              comments: currentComments,
            })
          }
        ></i>
      </>
    );
  }

  return <i className={PCRA_MARKUP.STATUS_ICON[status]}></i>;
}
