import React, { useState } from "react";

import { useLocations } from "../../api/admin/RegionManagementAPI";
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";

// Functions
import { generateCities } from "../project-create/CreateProject.functions";
import { saveTPILocations } from "../../api/benchmarking-control/BenchmarkingControlAPI";

// Components
import { RegionSelect, CountrySelect } from "../locations/LocationSelect";
// Style
import "./BenchmarkTPICityControl.css";

export default function BenchmarkTPICity(props) {
  const { selectedBenchmarks } = props;
  const { setBenchmarking } = props;
  const { user } = props;

  const [region, setRegion] = useState(user.region);
  const [country, setCountry] = useState(user.country);
  const [TPICity, setTPICity] = useState("");
  const [error, setError] = useState({});

  const { locations } = useLocations(true);

  // TPI API
  const { TPI } = useTPI(true, region, country, false);
  const citiesList = generateCities(TPI);

  return (
    <div className="benchmark-tpi-city-control">
      <div className="benchmark-tpi-city-control-text">
        Benchmark Projects use the <b>City</b> field to determine the
        appropriate TPI by default.
      </div>
      <div className="benchmark-tpi-city-control-text">
        Override this by saving a <b>TPI Location</b> to the selected Benchmark
        Projects.
      </div>
      <div className="general-row-container"></div>
      <div className="tpi-location-select-row">
        <Label label="Region" />
        <RegionSelect
          value={region}
          set={setRegion}
          options={locations.data}
          classType={"create-project-select"}
          setCountry={setCountry}
        />
      </div>
      <div className="tpi-location-select-row">
        <Label label="Country" />
        <CountrySelect
          value={country}
          set={setCountry}
          options={locations.data}
          classType={"create-project-select"}
          region={region}
        />
      </div>

      <div className="tpi-location-select-row">
        <Label label="TPI" />
        <Select value={TPICity} options={citiesList} onChange={setTPICity} />
      </div>
      <div className="general-row-container">
        <Error error={error} setError={setError} />
      </div>
      <div className="benchmark-tpi-city-control-text">
        <div>{`${selectedBenchmarks.length} Benchmark Projects will be updated.`}</div>
      </div>
      <div className="general-row-container">
        <div
          className="general-upload-button"
          onClick={() => {
            saveTPILocations(
              selectedBenchmarks,
              TPICity,
              setBenchmarking,
              setError
            );
          }}
        >
          Save
        </div>
      </div>
      <div className="benchmark-tpi-city-control-text">
        Clear <b>TPI Location</b> override and default to <b>City</b> for
        selected Benchmark Projects.
      </div>
      <div className="general-row-container">
        <div
          className="general-upload-button"
          onClick={() => {
            saveTPILocations(
              selectedBenchmarks,
              null,
              setBenchmarking,
              setError
            );
          }}
        >
          Clear
        </div>
      </div>
    </div>
  );
}

function Label(props) {
  const { label } = props;
  return (
    <div className="tpi-location-label">
      <b>{label}</b>
    </div>
  );
}

function Select(props) {
  const { value, onChange, options } = props;
  return (
    <select
      className="create-project-select"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <SelectList options={options} />
    </select>
  );
}

function SelectList(props) {
  const { options } = props;
  return options.map((item, i) => {
    return (
      <option key={i} value={item.value} className="option">
        {item.label}
      </option>
    );
  });
}

function Error(props) {
  const { error } = props;
  return (
    <div className="account-edit-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
