// React
import React from "react";
import PropTypes from "prop-types";

// Functions
import {
  arrangeProjectsByMetric,
  combineBuiltAreaMetricsByPercentage,
  combineBuiltAreaMetricsByArea,
  organiseMetrics,
} from "./BenchmarkingMetrics.functions";
import isEmpty from "../../validation/is-empty";

// Components
import MetricsContainer from "./BenchmarkMetricsContainer";

// Bring in Redux
import { connect } from "react-redux";
import { MuiAccordion } from "../accordion/MuiAccordion";

/// props validation | SQ(javascript:S6774)
BenchmarkingMetrics.propTypes = {
  skipHighlight: PropTypes.bool,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  auth: PropTypes.object,
  currencySymbol: PropTypes.string,
  settings: PropTypes.array,
  onSettingsToggle: PropTypes.func,
};
///
function BenchmarkingMetrics(props) {
  const { skipHighlight } = props;

  const { thisBenchmark, benchmarks } = props;
  const { user } = props.auth;
  const { currencySymbol } = props;
  const { settings } = props;
  const { onSettingsToggle } = props;

  if (isEmpty(thisBenchmark.metrics)) {
    return null;
  }
  // Evaluate the metric types for this benchmark
  let metrics = arrangeProjectsByMetric(
    thisBenchmark.metrics,
    thisBenchmark,
    benchmarks
  );
  // Add the Combined Built Area Metrics
  metrics = combineBuiltAreaMetricsByArea(metrics);

  // Add the Combined Built Area Metrics
  metrics = combineBuiltAreaMetricsByPercentage(metrics);

  // Split the metrics up
  metrics = organiseMetrics(metrics);

  const designMetrics = [];
  const costMetrics = [];
  metrics.designMetrics.forEach((m, i) => {
    const key = `designMetrics_${i}`;
    designMetrics.push(
      <MetricsContainer
        skipHighlight={skipHighlight}
        currencySymbol={currencySymbol}
        key={key}
        m={m}
        user={user}
        settings={settings}
        onSettingsToggle={onSettingsToggle}
      />
    );
  });

  metrics.costMetrics.forEach((m, i) => {
    const key = `costMetrics_${i}`;
    costMetrics.push(
      <MetricsContainer
        skipHighlight={skipHighlight}
        currencySymbol={currencySymbol}
        key={key}
        m={m}
        user={user}
        settings={settings}
        onSettingsToggle={onSettingsToggle}
      />
    );
  });

  return (
    <>
      {designMetrics.length > 0 && (
        <BenchmarkMetricsAccordion
          title="Design Metrics"
          metrics={designMetrics}
        />
      )}
      {costMetrics.length > 0 && (
        <BenchmarkMetricsAccordion title="Cost Metrics" metrics={costMetrics} />
      )}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkMetricsAccordion.propTypes = {
  title: PropTypes.string,
  metrics: PropTypes.array,
};
///
function BenchmarkMetricsAccordion(props) {
  const { title, metrics } = props;
  return (
    <MuiAccordion title={title}>
      <div className="metrics-graph-box">{metrics}</div>
    </MuiAccordion>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(BenchmarkingMetrics);
