// React
import React from "react";

// Img
import ExampleCashflow from "../../img/example-cashflow.png";

// Style
import "./PortfolioCashflow.css";

export default function PortfolioCashflow(props) {
  return (
    <div className="portfolio-cashflow-container">
      <div className="portfolio-cashflow-overlay">
        <div className="portfolio-cashflow-text-field">
          <div className="display-4">
            We are working hard to add Expenditure Profiles and further features
            to this dashboard. If there is something you would like to see
            added, please use the Feedback button on the top ribbon.
          </div>
        </div>
      </div>

      <img
        className="portfolio-cashflow"
        src={ExampleCashflow}
        alt={"Example Cashflow"}
      ></img>
    </div>
  );
}
