import isEmpty from "../../validation/is-empty";

//Get PSs which are expended
export function getExpended(provisionalSums) {
  return provisionalSums.filter((p) => p.percent_complete_to_date !== 0);
}

//get not expended array
export function getNonExpendedValueArray(provisionalSums) {
  let nonExpended = provisionalSums.filter((p) => {
    return p.percent_complete_to_date !== 100;
  });
  return nonExpended.map((s) => {
    return s.actual_cost - s.value_complete_to_date;
  });
  //return nonExpended;
}

//Get total expended value
export function getTotalExpended(provisionalSums) {
  const PS = getExpended(provisionalSums);
  return PS.reduce(
    (accumulator, current) => accumulator + current.value_complete_to_date,
    0
  );
}
//Get total Non expended value
export function getTotalNonExpended(provisionalSums) {
  return provisionalSums.reduce(
    (accumulator, current) => accumulator + current,
    0
  );
}

//build table for UI
export function getProvisionalSumsTableData(provisionalSums) {
  let expended = getExpended(provisionalSums);
  let nonexpended = getNonExpendedValueArray(provisionalSums);
  let totalExpended = getTotalExpended(provisionalSums);
  let totalNonExpended = getTotalNonExpended(nonexpended);
  return [
    {
      display: "Expended",
      count: expended.length,
      value: totalExpended,
    },
    {
      display: "Non Expended",
      count: nonexpended.length,
      value: totalNonExpended,
    },
  ];
}
//Sort PSs based on value complted till date
export function sortExpended(provisionalSums) {
  return []
    .concat(provisionalSums)
    .sort((a, b) => a.value_complete_to_date - b.value_complete_to_date);
}

//sort PSs based on non expended value
export function getPercentageAdjustement(forecast, original) {
  return (forecast - original) / forecast;
}
export function getProvisionalSumsStatus(PSs) {
  const statusTypes = [];

  Array.from(new Set(PSs.map((v) => v.status))).forEach((e) => {
    statusTypes.push({
      name: e,
      count: PSs.filter((p) => {
        return p.status === e;
      }).length,
      value: PSs.filter((p) => {
        return p.status === e;
      }).reduce((accumulator, current) => accumulator + current.actual_cost, 0),
      isBold: false,
      // array: PSs.filter((p) => {
      //   return p.status === e;
      // }),
    });
  });
  let newObject = {
    name: "Total",
    count: statusTypes.reduce(
      (accumulator, current) => accumulator + current.count,
      0
    ),
    value: statusTypes.reduce(
      (accumulator, current) => accumulator + current.value,
      0
    ),
    isBold: true,
  };
  statusTypes.push(newObject);
  return statusTypes;
}

export function generateProvisionalSumsDonutData(cr) {
  let provisionalSumsTotal = 0;
  const data = [];
  const proivionTypes = [];

  if (isEmpty(cr)) {
    return data;
  }

  provisionalSumsTotal =
    cr.provisional_sums_forecast +
    cr.provisional_sums_submitted +
    cr.provisional_sums_approved;

  proivionTypes.push({
    field: cr.provisional_sums_forecast_label,
    counts: cr.provisional_sums_forecast_count,
    value: cr.provisional_sums_forecast,
  });
  proivionTypes.push({
    field: cr.provisional_sums_submitted_label,
    counts: cr.provisional_sums_submitted_count,
    value: cr.provisional_sums_submitted,
  });
  proivionTypes.push({
    field: cr.provisional_sums_approved_label,
    counts: cr.provisional_sums_approved_count,
    value: cr.provisional_sums_approved,
  });

  proivionTypes.forEach((o, i) => {
    if (o !== undefined) {
      if (o.value > 0) {
        data.push({
          quantity: o.value.toFixed(0), // display value
          value: o.value, // value used for total calculation
          percentage: ((o.value / provisionalSumsTotal) * 100).toFixed(1),
          name: o.field,
          counts: o.counts,
          id: i + 1,
        });
      }
    }
  });

  return data;
}
