import React, { useState } from "react";

import { uploadAreaSchedule } from "../../api/cost-planning/AreaSchedulesAPI";
import CostPlanningUploader from "../cost-planning-uploader/CostPlanningUploader";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { translate } from "../../utils/translation";

import "../cost-planning/CostPlanningUploadModal.css";
import isEmpty from "../../validation/is-empty";

export default function AreaScheduleUploadModal(props) {
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { CP } = props;

  const [modal, setModal] = useState(false);

  // Hide if not Summary
  let subLocation = determineSubLocation();
  if (subLocation !== "area-schedule") {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (isEmpty(CPs.data)) {
    return null;
  }

  const data = {
    projectID: project.id,
    project: project,
    CP: CP,
    CPs: CPs,
    setModal: setModal,
  };

  return (
    <div>
      <div
        className="cost-planning-upload-modal-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Upload Area Schedule")}
      </div>
      <Modal
        title={translate("Upload Area Schedule")}
        Component={CostPlanningUploader}
        modal={modal}
        setModal={setModal}
        // Component Props
        data={data}
        uploadFunction={uploadAreaSchedule}
        isAreaSchedule={true}
      />
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
