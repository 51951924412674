import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { feedbackAPIs } from "../../api/api-endpoints/FormDataEndpoints";
import { onClickFeedback, onClickNote } from "../../api/admin/FeedbackAPI";
export function FeedbackDisplay(props) {
  const { selected } = props;

  if (isEmpty(selected)) {
    return <div></div>;
  }

  return (
    <div className="feedback-display-container">
      <h1 className="display-4" style={{ fontSize: "30px" }}>
        Selected Feedback Comments
      </h1>
      {selected.map((feedbackItem) => {
        return (
          <div key={feedbackItem.id} className="feedback-display">
            <div className="feedback-display-label-container">
              <div className="feedback-display-name">
                <b>{feedbackItem.author_name}</b>
              </div>
              <div className="feedback-display-name">
                <b>{feedbackItem.author_surname}</b>
              </div>
            </div>
            <div>{feedbackItem.text}</div>
          </div>
        );
      })}
    </div>
  );
}

export function AdminNoteDisplay(props) {
  const { selected } = props;
  const { setFeedback } = props;

  const [note, setNote] = useState("");

  useEffect(() => {
    if (!isEmpty(selected)) {
      setNote(selected[0].note);
    }
  }, [selected]);

  if (isEmpty(selected)) {
    return <div></div>;
  }

  return (
    <div className="feedback-note-container">
      <div className="feedback-control-note-container">
        <h1 className="display-4" style={{ fontSize: "30px" }}>
          Admin Note
        </h1>

        <div
          className="feedback-control-save"
          onClick={() => {
            onClickNote(
              feedbackAPIs().create_feedback_note,
              selected,
              note,
              setFeedback
            );
          }}
        >
          Save Note
        </div>
      </div>

      <textarea
        value={note === null ? "" : note}
        className="feedback-control-note-textarea"
        onChange={(e) => setNote(e.target.value)}
      />
    </div>
  );
}

export function FeedbackControl(props) {
  const { selected } = props;
  const { setFeedback } = props;

  if (isEmpty(selected)) {
    return <div></div>;
  }

  return (
    <div className="feedback-container1">
      <h1 className="display-4" style={{ fontSize: "30px" }}>
        Feedback Control
      </h1>
      <div className="feedback-control-button-container">
        <div
          className="feedback-control-button"
          onClick={() => {
            onClickFeedback(
              "POST",
              feedbackAPIs().update_feedback_complete,
              selected,
              setFeedback
            );
          }}
        >
          Complete Selected
        </div>
        <div
          className="feedback-control-button"
          onClick={() => {
            onClickFeedback(
              "POST",
              feedbackAPIs().update_feedback_incomplete,
              selected,
              setFeedback
            );
          }}
        >
          Incomplete Selected
        </div>
        <div
          className="feedback-control-button"
          onClick={() => {
            onClickFeedback(
              "DELETE",
              feedbackAPIs().delete_feedback,
              selected,
              setFeedback
            );
          }}
        >
          Delete Selected
        </div>
      </div>
    </div>
  );
}
