// React
import React from "react";
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

export function RegionSelect(props) {
  const { value, set, options, region } = props;
  const { classType } = props;

  const { setCountry } = props;
  const { setCity } = props;

  return (
    <select
      className={classType}
      value={value}
      onChange={(e) => {
        set(e.target.value);
        if (setCountry) {
          setCountry("");
        }

        if (setCity) {
          setCity("");
        }
      }}
    >
      <RegionSelectList options={options} region={region} />
    </select>
  );
}

function RegionSelectList(props) {
  const { options } = props;
  let array = removeDuplicateRegions(options);
  array.forEach((e) => {
    e.label = e.region;
  });
  array.unshift({ id: 0, region: "", label: tranStr("Select Region") });
  return array.map((item, i) => {
    return (
      <option key={i} value={item.region} className="option">
        {item.label}
      </option>
    );
  });
}

export function CountrySelect(props) {
  const { value, set, options, region } = props;
  const { classType } = props;

  const { setCity } = props;

  return (
    <select
      className={classType}
      value={value}
      onChange={(e) => {
        set(e.target.value);
        if (setCity) {
          setCity("");
        }
      }}
    >
      <CountrySelectList options={options} region={region} />
    </select>
  );
}

function CountrySelectList(props) {
  const { options, region } = props;
  let array = removeDuplicateCountries(options);

  array.forEach((e) => {
    e.label = e.country;
  });

  array.unshift({
    id: 0,
    country: "",
    label: tranStr("Select Country"),
    region: "*",
  });

  return array.map((item, i) => {
    if (item.region === region || item.region === "*") {
      return (
        <option key={i} value={item.country} className="option">
          {item.label}
        </option>
      );
    } else {
      return null;
    }
  });
}

export function CitySelect(props) {
  const { value, set, options, country } = props;
  const { classType } = props;

  return (
    <select
      className={classType}
      value={value}
      onChange={(e) => {
        set(e.target.value);
      }}
    >
      <CitySelectList options={options} country={country} />
    </select>
  );
}

function CitySelectList(props) {
  const { options, country } = props;
  let array = [];

  if (isEmpty(options)) {
    array = [{ id: 0, city: "", label: tranStr("Select City"), country: "*" }];
  }

  if (!isEmpty(options)) {
    array = [...options];
  }
  array.forEach((e) => {
    e.label = e.city;
  });
  array.unshift({
    id: 0,
    city: "",
    label: tranStr("Select City"),
    country: "*",
  });

  return array.map((item, i) => {
    if (item.country === country || item.country === "*") {
      return (
        <option key={i} value={item.city} className="option">
          {item.label}
        </option>
      );
    } else {
      return null;
    }
  });
}

function removeDuplicateRegions(array) {
  if (isEmpty(array)) {
    return [{ id: 0, region: "", label: tranStr("Select Region") }];
  }
  return array.filter(
    (e, index, self) => index === self.findIndex((t) => t.region === e.region)
  );
}

function removeDuplicateCountries(array) {
  if (isEmpty(array)) {
    return [
      { id: 0, country: "", label: tranStr("Select Country"), region: "*" },
    ];
  }
  return array.filter(
    (e, index, self) => index === self.findIndex((t) => t.country === e.country)
  );
}
