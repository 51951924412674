import React, { useState } from "react";

// Components
import isEmpty from "../../validation/is-empty";
import CreateProject from "../project-create/CreateProject";
import Modal from "../modal/Modal";
import Spinner from "../common/Spinner";

// API
import {
  deleteProject,
  deleteCostPlanning,
  deletePostContract,
} from "./ProjectProfile.functions";
import { humanize } from "../../validation/humanize";

import { useAdminProject } from "../../api/admin/ProjectAPI";
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { useLocations } from "../../api/admin/RegionManagementAPI";
import validateFilter from "../../validation/filtering";
import { projectAPIs } from "../../api/api-endpoints/ProjectEndpoints";
import ProjectPermissions from "../permissions/ProjectPermissions";
import { ImageDisplay } from "../images/ImageDisplay";

// Style

// Functions
import {
  displayProjectImage,
  displayUserImage,
} from "../../validation/image-clean";

import "./ProjectProfile.css";

export default function ProjectProfile(props) {
  const { selectedProject } = props;
  const { projects } = props;
  const { show, setShow } = props;
  const { project } = useAdminProject(selectedProject.id, show);
  const { user } = props;

  const { locations } = useLocations(show);
  // TPI API
  const { TPI } = useTPI(
    true,
    project.data.region,
    project.data.country,
    false
  );

  if (isEmpty(project)) {
    return null;
  }

  return (
    <div className="project-control-profile">
      <div className="project-control-profile-title-row">
        <ImageDisplay
          image={displayProjectImage(selectedProject)}
          imageWidth={"200px"}
          imageHeight={"100px"}
          isBezierDisabled={true}
        />
      </div>
      <div className="project-control-profile-details-container">
        <div className="project-control-profile-details">
          <DetailsRow label={"ID"} value={project.data.id} />
          <DetailsRow label={"Title"} value={project.data.title} />
          <DetailsRow label={"Job Number"} value={project.data.job_number} />
          <DetailsRow label={"Sector"} value={project.data.sector} />
          <DetailsRow label={"Subsector"} value={project.data.sub_sector} />
          <DetailsRow label={"Region"} value={project.data.region} />
          <DetailsRow label={"Country"} value={project.data.country} />
          <DetailsRow label={"City"} value={project.data.city} />
          <DetailsRow label={"Address"} value={project.data.address} />
          <DetailsRow label={"Post Code"} value={project.data.post_code} />
          <DetailsRow label={"TPI Location"} value={project.data.tpi_city} />
          <DetailsRow label={"Currency"} value={project.data.currency} />
          <DetailsRow label={"Procurement"} value={project.data.procurement} />
          <DetailsRow
            label={"Current Phase"}
            value={project.data.project_phase}
          />
          <DetailsRow
            label={"Date Created"}
            value={project.data.time_created}
          />
        </div>
        <UserList users={project.data.users} />
      </div>
      <div className="project-control-profile-buttons-row">
        <EditButton
          project={project}
          projects={projects}
          show={show}
          setShow={setShow}
          TPI={TPI}
          locations={locations}
          user={user}
        />
        <EditPermissions project={project} />
        <div className="project-control-profile-delete-buttons-row">
          <DeleteCostPlanningButton
            project={project}
            projects={projects}
            setShow={setShow}
          />
          <DeletePostContractButton
            project={project}
            projects={projects}
            setShow={setShow}
          />
          <DeleteButton
            project={project}
            projects={projects}
            setShow={setShow}
          />
        </div>
      </div>
    </div>
  );
}

function EditPermissions(props) {
  const { project } = props;
  const [modal, setModal] = useState(false);
  return (
    <div>
      <Modal
        // Modal Props
        title={"Edit Permissions"}
        Component={ProjectPermissions}
        modal={modal}
        setModal={setModal}
        // Component Props
        project={project.data}
        updateState={async () => {
          project.set(await project.get(project.data.id));
        }}
      />
      <div
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {"Edit Users"}
      </div>
    </div>
  );
}

function EditButton(props) {
  const { project } = props;
  const { projects } = props;
  const { show } = props;
  const { setShow } = props;
  const { locations, TPI } = props;
  const { user } = props;
  const [modal, setModal] = useState(false);
  return (
    <div>
      <Modal
        // Modal Props
        title={"Edit Project"}
        Component={CreateProject}
        modal={modal}
        setModal={setModal}
        // Component Props
        project={project.data}
        projectObject={project}
        projects={projects}
        locations={locations}
        TPI={TPI}
        show={show}
        setShow={setModal}
        setParentModalShow={setShow}
        urlMethod={"PUT"}
        // PROJECT API
        urlEndpoint={projectAPIs().update_project + "/" + project.data.id}
        user={user}
      />
      <div
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {"Edit Project"}
      </div>
    </div>
  );
}

function DeleteCostPlanningButton(props) {
  const { project } = props;
  const { projects } = props;
  const { setShow } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const data = {
    projectID: project.data.id,
    projects: projects,
    setShow: setShow,
  };

  if (isDeleting) {
    return <Spinner width={"20px"} />;
  }

  return (
    <div
      className="project-control-profile-delete-button"
      onClick={async () => {
        const confirm = window.confirm(
          `Confirm delete ALL data for Cost Planning in this project?`
        );

        if (!confirm) {
          return;
        }

        setIsDeleting(true);
        await deleteCostPlanning(data);
        setIsDeleting(false);
      }}
    >
      Delete Cost Planning
    </div>
  );
}

function DeletePostContractButton(props) {
  const { project } = props;
  const { projects } = props;
  const { setShow } = props;

  const data = {
    projectID: project.data.id,
    projects: projects,
    setShow: setShow,
  };

  return (
    <div
      className="project-control-profile-delete-button"
      onClick={() => {
        deletePostContract(data);
      }}
    >
      Delete Post Contract
    </div>
  );
}

function DeleteButton(props) {
  const { project } = props;
  const { projects } = props;
  const { setShow } = props;

  const data = {
    projectID: project.data.id,
    projects: projects,
    setShow: setShow,
  };

  return (
    <div
      className="project-control-profile-delete-button"
      onClick={() => {
        deleteProject(data);
      }}
    >
      Delete Project
    </div>
  );
}

function UserList(props) {
  const { users } = props;
  const [filter, setFilter] = useState("");
  if (isEmpty(users)) {
    return (
      <div className="project-control-profile-user-box">
        <div className="project-control-profile-user-title-row">
          <div className="display-4" style={{ fontSize: "25px" }}>
            {"Allocated Accounts"}
          </div>
        </div>
      </div>
    );
  }

  let filteredUsers = users.filter((p) => {
    let user =
      validateFilter(p.name) +
      " " +
      validateFilter(p.surname) +
      " " +
      validateFilter(p.jobTitle);

    if (filter === "") {
      return p;
    }

    if (user.includes(filter.toLowerCase())) {
      return p;
    }

    return null;
  });

  let table = [];

  // Heading Row
  table.push(
    <div key={0} className="project-control-profile-user-table-row">
      <div className="project-control-profile-user-avatar"></div>
      <div className="project-control-profile-user-name">
        <b>Full Name</b>
      </div>
      <div className="project-control-profile-user-title">
        <b>Job Title</b>
      </div>
    </div>
  );

  // User Rows
  filteredUsers.forEach((user, i) => {
    table.push(
      <div key={i + 1} className="project-control-profile-user-table-row">
        <ImageDisplay
          image={displayUserImage(user)}
          imageWidth={"20px"}
          imageHeight={"20px"}
          borderRadius={"10px"}
          isBezierDisabled={true}
        />
        <div className="project-control-profile-user-name">
          {user.name + " " + user.surname}
        </div>
        <div className="project-control-profile-user-title">
          {user.jobTitle}
        </div>
      </div>
    );
  });

  return (
    <div className="project-control-profile-user-box">
      <div className="project-control-profile-user-title-row">
        <div className="display-4" style={{ fontSize: "25px" }}>
          {"Allocated Accounts"}
        </div>
        <input
          className="project-control-profile-user-filter"
          placeholder={"Filter Name, Surname or Job Title"}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </div>

      {table}
    </div>
  );
}

function DetailsRow(props) {
  const { label, value } = props;

  if (isEmpty(value)) {
    return null;
  }

  let text = value;
  if (label === "Current Phase") {
    text = humanize(value);
  }

  if (label === "Date Created") {
    text = new Date(parseInt(text)).toLocaleString();
  }

  return (
    <div className="project-control-profile-details-row">
      <div className="project-control-profile-details-label">
        <b>{label}</b>
      </div>
      <div className="project-control-profile-details-text">{text}</div>
    </div>
  );
}
