import isEmpty from "../../validation/is-empty";
import { projectUpdate } from "../../api/projects/ProjectAPI";
import { authHeader } from "../../_helpers/auth-header";
import { cpAPIs } from "../../api/api-endpoints/CostPlanningEndpoints";
import { AutoCloseModal } from "../modal/Modal";

export async function onSaveMetrics(
  metricContainers,
  project,
  CP,
  setError,
  setModal
) {
  const metrics = metricContainers.filter((c) => c.metric !== "");

  setError({
    text: "Saving...",
    type: "feedback-success",
  });

  // COST PLAN API
  const url = cpAPIs().save_project_metrics;
  const payload = JSON.stringify({
    metrics: metrics,
    projectID: project.id,
    stage: CP.stage,
    revision: CP.version,
  });

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
    projectUpdate(project);

    AutoCloseModal(setModal);
  } else {
    // Response Not OK
    console.log("Network Error");
    return setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
}

export function onAddMetrics(metricContainers, setMetricContainers, projectID) {
  if (metricContainers.length >= 3) {
    return;
  }

  // Generate a temporary ID
  let id = 0;
  let lastContainer = metricContainers[metricContainers.length - 1];
  if (!isEmpty(lastContainer)) {
    id = lastContainer.id + 1;
  }

  let temp = [...metricContainers];
  temp.push({
    id: id,
    project_id: projectID,
    code: "",
    metric: "",
    quantity: 0,
    unit: "",
  });
  setMetricContainers(temp);
}

export function mergeMetrics(estimates) {
  // Combine all metrics across all estimates into one set
  let allMetrics = [];
  estimates.forEach((e) => {
    const { benchmark } = e;
    allMetrics.push(benchmark.metrics);
  });

  return [].concat.apply([], allMetrics);
}
