import React from "react";

import "./Modal.css";
import { ADLogout } from "../browser/LoginLogout.functions";

export default function ReLoginModal(props) {
  const { Component } = props;
  const { modal, setModal } = props;
  const { title } = props;

  const showHideClassName = modal ? "modal-show" : "modal-hide";

  return (
    <div className={"modal " + showHideClassName}>
      {modal && (
        <div className="modal-display">
          <TitleBar title={title} modal={modal} setModal={setModal} />
          <Component {...props} modal={modal} setModal={setModal} />
        </div>
      )}
    </div>
  );
}

function TitleBar(props) {
  return (
    <div className="modal-top-bar">
      <Title {...props} />
      <Close {...props} />
    </div>
  );
}

function Title(props) {
  const { title } = props;
  return (
    <div className="modal-title">
      <b>{title}</b>
    </div>
  );
}

function Close(props) {
  const { setModal } = props;
  return (
    <div
      className="modal-close-button"
      onClick={() => {
        setModal(false);
        ADLogout();
      }}
    >
      <i className="fas fa-times" />
    </div>
  );
}
