import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import isEmpty from "../../validation/is-empty";
import { commaToFixed } from "../../helpers/common.functions";

export default function ProgressClaimContractTrackerChart(props) {
  const { values, fields } = props;

  if (isEmpty(values) || isEmpty(fields)) {
    return null;
  }

  // Create the graph object
  let graph = {
    labels: fields,
    datasets: [
      {
        label: "Prelims & Margin",
        data: values,
        barThickness: 10,
        backgroundColor: colourGenerator(values, 1),
        borderColor: colourGenerator(values, 1),
      },
    ],
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem) {
          return commaToFixed(Math.round(tooltipItem.xLabel));
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (tick, index, ticks) {
              return commaToFixed(tick);
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (tick, index, ticks) {
              return tick;
            },
          },
        },
      ],
    },
    legend: {
      display: false,
      position: "bottom",
      fullWidth: false,
      reverse: false,
    },
  };
  return <HorizontalBar data={graph} options={options} />;
}

function colourGenerator(array, opacity) {
  return array.map((v, i) => {
    if (i === 0) {
      return `rgba(228, 97, 15,${opacity})`;
    } else {
      return `rgba(85, 87, 90,${opacity})`;
    }
  });
}
