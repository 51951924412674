import React from "react";
import CostPlanStageSelector from "../cost-plan-selector/CostPlanStageSelector";
import CostPlanRevisionSelector from "../cost-plan-selector/CostPlanRevisionSelector";

export default function ProjectCostPlanSelectors(props) {
  const { CPs } = props;
  const { CP, setCP } = props;
  const { setComparedCP, setComparedCPR } = props;
  const { dashboardType } = props;

  return (
    <div className="project-cost-plan-selectors">
      <CostPlanStageSelector
        CPs={CPs}
        CP={CP}
        setCP={setCP}
        setComparedCP={setComparedCP}
        setComparedCPR={setComparedCPR}
        dashboardType={dashboardType}
      />
      <CostPlanRevisionSelector
        CPs={CPs}
        CP={CP}
        setCP={setCP}
        setComparedCPR={setComparedCPR}
        dashboardType={dashboardType}
      />
    </div>
  );
}
