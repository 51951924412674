// import html2canvas from "html2canvas";

const html2canvas = (value) => {
  return value;
};

export function prepareCanvas(srcCanvas, { className, margin }) {
  // get source canvas attributes
  const srcCtx = srcCanvas.getContext("2d");
  const { width, height } = srcCanvas;

  // copy source image
  const imageData = srcCtx.getImageData(0, 0, width, height);

  // create empty destination canvas with specified margins
  const dstCanvas = document.createElement("canvas");
  dstCanvas.width = width + margin * 2;
  dstCanvas.height = height + margin * 2;

  // past copied image
  const dstCtx = dstCanvas.getContext("2d");
  dstCtx.fillStyle = "white";
  dstCtx.fillRect(0, 0, dstCanvas.width, dstCanvas.height);
  dstCtx.putImageData(imageData, margin, margin);

  // attach canvas to DOM
  dstCanvas.classList.add(className);
  dstCanvas.style.display = "none";
  document.body.appendChild(dstCanvas);

  // return prepared canvas
  return dstCanvas;
}

function getDateStringForFilename() {
  return new Date()
    .toISOString()
    .replace("T", "_")
    .replace("Z", "")
    .replace(/:/g, "-");
}

export function downloadCanvas({ selector, filenamePrefix }) {
  const date = getDateStringForFilename();

  const link = document.createElement("a");
  link.download = `${filenamePrefix}-${date}.png`;
  link.href = document.querySelector(selector).toDataURL();
  link.click();
}

function setIgnoredElements(selectors) {
  for (const selector of selectors) {
    const elements = document.querySelectorAll(selector);
    for (const element of elements) {
      element.setAttribute("data-html2canvas-ignore", "true");
    }
  }
}

export function captureScreenshot({
  selector,
  filenamePrefix,
  margin,
  ignoreSelectors,
}) {
  setIgnoredElements(ignoreSelectors);

  const className = filenamePrefix;

  const element = document.querySelector(selector);
  if (element) {
    html2canvas(element)
      .then((canvas) =>
        prepareCanvas(canvas, {
          className,
          margin,
        })
      )
      .then(() =>
        downloadCanvas({
          selector: `canvas.${className}`,
          filenamePrefix,
        })
      );
  }
}
