import React from "react";

import { comma, commaToFixed } from "../../helpers/common.functions";

export default function EstimateBreakdownSummaryRow(props) {
  const { row } = props;
  const { rowType } = props;
  const { bold } = props;
  const { enableLineRefs } = props;
  const { headCounter } = props;
  const { lineCounter } = props;

  headCounter.current = 1; // Reset head1 counter when there is a new Summary Line
  lineCounter.current = 1;

  let descriptionType = "ebt-description-cell";
  if (enableLineRefs) {
    descriptionType = "ebt-description-cell-ref";
  }

  return (
    <div id={row.id} key={row.id} className={rowType}>
      <DisplayRefCell row={row} bold={bold} enableLineRefs={enableLineRefs} />
      <BreakdownSummaryCell
        cellType={descriptionType}
        value={row.description}
        bold={bold}
      />

      <BreakdownSummaryCell
        cellType={"ebt-quantity-cell"}
        value={row.quantity ? comma(row.quantity) : null}
        bold={bold}
      />
      <BreakdownSummaryCell
        cellType={"ebt-unit-cell"}
        value={row.unit_of_measure}
        bold={bold}
      />
      <BreakdownSummaryCell
        cellType={"ebt-rate-cell"}
        value={row.rate ? commaToFixed(row.rate) : null}
        bold={bold}
      />
      <BreakdownSummaryCell
        cellType={"ebt-subtotal-cell"}
        value={row.subtotal ? comma(Math.round(row.subtotal)) : null}
        bold={bold}
      />
    </div>
  );
}

function DisplayRefCell(props) {
  const { enableLineRefs } = props;
  const { row } = props;
  const { bold } = props;

  if (enableLineRefs) {
    return (
      <BreakdownSummaryCell
        cellType={"ebt-section-cell"}
        value={sectionNumber(row)}
        bold={bold}
      />
    );
  } else {
    return null;
  }
}

function BreakdownSummaryCell(props) {
  const { cellType } = props;
  const { value } = props;
  const { bold } = props;

  if (bold) {
    return (
      <div className={cellType}>
        <b>{value}</b>
      </div>
    );
  } else {
    return <div className={cellType}>{value}</div>;
  }
}

function sectionNumber(row) {
  if (
    row.code === "st" ||
    row.code === "cc" ||
    row.code === "pc" ||
    row.code === "pct"
  ) {
    return "";
  } else {
    return row.section;
  }
}
