// MSAL
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

// Store
import store from "../store";

// Auth
import { setCurrentUser, logoutUser } from "../actions/authActions";

export default async function refreshToken(user) {
  // Only refresh if B2C is enabled
  const myMSALObj = new Msal.PublicClientApplication(msalConfig());
  const account = myMSALObj.getAllAccounts()[0];

  if (myMSALObj.getAllAccounts().length > 1) {
    console.warn("Multiple accounts detected.");
    console.log(myMSALObj.getAllAccounts());
  }

  const accessTokenRequest = {
    account: account,
  };
  // Use the same publicClientApplication instance provided to MsalProvider
  myMSALObj
    .acquireTokenSilent(accessTokenRequest)
    .then((res) => {
      // Acquire token silent success
      const { idToken } = res;
      const { exp } = res.idTokenClaims;
      // Set user data to local storage
      user.exp = exp;
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("user", JSON.stringify(user));

      // Set current user in Redux
      store.dispatch(setCurrentUser(user));
    })
    .catch((error) => {
      console.log(error);
      //Acquire token silent failure
      store.dispatch(logoutUser());
      window.location.href = "/";
    });
}
