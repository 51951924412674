import React from "react";
import PropTypes from "prop-types";

import { Bubble } from "react-chartjs-2";
import { comma } from "../../helpers/common.functions";

import { getCSSColorPalette } from "../../actions/cssColors";

/// props validation | SQ(javascript:S6774)
MetricsBubbleChart.propTypes = {
  skipHighlight: PropTypes.bool,
  data: PropTypes.array,
};
///
export default function MetricsBubbleChart(props) {
  const { skipHighlight } = props;
  const { data } = props;

  const CSS_COLORS = getCSSColorPalette();

  const highlightColor = CSS_COLORS["--heritage"];
  const baseColor = CSS_COLORS["--fluidity1"];

  // Take all cost plans for this project
  let field = [];
  let value = [];

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (tick) {
              return field[tick];
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 0,
            callback: function (tick) {
              return comma(tick);
            },
          },
        },
      ],
    },
    animation: {
      duration: 3000,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: true } },
    tooltips: {
      enabled: true,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: function (tooltipItem) {
          return field[tooltipItem.yLabel] + " : " + comma(tooltipItem.xLabel);
        },
      },
    },

    legend: {
      display: false,
      position: "bottom",
      fullWidth: false,
      reverse: false,
    },
  };

  const getColor = (index) =>
    index === value.length - 1 && !skipHighlight ? highlightColor : baseColor;

  let total = 0;
  data.forEach((b) => {
    field.push(b.project_name);
    value.push(b.quantity);
    total += parseInt(b.quantity);
  });

  value = value.reverse();
  field = field.reverse();

  // The array of datasets for the graph
  const datasets = [];

  // Prepare a dataset for each value
  value.forEach((bubble, index) => {
    const dataset = {
      label: field[index],
      data: [],
      backgroundColor: getColor(index),
    };

    const percent = Math.ceil((bubble / (total / value.length)) * 10);

    const dataItem = {
      x: bubble,
      y: index,
      r: percent,
    };

    dataset.data.push(dataItem);
    datasets.push(dataset);
  });

  // Create the graph object
  let graph = {
    datasets: datasets,
  };

  return (
    <div className="metrics-chart">
      <Bubble data={graph} options={options} height={250} />
    </div>
  );
}
