import React from "react";

import BreakdownRow from "./EstimateBreakdownRow";

export default function EstimateBreakDownRows(props) {
  const { row } = props;
  const { search } = props;
  const { project } = props;
  const { projectID } = props;
  const { CPs } = props;
  const { enableLineRefs } = props;
  const { lineCounter } = props;
  const { headCounter } = props;
  const { filtered, notificationsOnly = false } = props;

  let { rowType } = props;
  const display = [];
  let push = false;
  row.breakdown.forEach((bRow, i) => {
    if (filtered === undefined) {
      push = true;
    } else {
      push = bRow.comments.length > 0;
    }
    if (push) {
      display.push(
        <BreakdownRow
          key={i}
          i={i}
          row={row}
          rowType={rowType}
          bRow={bRow}
          search={search}
          project={project}
          projectID={projectID}
          CPs={CPs}
          enableLineRefs={enableLineRefs}
          lineCounter={lineCounter}
          headCounter={headCounter}
          filtered={filtered}
          notificationsOnly={notificationsOnly}
        />
      );
    }
  });

  return <div>{display}</div>;
}
