import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";

import {
  getCostPlanningPart,
  getPostContractPart,
  getSustainabilityPart,
  getOptioneeringPart,
  getCostPlanningPartURL,
  getSustainabilityPartURL,
  getPostContractPartURL,
  getOptioneeringPartURL,
} from "./ProjectPath.helpers";

import "./Path.css";

export default function ProjectPath(props) {
  const { project_id, project_name } = props;
  const { projectLoading } = props;
  if (projectLoading) {
    return null;
  }

  const costPlanningPart = getCostPlanningPart();
  const postContractPart = getPostContractPart();
  const sustainabilityPart = getSustainabilityPart();
  const optioneeringPart = getOptioneeringPart();
  const projectPath = "/project/" + project_id;
  const costPlanningPath = "/project/" + project_id + "/cost-planning";
  const sustainabilityPath = "/project/" + project_id + "/sustainability";
  const postContractPath = "/project/" + project_id + "/post-contract";
  const optioneeringPath = "/project/" + project_id + "/options-analysis";
  const partPathCostPlanning =
    "/project/" + project_id + "/cost-planning/" + getCostPlanningPartURL();
  const partPathSustainability =
    "/project/" + project_id + "/sustainability/" + getSustainabilityPartURL();
  const partPathPostContract =
    "/project/" + project_id + "/post-contract/" + getPostContractPartURL();
  const partPathOptioneering =
    "/project/" + project_id + "/options-analysis/" + getOptioneeringPartURL();

  if (window.location.href.includes("cost-planning")) {
    return (
      <div className="path">
        <Link to="/portfolio" className="path-link">
          {translate("Portfolio")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={projectPath} className="path-link">
          {project_name}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={costPlanningPath} className="path-link-current">
          {translate("Cost Planning")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={partPathCostPlanning} className="path-link-current">
          {costPlanningPart}
        </Link>
      </div>
    );
  }
  if (window.location.href.includes("sustainability")) {
    return (
      <div className="path">
        <Link to="/portfolio" className="path-link">
          {translate("Portfolio")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={projectPath} className="path-link">
          {project_name}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={sustainabilityPath} className="path-link-current">
          {translate("Sustainability")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={partPathSustainability} className="path-link-current">
          {sustainabilityPart}
        </Link>
      </div>
    );
  }
  if (window.location.href.includes("post-contract")) {
    return (
      <div className="path">
        <Link to="/portfolio" className="path-link">
          {translate("Portfolio")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={"/project/" + project_id} className="path-link">
          {project_name}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={postContractPath} className="path-link-current">
          {translate("Post Contract")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={partPathPostContract} className="path-link-current">
          {postContractPart}
        </Link>
      </div>
    );
  }
  if (window.location.href.includes("project-team")) {
    return (
      <div className="path">
        <Link to="/portfolio" className="path-link">
          {translate("Portfolio")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={"/project/" + project_id} className="path-link">
          {project_name}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={postContractPath} className="path-link-current">
          {translate("Project Team")}
        </Link>
      </div>
    );
  }
  if (window.location.href.includes("options-analysis")) {
    return (
      <div className="path">
        <Link to="/portfolio" className="path-link">
          {translate("Portfolio")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={projectPath} className="path-link">
          {project_name}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={optioneeringPath} className="path-link-current">
          {translate("Optioneering")}
        </Link>
        <div className="path-divider">{" > "}</div>
        <Link to={partPathOptioneering} className="path-link-current">
          {optioneeringPart}
        </Link>
      </div>
    );
  }
  return (
    <div className="path">
      <Link to="/portfolio" className="path-link">
        {translate("Portfolio")}
      </Link>
      <div className="path-divider">{" > "}</div>
      <Link to={projectPath} className="path-link-current">
        {project_name}
      </Link>
    </div>
  );
}
