import PropTypes from "prop-types";

export const headerTitles = {
  //  benchmarkId : "Benchmark Id",
  code: { content: "Elemental Code", type: "text", isReport: true },
  reserved: { content: "Reserved", type: "number", isReport: false },
  description: {
    content: "Elemental Description",
    type: "description",
    isReport: true,
  },
  total: { content: "Total Cost", type: "currency", isReport: true },
  gia_rate: { content: "Cost per m2 GIA", type: "currency", isReport: true },
  local_region_rate: {
    content: "Cost per m2 Local Region Area",
    type: "currency",
    isReport: true,
  },
  quantity: { content: "Elemental Quantity", type: "number", isReport: true },
  unit: { content: "Elemental Unit", type: "text", isReport: true },
  rate: { content: "Elemental Rate", type: "currency", isReport: true },
  // id: "id"
};

Table.propTypes = {
  headerRow: PropTypes.array,
  rows: PropTypes.array,
};
export function Table(props) {
  const { headerRow } = props;
  const { rows } = props;
  return (
    <div className="info-table-container">
      {headerRow}

      <div className="info-table"> {rows}</div>
    </div>
  );
}

Row.propTypes = {
  Cells: PropTypes.array,
  rowSpecificClass: PropTypes.string,
};
export function Row(props) {
  const { Cells } = props;
  let { rowSpecificClass } = props;
  rowSpecificClass = "info-table-row " + rowSpecificClass;
  return <div className={rowSpecificClass}>{Cells}</div>;
}

Cell.propTypes = {
  content: PropTypes.string,
  cellSpecificClass: PropTypes.string,
};
export function Cell(props) {
  const { content } = props;
  let { cellSpecificClass } = props;
  cellSpecificClass = "info-table-cell " + cellSpecificClass;
  return <div className={cellSpecificClass}>{content}</div>;
}

export function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}