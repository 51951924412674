import React from "react";
import { Line } from "react-chartjs-2";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";
import { tranStr } from "../../utils/translation";

// This graph shows the COMBINED scope design changes
export default function DesignDevelopmentProgression(props) {
  const { project } = props;
  const { CP } = props;
  const { DCs, CCs, fields } = props;
  const { height, width } = props;

  if (isEmpty(CP)) {
    return null;
  }

  let heritage = "rgba(228, 97, 15, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";

  let dataSetArray = [];
  if (!DCs.every((e) => e === 0)) {
    dataSetArray.push({
      label: tranStr("Design Contingency"),
      data: DCs,
      yAxisID: "designContingency",
      minLineWidth: 7,
      borderColor: collaboration1,
      backgroundColor: collaboration1,
    });
  }
  if (!CCs.every((e) => e === 0)) {
    dataSetArray.push({
      label: tranStr("Cost Change (Excl. DC)"),
      data: CCs,
      yAxisID: "designContingency",

      borderColor: heritage,
      backgroundColor: heritage,
    });
  }

  // Create the graph object
  let graph = {
    labels: fields,
    datasets: dataSetArray,
    text: fields,
  };

  // Create the options object
  let options = {
    elements: {
      line: {
        fill: false, // by default, fill lines to the previous dataset
      },
    },
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return (
            project.currency_symbol +
            comma(tooltipItem.yLabel) +
            " (" +
            chart.datasets[tooltipItem.datasetIndex].label +
            ")"
          );
        },
      },
    },
    stacked: false,
    layout: {},
    scales: {
      yAxes: [
        {
          id: "designContingency",
          stacked: false,
          display: true,
          position: "left",
          beforeBuildTicks: (chart) => null,
          ticks: {
            callback: function (value, index, values) {
              return project.currency_symbol + comma(value);
            },
          },
        },
      ],
    },

    legend: {
      display: true,
      position: "bottom",
      fullWidth: true,
      reverse: false,
    },
  };

  if (fields.length <= 1) {
    return null;
  }

  return <Line data={graph} options={options} height={height} width={width} />;
}
