import React, { useState } from "react";
import { hasRoles } from "../../utils/roles";
import store from "../../store";
import { setCurrentUser } from "../../actions/authActions";

import jwt_decode from "jwt-decode";
import ToggleControlSwitch from "../checkboxes/ToggleControlSwitch";

export default function ClientViewToggle(props) {
  const { idToken } = props;
  const { user } = props;

  const [isClient, setIsClient] = useState(
    hasRoles(user.roles, ["Client"]) ? true : false
  );
  const account = jwt_decode(idToken, { complete: true }); // Complete returns full token

  if (!hasRoles(account.roles, ["CostManager"])) {
    return null;
  }

  function onChange() {
    if (!isClient) {
      setIsClient(true);

      let impersonateClient = { ...user };
      impersonateClient.exp = account.exp; // B2C expiry is 1 hour
      impersonateClient.iat = account.iat;
      impersonateClient.roles = ["Client"];
      localStorage.setItem("user", JSON.stringify(impersonateClient));
      store.dispatch(setCurrentUser(impersonateClient)); // Reset redux on page refresh
    }

    if (isClient) {
      setIsClient(false);

      let resetUser = { ...user };
      resetUser.exp = account.exp; // B2C expiry is 1 hour
      resetUser.iat = account.iat;
      resetUser.roles = account.roles;
      localStorage.setItem("user", JSON.stringify(resetUser));
      store.dispatch(setCurrentUser(resetUser)); // Reset redux on page refresh
    }
  }

  return (
    <ToggleControlSwitch
      style={{ margin: "0 15px 10px 15px" }}
      label="Client"
      checked={isClient}
      onChange={onChange}
    />
  );
}
