import { CORRESPONDENCE } from "./Correspondence.strings";
import ProjectsListSection from "./ProjectsListSection";

export default function ProjectsList(props) {
  const { projects } = props;
  const { selectedProjectId, setSelectedProjectId } = props;
  const { notificationsOnly } = props;

  const projectsWithPendingNotifications = projects
    .filter((p) => p.notify_estimate_comment > 0)
    .sort((a, b) => b.notify_estimate_comment - a.notify_estimate_comment);

  const projectsWithClosedOutComments = projects
    .filter((p) => p.notify_estimate_comment === 0)
    .sort((a, b) => a.id - b.id);

  return (
    <div className="correspondence-projects-list">
      <ProjectsListSection
        projects={projectsWithPendingNotifications}
        heading={CORRESPONDENCE.PROJECTS_LIST_SECTION_PENDING_LABEL}
        type="pending"
        selectedProjectId={selectedProjectId}
        setSelectedProjectId={setSelectedProjectId}
      />
      {!notificationsOnly && (
        <ProjectsListSection
          projects={projectsWithClosedOutComments}
          heading={CORRESPONDENCE.PROJECTS_LIST_SECTION_CLOSED_OUT_LABEL}
          type="closed-out"
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
        />
      )}
    </div>
  );
}
