import React from "react";
import { Line } from "react-chartjs-2";
import { tranStr, translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

export default function CashflowGraphCumulative(props) {
  const { cashflow } = props;

  if (isEmpty(cashflow.data)) {
    return null;
  }

  let field = [];
  let value = [];
  let value2 = [];
  let value3 = [];

  !isEmpty(cashflow.data) &&
    cashflow.data.forEach((d) => {
      field.push(d["date"]);
      value.push(d["forecastCumulative"]);
      value2.push(d["contractorCumulative"]);
      value3.push(d["actualCumulative"]);
    });

  // Create the graph object
  let graph = {
    labels: field,
    datasets: [
      {
        yAxisID: "forecastCumulative",
        label: tranStr("Forecast"),
        data: value,
        barThickness: 10,
        borderColor: `rgba(228, 97, 15,1)`,
        backgroundColor: `rgba(228, 97, 15,1)`,
        lineTension: 0,
        fill: false,
      },
      {
        yAxisID: "forecastCumulative",
        label: tranStr("Contractor"),
        data: value2,
        barThickness: 10,
        borderColor: `rgba(85, 87, 90, 0.7)`,
        backgroundColor: `rgba(85, 87, 90, 0.7)`,
        lineTension: 0,
        fill: false,
      },
      {
        yAxisID: "forecastCumulative",
        label: tranStr("Actuals"),
        data: value3,
        barThickness: 10,
        borderColor: `rgba(50, 98, 149,1)`,
        backgroundColor: `rgba(50, 98, 149,1)`,
        lineTension: 0,
        fill: false,
      },
    ],
    text: field,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    maintainAspectRatio: false,
    plugins: { datalabels: { display: false } },

    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem) {
          return comma(Math.round(tooltipItem.yLabel));
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: false,
        },
      ],
      yAxes: [
        {
          id: "forecastCumulative",
          scaleLabel: {
            display: true,
            labelString: "Cumulative Cashflow",
          },
          position: "left",
          stacked: false,
          ticks: {
            callback: function (tick, index, values) {
              return comma(Math.round(tick));
            },
            min: 0,
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "right",
      fullWidth: true,
      reverse: false,
    },
  };
  return (
    <div className="post-contract-cashflow-graph-container">
      <h1
        className="display-4"
        style={{ fontSize: "20px", marginBottom: "20px" }}
      >
        {translate("Cashflow Cumulative Cost")}
      </h1>
      <Line data={graph} options={options} />
    </div>
  );
}
