import React, { useState } from "react";

import Modal from "../modal/Modal";

import { hasRoles } from "../../utils/roles";
import { tranStr } from "../../utils/translation";
import { updateBenchmarkComparisons } from "../../api/estimates/EstimateBenchmarkAPI";

import { bmAPIs } from "../../api/api-endpoints/BenchmarkingEndpoints";
import "../estimates/EstimatesEdit.css";
import CreateBenchmarkQuick from "./CreateBenchmarkQuick";
import { getBenchmarking } from "../../api/benchmarking-control/BenchmarkingControlAPI";

export default function CreateBenchmarkQuickModal(props) {
  const { project } = props;
  const { estimate } = props;
  const { user } = props;
  const { CP, CPs } = props;
  const { setBenchmarking } = props;
  const { benchmarkSelectionFilters } = props;

  const [modal, setModal] = useState(false);

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (!benchmarkSelectionFilters.isQuickBenchmark) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        Component={CreateBenchmarkQuick}
        title={tranStr("Create Quick Benchmark")}
        modal={modal}
        setModal={setModal}
        setShow={setModal}
        // Component Props
        estimate={estimate}
        CP={CP}
        project={project}
        CPs={CPs}
        user={user}
        // Form Settings
        rowsFormIsEnabled={true}
        metricsFormIsEnabled={true}
        mappingFunction={mappingFunction}
        exclusiveProjectId={project.id.toString()}
        url={bmAPIs().create_benchmark}
        benchmarkSource={"FORM"}
      />
      <button
        className="benchmarking-save-button"
        id={"button-create-benchmark-quick-modal"}
        onClick={() => {
          setModal(true);
        }}
        title="Quick Benchmark"
      >
        Quick Benchmark
      </button>
    </div>
  );

  async function mappingFunction(newBenchmarkId) {
    // Get existing Benchmark IDs that are linked
    const { benchmark } = estimate;
    const { linkedBenchmarksIds } = benchmark;

    const benchmarkComparisonIds = [];
    linkedBenchmarksIds?.forEach((relation) => {
      benchmarkComparisonIds.push({
        BenchmarkId: relation.benchmarkId,
        IsAnonymous: relation.isAnonymous,
      });
    });

    benchmarkComparisonIds.push({
      BenchmarkId: newBenchmarkId,
      IsAnonymous: false,
    });

    const data = {
      CPs: CPs,
      setShow: () => null,
      setError: () => null,

      // Data
      projectId: project.id,
      benchmarkId: benchmark.id,
      benchmarkComparisonIds: benchmarkComparisonIds,
    };

    await updateBenchmarkComparisons(data);
    setBenchmarking(await getBenchmarking());
  }
}
