import React from "react";
import i18n from "i18next";
import { translate } from "../../utils/translation";

import "./LanguageSelectGuest.css";

export default function LanguageSelectGuest() {
  const ENABLE_TRANSLATION = false;

  if (!ENABLE_TRANSLATION) {
    return null;
  }

  return (
    <div className="language-select-guest">
      <div className="dropdown">
        <button className="dropbtn">
          <div>{translate("Language")}</div>
          <div className="dropbtn-caret">
            <i className="fa fa-caret-down" />
          </div>
        </button>
        <div className="dropdown-content">
          <div className="language-select-guest-heading"></div>
          <div className="language-select-guest-row">
            <div
              className="language-select-guest-button"
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              English
            </div>
          </div>
          <div className="language-select-guest-row">
            <div
              className="language-select-guest-button"
              onClick={() => {
                i18n.changeLanguage("zh");
              }}
            >
              {"中文（简体)"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
