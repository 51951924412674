import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import { generateRiskRegisterTypes } from "../post-contract-risk-register/CostReportRiskRegisterChart.functions";

export const COLORIGINALBUDGET = 1;
export const COLADJBUDGET = 2;
export const COLADJUSTEDBUDGET = 3;
export const COLCONTRACTSUM = 4;
export const COLAPPROVED = 5;
export const COLREVISEDCONTRACTVALUE = 6;
export const COLSUBMITTED = 8;
export const COLFORECAST = 7;
export const COLADJUSTMENT = 9;
export const COLANTICIPATEDVARIATIONS = 10;
export const COLFORECASTTOTALCOST = 11;
export const COLVARIANCEADJUSTEDBUDGET = 12;
export const COLPREVIOUSLYPAID = 13;
export const COLPAIDTHISMONTH = 14;
export const COLTOTEXPTODATE = 15;
export const COLCOSTTOCOMPLETE = 16;
export const COLPREVFORECASTTOTAL = 17;
export const COLCHANGEINPERIOD = 18;

export const APPROVEDVARIATIONTITLE = "Approved Variations";
export const UNAPPROVEDVARIATIONTITLE = "Unapproved Variations";
export const RISKALLOWANCETITLE = "Risk Allowance";
export const DIRECTCOSTSSUBTOTAL = "Direct Costs Sub-Total";
export const RISKANDCONTINGENCYSUBTOTAL = "Risk and Contingency Sub-Total";
export const PROJECTCONTINGENCYTITLE = "Project Contingency";
export const ENABLINGWORKSTITLE = "Enabling Works";
export const PROVISIONALSUMSTITLE = "Provisional Sums";
export const PTCONSULTANTFESSTITLE = "Project Team Consultant Fees";

export const MEASUREDWORKSTITLE = "Measured Works";
export const PRELIMSTITLE = "Preliminaries";
export const CDMTITLE = "Main Contractor’s Consultants’ Fees";
export const OTHER2TITLE = "Overheads and Profit";
export const CCPROVISIONALSUMSTITLE = "Provisional Sum";

export const rowFields = [];

export let rrTotals = {};

export let PCForecastedVariations = 0;

//Cost Report Table Heading >> Rows >>
export let totalColumns = {
  _original_budget: 0,
  _adjustment_budget: 0,
  _adjusted_budget: 0,
  _contract_sum: 0,
  _approved: 0,
  _revised_contract_value: 0,
  _submitted: 0,
  _forecast: 0,
  _adjustment: 0,
  _anticipated_variations: 0,
  _forecast_total_cost: 0,
  _variance_adj_budget: 0,
  _previously_paid: 0,
  _paid_this_month: 0,
  _tot_exp_todate: 0,
  _cost_to_complete: 0,
  _previous_forecast_total: 0,
  _change_in_period: 0,
};

export const TABLEGROUPHEADINGS = [
  "",
  "BUDGET",
  "COMMITTED COSTS",
  "NON-COMMITTED COSTS",
  "SUMMARY",
  "EXPENDITURE",
  "MOVEMENT",
];

export const TABLEHEADINGS = [
  "",
  translate("Original Budget"),
  translate("Budget Adjustments"),
  translate("Adjusted Budget"),
  translate("Contract Sum"),
  translate("Approved Variations"),
  translate("Revised Contract Value"),

  translate("Forecast Variations"),
  translate("Submitted Variations"),
  translate("Adjustments to Provisional Items"),
  translate("Anticipated Variations"),
  translate("Forecast Total Cost"),
  translate("Variance to Adjusted Budget"),

  translate("Previously Paid"),
  translate("Paid this month"),
  translate("Total Expenditure to Date"),
  translate("Cost to Complete"),

  translate("Previous Forecast Total Cost"),
  translate("Change in Period"),
];

export const TRADECOSTSROWS = [
  {
    title: MEASUREDWORKSTITLE,
    CRObjectKey: "construction_cost_works",
    PCObjectKey: "construction_work_complete_works",
  },
  {
    title: CCPROVISIONALSUMSTITLE,
    CRObjectKey: "construction_cost_prov_sums",
    PCObjectKey: "construction_work_complete_psums",
  },
  {
    title: PRELIMSTITLE,
    CRObjectKey: "construction_cost_prelims",
    PCObjectKey: "construction_work_complete_prelims",
  },
  {
    title: CDMTITLE,
    CRObjectKey: "construction_cost_cdp",
    PCObjectKey: "construction_work_complete_cdp",
  },
  {
    title: OTHER2TITLE,
    CRObjectKey: "construction_cost_other2",
    PCObjectKey: "construction_work_complete_other2",
  },
];

export const CONSTRUCTIONCOSTSUMMARYROWS = [
  {
    title: "Construction Cost",
    CRObjectKey: "construction_cost",
    PCObjectKey: "construction_work_complete",
  },
];

export const _CONSTRUCTIONCOSTROWS = [
  {
    title: APPROVEDVARIATIONTITLE,
    CRObjectKey: "variations",
    PCObjectKey: "variations_work_complete",
  },
  {
    title: UNAPPROVEDVARIATIONTITLE,
    CRObjectKey: "variations",
    PCObjectKey: "variations_work_complete",
  },
  {
    title: "Provisional Quantities",
    CRObjectKey: "provisional_quantities",
    PCObjectKey: "prov_quants_work_complete",
  },
  {
    title: "Provisional Sums",
    CRObjectKey: "provisional_sums",
    PCObjectKey: "prov_sums_work_complete",
  },
  {
    title: "Delays / EoTs",
    CRObjectKey: "delays_and_extensions_of_time",
    PCObjectKey: "extensions_of_time_work_complete",
  },
];

export let CONSTRUCTIONCOSTROWS = TRADECOSTSROWS.concat(_CONSTRUCTIONCOSTROWS);

export const CLIENTCOSTROWS = [
  {
    title: "Enabling Works",
    CRObjectKey: "enabling_works",
    PCObjectKey: "enabling_works",
  },
  {
    title: PTCONSULTANTFESSTITLE,
    CRObjectKey: "project_team_consulting_fees",
    PCObjectKey: "project_team_consulting_fees",
  },
  {
    // Consultant Fees
    title: "Consultant Fees",
    CRObjectKey: "consultants_fees",
    PCObjectKey: "Consult",
  },

  {
    // Client Staffing Costs
    title: "Client Staffing Costs",
    CRObjectKey: "client_staffing",
    PCObjectKey: "Client Staff",
  },

  {
    // Authority Fees
    title: "Authority Fees",
    CRObjectKey: "authority_fees",
    PCObjectKey: "Authorities",
  },

  {
    // Legal Fees
    title: "Legal Fees",
    CRObjectKey: "legal_fees",
    PCObjectKey: "Legal",
  },

  {
    // Furniture, Fittings & Equipment
    title: "Furniture, Fittings & Equipment",
    CRObjectKey: "ffe",
    PCObjectKey: "FF&E",
  },
  {
    // Loose Furniture
    title: "Loose Furniture",
    CRObjectKey: "loose_furniture",
    PCObjectKey: "LF",
  },
  {
    // Workstations
    title: "Workstations",
    CRObjectKey: "workstations",
    PCObjectKey: "WS",
  },
  {
    // Audio Visual
    title: "Audio Visual",
    CRObjectKey: "audio_visual",
    PCObjectKey: "AV",
  },
  {
    // Specialist Equipment
    title: "Specialist Equipment",
    CRObjectKey: "specialist_equipment",
    PCObjectKey: "Spec Equip",
  },
  {
    // ICT Costs
    title: "ICT Costs",
    CRObjectKey: "ict",
    PCObjectKey: "ICT",
  },
  {
    // Relocation Costs
    title: "Relocation Costs",
    CRObjectKey: "relocation",
    PCObjectKey: "Relocate",
  },
  {
    // Other 1
    title: "Other 1",
    CRObjectKey: "other1",
    PCObjectKey: "Other 1",
  },
  {
    // Other 2
    title: "Other 2",
    CRObjectKey: "other2",
    PCObjectKey: "Other 2",
  },
  {
    // Other 3
    title: "Other 3",
    CRObjectKey: "other3",
    PCObjectKey: "Other 3",
  },
];

export const CONTINGENCYROWS = [
  {
    // Project Contingency
    title: RISKALLOWANCETITLE,
    CRObjectKey: "risk_allowance",
    PCObjectKey: "",
  },
  {
    title: "Project Contingency",
    CRObjectKey: "project_contingency",
    PCObjectKey: "",
  },
];

// Get Total for Enabling Works >>OriginalBudget>>Submitted>>ContractSum>>Approved columns
// Get Total for Construction Cost Works>>OriginalBudget>>ContractSum
export function determineAndGetToal(SELECTEDCR, columName, CRObjectKey) {
  switch (CRObjectKey) {
    case "enabling_works":
      return determineEnablingworksGetTotal(SELECTEDCR, columName);
    case "construction_cost_works":
      return determineWorksGetTotal(SELECTEDCR, columName);
    case "construction_cost_prelims":
      return determinePrelimsGetTotal(SELECTEDCR, columName);
    case "construction_cost_cdp":
      return determineCdpGetTotal(SELECTEDCR, columName);
    case "construction_cost_other2":
      return determineOther2GetTotal(SELECTEDCR, columName);
    case "construction_cost_prov_sum":
      return determineProvSumsGetTotal(SELECTEDCR, columName);
    default:
      return 0;
  }
}

export function determineEnablingworksGetTotal(SELECTEDCR, columName) {
  switch (columName) {
    case "originalbudget":
      return getEWValue(SELECTEDCR, "originalbudget");
    case "Submitted":
      return getEWValueFor(SELECTEDCR, "Submitted");
    case "contractsum":
      return getEWValue(SELECTEDCR, "contractsum");
    case "Approved":
      return getEWValueFor(SELECTEDCR, "Approved");
    default:
      return 0;
  }
}

export function determineWorksGetTotal(SELECTEDCR, columName) {
  switch (columName) {
    case "originalbudget":
      return getMwValue(SELECTEDCR, "originalbudget");
    case "contractsum":
      return getMwValue(SELECTEDCR, "contractsum");
    default:
      return 0;
  }
}
export function determinePrelimsGetTotal(SELECTEDCR, columName) {
  switch (columName) {
    case "originalbudget":
      return getPrelimsValue(SELECTEDCR, "originalbudget");
    case "contractsum":
      return getPrelimsValue(SELECTEDCR, "contractsum");
    default:
      return 0;
  }
}

export function determineCdpGetTotal(SELECTEDCR, columName) {
  switch (columName) {
    case "originalbudget":
      return getCDMValue(SELECTEDCR, "originalbudget");
    case "contractsum":
      return getCDMValue(SELECTEDCR, "contractsum");
    default:
      return 0;
  }
}
export function determineProvSumsGetTotal(SELECTEDCR, columName) {
  switch (columName) {
    case "originalbudget":
      return getProvSumValue(SELECTEDCR, "originalbudget");
    case "contractsum":
      return getProvSumValue(SELECTEDCR, "contractsum");
    default:
      return 0;
  }
}
export function determineOther2GetTotal(SELECTEDCR, columName) {
  switch (columName) {
    case "originalbudget":
      return getOther2Value(SELECTEDCR, "originalbudget");
    case "contractsum":
      return getOther2Value(SELECTEDCR, "contractsum");
    default:
      return 0;
  }
}

// Get Total for Project Team Consulting Fees >>OriginalBudget>>Submitted>>ContractSum>>Approved columns
export function determinePtConFeesAndGetToal(
  SELECTEDCR,
  columName,
  CRObjectKey
) {
  if (CRObjectKey === "project_team_consulting_fees") {
    switch (columName) {
      case "originalbudget":
        return getPTCFValue(SELECTEDCR, "originalbudget");
      case "Submitted":
        return getPTCFVsValueFor(SELECTEDCR, "Submitted");
      case "contractsum":
        return getPTCFValue(SELECTEDCR, "contractsum");
      case "Approved":
        return getPTCFVsValueFor(SELECTEDCR, "Approved");
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

//GetColumn Total based on Column No and Name
export function getColumnTotal(CR, project, columnNo, SELECTEDCR) {
  let columnTotal = 0;
  //1 2 3 4 5 7 8 9
  if (rowFields !== undefined && rowFields.length > -1) {
    rowFields[0].forEach((item) => {
      if (
        project !== undefined &&
        showHideRow(item.CRObjectKey, project) &&
        item.title !== UNAPPROVEDVARIATIONTITLE
      ) {
        switch (columnNo) {
          case COLORIGINALBUDGET:
            columnTotal += checkUndefined(
              CR[`${item.CRObjectKey}_original_budget`]
            );
            columnTotal += determineAndGetToal(
              SELECTEDCR,
              "originalbudget",
              item.CRObjectKey
            );
            columnTotal += determinePtConFeesAndGetToal(
              SELECTEDCR,
              "originalbudget",
              item.CRObjectKey
            );

            totalColumns._original_budget = checkUndefined(columnTotal);
            break; //1
          case COLADJBUDGET:
            columnTotal += checkUndefined(
              CR[`${item.CRObjectKey}_adjustment_budget`]
            );
            totalColumns._adjustment_budget = checkUndefined(columnTotal);
            break; //2;
          case COLADJUSTEDBUDGET:
            columnTotal += checkUndefined(
              CR[`${item.CRObjectKey}_adjusted_budget`]
            );
            totalColumns._adjusted_budget = checkUndefined(columnTotal);
            break; //2;
          case COLCONTRACTSUM:
            columnTotal += checkUndefined(
              CR[`${item.CRObjectKey}_contract_sum`]
            );

            columnTotal += determineAndGetToal(
              SELECTEDCR,
              "contractsum",
              item.CRObjectKey
            );
            columnTotal += determinePtConFeesAndGetToal(
              SELECTEDCR,
              "contractsum",
              item.CRObjectKey
            );
            totalColumns._contract_sum = checkUndefined(columnTotal);
            break; //4;
          case COLAPPROVED:
            columnTotal +=
              item.CRObjectKey !== "provisional_sums"
                ? checkUndefined(CR[`${item.CRObjectKey}_approved`])
                : 0;
            columnTotal += determineAndGetToal(
              SELECTEDCR,
              "Approved",
              item.CRObjectKey
            );
            columnTotal += determinePtConFeesAndGetToal(
              SELECTEDCR,
              "Approved",
              item.CRObjectKey
            );
            totalColumns._approved = checkUndefined(columnTotal);
            break; // 5;
          case COLSUBMITTED:
            columnTotal += checkUndefined(CR[`${item.CRObjectKey}_submitted`]);
            columnTotal += determineAndGetToal(
              SELECTEDCR,
              "Submitted",
              item.CRObjectKey
            );
            columnTotal += determinePtConFeesAndGetToal(
              SELECTEDCR,
              "Submitted",
              item.CRObjectKey
            );
            totalColumns._submitted = checkUndefined(columnTotal);
            break; //6;
          case COLFORECAST:
            switch (item.CRObjectKey) {
              case "risk_allowance":
                columnTotal += checkUndefined(rrTotals.risk_analysis);
                break;
              case "project_contingency":
                columnTotal += checkUndefined(PCForecastedVariations);
                break;
              case "provisional_sums":
                break;
              case "enabling_works":
                columnTotal += checkUndefined(
                  getEWValueFor(SELECTEDCR, "Forecast")
                );
                break;
              case "project_team_consulting_fees":
                columnTotal += checkUndefined(
                  getPTCFVsValueFor(SELECTEDCR, "Forecast")
                );
                break;
              default:
                columnTotal += checkUndefined(
                  CR[`${item.CRObjectKey}_forecast`]
                );
                break;
            }
            // columnTotal +=
            //   (item.CRObjectKey === "risk_allowance" ||
            //     item.CRObjectKey === "project_contingency") &&
            //   showHideRow(RISKALLOWANCETITLE, project)
            //     ? checkUndefined(rrTotals.risk_analysis)
            //     : checkUndefined(CR[`${item.CRObjectKey}_forecast`]);
            totalColumns._forecast = checkUndefined(columnTotal);
            break; //7;
          case COLADJUSTMENT:
          case COLPREVIOUSLYPAID:
            columnTotal += checkUndefined(CR[`${item.CRObjectKey}_adjustment`]);
            totalColumns._adjustment = checkUndefined(columnTotal);
            break; // 9 13;
          default:
            break;
        }
      }
    });
  }
  return columnTotal;
}

//Get TotalExpenditure To Date Field Value based on the Title/Key of the row
export function getTotExpToDateValue(
  progressClaim,
  PCObjectKey,
  tax,
  SELECTEDCR
) {
  //Construct Total Expenditure To Date cell value
  if (
    !isEmpty(progressClaim) &&
    progressClaim !== undefined &&
    !isEmpty(PCObjectKey)
  ) {
    switch (PCObjectKey) {
      case "construction_work_complete":
        return (
          progressClaim.construction_work_complete +
          progressClaim.unfixed_materials_off_site +
          progressClaim.unfixed_materials_on_site
        );
      case "Consult":
      case "Client Staff":
      case "Authorities":
      case "Legal":
      case "Other 1":
      case "Other 2":
      case "Other 3":
      case "FF&E":
      case "LF":
      case "WS":
      case "AV":
      case "Spec Equip":
      case "ICT":
      case "Relocate":
      case "Client Sales":
        const clientdirecttotals = Object.values(
          SELECTEDCR.CDs.filter(
            (cd) => cd.client_cost_type.indexOf(PCObjectKey) !== -1
          ).map((item) => checkUndefined(item.value_complete_to_date))
        ).reduce((a, b) => a + b, 0);
        return clientdirecttotals;
      case "other2":
      case "other3":
        return 0;
      case "forecast_cost_excl_tax":
        return progressClaim.gross_total_work_executed_excl_tax;
      case "forecast_cost_incl_tax":
        return (
          progressClaim.gross_total_work_executed_excl_tax +
          (progressClaim.gross_total_work_executed_excl_tax * tax) / 100
        );
      case "enabling_works":
        return checkUndefined(getEWValueForExpTodate(SELECTEDCR));
      case "project_team_consulting_fees":
        return checkUndefined(getPTCFeesValueForExpTodate(SELECTEDCR));
      case "construction_work_complete_works":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_to_date && mw.sub_heading === "Works"
          )
          .map((item) => checkUndefined(item.value_complete_to_date))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_psums":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_to_date && mw.sub_heading === "Prov Sums"
          )
          .map((item) => checkUndefined(item.value_complete_to_date))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_prelims":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_to_date && mw.sub_heading === "Prelims"
          )
          .map((item) => checkUndefined(item.value_complete_to_date))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_cdp":
        return SELECTEDCR.trades
          .filter((mw) => mw.value_complete_to_date && mw.sub_heading === "CDP")
          .map((item) => checkUndefined(item.value_complete_to_date))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_other2":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_to_date && mw.sub_heading === "Other 2"
          )
          .map((item) => checkUndefined(item.value_complete_to_date))
          .reduce((a, b) => a + b, 0);
      default:
        return progressClaim[`${PCObjectKey}`];
    }
  }
  return 0;
}

//Get PreviouslyPaidValue To Date Field Value based on the Title/Key of the row
export function getPreviouslyPaidValue(
  previousProgressClaim,
  PCObjectKey,
  tax,
  SELECTEDCR
) {

  if (
    !isEmpty(previousProgressClaim) &&
    previousProgressClaim !== undefined &&
    !isEmpty(PCObjectKey)
  ) {
    switch (PCObjectKey) {
      case "construction_work_complete":
        let constructionWork =
          previousProgressClaim.construction_work_complete +
          previousProgressClaim.unfixed_materials_off_site +
          previousProgressClaim.unfixed_materials_on_site;
        return constructionWork;
      case "forecast_cost_excl_tax":
        break;
      case "variations_work_complete":
        return previousProgressClaim.variations_work_complete;
      case "prov_sums_work_complete":
        return previousProgressClaim.prov_sums_work_complete;
      case "prov_quants_work_complete":
        return previousProgressClaim.prov_quants_work_complete;
      case "forecast_cost_incl_tax":
        break;
      case "construction_work_complete_works":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_last_claim && mw.sub_heading === "Works"
          )
          .map((item) => checkUndefined(item.value_complete_last_claim))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_psums":
        return SELECTEDCR.trades
          .filter(
            (mw) =>
              mw.value_complete_last_claim && mw.sub_heading === "Prov Sums"
          )
          .map((item) => checkUndefined(item.value_complete_last_claim))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_prelims":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_last_claim && mw.sub_heading === "Prelims"
          )
          .map((item) => checkUndefined(item.value_complete_last_claim))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_cdp":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_last_claim && mw.sub_heading === "CDP"
          )
          .map((item) => checkUndefined(item.value_complete_last_claim))
          .reduce((a, b) => a + b, 0);
      case "construction_work_complete_other2":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.value_complete_last_claim && mw.sub_heading === "Other 2"
          )
          .map((item) => checkUndefined(item.value_complete_last_claim))
          .reduce((a, b) => a + b, 0);
      default:
        break;
    }
  }
  return 0;
}

export function getProjectContingencyForecastedVariations(CongingenciesList) {
  PCForecastedVariations = checkUndefined(
    CongingenciesList.map(
      (contingency) => contingency.contingency_allowance
    ).reduce((acc, amount) => acc + amount, 0)
  );
  return PCForecastedVariations;
}

//Enabling Works
export function getEWValueFor(SELECTEDCR, status) {
  if (!isEmpty(SELECTEDCR.EWVariations)) {
    return SELECTEDCR.EWVariations.filter(
      (ewv) => ewv.status.indexOf(status) !== -1
    )
      .map((item) => checkUndefined(item.variation_value))
      .reduce((a, b) => a + b, 0);
  }
  return 0;
}
export function getEWValueForExpTodate(SELECTEDCR) {
  let expToDate=0;
  if (!isEmpty(SELECTEDCR.EW)) {
     expToDate = SELECTEDCR.EW.filter((ew) => ew.value_complete_to_date)
      .map((item) => checkUndefined(item.value_complete_to_date))
      .reduce((a, b) => a + b, 0);
  }
  if (!isEmpty(SELECTEDCR.EWVariations)) {
    expToDate += SELECTEDCR.EWVariations.filter(
      (ew) => ew.value_complete_to_date
    )
      .map((item) => checkUndefined(item.value_complete_to_date))
      .reduce((a, b) => a + b, 0);

    return expToDate;
  }
  return expToDate;
}
export function getEWValue(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.EW)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.EW.filter((ew) => ew.contract_value_incl_prov_items)
          .map((item) => checkUndefined(item.contract_value_incl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.EW.filter((ew) => ew.budget)
          .map((item) => checkUndefined(item.budget))
          .reduce((a, b) => a + b, 0);
      default:
        return 0;
    }
  }
  return 0;
}

//Project team Consultant Fees
export function getPTCFVsValueFor(SELECTEDCR, status) {
  if (!isEmpty(SELECTEDCR.PTCFVs)) {
    return SELECTEDCR.PTCFVs.filter((ewv) => ewv.status.indexOf(status) !== -1)
      .map((item) => checkUndefined(item.variation_value))
      .reduce((a, b) => a + b, 0);
  }
  return 0;
}
export function getPTCFeesValueForExpTodate(SELECTEDCR) {
  let expToDate=0;
  if (!isEmpty(SELECTEDCR.PTCF)) {
    expToDate = SELECTEDCR.PTCF.filter(
      (ptcf) => ptcf.value_complete_to_date
    )
      .map((item) => checkUndefined(item.value_complete_to_date))
      .reduce((a, b) => a + b, 0);
  }
  if (!isEmpty(SELECTEDCR.PTCFVs)) {
    expToDate += SELECTEDCR.PTCFVs.filter(
      (ptcfv) => ptcfv.value_complete_to_date
    )
      .map((item) => checkUndefined(item.value_complete_to_date))
      .reduce((a, b) => a + b, 0);

    return expToDate;
  }
  return expToDate;
}
export function getPTCFValue(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.PTCF)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.PTCF.filter(
          (ptcf) => ptcf.contract_value_incl_prov_items
        )
          .map((item) => checkUndefined(item.contract_value_incl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.PTCF.filter((ptcf) => ptcf.budget)
          .map((item) => checkUndefined(item.budget))
          .reduce((a, b) => a + b, 0);
      default:
        return 0;
    }
  }
  return 0;
}

//Construction Cost >> Measured Works
export function getMwValue(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.trades)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.trades
          .filter(
            (mw) =>
              mw.contract_value_excl_prov_items && mw.sub_heading === "Works"
          )

          .map((item) => checkUndefined(item.contract_value_excl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.contract_value && mw.sub_heading === "Works")
          .map((item) => checkUndefined(item.contract_value))
          .reduce((a, b) => a + b, 0);
      case "variancetoadjustedbudget":
        let sum = SELECTEDCR.trades.filter(
          (mw) => mw.difference && mw.sub_heading === "Works"
        );
        let sumtot = sum
          .map((item) => checkUndefined(item.difference))
          .reduce((a, b) => a + b, 0);

        return sumtot;
      default:
        return 0;
    }
  }
  return 0;
}

//Construction Cost >> Provisional Sums
export function getProvSumValue(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.trades)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.trades
          .filter(
            (mw) =>
              mw.contract_value_excl_prov_items &&
              mw.sub_heading === "Provisional Sums"
          )

          .map((item) => checkUndefined(item.contract_value_excl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.contract_value && mw.sub_heading === "Provisional Sums"
          )
          .map((item) => checkUndefined(item.contract_value))
          .reduce((a, b) => a + b, 0);
      case "variancetoadjustedbudget":
        return SELECTEDCR.trades
          .filter(
            (mw) => mw.difference && mw.sub_heading === "Provisional Sums"
          )
          .map((item) => checkUndefined(item.difference))
          .reduce((a, b) => a + b, 0);
      default:
        return 0;
    }
  }
  return 0;
}

//Construction Cost >> Prelims
export function getPrelimsValue(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.trades)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.trades
          .filter(
            (mw) =>
              mw.contract_value_excl_prov_items && mw.sub_heading === "Prelims"
          )

          .map((item) => checkUndefined(item.contract_value_excl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.contract_value && mw.sub_heading === "Prelims")
          .map((item) => checkUndefined(item.contract_value))
          .reduce((a, b) => a + b, 0);
      case "variancetoadjustedbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.difference && mw.sub_heading === "Prelims")
          .map((item) => checkUndefined(item.difference))
          .reduce((a, b) => a + b, 0);
      default:
        return 0;
    }
  }
  return 0;
}

//Construction Cost >> CDP
export function getCDMValue(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.trades)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.trades
          .filter(
            (mw) =>
              mw.contract_value_excl_prov_items && mw.sub_heading === "CDP"
          )

          .map((item) => checkUndefined(item.contract_value_excl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.contract_value && mw.sub_heading === "CDP")
          .map((item) => checkUndefined(item.contract_value))
          .reduce((a, b) => a + b, 0);
      case "variancetoadjustedbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.difference && mw.sub_heading === "CDP")
          .map((item) => checkUndefined(item.difference))
          .reduce((a, b) => a + b, 0);
      default:
        return 0;
    }
  }
  return 0;
}

//Construction Cost >> Measured Other2
export function getOther2Value(SELECTEDCR, column) {
  if (!isEmpty(SELECTEDCR.trades)) {
    switch (column) {
      case "contractsum":
        return SELECTEDCR.trades
          .filter(
            (mw) =>
              mw.contract_value_excl_prov_items && mw.sub_heading === "Other 2"
          )

          .map((item) => checkUndefined(item.contract_value_excl_prov_items))
          .reduce((a, b) => a + b, 0);
      case "originalbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.contract_value && mw.sub_heading === "Other 2")
          .map((item) => checkUndefined(item.contract_value))
          .reduce((a, b) => a + b, 0);
      case "variancetoadjustedbudget":
        return SELECTEDCR.trades
          .filter((mw) => mw.difference && mw.sub_heading === "Other 2")
          .map((item) => checkUndefined(item.difference))
          .reduce((a, b) => a + b, 0);
      default:
        return 0;
    }
  }
  return 0;
}

//Main function which will generate each cell value for a particular row based on Title / key
//Needs to change or add or delete cell values here
export function generateTableRowValues(CR, SELECTEDCR, CRObjectKey, title) {
  let contractsum = 0;
  let approved = 0;
  let submitted = 0;
  let forecasted = 0;
  let adjprovitems = 0;
  let originalbudget = 0;
  let adjustmentbudget = 0;
  let adjustedbudget = 0;
  let anticipatedvariations = 0;
  let forecasttotal = 0;
  let variancetotadjustedbudget = 0;
  switch (title) {
    case APPROVEDVARIATIONTITLE:
      contractsum = checkUndefined(CR[`${CRObjectKey}_contract_sum`]);
      approved = checkUndefined(CR[`${CRObjectKey}_approved`]);
      originalbudget = checkUndefined(CR[`${CRObjectKey}_original_budget`]);
      adjustmentbudget = checkUndefined(CR[`${CRObjectKey}_adjustment_budget`]);
      adjustedbudget = checkUndefined(CR[`${CRObjectKey}_adjusted_budget`]);
      anticipatedvariations = checkUndefined(
        CR[`${CRObjectKey}_anticipated_variations`]
      );
      forecasttotal = checkUndefined(CR[`${CRObjectKey}_forecast_total`]);
      variancetotadjustedbudget = checkUndefined(
        CR[`${CRObjectKey}_variance_toadjusted_Budget`]
      );
      break;
    case UNAPPROVEDVARIATIONTITLE:
      submitted = checkUndefined(CR[`${CRObjectKey}_submitted`]);
      forecasted = checkUndefined(CR[`${CRObjectKey}_forecast`]);
      adjprovitems = checkUndefined(CR[`${CRObjectKey}_adjustment`]);
      break;
    case RISKALLOWANCETITLE:
      forecasted = checkUndefined(rrTotals.risk_analysis);
      break;
    case PROJECTCONTINGENCYTITLE:
      originalbudget = checkUndefined(CR[`${CRObjectKey}_original_budget`]);
      adjustmentbudget = checkUndefined(CR[`${CRObjectKey}_adjustment_budget`]);
      adjustedbudget = checkUndefined(CR[`${CRObjectKey}_adjusted_budget`]);
      forecasted = getProjectContingencyForecastedVariations(
        checkUndefined(SELECTEDCR.contingencies) === 0
          ? []
          : SELECTEDCR.contingencies
      );
      break;
    case PROVISIONALSUMSTITLE:
      contractsum = checkUndefined(CR[`${CRObjectKey}_contract_sum`]);
      adjprovitems = checkUndefined(CR[`${CRObjectKey}_adjustment`]);
      originalbudget = checkUndefined(CR[`${CRObjectKey}_original_budget`]);
      adjustmentbudget = checkUndefined(CR[`${CRObjectKey}_adjustment_budget`]);
      adjustedbudget = checkUndefined(CR[`${CRObjectKey}_adjusted_budget`]);
      submitted = checkUndefined(CR[`${CRObjectKey}_submitted`]);

      anticipatedvariations = checkUndefined(
        CR[`${CRObjectKey}_anticipated_variations`]
      );
      forecasttotal = checkUndefined(CR[`${CRObjectKey}_forecast_total`]);
      variancetotadjustedbudget = checkUndefined(
        CR[`${CRObjectKey}_variance_toadjusted_Budget`]
      );
      break;
    case ENABLINGWORKSTITLE:
      originalbudget = checkUndefined(getEWValue(SELECTEDCR, "originalbudget"));
      contractsum = checkUndefined(getEWValue(SELECTEDCR, "contractsum"));
      approved = checkUndefined(getEWValueFor(SELECTEDCR, "Approved"));
      submitted = checkUndefined(getEWValueFor(SELECTEDCR, "Submitted"));
      forecasted = checkUndefined(getEWValueFor(SELECTEDCR, "Forecast"));

      break;
    case PTCONSULTANTFESSTITLE:
      originalbudget = checkUndefined(
        getPTCFValue(SELECTEDCR, "originalbudget")
      );
      contractsum = checkUndefined(getPTCFValue(SELECTEDCR, "contractsum"));
      approved = checkUndefined(getPTCFVsValueFor(SELECTEDCR, "Approved"));
      submitted = checkUndefined(getPTCFVsValueFor(SELECTEDCR, "Submitted"));
      forecasted = checkUndefined(getPTCFVsValueFor(SELECTEDCR, "Forecast"));

      break;

    case MEASUREDWORKSTITLE:
      originalbudget = checkUndefined(getMwValue(SELECTEDCR, "originalbudget"));
      contractsum = checkUndefined(getMwValue(SELECTEDCR, "contractsum"));
      variancetotadjustedbudget = checkUndefined(
        getMwValue(SELECTEDCR, "variancetoadjustedbudget")
      );

      break;
    case PRELIMSTITLE:
      originalbudget = checkUndefined(
        getPrelimsValue(SELECTEDCR, "originalbudget")
      );
      contractsum = checkUndefined(getPrelimsValue(SELECTEDCR, "contractsum"));
      variancetotadjustedbudget = checkUndefined(
        getPrelimsValue(SELECTEDCR, "variancetoadjustedbudget")
      );

      break;
    case CCPROVISIONALSUMSTITLE:
      originalbudget = checkUndefined(
        getProvSumValue(SELECTEDCR, "originalbudget")
      );
      contractsum = checkUndefined(getProvSumValue(SELECTEDCR, "contractsum"));

      variancetotadjustedbudget = checkUndefined(
        getProvSumValue(SELECTEDCR, "variancetoadjustedbudget")
      );

      break;
    case CDMTITLE:
      originalbudget = checkUndefined(
        getCDMValue(SELECTEDCR, "originalbudget")
      );
      contractsum = checkUndefined(getCDMValue(SELECTEDCR, "contractsum"));
      variancetotadjustedbudget = checkUndefined(
        getCDMValue(SELECTEDCR, "variancetoadjustedbudget")
      );

      break;
    case OTHER2TITLE:
      originalbudget = checkUndefined(
        getOther2Value(SELECTEDCR, "originalbudget")
      );
      contractsum = checkUndefined(getOther2Value(SELECTEDCR, "contractsum"));
      variancetotadjustedbudget = checkUndefined(
        getOther2Value(SELECTEDCR, "variancetoadjustedbudget")
      );

      break;
    default:
      contractsum = checkUndefined(CR[`${CRObjectKey}_contract_sum`]);
      approved = checkUndefined(CR[`${CRObjectKey}_approved`]);
      submitted = checkUndefined(CR[`${CRObjectKey}_submitted`]);
      forecasted = checkUndefined(CR[`${CRObjectKey}_forecast`]);
      adjprovitems = checkUndefined(CR[`${CRObjectKey}_adjustment`]);
      originalbudget = checkUndefined(CR[`${CRObjectKey}_original_budget`]);
      adjustmentbudget = checkUndefined(CR[`${CRObjectKey}_adjustment_budget`]);
      adjustedbudget = checkUndefined(CR[`${CRObjectKey}_adjusted_budget`]);
      anticipatedvariations = checkUndefined(
        CR[`${CRObjectKey}_anticipated_variations`]
      );
      forecasttotal = checkUndefined(CR[`${CRObjectKey}_forecast_total`]);
      variancetotadjustedbudget = checkUndefined(
        CR[`${CRObjectKey}_variance_toadjusted_Budget`]
      );
      break;
  }
  return [
    originalbudget,
    adjustmentbudget,
    adjustedbudget,
    contractsum,
    approved,
    checkUndefined(CR[`${CRObjectKey}_approved`]),
    forecasted,
    submitted,
    adjprovitems,
    anticipatedvariations,
    forecasttotal,
    variancetotadjustedbudget,
  ];
}

export const getForecastTotal = (CR, CRObjectKey, title) => {
  switch (CRObjectKey) {
    case "provisional_sums":
      return checkUndefined(CR[`${CRObjectKey}_forecast_total`]);
    case "variations":
      if (title === UNAPPROVEDVARIATIONTITLE)
        return (
          checkUndefined(CR[`${CRObjectKey}_submitted`]) +
          checkUndefined(CR[`${CRObjectKey}_forecast`]) +
          checkUndefined(CR[`${CRObjectKey}_adjustment`])
        );
      else
        return (
          checkUndefined(CR[`${CRObjectKey}_contract_sum`]) +
          checkUndefined(CR[`${CRObjectKey}_approved`])
        );

    // break;
    default:
      return (
        checkUndefined(CR[`${CRObjectKey}_contract_sum`]) +
        checkUndefined(CR[`${CRObjectKey}_approved`]) +
        checkUndefined(CR[`${CRObjectKey}_submitted`]) +
        checkUndefined(CR[`${CRObjectKey}_forecast`]) +
        checkUndefined(CR[`${CRObjectKey}_adjustment`])
      );
  }
};

export function checkUndefined(value) {
  return value === undefined ? 0 : value;
}
export function showHideRow(CRObjectKey, project) {
  let showHide = false;
  switch (CRObjectKey) {
    case "provisional_sums":
      showHide = project.enable_pc_provisional_sums;
      break;
    case "provisional_quantities":
      showHide = project.enable_pc_provisional_quantities;
      break;
    case "delays_and_extensions_of_time":
      showHide = project.enable_pc_delays;
      break;
    case "Client Direct Cost":
    case "consultants_fees":
    case "client_staffing":
    case "authority_fees":
    case "legal_fees":
    case "ffe":
    case "loose_furniture":
    case "workstations":
    case "audio_visual":
    case "specialist_equipment":
    case "ict":
    case "relocation":
    case "other1":
    case "other2":
    case "other3":
      showHide = project.enable_pc_client_directs;
      break;
    case "project_team_consulting_fees":
      showHide = project.enable_pc_project_team_consultant_fees;
      break;
    case "enabling_works":
      showHide = project.enable_pc_enabling_works;
      break;
    case "tax":
      showHide = project.enable_pc_tax;
      break;
    case "risk_allowance":
      showHide = project.enable_pc_risk_register;
      break;
    case "project_contingency":
      showHide = project.enable_pc_contingency;
      break;
    case "variations":
      showHide = project.enable_pc_variations;
      break;
    default:
      showHide = true;
      break;
  }
  return showHide === null || showHide === undefined ? false : showHide;
}

export function TableCellShading(column) {
  switch (column) {
    case COLREVISEDCONTRACTVALUE:
    case COLADJUSTEDBUDGET:
    case COLANTICIPATEDVARIATIONS:
    case COLCOSTTOCOMPLETE:
    case COLCHANGEINPERIOD:
    case COLVARIANCEADJUSTEDBUDGET:
      return "cost-report-table-shaded-cell";
    case COLFORECASTTOTALCOST:
      return "cost-report-table-darkshaded-cell";
    default:
      break;
  }
}

export function getRRsTotals(RRs) {
  const { totals } = generateRiskRegisterTypes(RRs);
  rrTotals = checkUndefined(totals) === 0 ? {} : totals;
}

export function getTaxTotalValue(
  tax,
  total,
  subtotal,
  CR,
  valueToBeInjToCell,
  totalColumnFieldValue
) {
  if (tax) {
    valueToBeInjToCell = (valueToBeInjToCell * CR.tax_percent) / 100;
  } else {
    if (total && !subtotal) {
      valueToBeInjToCell =
        totalColumnFieldValue * ((CR.tax_percent + 100) / 100);
    }
  }
  return valueToBeInjToCell;
}

export function GetExpenditureColumnValues(
  CRObjectKey,
  valueToBeInjToCell,
  subtotalablecolumn,
  totalablecolumn,
  total,
  subtotal,
  tax,
  tax_percent
) {
  if (CRObjectKey === "forecast_cost_excl_risk_tax") {
    valueToBeInjToCell = subtotalablecolumn + totalablecolumn;
  } else {
    valueToBeInjToCell = totalablecolumn;
    if (tax) {
      valueToBeInjToCell = (valueToBeInjToCell * tax_percent) / 100;
    } else {
      if (total && !subtotal) {
        valueToBeInjToCell = totalablecolumn * ((tax_percent + 100) / 100);
      }
    }
  }
  return valueToBeInjToCell;
}

/// code refactoring

export function CodeRefactoring(SELECTEDCR, project) {
  const record = {
    category: "",
    description: "",
    originalbudget: 0,
    budgetadjustments: 0,
    adjustedbudget: 0,
    contractsum: 0,
    approvedvariations: 0,
    revisedcontractvalue: 0,
    submittedvariations: 0,
    forecastvariations: 0,
    adjustedprovisionalitems: 0,
    anticipatedvariations: 0,
    forecasttotalcost: 0,
    variancetoadjustedbudget: 0,
    previouslypaid: 0,
    paidthismonth: 0,
    totalexpendituretodate: 0,
    costtocomplete: 0,
    previousforecasttotalcost: 0,
    changeinperiod: 0,
    settings: {
      visible: "true",
      colored: "",
      clcickable: "",
      url: "",
    },
  };

  const allrows = CONSTRUCTIONCOSTROWS.concat(
    CLIENTCOSTROWS.concat(CONTINGENCYROWS)
  );

  const records = [];
  const CR = SELECTEDCR.cost_report;
  allrows.forEach((row) => {
    const rec = Object.create(record);
    rec.description = checkUndefined(CR[`${row.CRObjectKey}_label`]);
    rec.contractsum = checkUndefined(CR[`${row.CRObjectKey}_contract_sum`]);
    rec.approvedvariations = checkUndefined(CR[`${row.CRObjectKey}_approved`]);
    rec.submittedvariations = checkUndefined(
      CR[`${row.CRObjectKey}_submitted`]
    );
    rec.forecastvariations = checkUndefined(CR[`${row.CRObjectKey}_forecast`]);
    rec.adjustedprovisionalitems = checkUndefined(
      CR[`${row.CRObjectKey}_adjustment`]
    );
    rec.originalbudget = checkUndefined(
      CR[`${row.CRObjectKey}_original_budget`]
    );
    rec.budgetadjustments = checkUndefined(
      CR[`${row.CRObjectKey}_adjustment_budget`]
    );
    rec.adjustedbudget = checkUndefined(
      CR[`${row.CRObjectKey}_adjusted_budget`]
    );
    rec.anticipatedvariations = checkUndefined(
      CR[`${row.CRObjectKey}_anticipated_variations`]
    );
    rec.forecasttotalcost = checkUndefined(
      CR[`${row.CRObjectKey}_forecast_total`]
    );
    rec.variancetoadjustedbudget = checkUndefined(
      CR[`${row.CRObjectKey}_variance_toadjusted_Budget`]
    );
    const sett = Object.create(record.settings);
    sett.colored = "red";
    sett.visible = showHideRow(row.CRObjectKey, project);
    sett.clcickable = true;
    sett.url = "costclarity.com";
    rec.settings = sett;
    records.push(rec);
  });
  console.log(JSON.stringify(records));
}
