import React from "react";
import generateXLSX from "../../utils/spreadsheets";

import { commaWithNaN } from "../../helpers/common.functions";

export default function ExportCashflow(props) {
  const { cashflow } = props;

  let rows = [
    [
      "Date",
      "Forecast Monthly Cost",
      "Forecast Cumulative",
      "Contractor Monthly",
      "Contractor Cumulative",
      "Actual Monthly",
      "Actual Cumulative",
    ],
  ];

  cashflow.data.forEach((row) => {
    rows.push([
      row.date,
      // Forecast
      commaWithNaN(Math.round(row.forecastMonthly)),
      commaWithNaN(Math.round(row.forecastCumulative)),
      // Contractor
      commaWithNaN(Math.round(row.contractorMonthly)),
      commaWithNaN(Math.round(row.contractorCumulative)),
      // Actuals
      commaWithNaN(Math.round(row.actualMonthly)),
      commaWithNaN(Math.round(row.actualCumulative)),
    ]);
  });

  const totalForecast = commaWithNaN(Math.round(cashflow.totalForecast));
  const totalContractor = commaWithNaN(Math.round(cashflow.totalContractor));
  const totalActuals = commaWithNaN(Math.round(cashflow.totalActuals));

  rows.push(["Total", totalForecast, "", totalContractor, "", totalActuals]);

  return (
    <div className="cashflow-save-button-container">
      <button
        className="cashflow-save-button"
        onClick={() => {
          generateXLSX("Cashflow Data", rows);
        }}
      >
        <i className="fas fa-download"></i>
      </button>
      <span className="tooltiptext">Export Table</span>
    </div>
  );
}
