// React
import React, { useState } from "react";

// Components
import Table from "../table/Table";
import Modal from "../modal/Modal";
import PostContractEnablingWorksVariationsModal from "../post-contract-table-modals/PostContractEnablingWorksVariationsModal";

// Functions
import { EWVColumns } from "../post-contract-tables/PostContractTable.functions";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

export default function PostContractEnablingWorksVariations(props) {
  const { EWV } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  // No data available so return null
  if (isEmpty(EWV)) {
    return null;
  }

  return (
    <div>
      <div className="pct-spacer" />
      <Table
        title={"Enabling Works Variations"}
        tableArray={EWV}
        columns={EWVColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}
      />
      <Modal
        // Modal Props
        title={translate("User Profile")}
        Component={PostContractEnablingWorksVariationsModal}
        modal={modal}
        setModal={setModal}
        columns={EWVColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
    </div>
  );
}
