import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../../api/api-endpoints/SharedServiceEndpoints";
export async function deleteCompany(data) {
  let result = window.confirm(
    `This will permanently delete this company. Confirm?`
  );

  if (!result) {
    return null;
  }

  const url = sharedAPIs().delete_company + "/" + data.companyID;

  const config = {
    method: "DELETE",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }
  if (response.ok) {
    data.setShow(false);
  } else {
    console.log("Delete Error");
  }
  data.companies.set(await data.companies.get());
}
