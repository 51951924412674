// APIs
import { getTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { sharedAPIs } from "../../../src/api/api-endpoints/SharedServiceEndpoints";

import { authHeader } from "../../_helpers/auth-header";

export async function uploadTPI(
  file,
  setError,
  setShow,
  setTPI,
  setFilePreview,
  region,
  country
) {
  if (region === "") {
    return setError({
      text: "Please complete Region",
      type: "feedback-error",
    });
  }

  if (country === "") {
    return setError({
      text: "Please complete Country",
      type: "feedback-error",
    });
  }

  if (file === null) {
    return setError({
      text: "Please attach a file",
      type: "feedback-error",
    });
  }

  setError({
    text: "Creating...",
    type: "feedback-success",
  });

  const field = JSON.stringify({
    region: region,
    country: country,
    timestamp: Date.now(),
  });
  const formData = new FormData();
  formData.append("file", file);
  formData.append("field", field);

  const url = sharedAPIs().upload_tpi;
  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({
      authJson: false,
      authForm: true,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    setError({
      text: response.message,
      type: "feedback-error",
    });
  }

  setError({
    text: response.message,
    type: "feedback-success",
  });

  setTPI(await getTPI(region, country));
  setError({});
  setFilePreview(null);
  setShow(false);
}

export async function deleteTPI(setTPI, region, country) {
  let result = window.confirm(
    `This will permanently clear the TPI records for ${country}. Confirm?`
  );

  if (!result) {
    return null;
  }

  const payload = {
    region: region,
    country: country,
  };

  const url = sharedAPIs().delete_tpi;

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = await fetch(url, config);
  if (response.ok) {
    setTPI(await getTPI(region, country));
  } else {
    return console.log("Network response was not ok.");
  }
}
