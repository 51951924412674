import React, { useEffect, useState } from "react";

import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";

import "./CostPlanRevisionSelector.css";

import { PCRAStatusSummary } from "../pcra/PCRAStatusSummary";

export default function CostPlanRevisionSelector(props) {
  const { CPs } = props;
  const { CP, setCP } = props;
  const { setComparedCPR } = props;
  const { dashboardType } = props;

  const [versions, setVersions] = useState([]);
  const [CPVersions, setCPVersions] = useState(null);

  // Identify the Versions available
  useEffect(() => {
    if (!isEmpty(CP)) {
      const tempVersions = [];
      CPs.data.forEach((CPStage, i) => {
        if (!isEmpty(CPStage.versions)) {
          if (CP.stage === CPStage.stage) {
            setCPVersions(CPStage.versions);
            CPStage.versions.forEach((v) => {
              tempVersions.push(v.version);
            });
          }
        }
      });
      setVersions(tempVersions);
    }
  }, [CP, CPs.data]);

  const displayRevisions = [];

  versions.forEach((version, i) => {
    const selectableCP = CPVersions[i];
    let selectableComparisonCP = CPVersions[i + 1];

    if (isEmpty(selectableComparisonCP)) {
      selectableComparisonCP = {};
    }

    displayRevisions.push(
      <Box
        key={i}
        version={version}
        CP={CP}
        setCP={setCP}
        selectableCP={selectableCP}
        selectableComparisonCP={selectableComparisonCP}
        setComparedCPR={setComparedCPR}
      />
    );
  });

  // Dashboard Page
  if (
    window.location.href.includes("dashboard") &&
    dashboardType !== "COST_PLANNING"
  ) {
    return null;
  }

  // Cost Planning Page
  if (
    !window.location.href.includes("dashboard") &&
    !window.location.href.includes("cost-planning") &&
    dashboardType !== "CORRESPONDENCE"
  ) {
    return null;
  }

  if (isEmpty(displayRevisions)) {
    return (
      <div className="cost-plan-revision-selector">
        <div className="cost-plan-revision-selector-bar">
          <div className="cost-plan-revision-selector-label"></div>
        </div>
      </div>
    );
  }

  displayRevisions.reverse();

  return (
    <div className="cost-plan-revision-selector">
      <div className="cost-plan-revision-selector-bar">
        <div className="cost-plan-revision-selector-label">
          {translate("Release")}
        </div>
        {displayRevisions}
      </div>
    </div>
  );
}

function Box(props) {
  const { version } = props;
  const { CP, setCP } = props;
  const { selectableCP } = props;
  const { selectableComparisonCP } = props;
  const { setComparedCPR } = props;

  const [showPCRAStatusSummary, setShowPCRAStatusSummary] = useState(false);
  const pcraLog = selectableCP.pcra_log;

  let boxType = "cost-plan-revision-selector-box";
  if (!isEmpty(CP) && CP.version === version) {
    boxType += " current-revision";
  }

  if (pcraLog && !pcraLog.isPublished) {
    boxType += " DRAFT";
  }

  return (
    <div
      className={boxType}
      onClick={() => {
        setCP(selectableCP);
        setComparedCPR(selectableComparisonCP);
      }}
      onMouseOver={() => setShowPCRAStatusSummary(true)}
      onMouseOut={() => setShowPCRAStatusSummary(false)}
    >
      <div>{version}</div>
      {showPCRAStatusSummary && <PCRAStatusSummary pcraLog={pcraLog} />}
    </div>
  );
}
