import React from "react";
import isEmpty from "../../validation/is-empty";
import { generateChangeTypeData } from "../post-contract-variations/PostContractVariations.functions";
import { translate } from "../../utils/translation";
import "./PDPCTableChangeTypeSummary.css";
import { commaToFixed } from "../../helpers/common.functions";

export default function PDPCTableChangeTypeSummary(props) {
  const { variations } = props;

  // No Variations return empty
  if (isEmpty(variations)) {
    return null;
  }
  let changeTypeData = generateChangeTypeData(variations);

  return (
    <div className="pccts-content">
      <TitleRow />
      <ChangeType
        data={changeTypeData}
        objKey={"Change_Type"}
        title={"Change Type"}
      />
    </div>
  );
}

function TitleRow() {
  return (
    <div className="pccts-row">
      <div className="pccts-description-cell">
        <b>{translate("Change Type")}</b>
      </div>
      <div className="pccts-value-cell-heading">
        <b>{translate("Count")}</b>
      </div>
      <div className="pccts-value-cell-heading">
        <b>{translate("Value")}</b>
      </div>
    </div>
  );
}

function ChangeType(props) {
  const { data } = props;

  return data.map((d, i) => {
    return (
      <div className="pccts-row" key={i}>
        <div className="pccts-description-cell">
          <div></div>
          {d.name}
        </div>
        <div className="pccts-count-cell">
          <div></div>
          {d.counts}
        </div>
        <div className="pccts-value-cell">
          <div></div>
          {commaToFixed(d.value)}
        </div>
      </div>
    );
  });
}
