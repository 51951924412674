import isEmpty from "../../validation/is-empty";
import zoomIn from "../../img/zoom-in.png";
import zoomOut from "../../img/zoom-out.png";
import { comma, commaToFixed } from "../../helpers/common.functions";

export function AccordionTotal(props) {
  const { total } = props;

  let displayTotal = "";
  if (total !== undefined) {
    displayTotal = comma(total);
  }

  return <div className="accordion-total">{displayTotal}</div>;
}

export function AccordionRates(props) {
  const { local_region_area } = props;
  const { unit } = props;
  const { rate } = props;

  if (
    isEmpty(local_region_area) ||
    isEmpty(unit) ||
    isEmpty(rate) ||
    local_region_area === 0
  ) {
    return null;
  }

  return (
    <div className="accordion-rates">
      <div className="accordion-local-region-area">
        {comma(local_region_area)}
      </div>
      <div className="accordion-unit">{unit}</div>
      <div className="accordion-rate">{commaToFixed(rate)}</div>
    </div>
  );
}

export function AccordionDescription(props) {
  const { show } = props;
  const { label } = props;

  let icon = zoomIn;
  if (show) {
    icon = zoomOut;
  }

  return (
    <div className="accordion-description">
      <div className="accordion-label">{label}</div>
      <img className="accordion-icon" alt="upload" src={icon} />
    </div>
  );
}
