import { authHeader } from "../../_helpers/auth-header";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function costPlanningBenchmarkCreate(data) {
  const { setErrors, setShow } = data;
  const { payload } = data;
  const { url } = data;
  const { mappingFunction } = data;

  setErrors((errors) => {
    return { ...errors, saving: "Creating..." };
  });

  console.log("payload", payload);

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
    console.log("response", response);
    // Map the new Benchmark to the selected Estimate
    await mappingFunction(response.data);

    setErrors((errors) => {
      return { ...errors, saving: "Benchmark Created" };
    });

    // close modal after timeout
    AutoCloseModal(setShow);
  } else {
    response = await response.json();
    console.log("response", response);

    setErrors(() => {
      return { ...response.errors, saving: "Benchmark Failed" };
    });
  }
}
