import React from "react";
import { comma } from "../../helpers/common.functions";
import { Bar } from "react-chartjs-2";
import { translate } from "../../utils/translation";

export default function CostCategories(props) {
  const { costCategories } = props;

  // Create the graph object
  let graph = {
    labels: costCategories.projectTitles,
    datasets: costCategories.graphData,
    text: costCategories.projectTitles,
  };

  // Create the options object
  let options = {
    animation: {
      duration: 3000,
    },
    plugins: { datalabels: { display: false } },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, chart) {
          return (
            comma(Math.round(tooltipItem.yLabel)) +
            " (" +
            chart.datasets[tooltipItem.datasetIndex].label +
            ")"
          );
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          position: "left",
          stacked: true,
          ticks: {
            callback: function (value, index, values) {
              return comma(Math.round(value));
            },
          },
        },
      ],
    },

    legend: {
      display: true,
      position: "right",
      fullWidth: true,
      reverse: true,
    },
  };

  return (
    <div>
      <div className="cost-report-comparison-chart-hint">
        {translate("Data sets can be toggled when clicked in the legend.")}
      </div>
      <Bar data={graph} options={options} />
    </div>
  );
}
