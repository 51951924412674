import React from "react";

import {
    // Description,
    OneValueRow,
    DocumentLink,
} from "./PostContractModal.components";


import "./PostContractTableModal.css";

export default function PostContractContingencyModal(props) {
    const { selectedRow } = props;
    const R = selectedRow;

    return (
        <div className="pctm">
            <div className="pctm-container-row">
                <OneValueRow heading1={"Description"} value1={R.description} />
            </div>
            <div className="pctm-container-row">
                <OneValueRow heading1={"Action With"} value1={R.action} />
            </div>
            <div className="pctm-container-row">
                <OneValueRow heading1={"Comments"} value1={R.comments} />
            </div>
            <div className="pctm-container-row">
                <DocumentLink R={R} />
            </div>
        </div>
    );
}
