export function selectCostPlan(cp, selector) {
  // based on the currently selected stage
  return cp.estimates.filter((e) =>
    e.stage + "." + e.revision === selector ? e : null
  );
}

export function determineSelection() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
