import React from "react";

import { hasRoles } from "../../utils/roles";

import PCRALogModalTrigger from "../pcra/PCRALogModalTrigger";
import VariationsPCRALogModal from "./VariationsPCRALogModal";

export default function VariationsPCRALogModalTrigger(props) {
  const { user } = props;
  const { project } = props;
  const { postContract } = props;
  const { variations } = props;
  const { isLocked, lockedReason } = props;

  // Hide if not Cost Report
  let subLocation = determineSubLocation();
  if (subLocation !== "variations") {
    return null;
  }

  if (!hasRoles(user.roles, ["CostManager", "Client"])) {
    return null;
  }

  return (
    <PCRALogModalTrigger
      modalComponent={VariationsPCRALogModal}
      projectID={project.id}
      project={project}
      postContract={postContract}
      variations={variations}
      user={user}
      isLocked={isLocked}
      lockedReason={lockedReason}
    />
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
