import React from "react";
import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import OptionsAnalysisCostBaselineForm from "./OptionsAnalysisCostBaselineForm";

export default function OptionsAnalysisCostBaselineModal(props) {
  const { CPs } = props;
  const { modal, setModal } = props;
  const { project, getOptions, setOptions } = props;
  const { setCostBaselineValue, activeStage } = props;

  if (isEmpty(project)) {
    return null;
  }

  return (
    <Modal
      Component={OptionsAnalysisCostBaselineForm}
      title={translate("Add Options Cost Baseline")}
      modal={modal}
      setModal={setModal}
      setShow={setModal}
      show={modal}
      CPs={CPs}
      project={project}
      setCostBaselineValue={setCostBaselineValue}
      activeStage={activeStage}
      getOptions={getOptions}
      setOptions={setOptions}
    />
  );
}
