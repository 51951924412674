import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";

export default function ProjectNavigationDashboard(props) {
  const { projectID } = props;

  let name = "project-navigation-section";

  if (window.location.href.includes("dashboard")) {
    name = "project-navigation-section-selected";
  }

  return (
    <Link to={`/project/${projectID}/`} className="project-navigation-tab-link">
      <div className={name}>
        <div className="project-navigation-label">
          <div className="front-icon">
            <div className="icon-container">
              <i
                className="far fa-chart-bar"
                style={{ marginRight: "10px" }}
              ></i>
            </div>
            {translate("Dashboard")}
          </div>
        </div>
      </div>
    </Link>
  );
}
