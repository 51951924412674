import moment from "moment";
import isEmpty from "../../validation/is-empty";

export function processTime(dataArray, project) {
  if (!isEmpty(dataArray)) {
    dataArray.forEach((PC) => {
      // Cost Report
      if (!isEmpty(PC.cost_report)) {
        const cr_date = PC.cost_report.date_work_completed_to;
        const cr_time = processTimeDifference(cr_date, project);
        PC.cost_report.time = cr_time;
        PC.cost_report.work_completed_to_date = processDate(
          PC.cost_report.date_work_completed_to
        );
      }
      // Progress Claim
      if (!isEmpty(PC.progress_claim)) {
        const pc_date = PC.progress_claim.date_work_completed_to;
        const pc_time = processTimeDifference(pc_date, project);
        PC.progress_claim.time = pc_time;
        PC.progress_claim.work_completed_to_date = processDate(
          PC.progress_claim.date_work_completed_to
        );
        PC.progress_claim.submission_date = processDate(
          PC.progress_claim.date_submitted
        );
        PC.progress_claim.recommendation_date = processDate(
          PC.progress_claim.date_recommended
        );
      }
    });
  }
}

function processTimeDifference(date, project) {
  const selectedMonth = project.post_contract_start_month;
  const selectedYear = project.post_contract_start_year;
  const programMonths = project.post_contract_duration;

  let startDate = new Date(selectedMonth + "/1/" + selectedYear).getTime();

  let currentDate = new Date(date);

  const dateStart = moment(startDate);
  const dateNow = moment(currentDate);
  const dateEnd = moment(startDate).add(programMonths, "months");

  const total = dateEnd.diff(dateStart, "months");
  const elapsed = dateNow.diff(dateStart, "months") + 1;
  const remaining = total - elapsed;

  let total_months = total;
  if (isNaN(total_months)) {
    total_months = "N/A";
  } else {
    total_months = total_months + " Months";
  }

  let elapsed_percent = Math.round((elapsed / total) * 100);
  if (isNaN(elapsed_percent)) {
    elapsed_percent = "N/A";
  } else {
    elapsed_percent = elapsed_percent + "%";
  }

  let remaining_percent = Math.round((remaining / total) * 100);
  if (isNaN(remaining_percent)) {
    remaining_percent = "N/A";
  } else {
    remaining_percent = remaining_percent + "%";
  }

  return {
    total_months_number: total,
    total_months: total_months,
    elapsed: elapsed,
    elapsed_percent: elapsed_percent,
    remaining: remaining,
    remaining_percent: remaining_percent,
  };
}

function processDate(value) {
  return {
    day: new Date(value).toLocaleString("default", {
      day: "numeric",
    }),
    month: new Date(value).toLocaleString("default", {
      month: "short",
    }),
    year: new Date(value).toLocaleString("default", {
      year: "numeric",
    }),
  };
}
