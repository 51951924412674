import React from "react";
import "./MetricItem.css";
import { comma } from "../../helpers/common.functions";

export default function MetricItem(props) {
  const { value, text, type } = props;

  let classType = "";
  if (type === 1) {
    classType = "metric-item-box1";
  }

  if (type === 2) {
    classType = "metric-item-box2";
  }

  if (type === 3) {
    classType = "metric-item-box3";
  }

  let displayValue = "";

  if (!isNaN(value)) {
    displayValue = comma(Math.round(value));
  }

  return (
    <div className="metric-item-item">
      <div className={classType}>
        <b>{displayValue}</b>
      </div>
      <div className="metric-item-label">{text}</div>
    </div>
  );
}
