import isEmpty from "../../validation/is-empty";

export function displayDate(CP) {
  if (isEmpty(CP)) {
    return null;
  }

  if (isEmpty(CP.estimates[0])) {
    return null;
  }

  let date = new Date(CP.estimates[0].date);
  return formatDate(date);
}

export function displayReportDate(reportDate) {
  if (isEmpty(reportDate)) {
    return null;
  }
  let date = new Date(reportDate);
  return formatDate(date);
}

function formatDate(date) {
  let day = date.toLocaleString("default", { day: "numeric" });
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.toLocaleString("default", { year: "numeric" });

  return day + " " + month + " " + year;
}
