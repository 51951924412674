import React from "react";

export default function PCRABanner(props) {
  const { icon, text } = props;

  return (
    <div className="pcra-banner">
      <div className="pcra-banner-content">
        <i className={icon} />
        <div className="text">{text}</div>
      </div>
    </div>
  );
}
