import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import "./ProgressClaimReconciliation.css";
import { commaToFixed } from "../../helpers/common.functions";

export default function ProgressClaimReconciliation(props) {
  const { PC } = props;

  return (
    <div className="progress-claim-panel">
      <div className="pcr-content">
        <div className="pcr-row">
          <div className="pcr-title-cell1"></div>
          <b>
            <div className="pcr-title-cell2">{translate("Recommended")}</div>
          </b>
          <b>
            <div className="pcr-title-cell2">{translate("Claim")}</div>
          </b>
          <b>
            <div className="pcr-title-cell2">{translate("Difference")}</div>
          </b>
        </div>
        <Row
          description={translate("Construction Work Complete")}
          recommendation={PC.construction_work_complete}
          claim={PC.contractor_construction_work_complete}
        />
        <Row
          description={translate("Variations Work Complete")}
          recommendation={PC.variations_work_complete}
          claim={PC.contractor_variations_work_complete}
        />

        <Row
          description={translate("Provisional Quantities Work Complete")}
          recommendation={PC.prov_quants_work_complete}
          claim={PC.contractor_prov_quants_work_complete}
        />
        <Row
          description={translate("Provisional Sums Work Complete")}
          recommendation={PC.prov_sums_work_complete}
          claim={PC.contractor_prov_sums_work_complete}
        />
        <Row
          description={translate("Provisional Delays Work Complete")}
          recommendation={PC.extensions_of_time_work_complete}
          claim={PC.contractor_extensions_of_time_work_complete}
        />
        <RowSubTotal
          description={translate("Gross Total of Work Executed")}
          recommendation={PC.gross_total_work_executed_excl_tax}
          claim={PC.contractor_gross_total_work_executed_excl_tax}
        />
        <Row
          description={translate("Deduct Retention")}
          recommendation={PC.deduct_security}
          claim={PC.contractor_deduct_security}
        />
        <Row
          description={translate("Deduct Amount Previously Certified")}
          recommendation={PC.amount_previously_certified}
          claim={PC.contractor_amount_previously_certified}
        />
        <RowSubTotal
          description={translate("Subtotal of Work Executed")}
          recommendation={PC.subtotal_work_executed_excl_tax}
          claim={PC.contractor_subtotal_work_executed_excl_tax}
        />
        <Row
          description={
            <div>
              {translate("Add")}
              {isEmpty(PC.tax_percent) ? " 0% " : PC.tax_percent + "% "}
              {isEmpty(PC.tax_type) ? " tax" : " " + PC.tax_type}
            </div>
          }
          recommendation={PC.add_tax}
          claim={PC.contractor_add_tax}
        />
        <RowTotal
          description={
            <div>
              {translate("Recommended Payment Incl")}
              {isEmpty(PC.tax_type) ? " tax" : " " + PC.tax_type}
            </div>
          }
          recommendation={PC.recommended_payment}
          claim={PC.contractor_recommended_payment}
        />
      </div>
    </div>
  );
}

function Row(props) {
  let { description, recommendation, claim } = props;
  if (isNaN(claim)) {
    claim = 0;
  }
  if (isNaN(recommendation)) {
    recommendation = 0;
  }

  let difference = recommendation - claim;

  return (
    <div className="pcr-row">
      <div className="pcr-description-cell">{description}</div>
      <div className="pcr-value-cell">
        <div></div>
        {commaToFixed(recommendation)}
      </div>
      <div className="pcr-value-cell">
        <div></div>
        {commaToFixed(claim)}
      </div>
      <div className="pcr-value-cell">
        <div></div>
        {commaToFixed(difference)}
      </div>
    </div>
  );
}

function RowSubTotal(props) {
  let { description, recommendation, claim } = props;
  if (isNaN(claim)) {
    claim = 0;
  }
  if (isNaN(recommendation)) {
    recommendation = 0;
  }
  let difference = recommendation - claim;

  return (
    <div className="pcr-row-subtotal">
      <b>
        <div className="pcr-description-cell">{description}</div>
      </b>
      <div className="pcr-value-cell">
        <b>
          <div></div>
        </b>
        <b>{commaToFixed(recommendation)}</b>
      </div>
      <div className="pcr-value-cell">
        <b>
          <div></div>
        </b>
        <b>{commaToFixed(claim)}</b>
      </div>
      <div className="pcr-value-cell">
        <b>
          <div></div>
        </b>
        <b> {commaToFixed(difference)}</b>
      </div>
    </div>
  );
}

function RowTotal(props) {
  let { description, recommendation, claim } = props;
  if (isNaN(claim)) {
    claim = 0;
  }
  if (isNaN(recommendation)) {
    recommendation = 0;
  }
  let difference = recommendation - claim;

  return (
    <div className="pcr-row-total">
      <b>
        <div className="pcr-description-cell">{description}</div>
      </b>
      <div className="pcr-value-cell">
        <b>
          <div></div>
        </b>
        <b>{commaToFixed(recommendation)}</b>
      </div>
      <div className="pcr-value-cell">
        <b>
          <div></div>
        </b>
        <b>{commaToFixed(claim)}</b>
      </div>
      <div className="pcr-value-cell">
        <b>
          <div></div>
        </b>
        <b> {commaToFixed(difference)}</b>
      </div>
    </div>
  );
}
