import React from "react";
import PropTypes from "prop-types";

import "./BenchmarkingDisclaimer.css";

import { FormError, AcceptForm } from "./BenchmarkCreate.components";
import BenchmarkingLimitationsData from "../benchmarking-project/BenchmarkingLimitationsData";

/// props validation | SQ(javascript:S6774)
BenchmarkingDisclaimer.propTypes = {};
///
export default function BenchmarkingDisclaimer(props) {
  return (
    <div className="benchmarking-disclaimer">
      <BenchmarkingDisclaimerText {...props} />
      <BenchmarkingDisclaimerAcceptance {...props} />
      <FormError {...props} />
      <AcceptForm {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkingDisclaimerText.propTypes = {};
///
function BenchmarkingDisclaimerText(props) {
  return <BenchmarkingLimitationsData />;
}

/// props validation | SQ(javascript:S6774)
BenchmarkingDisclaimerAcceptance.propTypes = {
  disclaimerAccepted: PropTypes.bool,
  onDisclaimerAcceptedChange: PropTypes.func,
};
///
function BenchmarkingDisclaimerAcceptance(props) {
  const { disclaimerAccepted } = props;
  const { onDisclaimerAcceptedChange } = props;

  const id = "benchmarking-disclaimer-acceptance-checkbox";

  return (
    <div className="benchmarking-disclaimer-acceptance">
      <input
        type="checkbox"
        id={id}
        checked={disclaimerAccepted}
        onChange={() =>
          onDisclaimerAcceptedChange({
            disclaimerAccepted: !disclaimerAccepted,
          })
        }
      />
      <label htmlFor={id}>
        I have read and understood the limitations associated with the benchmark
        data presented.
      </label>
    </div>
  );
}
