export function CbsName(props) {
  const { get } = props;
  const { set } = props;

  return (
    <input
      className="cbs-input-field"
      value={get}
      onChange={(e) => {
        set(e.target.value);
      }}
    ></input>
  );
}
