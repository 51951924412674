import React from "react";
import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

import "./PDCPMetric.css";

export default function PDCPMetrics(props) {
  const { allMetrics } = props;
  const { CP } = props;

  if (isEmpty(allMetrics)) {
    return null;
  }

  let metrics = allMetrics.filter(
    (m) => m.stage + "." + m.revision === CP.stage + "." + CP.version
  );

  if (isEmpty(metrics)) {
    return null;
  }

  let display = [];

  metrics.forEach((metric, i) => {
    display.push(
      <div
        key={metric.id}
        className="project-dashboard-estimate-metric-display"
      >
        <div className={"project-dashboard-estimate-metric-display-value" + i}>
          <div className="project-control-estimate-metric-display-quantity">
            <b>{comma(metric.quantity)}</b>
          </div>
          <div className="project-control-estimate-metric-display-unit">
            {metric.unit}
          </div>
        </div>
        <div className="project-dashboard-estimate-metric-display-metric">
          {metric.metric}
        </div>
      </div>
    );
  });

  return (
    <div className="project-dashboard-estimate-metric-column">{display}</div>
  );
}
