import React, { useState } from "react";
import { translate } from "../../utils/translation";
import OptioneeringControlDrawer from "./OptioneeringControlDrawer";
import OptioneeringControlComponent from "./OptioneeringControlComponent";
import OptionsAnalysisUploadModal from "./OptionsAnalysisUploadModal";

// Style
import "./OptioneeringControlPanel.css";

export default function OptioneeringControl(props) {
  const { user, project, CPs, CP } = props;
  const { auth } = props;
  const [showOptionsControl, setShowOptionsControl] = useState(false);
  const { dashboardType } = props;

  const { options } = props;

  if (!window.location.href.includes("optioneering")) {
    return null;
  }

  return (
    <div className="project-control-panel">
      <OptioneeringControlDrawer
        Component={OptioneeringControlComponent}
        dashboardType={dashboardType}
        dashboardSelect={"OPTIONEERING"}
        user={auth.user}
        label={translate("Options Control")}
        show1={showOptionsControl}
        setShow1={setShowOptionsControl}
        {...props}
      />
      <OptionsAnalysisUploadModal
        user={user}
        project={project}
        CPs={CPs}
        CP={CP}
        options={options}
        {...props}
      />
    </div>
  );
}
