import { tranStr } from "../../utils/translation";

export function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toString().toLowerCase()).includes(
        filter.value.toString().toLowerCase()
      )
    : true;
}

export function columns() {
  return [
    {
      type: "CHECK",
      key: "id",
      width: 50,
      isModal: false,
    },
    {
      heading: tranStr("Ref"),
      key: "ref",
      type: "NUMBER",
      width: 50,
      isFiltered: false,
    },
    {
      heading: tranStr("Description"),
      key: "description",
      type: "DESCRIPTION",
      width: 711,
      isFiltered: true,
    },
  ];
}
