import { cpAPIs } from "../api-endpoints/CostPlanningEndpoints";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function saveCostPlanBudget(fields, data) {
  const { CP } = data;
  const { CPs } = data;
  const { projectID } = data;
  const { project } = data;

  const { setError } = data;

  fields.forEach((f) => {
    f.stage = CP.stage;
    f.revision = CP.version;
    delete f.id;
    delete f.CostAllocations;
  });

  const payload = {
    budget: fields,
    projectID: projectID,
    stage: CP.stage,
    revision: CP.version,
    cost_plan_id: CP.cost_plan_id,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  setError({
    text: tranStr("Saving..."),
    type: "feedback-success",
  });

  const url = cpAPIs().save_budget;

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    CPs.set(await CPs.get(projectID, project));

    setError({
      text: tranStr("Saved Successfully"),
      type: "feedback-success",
    });

    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    setError({
      text: tranStr("Save Failed"),
      type: "feedback-error",
    });
  }
}
