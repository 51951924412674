import React from "react";

import { commaToFixed } from "../../helpers/common.functions";
import { translate } from "../../utils/translation";

import "./CostReportBudgetSummary.css";

export default function CostReportBalanceOfContingency(props) {
  const { CR } = props;

  return (
    <div className="crfcs-content">
      <TitleRow />
      <Row
        title={translate("Forecast Total Cost", {
          tax: CR.tax_type,
        })}
        CR={CR}
      />
    </div>
  );
}

function TitleRow() {
  return (
    <div className="crfcs-title-row">
      <div className="crfcs-description-cell"></div>
      <div className="crfcs-value-cell-heading">
        <b>{translate("Adjusted Budget")}</b>
      </div>
      <div className="crfcs-value-cell-heading">
        <b>{translate("Forecast Total")}</b>
      </div>
      <div className="crfcs-value-cell-heading">
        <b>{translate("Headroom")}</b>
      </div>
    </div>
  );
}

function Row(props) {
  const { CR } = props;
  const { title } = props;

  return (
    <div className="crfcs-total-row">
      <div className="crfcs-description-cell">
        <b>{title}</b>
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(CR.forecast_cost_excl_tax_adjusted_budget)}</b>
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(CR.forecast_cost_excl_tax_forecast_total)}</b>
      </div>
      <div className="crfcs-value-cell">
        <div></div>
        <b>{commaToFixed(CR.forecast_cost_excl_tax_contingency)}</b>
      </div>
    </div>
  );
}
