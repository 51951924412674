import React, { useState } from "react";
import { useFeedback } from "../../api/admin/FeedbackAPI";

import { columns } from "./FeedbackManagement.functions";

import "./FeedbackManagement.css";

import {
  FeedbackControl,
  FeedbackDisplay,
  AdminNoteDisplay,
} from "./FeedbackManagement.components";

import Spinner from "../common/Spinner";
import Table from "../table/Table";
import { tranStr } from "../../utils/translation";

export default function FeedbackManagement() {
  const { feedback, feedbackLoading, setFeedback } = useFeedback();

  const [selected, setSelected] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const [modal, setModal] = useState(false);

  if (feedbackLoading) {
    return (
      <div className="project-management-content">
        <Spinner marginLeft={"625px"} marginTop={"200px"} />
      </div>
    );
  }

  return (
    <div>
      <Table
        title={tranStr("Feedback")}
        tableArray={feedback}
        columns={columns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selected}
        setChecked={setSelected}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        modal={modal}
        setModal={setModal}
      />
      <div className="feedback-control-container">
        <FeedbackControl selected={selected} setFeedback={setFeedback} />
        <FeedbackDisplay selected={selected} />
        <AdminNoteDisplay selected={selected} setFeedback={setFeedback} />
      </div>
    </div>
  );
}
