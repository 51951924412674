import { AutoCloseModal } from "../modal/Modal";
import { authHeader } from "../../_helpers/auth-header";
import { tranStr } from "../../utils/translation";

export function updateField(field, value, i, array, setFields) {
  let temp = [...array];
  temp[i][field] = value;
  setFields(temp);
}

export function deleteField(i, array, setFields) {
  let temp = [...array];

  temp.splice(i, 1);
  setFields(temp);
}

export async function saveFunction(data) {
  const { projectID } = data;
  const { setError } = data;
  const { CPs } = data;
  const { project } = data;
  const { setModal } = data;
  const { url, payload } = data;

  const config = {
    method: "POST",
    body: payload,
    headers: authHeader({
      authJson: true,
      authForm: false,
      guestJson: false,
    }),
  };

  setError({
    text: tranStr("Saving..."),
    type: "project-stages-success",
  });

  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    CPs.set(await CPs.get(projectID, project));

    setError({
      text: tranStr("Saved Successfully"),
      type: "project-stages-success",
    });

    AutoCloseModal(setModal);
  } else {
    // Response Not OK
    setError({
      text: tranStr("Save Failed"),
      type: "project-stages-error",
    });
  }
}
