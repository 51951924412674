import React from "react";
import generateXLSX from "../../utils/spreadsheets";

import { commaWithNaN } from "../../helpers/common.functions";

export default function ExportCashflowCostPlanning(props) {
  const { cashflow, total } = props;

  let rows = [["Date", "Forecast Monthly Cost", "Forecast Cumulative"]];

  cashflow.forEach((row) => {
    rows.push([
      row.date,
      // Forecast
      commaWithNaN(Math.round(row.forecastMonthly)),
      commaWithNaN(Math.round(row.forecastCumulative)),
    ]);
  });

  const totalForecast = commaWithNaN(Math.round(total));

  rows.push(["Total", totalForecast, "", "", "", ""]);

  return (
    <div className="cashflow-save-button-container">
      <button
        className="cashflow-save-button"
        onClick={() => {
          generateXLSX("Cashflow Data", rows);
        }}
      >
        <i className="fas fa-download"></i>
      </button>
      <span className="tooltiptext">Export Table</span>
    </div>
  );
}
