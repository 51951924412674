import React, { useState, useEffect } from "react";
import { translate } from "../../utils/translation";

import ExportAccounts from "../export/ExportAccounts";

import "./AccountFilter.css";

export default function AccountFilter(props) {
  const { setFilters } = props;
  const { accounts } = props;

  // Advanced Search Inputs
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [regionCountryCity, setRegionCountryCity] = useState("");

  useEffect(() => {
    setFilters({
      name: name,
      surname: surname,
      jobTitle: jobTitle,
      regionCountryCity: regionCountryCity,
    });
  }, [setFilters, name, surname, jobTitle, regionCountryCity]);

  // Display on Account Control Only
  if (!window.location.href.includes("account-control")) {
    return null;
  }

  return (
    <div className="account-filters-container">
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Account Filters")}
      </h1>
      <PortfolioInputSingle
        placeholder={translate("First Name")}
        value={name}
        onChange={setName}
        icon={<i className="fas fa-pen-fancy"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Surname")}
        value={surname}
        onChange={setSurname}
        icon={<i className="fas fa-pen-fancy"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Job Title")}
        value={jobTitle}
        onChange={setJobTitle}
        icon={<i className="fas fa-user-graduate"></i>}
      />
      <PortfolioInputSingle
        placeholder={translate("Region / Country / City")}
        value={regionCountryCity}
        onChange={setRegionCountryCity}
        icon={<i className="fas fa-globe-asia"></i>}
      />
      <h1 className="display-4" style={{ fontSize: "20px" }}>
        {translate("Export Accounts")}
      </h1>
      <ExportAccounts accounts={accounts} />
    </div>
  );
}

export function PortfolioInputSingle(props) {
  const { value, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "account-filter-input-icon";
  if (focus || value !== "") iconType = "account-filter-input-icon-focus";

  return (
    <div className="account-filter-input-container">
      <div className={iconType}>{icon}</div>
      <div className="account-filter-input-col">
        {placeholder}
        <div className="account-filter-input-row">
          <input
            className="account-filter-search-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}
